import { pluralize } from "~/src/modules/formatters";
import { fundingLabels } from "~/src/modules/labels";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";
/* eslint-disable prefer-destructuring */
import { snakeCase } from "lodash-es";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const ProjectOverviewFunding = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    funding
  } = project;

  const fundingContent = [
    "notFunded",
    [
      "normalFunded",
      [
        "superFunded",
        "smartFunded",
        "smartSuperFunded",
        "wienerWohnbau"
      ]
    ],
    "gemeinde"
  ]
    .filter((category) => {
      let key = category;

      if (Array.isArray(category)) {
        ([key] = category);
      }

      const snakeCaseKey = snakeCase(key);

      return funding[snakeCaseKey];
    })
    .map((category) => {
      if (Array.isArray(category)) {
        const key = category[0];
        const snakeCaseKey = snakeCase(key);
        const name = fundingLabels[snakeCaseKey];
        const total = funding[`${snakeCaseKey}_total`];

        const subCategories = category[1]
          .filter((subCategoryKey) => funding[snakeCase(subCategoryKey)]);

        return (
          <li className="flex flex-col gap-0.5" key={key}>
            <span>{total} {pluralize("Einheit", "Einheiten", "Einheiten")} {name}</span>

            {
              subCategories.length > 0 && (
                <>
                  <span>davon:</span>

                  <ul className="flex flex-col gap-0.5 pl-4">
                    {
                      subCategories.map((subCategoryKey) => {
                        const snakeCaseSubCategoryKey = snakeCase(subCategoryKey);
                        const subCategoryName = fundingLabels[snakeCaseSubCategoryKey];
                        const subCategoryTotal = funding[`${snakeCaseSubCategoryKey}_total`];
                        const subCategoryString = subCategoryTotal
                          ? `${subCategoryTotal} ${pluralize("Einheit", "Einheiten", "Einheiten", subCategoryTotal)} ${subCategoryName}`
                          : `${subCategoryName} (Anzahl nicht bekannt)`;

                        return (
                          <li key={subCategoryKey}>
                            <span>{subCategoryString}</span>
                          </li>
                        );
                      })
                    }
                  </ul>
                </>
              )
            }

          </li>
        );
      }

      const key = category;
      const snakeCaseKey = snakeCase(key);
      const name = fundingLabels[snakeCaseKey];
      const total = funding[`${snakeCaseKey}_total`];
      const string = total
        ? `${total} ${pluralize("Einheit", "Einheiten", "Einheiten", total)} ${name}`
        : `${name} (Anzahl nicht bekannt)`;

      return (
        <li key={key}>
          <span>{string}</span>
        </li>
      );
    });

  return (
    <ContentSection id="funding" title="Förderungen">
      <ul className="flex flex-col gap-0.5">
        {(fundingContent.length > 0)
          ? fundingContent
          : "-"}
      </ul>
    </ContentSection>
  );
};

export default ProjectOverviewFunding;
