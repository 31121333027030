import { useLocation } from "react-router-dom";
import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @example
 */
export default function useRegions() {
  const location = useLocation();

  const searchParameters = new URLSearchParams(location.search);

  const userId = searchParameters.get("userId");

  const {
    data, error, mutate
  } = useSWR(`/regions${userId ? `?userId=${userId}` : ""}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    regions: data?.payload?.regions,
    states: data?.payload?.states
  };
}
