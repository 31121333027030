/* eslint-disable import/max-dependencies */
import {
  RadioGroup, Transition, TransitionChild
} from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { useState } from "react";

import GoogleMapsWrapper from "~/src/features/maps/google-maps.jsx";
import ProjectsMap from "~/src/features/maps/projects-map/index.jsx";

import useCount from "~/src/hooks/use-count.js";
import useMediaQuery from "~/src/hooks/use-media-query.js";
import useProjectList from "~/src/hooks/use-project-list.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";

import SelectAllProjectsFilter from "~/src/ui/filters/select-all-projects-filter/index.jsx";
import LocationSearchSelect from "~/src/ui/selects/location-search-select/index.jsx";

import LegendBox from "../legend-box/index.jsx";
import MapBBoxToggle from "../map-bbox-toggle.jsx";

/**
 *
 * @param props - The root object
 * @param props.projectSlugs - The root object
 * @param props.isSimple - The root object
 * @param props.handleCheckbox - The root object
 * @param props.automated - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
const ProjectsMapTile = ({
  automated = null,
  handleCheckbox,
  isSimple = false,
  projectSlugs = null
}) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const { query } = useProjectQueryParams();
  const {
    selectionStatus
  } = useProjectList({
    isSimple,
    query
  });

  const [mapType, setMapType] = useState("leaflet");

  const [searchLocation, setSearchLocation] = useState(null);

  const [isStreetView, setIsStreetView] = useState(false);
  const [legendOpen, setLegendOpen] = useState(false);

  const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  const selectionLoading = useStore((state) => state.selectionLoading);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);
  const setChange = useStore((state) => state.setChange);
  const miniListTileCollapsed = useStore((state) => state.miniListTileCollapsed);

  const {
    projects: {
      filtered: projectCountFiltered,
      selected: projectCountSelected
    } = {}
  } = useCount({ query });

  const updateSelection = (update) => {
    setChange(update);
  };

  const setAll = async (value) => {
    const newMainType = value ? "addAll" : "removeAll";

    setMainSelectionType(newMainType);

    await updateSelection({
      source: "map",
      type: newMainType
    });
  };

  const handleAllChecked = async (value) => {
    if (!selectionLoading) {
      const checkAll = selectionStatus === "some" && projectCountSelected > projectCountFiltered / 2
        ? false
        : value;

      await setAll(checkAll);
    }
  };

  return (
    <div className="relative flex size-full flex-col overflow-hidden border-gray-200 bg-white lg:rounded lg:border">
      {
        !isSimple && (
          <div className="top-0 z-[15] flex flex-col items-center justify-between gap-2 border-b border-gray-200 bg-white p-4 text-sm sm:flex-row sm:gap-4 lg:h-[62px] lg:flex-row">
            <div
              className={cn(
                "shrink-0",
                {
                  "opacity-0 pointer-events-none": !miniListTileCollapsed
                }
              )}
            >
              <SelectAllProjectsFilter disabled={selectionLoading} handleCheck={handleAllChecked} selectionStatus={currentSelectionStatus} />
            </div>

            <RadioGroup className="grid h-full cursor-pointer grid-cols-2 overflow-hidden rounded text-xs" onChange={setMapType} value={mapType}>
              <RadioGroup.Option value="leaflet">
                {({ checked }) => (
                  <span
                    className={cn(
                      "flex h-full items-center justify-center px-2 py-1",
                      {
                        "text-gray-900 bg-secondary-light": !checked,
                        "text-white bg-primary": checked
                      }
                    )}
                  >
                    Standard
                  </span>
                )}
              </RadioGroup.Option>

              <RadioGroup.Option value="google">
                {({ checked }) => (
                  <span
                    className={cn(
                      "flex h-full items-center justify-center px-2 py-1",
                      {
                        "text-gray-900 bg-secondary-light": !checked,
                        "text-white bg-primary-green": checked
                      }
                    )}
                  >
                    Street View
                  </span>
                )}
              </RadioGroup.Option>
            </RadioGroup>

            {
              isMobile
                ? <div/>
                : <MapBBoxToggle />
            }

          </div>
        )
      }

      {
        (isMobile && !isSimple && mapType === "leaflet") && (
          <>
            <button
              className="absolute bottom-16 right-4 z-20 rounded-full bg-secondary-light text-white"
              onClick={() => setLegendOpen(!legendOpen)}
            >
              <InformationCircleIcon className="size-12 text-primary" />
            </button>

            <Transition
              as="div"
              className="absolute bottom-0 z-20"
              show={legendOpen}
            >
              <TransitionChild
                as="div"
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
                show={legendOpen}
              >
                <div className="w-[calc(100vw-5rem)] max-w-[330px] rounded bg-secondary-light p-4 text-xs">
                  <LegendBox />

                  <button
                    className="absolute right-4 top-4"
                    onClick={() => setLegendOpen(false)}
                  >
                    <XMarkIcon className="size-4 text-gray-900" />
                  </button>
                </div>
              </TransitionChild>
            </Transition>
          </>

        )
      }

      <div
        className="relative h-full"
      >
        {
          (!isSimple && !isMobile) && (
            <LocationSearchSelect className="!absolute left-4 top-4 !z-50 hidden w-60 shrink-0 !text-sm lg:block" isMobile={isMobile} onSelect={setSearchLocation} title={searchLocation?.title} />
          )
        }

        {
          {
            google: (
              <GoogleMapsWrapper
                {...{
                  automated,
                  handleCheckbox,
                  isSimple,
                  projectSlugs,
                  searchLocation,
                  setIsStreetView
                }}
              />
            ),
            leaflet: (
              <ProjectsMap
                {...{
                  automated,
                  handleCheckbox,
                  isSimple,
                  projectSlugs,
                  searchLocation,
                  setIsStreetView
                }}
              />
            )
          }[mapType]
        }
      </div>

      {query.activeBBox && (
        <div
          className="pointer-events-none absolute inset-0 z-[1000] rounded border-4 border-primary"
        />
      )}

    </div>
  );
};

export default ProjectsMapTile;
