import { stringify } from "query-string";
import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @example
 */
export default function useInsolvencies({ query }) {
  const { data, error } = useSWR(query.type === "liegenschaft" ? `/insolvency-properties?${stringify(query, { arrayFormat: "comma" })}` : `/insolvencies?${stringify(query, { arrayFormat: "comma" })}`, fetcher);

  return {
    insolvencies: data?.payload?.insolvencies,
    isError: error,
    isLoading: !error && !data,
    pagination: data?.payload?.pagination,
    sortDirection: data?.payload?.sortDirection
  };
}
