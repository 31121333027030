import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.changeDisplayWithChild - The root object
 * @param props.sortByValue - The root object
 * @param props.small - The root object
 * @param props.name - The root object
 * @example
 */
export default function PieChart({
  changeDisplayWithChild = () => {},
  data = [],
  small = false,
  sortByValue = true,
  ...properties
}) {
  const [animatedData, setAnimatedData] = useState([]);

  const defineArcLabel = (data) => `${data.label}`;

  const commonProperties = (small)
    ? {
      borderColor: "#ffffff",
      borderWidth: 1,
      enableArcLabels: false,
      enableArcLinkLabels: false,
      innerRadius: 0.82,
      margin: {
        bottom: 165,
        left: 165,
        right: 145,
        top: 125
      }
    }
    : {
      activeInnerRadiusOffset: 0,
      activeOuterRadiusOffset: 7,
      arcLabelsSkipAngle: 10,
      arcLabelsTextColor: "#ffffff",
      arcLinkLabel: (datum) => (/ \| /u.test(datum.label)
        ? (
          <tspan>
            {datum.label.split(" | ").map((part, index) => <tspan dy={`${-0.75 + (index * 2)}em`} x="0">{part}</tspan>)}
          </tspan>
        )
        : datum.label),
      arcLinkLabelsColor: { from: "color" },
      arcLinkLabelsSkipAngle: 16,
      arcLinkLabelsTextColor: "#333333",
      arcLinkLabelsThickness: 2,
      borderColor: "#ffffff",
      borderWidth: 1,
      innerRadius: 0.5,
      margin: {
        bottom: 80,
        left: 100,
        right: 80,
        top: 40
      },
      theme: {
        labels: {
          text: {
            fontSize: 12
          }
        }
      }
    };

  const updatedProperties = {
    ...commonProperties,
    ...properties.props
  };

  useEffect(() => {
    // Delay setting the animated data to create the initial animation
    const timeoutId = setTimeout(() => setAnimatedData(data), 1);

    /**
     * Cleanup function
     *
     * @example
     */
    return () => clearTimeout(timeoutId);
  }, [data]);

  return (
    <ResponsivePie
      animate={true}
      arcLinkLabel={(data) => defineArcLabel(data)}
      colors={{ datum: "data.color" }}
      data={animatedData}
      motionConfig="slow"
      onClick={changeDisplayWithChild}
      sortByValue={sortByValue}
      {...updatedProperties}
      tooltip={(datum) => datum.datum.data.tooltip}
    />
  );
}

/*
      onMouseEnter = {() => {
        changeDisplayWithChild();
      }}
      onMouseLeave = {() => {
        changeDisplayWithChild();
      }}
*/
