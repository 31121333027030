/* eslint-disable import-x/max-dependencies */
/* eslint-disable max-lines-per-function */
/* eslint-disable import/max-dependencies */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import API from "~/src/modules/api.js";

import useAnalytics from "~/src/hooks/use-analytics.js";
import useCount from "~/src/hooks/use-count.js";
import useProjectList from "~/src/hooks/use-project-list.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useProjectsDetailStats from "~/src/hooks/use-projects-detail-stats.js";
import useProjectsStats from "~/src/hooks/use-projects-stats.js";
import useUserSettings from "~/src/hooks/use-user-settings.js";

import ExcelIcon from "~/src/ui/icons/excel-icon/index.jsx";

import ExportLimitModal from "../export-limit-modal.jsx";

import { handleExcelExport } from "./helpers.js";

const MAX_EXPORT_SIZE = 25;

/**
 *
 * @example
 */
const ExcelExportProjectsButton = () => {
  const location = useLocation();

  const swrOptions = {
    revalidateOnFocus: false
  };

  const [shouldFetch, setShouldFetch] = useState(false);
  const [mutated, setMutated] = useState(false);

  const [exporting, setExporting] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const { userSettings } = useUserSettings();
  const { query } = useProjectQueryParams();

  const { mutate: mutateCounts, ...counts } = useCount({
    query,
    shouldFetch
  }, swrOptions);

  const { addAnalytics } = useAnalytics();

  const { mutate: mutateStats, stats } = useProjectsStats({
    filtersetting: userSettings?.filtersetting,
    query,
    shouldFetch
  }, swrOptions);

  const { mutate: mutateProjectListForExport, projects } = useProjectList(
    {
      query: {
        ...query,
        forExport: true,
        page: location.pathname === "/liste" ? query.page : 1
      },
      shouldFetch
    },
    swrOptions
  );

  const {
    detailStats,
    isLoadingDetailStats,
    mutate: mutateDetailStats
  } = useProjectsDetailStats({
    query,
    shouldFetch
  }, swrOptions);

  const handleClick = async () => {
    const { data: { exportProjects } } = await API.get("/permissions");

    if (exportProjects) {
      setExporting(true);
      setShouldFetch(true);

      addAnalytics({
        action: "export-projects-excel",
        data: {
          userSettings
        }
      });
    }
    else {
      setIsOpen(true);
    }
  };

  useEffect(
    () => {
      if (shouldFetch) {
        const mutating = async () => {
          await mutateProjectListForExport();
          await mutateCounts();
          await mutateStats();
          await mutateDetailStats();

          setMutated(true);
        };

        mutating();
      }
    },
    [shouldFetch]
  );

  useEffect(() => {
    if (mutated) {
      if (!shouldFetch || !stats || !counts) {
        return;
      }

      setShouldFetch(false);
      const projectIds = projects.map((project) => project.id);

      API
        .post("/units", projectIds)
        .then(
          ({ data: units }) => handleExcelExport(userSettings, projects, units, stats, counts, detailStats)
        );

      // TODO: return destroy function to cancel tasks

      setMutated(false);

      setExporting(false);
    }
  }, [mutated]);

  return (
    <>
      <button
        className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-excel-green p-1 hover:bg-excel-green-dark disabled:cursor-wait disabled:grayscale"
        data-tooltip-content="Excel-Export: bis zu 25 Projekte"
        data-tooltip-id="tooltip-excel-export"
        disabled={exporting}
        onClick={handleClick}
        type="button"
      >
        <ExcelIcon />
      </button>

      <Tooltip
        className="z-50"
        delayShow={500}
        id="tooltip-excel-export"
        place="bottom"
      />

      <ExportLimitModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ExcelExportProjectsButton;
