import { useRef } from "react";

import SearchFilterContainer from "~/src/features/search-filter-modals/container";
import TransactionSearchFilter from "~/src/features/search-filter-modals/forms/transaction-search-filter";

/**
 *
 * @param root0 - The root object
 * @param root0.isActive - The root object
 * @param root0.hide - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @param root0.submit - The root object
 * @param root0.cancelSearchFilter - The root object
 * @example
 */
export default function TransactionModal({
  cancelSearchFilter, hide, isActive, query, submit, tempQuery, update
}) {
  const cancelButtonReference = useRef();

  /**
   *
   * @example
   */
  function handleSubmit() {
    submit();
    hide();
  }

  /**
   *
   * @example
   */
  function handleCancel() {
    cancelSearchFilter();
    hide();
  }

  return (
    <SearchFilterContainer
      cancelButtonRef={cancelButtonReference}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      isActive={isActive}
    >
      <TransactionSearchFilter query={query.liegenschaft} tempQuery={tempQuery.liegenschaft} update={update} />
    </SearchFilterContainer>
  );
}
