/* eslint-disable no-underscore-dangle */

import {
  DomEvent,
  DomUtil
} from "leaflet";

// don't switch to arrow functions, because of leaflet's rotten class system

/**
 *
 * @this {object}
 * @example
 */
const initLayout = function () {
  const prefix = "leaflet-popup";

  this._container = DomUtil.create("div", `${prefix} ${this.options.className || ""} leaflet-zoom-animated`);

  this._tipContainer = DomUtil.create("div", `${prefix}-tip-container`, this._container);
  this._tip = DomUtil.create("div", `${prefix}-tip`, this._tipContainer);
  this._wrapper = DomUtil.create("div", `${prefix}-content-wrapper`, this._container);

  this._contentNode = DomUtil.create("div", `${prefix}-content`, this._wrapper);

  DomEvent.disableClickPropagation(this._container);
  DomEvent.disableScrollPropagation(this._contentNode);
  DomEvent.on(this._container, "contextmenu", DomEvent.stopPropagation);

  if (this.options.closeButton) {
    this._closeButton = DomUtil.create("a", `${prefix}-close-button`, this._container);

    this._closeButton.setAttribute("role", "button");
    this._closeButton.setAttribute("aria-label", this.options.closeButtonLabel);

    this._closeButton.href = "#close";
    this._closeButton.innerHTML = "<span aria-hidden=\"true\">&#215;</span>";

    DomEvent.on(this._closeButton, "click", (event_) => {
      DomEvent.preventDefault(event_);
      this.close();
    }, this);
  }
};

export default initLayout;
