import { Route, Switch } from "react-router-dom";

import BuyerStatistics from "~/src/pages/projects/[id]/statistics/project-statistics-buyers-page";
import DetailStatistics from "~/src/pages/projects/[id]/statistics/project-statistics-details-page";
import RoomStatistics from "~/src/pages/projects/[id]/statistics/project-statistics-rooms-page";

/**
 * Contains the different routes, which are rendered based on the selection on the project-statistics-header.
 *
 * @returns {React.ReactElement} Returns an element to switch between the different routes and render the corresponding statistics
 * @example
 */
export default function StatisticsTabs() {
  return (
    <Switch>
      <Route
        exact
        path={["/projekte/:id/statistiken", "/projekte/:id/statistiken/preise"]}
      >
        <DetailStatistics />
      </Route>

      <Route exact path="/projekte/:id/statistiken/zielgruppen">
        <BuyerStatistics />
      </Route>

      <Route exact path="/projekte/:id/statistiken/zimmer">
        <RoomStatistics />
      </Route>

    </Switch>
  );
}
