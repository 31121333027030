import { pluralize } from "~/src/modules/formatters";
import { unitCategoryLabels } from "~/src/modules/labels";

import AreaRange from "~/src/ui/displays/area-range";

/**
 *
 * @param props - The root object
 * @param props.quantity - The root object
 * @param props.quantity.category - The root object
 * @param props.quantity.category.code - The root object
 * @param props.quantity.total_area_range_from - The root object
 * @param props.quantity.total_area_range_to - The root object
 * @param props.quantity.total_units - The root object
 * @example
 */
export default function ProjectQuantity({
  quantity: {
    category: {
      code: categoryCode
    },
    total_area_range_from: area,
    total_area_range_to: toArea,
    total_units: total
  }
}) {
  return (
    <>
      <dt className="flex gap-1">
        {total && <span className="font-bold">{total}</span>}

        <span>{pluralize(unitCategoryLabels[categoryCode].singular, unitCategoryLabels[categoryCode].plural, unitCategoryLabels[categoryCode].plural, total)}</span>
      </dt>

      <dd>
        {
          area && (
            <AreaRange
              {...{
                area,
                toArea
              }}
            />
          )
        }
      </dd>
    </>
  );
}
