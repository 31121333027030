import { marketingPhaseLabels } from "~/src/modules/labels";
import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function MarketingPhaseSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const hasActive = items.includes("marketing_phase_active");
  const hasComingSoon = items.includes("marketing_phase_coming_soon");
  const hasProjectFinished = items.includes("marketing_phase_project_finished");
  const hasUnknown = items.includes("unknown");

  const hasTemporaryActive = temporaryItems.includes("marketing_phase_active");
  const hasTemporaryComingSoon = temporaryItems.includes("marketing_phase_coming_soon");
  const hasTemporaryProjectFinished = temporaryItems.includes("marketing_phase_project_finished");
  const hasTemporaryUnknown = temporaryItems.includes("unknown");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("marketing_phase", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Vermarktungsphase
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasComingSoon, hasTemporaryComingSoon)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "marketing_phase_coming_soon")} type="checkbox" />

            <span className="ml-3">{marketingPhaseLabels.marketing_phase_coming_soon}</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasActive, hasTemporaryActive)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "marketing_phase_active")} type="checkbox" />

            <span className="ml-3">{marketingPhaseLabels.marketing_phase_active}</span>
          </label>
        </div>

        {/* <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasCompletedApartments, hasTempCompletedApartments)} onChange={(e) => onCheck(e, "marketing_phase_completed_apartments")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Wohneinheiten vermarktet</span>
          </label>
        </div> */}
        {/* <div>
          <label className="inline-flex items-center cursor-pointer">
            <input checked={checked(hasCompletedAll, hasTempCompletedAll)} onChange={(e) => onCheck(e, "marketing_phase_completed_all")} type="checkbox" className="w-6 h-6 rounded border-gray-400 border text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" />
            <span className="ml-3">Alle Einheiten vermarktet</span>
          </label>
        </div> */}
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasProjectFinished, hasTemporaryProjectFinished)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "marketing_phase_project_finished")} type="checkbox" />

            <span className="ml-3">{marketingPhaseLabels.marketing_phase_project_finished}</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasUnknown, hasTemporaryUnknown)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "unknown")} type="checkbox" />

            <span className="ml-3">{marketingPhaseLabels.unknown}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
