import useMediaQuery from "~/src/hooks/use-media-query.js";

import CloseIcon from "~/src/ui/icons/close-icon/index.jsx";

/**
 *
 * @param root0 - The root object
 * @param root0.label - The root object
 * @param root0.slug - The root object
 * @param root0.handleClick - The root object
 * @example
 */
export default function CompanyFilterTag({
  handleClick, label, slug
}) {
  const isPrint = useMediaQuery("print");

  if (!label || !slug) {
    return null;
  }

  return (
    <button
      className="flex items-center gap-x-1 rounded bg-gray-100 px-1.5 py-0.5 text-xs text-secondary-darkest shadow hover:bg-gray-200 focus:outline-none print:border print:border-gray-200 print:bg-white print:shadow-none"
      onClick={handleClick}
      type="button"
    >
      <span>Unternehmen: {label}</span>

      {!isPrint && <CloseIcon className="-mr-0.5 size-4 text-secondary-darkest" />}
    </button>
  );
}
