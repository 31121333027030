import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

import { generatePageItems, generatePageItemsMobile } from "./pagination-helper";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 *
 * @param props - The root object
 * @param props.children - The root object
 * @param props.pagination - The root object
 * @param props.page - The root object
 * @param props.onPageChange - The root object
 * @example
 */
export default function Pagination({
  children, onPageChange, page, pagination
}) {
  if (!pagination) {
    return null;
  }

  /**
   *
   * @param pageItem
   * @example
   */
  function handlePageClick(pageItem) {
    if ((page === undefined && pageItem === 1) || page !== pageItem) {
      onPageChange(pageItem);
    }
  }

  /**
   *
   * @example
   */
  function handleClickBack() {
    onPageChange(page - 1);
  }

  /**
   *
   * @example
   */
  function handleClickNext() {
    onPageChange(page ? Number.parseInt(page) + 1 : 2);
  }

  if (pagination?.totalRecords === 0) {
    return null;
  }

  return (
    <nav className="flex items-center justify-between">

      {children}

      <div className="hidden flex-1 flex-wrap justify-center space-x-1 sm:flex xl:justify-end ">

        {generatePageItems(pagination?.totalPages, page ? Number.parseInt(page) : 1).map((pageItem) => {
          if (pageItem === LEFT_PAGE) {
            return (
              <button className={cn("flex justify-center items-center w-10 h-8  bg-white border border-gray-200 rounded-sm focus:outline-none")} key={`page_item_${pageItem}`} onClick={handleClickBack} type="button">
                <ChevronDoubleLeftIcon className="size-4 text-gray-800" />
              </button>
            );
          }

          if (pageItem === RIGHT_PAGE) {
            return (
              <button className={cn("flex justify-center items-center w-10 h-8 bg-white rounded-sm border border-gray-200 focus:outline-none")} key={`page_item_${pageItem}`} onClick={handleClickNext} type="button">
                <ChevronDoubleRightIcon className="size-4 text-gray-800" />
              </button>
            );
          }

          const isCurrentPage = ([null, undefined].includes(page) && pageItem === 1) || Number.parseInt(page) === pageItem;

          return (
            <button
              key={`page_item_${pageItem}`}
              onClick={() => handlePageClick(pageItem)}
              type="button"
              className={cn("flex justify-center items-center w-10 h-8 rounded-sm border border-gray-200 focus:outline-none", {
                "bg-primary hover:bg-primary text-white cursor-default": isCurrentPage,
                "bg-white hover:bg-gray-50": !isCurrentPage
              })}
            >
              {pageItem}
            </button>
          );
        })}

      </div>

      <div className="flex flex-1 flex-wrap justify-center space-x-1 sm:hidden xl:justify-end ">

        {generatePageItemsMobile(pagination?.totalPages, page ? Number.parseInt(page) : 1).map((pageItem) => {
          if (pageItem === LEFT_PAGE) {
            return (
              <button className={cn("flex justify-center items-center w-10 h-8  bg-white border border-gray-200 rounded-sm focus:outline-none")} key={`page_item_${pageItem}`} onClick={handleClickBack} type="button">
                <ChevronDoubleLeftIcon className="size-4 text-gray-800" />
              </button>
            );
          }

          if (pageItem === RIGHT_PAGE) {
            return (
              <button className={cn("flex justify-center items-center w-10 h-8 bg-white rounded-sm border border-gray-200 focus:outline-none")} key={`page_item_${pageItem}`} onClick={handleClickNext} type="button">
                <ChevronDoubleRightIcon className="size-4 text-gray-800" />
              </button>
            );
          }

          const isCurrentPage = ([null, undefined].includes(page) && pageItem === 1) || Number.parseInt(page) === pageItem;

          return (
            <button
              key={`page_item_${pageItem}`}
              onClick={() => handlePageClick(pageItem)}
              type="button"
              className={cn("flex justify-center items-center w-10 h-8 rounded-sm border border-gray-200 focus:outline-none", {
                "bg-primary hover:bg-primary text-white cursor-default": isCurrentPage,
                "bg-white hover:bg-gray-50": !isCurrentPage
              })}
            >
              {pageItem}
            </button>
          );
        })}

      </div>

    </nav>
  );
}
