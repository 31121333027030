import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.setShowStats - The root object
 * @param root0.setShowMap - The root object
 * @example
 */
export default function ToggleViewButton({ setShowMap, setShowStats }) {
  return (
    <span className="relative z-0 inline-flex rounded-md shadow-sm">
      <button
        className={cn("relative w-24 inline-flex justify-center items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0")}
        onClick={() => setShowStats(true)}
        type="button"
      >
        Statistik
      </button>

      <button
        className={cn("-ml-px w-24 relative inline-flex justify-center items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-0")}
        onClick={() => setShowMap(true)}
        type="button"
      >
        Karte
      </button>
    </span>
  );
}
