import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0
 * @param root0.isPrint
 * @param root0.userId
 * @param isPrint
 * @param userId
 * @example
 */
export default function useUserSettings(isPrint = false, userId) {
  if (isPrint && userId) {
    const {
      data, error, mutate
    } = useSWR(() => `/user-settings?userId=${userId}`, fetcher);

    return {
      isError: error,
      isLoading: !error && !data,
      mutate,
      userSettings: data?.payload?.settings
    };
  }

  const {
    data, error, mutate
  } = useSWR(() => "/user-settings", fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    userSettings: data?.payload?.settings
  };
}
