import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param {object} options - The root object
 * @param {string} options.projectId - Project slug, unique project identifier.
 * @param {string[]} options.types - Types of statistics to query for. Specifying multiple types
 * will return a combined result, and in the case of `structure` being `composition`, the
 * result will be a nested object, where each category has a `sub` property with the subcategories.
 * The order of the types specified will determine the "hierarchy" of these nested objects.
 * @param {"composition"|"correlation"} options.structure - Structure of the statistics.
 * @returns {object} The payload from the request.
 * @example
 * const { statistics, isLoading, isError, mutate } = useProjectStatistics({
 *  projectId: "my-project",
 *  types: ["units-buyers-gender", "units-buyers-age"],
 *  structure: "composition"
 * });
 */

/**
 *
 * @param options0 - The root object
 * @param options0.projectId - The root object
 * @param options0.structure - The root object
 * @param options0.types - The root object
 * @example
 */
export default function useProjectStatistics({
  projectId,
  structure,
  types
}) {
  const parameters = new URLSearchParams({
    structure,
    types
  });

  const {
    data,
    error,
    mutate
  } = useSWR(`/projects/${projectId}/statistics?${parameters}`, fetcher);

  return {
    isError: error || data === "",
    isLoading: !error && !data,
    mutate,
    statistics: data?.payload?.statistics
  };
}
