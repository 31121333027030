import { snakeCase, upperFirst } from "lodash-es";

import { roundToDecimals } from "../_common/export.js";

/**
 * Highly optimized for performance, please don't change general structure around the reduce method.
 * Basically, only loop projects once and calculate all stats in one go.
 *
 * @param {object} options - Options object.
 * @param {object[]} options.projects - Array of projects.
 * @returns {object} - Calculated stats.
 * @example
 */
const calculateStats = ({ projects }) => {
  const priceStatsKeys = [
    "offerPriceEigennutzer",
    "offerPriceInvestor",
    "salePriceEigennutzer",
    "salePriceInvestor"
  ].map((key) => [key, `avg${upperFirst(key)}`].map((string) => [snakeCase(string), string]));

  const aggregateStatsKeys = [
    "gka",
    "rent",
    ...priceStatsKeys.map(([[key, camelCaseKey]]) => camelCaseKey)
  ];

  const stats = {
    ...projects
      .reduce(
        // eslint-disable-next-line max-statements
        (currentStats, project) => {
          const projectCount = currentStats.projectCount + 1;

          const unitCount = currentStats.unitCount + Number(project.count_wohnungen || 0);

          const newStats = currentStats;

          newStats.projectCount = projectCount;
          newStats.unitCount = unitCount;

          if (project.show_gka && project.gka) {
            newStats.gka = currentStats.gka + Number(project.gka || 0);
            newStats.gkaCount = currentStats.gkaCount + 1;
          }

          if (project.avg_miete) {
            newStats.rent = currentStats.rent + (Number(project.avg_miete || 0) * Number(project.avg_miete_count || 0));
            newStats.rentCount = currentStats.rentCount + 1;
            newStats.rentCountSum = currentStats.rentCountSum + Number(project.avg_miete_count || 0);
          }

          const applicablePriceStatsKeys = priceStatsKeys
            .filter(([keys, [averageKey]]) => project[averageKey]);

          for (const [[key, camelCaseKey], [averageKey]] of applicablePriceStatsKeys) {
            const countKey = `${camelCaseKey}Count`;
            const countSumKey = `${camelCaseKey}CountSum`;

            const currentCount = currentStats[countKey] + 1;
            const currentSum = currentStats[camelCaseKey] + (Number(project[averageKey] || 0) * Number(project[`${averageKey}_count`] || 0));
            const currentCountSum = currentStats[countSumKey] + Number(project[`${averageKey}_count`] || 0);

            newStats[camelCaseKey] = currentSum;
            newStats[countKey] = currentCount;
            newStats[countSumKey] = currentCountSum;
          }

          return newStats;
        },
        {
          ...Object.fromEntries(
            aggregateStatsKeys.flatMap((key) => [
              key,
              `${key}Count`,
              `${key}CountSum`
            ])

              .map((key) => [key, 0])
          )
        }
      )
  };

  for (const key of aggregateStatsKeys) {
    const countKey = `${key}Count`;
    const countSumKey = `${key}CountSum`;
    const newAverageKey = `${key}Average`;

    stats[newAverageKey] = roundToDecimals(stats[key] / stats[key === "gka" ? countKey : countSumKey], 2);
    stats[key] = roundToDecimals(stats[key], 2);
  }

  return stats;
};

export default calculateStats;
