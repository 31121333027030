import cn from "classnames";
import { useEffect, useState } from "react";

import { formatMonth } from "~/src/modules/formatters";

import ShareDealsTransactionSlide from "~/src/features/share-deal-transaction-slide";

import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0 - The root object
 * @param root0.isLoading - The root object
 * @param root0.transactions - The root object
 * @param root0.loadingItems - The root object
 * @param root0.query - The root object
 * @example
 */
export default function ShareDealsTransactionList({
  isLoading, loadingItems = 10, query, transactions = []
}) {
  const { trackPageView } = useTracking();
  const [showShareDealDetails, setShowShareDealDetails] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(false);
  const [activeColumn, setActiveColumn] = useState("date");

  /**
   *
   * @param shareDeal
   * @example
   */
  function handleShareDealClick(shareDeal) {
    setActiveTransaction(shareDeal);
    setShowShareDealDetails(true);
    trackPageView(shareDeal.doc_id, "ShareDeal");
  }

  /**
   *
   * @example
   */
  function hideShareDealDetails() {
    setShowShareDealDetails(false);
  }

  useEffect(() => {
    if (query.sortByTransaction) {
      setActiveColumn(query.sortByTransaction);
    }
  }, [query.sortByTransaction]);

  return (
    <div>
      <ShareDealsTransactionSlide active={showShareDealDetails} hide={hideShareDealDetails} transaction={activeTransaction} />

      <div className="mb-4 ml-4 flex flex-col sm:ml-0">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" id="scrollable-table">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 shadow-sm sm:rounded">

              <div className="min-w-full divide-y divide-gray-200">

                <div className="flex grid-cols-12 items-center bg-gray-100 lg:grid">
                  <div className="col-span-1 w-20 px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Datum
                  </div>

                  <div className="col-span-4 w-64 whitespace-nowrap px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Firmenname
                  </div>

                  <div className="col-span-3 w-64 whitespace-nowrap px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Firmenadresse
                  </div>

                  <div className="col-span-2 w-48 px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Änderung
                  </div>

                  <div className="col-span-2 w-48 whitespace-nowrap px-4 py-3 text-right text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Liegenschaften
                  </div>
                </div>

                <div className="divide-y divide-gray-200 bg-white">
                  {isLoading &&
                    [...new Array(loadingItems)].map((e, index) => (
                      <div className="flex h-20 cursor-pointer grid-cols-12 items-center hover:bg-gray-50 lg:grid" key={`share_deal_transactions_skeleton_${index}`}>
                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-4 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-56 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-1 flex h-12 w-48 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>
                      </div>
                    ))}

                  {!isLoading && transactions?.length === 0 && (
                    <div className="bg-white">
                      <p className="whitespace-nowrap p-4 text-center font-light text-gray-900">keine Personen- und Anteilsänderungen für die ausgewählten Kriterien gefunden</p>
                    </div>
                  )}

                  {transactions?.map((transaction) => (
                    <div className="flex cursor-pointer grid-cols-12 items-center hover:bg-gray-50 lg:grid" key={transaction.id} onClick={() => handleShareDealClick(transaction)}>

                      <div
                        className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap text-sm", {
                          "text-gray-900 font-medium": activeColumn !== "date",
                          "text-primary font-bold": activeColumn === "date"
                        })}
                      >
                        {formatMonth(transaction.change_date)}
                      </div>

                      <div className="col-span-4 w-64 truncate whitespace-nowrap p-4 text-sm text-gray-600 lg:w-auto">
                        <span
                          className={cn("", {
                            "text-primary font-bold": activeColumn === "company"
                          })}
                        >
                          {transaction.company_name}
                        </span>

                        <br />

                        <span
                          className={cn("", {
                            "text-primary font-bold": activeColumn === "fbnr"
                          })}
                        >
                          {transaction.company_fbnr}
                        </span>
                      </div>

                      <div className="col-span-3 w-64 truncate whitespace-nowrap p-4 text-sm text-gray-600 lg:w-auto">{transaction.company_zipcode} {transaction.company_city}, {transaction.company_street}</div>

                      <div className="col-span-3 w-56 whitespace-nowrap p-4 text-sm text-gray-600 lg:w-auto">{transaction.category?.name}</div>

                      <div className="col-span-1 w-48 whitespace-nowrap p-4 text-center text-sm text-gray-600 lg:w-auto">
                        <span
                          className={cn("", {
                            "text-primary font-bold": activeColumn === "propertyCount"
                          })}
                        >
                          {transaction.propertyCount}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
