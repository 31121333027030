/**
 *
 * @param root0 - The root object
 * @param root0.children - The root object
 * @example
 */
export default function ContentContainer({ children }) {
  return (
    <div className="mt-4 border border-gray-200 bg-white first:mt-0 sm:rounded">
      <div className="px-4 py-5 sm:px-6">
        {children}
      </div>

    </div>
  );
}
