const selectByKeys = (keyValues, keysToSelect) => Object.fromEntries(keysToSelect.map(
  (key) => [key, keyValues[key]]
));

const replaceKeyNames = (keyValues, replaceFunction) => {
  const entries = Object.entries(keyValues);

  return Object.fromEntries(entries.map(
    ([keyName, value]) => [replaceFunction(keyName), value]
  ));
};

const someElementOfArrayIncludes = (array, valueToMatch) => (
  array.some((element) => element.includes(valueToMatch))
);

const extendCartesianProduct = (product, newProjection) => product.flatMap((tuple) => newProjection.map((newCoordinate) => [...tuple, newCoordinate]));

const cartesianProduct = (...projections) => projections.reduce(extendCartesianProduct, [[]]);

const permuteArrayIndex = (array, permutation) => permutation
  .map((index) => array[index]);

// objectMap({a:1,b:2},([key:value])=>[key,value**2])

/**
 *  result: {a:1,b:4}
 *
 * @param object
 * @param mapping
 * @example
 */
const objectMap = (object, mapping) => Object.fromEntries(
  Object.entries(object).map(mapping)
);

// operator[OPERATOR] converts syntax `OPERATOR x` such as `!x` to
//   function syntax `f(x)`.
// example: operator["!"](x) ⇨ !x
const operator = {
  "!": (x) => !x,
  "&&": (x, y) => x && y,
  "||": (x, y) => x || y
};

const constantValueFunction = (constant) => (x) => constant;

const identityFunction = (x) => x;

const nullFunction = constantValueFunction(null);

const addElementUniq = (
  array,
  element,
  unqualifiedValues = [
    null,
    undefined,
    ""
  ]
) => {
  const unqualifiedOrInArray =
    unqualifiedValues.includes(element) || array.includes(element);

  if (unqualifiedOrInArray) {
    return [...array];
  }

  return [...array, element];
};

export {
  addElementUniq,
  cartesianProduct,
  identityFunction as identityFn,
  nullFunction as nullFn,
  objectMap,
  operator,
  permuteArrayIndex,
  replaceKeyNames,
  selectByKeys,
  someElementOfArrayIncludes
};
