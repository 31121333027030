import ProjectOverviewCompanyRoles from "~/src/features/project-overview/company-roles.jsx";
import ProjectOverviewConstructionInfos from "~/src/features/project-overview/construction-infos.jsx";
import ProjectOverviewConstructionTypes from "~/src/features/project-overview/construction-types.jsx";
import ProjectOverviewDescription from "~/src/features/project-overview/description.jsx";
import ProjectOverviewDocuments from "~/src/features/project-overview/documents.jsx";
import ProjectOverviewEnergyClasses from "~/src/features/project-overview/energy-classes.jsx";
import ProjectESGCertificates from "~/src/features/project-overview/esg-certificates.jsx";
import ProjectOverviewFunding from "~/src/features/project-overview/funding.jsx";
import ProjectOverviewMap from "~/src/features/project-overview/map.jsx";
import ProjectOverviewNotes from "~/src/features/project-overview/notes.jsx";
import ProjectOverviewProperties from "~/src/features/project-overview/properties.jsx";
import ProjectOverviewQuantities from "~/src/features/project-overview/quantities.jsx";
import ProjectOverviewStats from "~/src/features/project-overview/stats.jsx";
import ProjectOverviewTransactions from "~/src/features/project-overview/transactions.jsx";
import ProjectOverviewZonings from "~/src/features/project-overview/zonings.jsx";

import useProject from "~/src/hooks/use-project.js";

import ContentContainer from "~/src/ui/containers/content-container/index.new.jsx";
/* eslint-disable import/max-dependencies */

import cn from "classnames";
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
export default function ProjectInfos() {
  const { id } = useParams();
  const {
    isError, isLoading, project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    automated,
    image_file_name: imageFileName,
    image_landscape: imageLandscape,
    name
  } = project;

  return (
    <main className="flex w-full flex-col gap-4">
      <ContentContainer>
        <ProjectOverviewStats />
      </ContentContainer>

      <ContentContainer>
        <ProjectOverviewMap />
      </ContentContainer>

      <ContentContainer
        image={
          !automated && (
            <div
              className={cn("relative w-64 xl:w-48 2xl:w-64 max-w-[calc(100vw-66px)] pb-2/3 rounded overflow-hidden", {
                "h-40 xl:h-32 2xl:h-40": imageLandscape,
                "h-64 xl:h-64 2xl:h-64": !imageLandscape
              })}
            >
              {(imageFileName) &&
                <img alt={name} className="absolute size-full object-cover transition duration-500" src={`https://immodeveloper.fra1.digitaloceanspaces.com/${imageFileName}`} />}
            </div>
          )
        }
      >
        <ProjectOverviewQuantities />

        <ProjectOverviewConstructionInfos />

        {
          !automated && (
            <>
              <ProjectOverviewFunding />

              <ProjectOverviewConstructionTypes />
            </>
          )
        }

      </ContentContainer>

      {
        !automated && (
          <>
            <ContentContainer>
              <ProjectOverviewCompanyRoles />
            </ContentContainer>

            <ContentContainer>
              <div className="flex w-full flex-col justify-between gap-6 lg:flex-row lg:gap-x-4">
                <div className="flex min-w-[60%] flex-col lg:max-w-[60%]">
                  <ProjectOverviewProperties />
                </div>

                <ProjectOverviewZonings />
              </div>
            </ContentContainer>

            <ContentContainer>
              <ProjectOverviewTransactions historic={false} />

              <ProjectOverviewTransactions historic={true} />
            </ContentContainer>

            <ContentContainer>
              <div className="xl:w-3/5">
                <ProjectESGCertificates />
              </div>
            </ContentContainer>

            <ContentContainer>
              <ProjectOverviewEnergyClasses />
            </ContentContainer>

            <ContentContainer>
              <div className="flex w-full flex-col justify-between gap-6 lg:flex-row lg:gap-x-4">
                <div className="flex min-w-[60%] flex-col gap-y-6 lg:max-w-[60%]">
                  <ProjectOverviewDescription />

                  <ProjectOverviewNotes />
                </div>

                <div className="w-full">
                  <ProjectOverviewDocuments />
                </div>

              </div>

            </ContentContainer>
          </>
        )
      }

    </main>
  );
}
