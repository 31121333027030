import ProjectsFiltersTile from "~/src/features/projects-filters-tile/index.jsx";
import ProjectsMiniListTile from "~/src/features/projects-mini-list-tile/index.jsx";
import DetailStatistics from "~/src/features/projects-statistic-tile/chart.jsx";
import StatsBox from "~/src/features/stats-box/index.jsx";

import Page from "~/src/ui/page/index.jsx";

/**
 *
 * @example
 */
const StatisticsPage = () => (
  <Page title="Statistik">
    <div className="flex h-[calc(100%-7rem)] max-h-[calc(100%-7rem)] gap-4 pb-28 lg:h-[calc(100vh-3rem)] lg:max-h-[calc(100vh-3rem)] lg:overflow-hidden lg:p-4">
      <ProjectsFiltersTile />

      <div className="flex w-full flex-col justify-between gap-4">
        <div className="z-20 @container">
          <StatsBox />
        </div>

        <div className="z-10 flex h-full flex-col gap-1 pb-14 lg:overflow-hidden lg:pb-0">
          <DetailStatistics />
        </div>
      </div>

      <ProjectsMiniListTile />
    </div>
  </Page>
);

export default StatisticsPage;
