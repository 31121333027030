/* eslint-disable id-length */

/* eslint-disable max-statements */
/* eslint-disable import/exports-last */

const removeEmptyArrayParameterFromURL = function (q) {
  for (const [key, value] of Object.entries(q)) {
    if (Array.isArray(value) && value.length === 0) {
      q[key] = undefined;
    }
  }
};

/**
 *
 * @param query
 * @param changedFilter
 * @param value
 * @example
 */
export const updateQuery = function (query, changedFilter, value) {
  const q = {
    ...query,
    page: 1
  };

  if (q.sort === "radius") {
    delete q.sort;
    delete q.direction;
  }

  if (value) {
    if (changedFilter === "company") {
      q.company = value.value;
      q.companyLabel = value.label;
    }
    else if (changedFilter === "street") {
      q.streetLabel = value.label;
      q.locationType = value.locationType;

      if (q.locationType === "street_address") {
        q.street = value.value;
        q.sort = "radius";
        q.direction = "asc";
      }

      if ([
        "city",
        "city_plz",
        "plz",
        "street"
      ].includes(q.locationType)) {
        q.locationId = value.locationId;
        delete q.radius;
        delete q.street;
      }
    }
    else {
      const changedKey = changedFilter;

      q[changedKey] = value;
    }
  }
  else {
    const changedKey = changedFilter;

    delete q[changedKey];

    if (changedFilter === "company") {
      delete q.companyLabel;
    }
    if (changedFilter === "street") {
      delete q.streetLabel;
      delete q.locationType;
      delete q.street;
      delete q.locationId;
      if (q.sort === "radius") {
        delete q.sort;
      }
    }
  }

  if (changedFilter !== "page") {
    q.page = 1;
  }

  removeEmptyArrayParameterFromURL(q);

  return q;
};

/**
 *
 * @param query
 * @example
 */
export const deleteFilterQueries = function (query) {
  const {
    activeBBox, bBox, centerLat, centerLng, direction, mapZoom, marked, sort, stats
  } = query;

  const q = {
    activeBBox,
    bBox,
    centerLat,
    centerLng,
    direction,
    mapZoom,
    marked,
    page: 1,
    sort,
    stats
  };

  if (sort === "radius") {
    delete q.sort;
  }

  return q;
};

/**
 *
 * @param query
 * @param tempQuery
 * @param temporaryQuery
 * @example
 */
export const updateQueryFromTemp = function (query, temporaryQuery) {
  const q = {
    ...query,
    page: 1
  };

  for (const key of Object.keys(temporaryQuery)) {
    const item = temporaryQuery[key];

    if (!item) {
      q[key] = undefined;

      continue;
    }

    if (key === "company") {
      q.company = item.value;
      q.companyLabel = item.label;
    }
    else if (key === "street") {
      q.street = item.value;
      q.streetLabel = item.label;
    }
    else {
      q[key] = item;
    }
  }

  removeEmptyArrayParameterFromURL(q);

  return q;
};

/**
 *
 * @param query
 * @example
 */
export const getQueryObject = function (query) {
  const searchParameters = new URLSearchParams(query);

  const q = {};

  for (const key of searchParameters.keys()) {
    q[key] = searchParameters.get(key);
  }

  return q;
};
