import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
// eslint-disable-next-line max-statements, max-lines-per-function
export default function TransactionSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const hasGrundstueck = items.includes("grundstueck");
  const hasBaurecht = items.includes("baurecht");
  const hasGebaeude = items.includes("gebaeude");
  const hasZinshaus = items.includes("zinshaus");
  const hasZinshausanteil = items.includes("zinshausanteil");
  const hasEinfamilienhaus = items.includes("einfamilienhaus");
  const hasMehrfamilienhaus = items.includes("mehrfamilienhaus");
  const hasRohdachboden = items.includes("rohdachboden");
  const hasMixed = items.includes("mixed");

  const hasTemporaryGrundstueck = temporaryItems.includes("grundstueck");
  const hasTemporaryBaurecht = temporaryItems.includes("baurecht");
  const hasTemporaryGebaeude = temporaryItems.includes("gebaeude");
  const hasTemporaryZinshaus = temporaryItems.includes("zinshaus");
  const hasTemporaryZinshausanteil = temporaryItems.includes("zinshausanteil");
  const hasTemporaryEinfamilienhaus = temporaryItems.includes("einfamilienhaus");
  const hasTemporaryMehrfamilienhaus = temporaryItems.includes("mehrfamilienhaus");
  const hasTemporaryRohdachboden = temporaryItems.includes("rohdachboden");
  const hasTemporaryMixed = temporaryItems.includes("mixed");

  const onCheck = (event, key) => {
    handleCheck("liegenschaft", event, tempQuery, items, temporaryItems, key, update);
  };

  const checked = (item, temporaryItem) => handleCheckedStatus(query, item, tempQuery, temporaryItem);

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Liegenschaftsankauf
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasGrundstueck, hasTemporaryGrundstueck)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "grundstueck")}
              type="checkbox"
            />

            <span className="ml-3">Grundstück</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasBaurecht, hasTemporaryBaurecht)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "baurecht")}
              type="checkbox"
            />

            <span className="ml-3">Baurecht</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasGebaeude, hasTemporaryGebaeude)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "gebaeude")}
              type="checkbox"
            />

            <span className="ml-3">Gebäude</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasZinshaus, hasTemporaryZinshaus)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "zinshaus")}
              type="checkbox"
            />

            <span className="ml-3">Zinshaus</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasZinshausanteil, hasTemporaryZinshausanteil)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "zinshausanteil")}
              type="checkbox"
            />

            <span className="ml-3">Zinshausanteil</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasEinfamilienhaus, hasTemporaryEinfamilienhaus)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "einfamilienhaus")}
              type="checkbox"
            />

            <span className="ml-3">Einfamilienhaus</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasMehrfamilienhaus, hasTemporaryMehrfamilienhaus)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "mehrfamilienhaus")}
              type="checkbox"
            />

            <span className="ml-3">Mehrfamilienhaus</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasRohdachboden, hasTemporaryRohdachboden)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "rohdachboden")}
              type="checkbox"
            />

            <span className="ml-3">Rohdachboden</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input
              checked={checked(hasMixed, hasTemporaryMixed)}
              className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              onChange={(event) => onCheck(event, "mixed")}
              type="checkbox"
            />

            <span className="ml-3">gemischt</span>
          </label>
        </div>
      </div>
    </div>
  );
}
