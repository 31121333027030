import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { insolvencySortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0 - The root object
 * @param root0.sortByInsolvency - The root object
 * @param root0.onSubmit - The root object
 * @example
 */
export default function SortInsolvenciesDropdown({ onSubmit, sortByInsolvency = "dateLastChange" }) {


  /**
   *
   * @example
   */
  function label() {
    return insolvencySortLabels[sortByInsolvency];
  }

  return (
    <Menu as="div" className="relative ml-8">
      <Menu.Button className="inline-flex h-10 w-full items-center justify-center border-b border-secondary-light text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {label()}

        <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-0.5 size-5 text-gray-500" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 -mt-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="w-full py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByInsolvency: "datePublished" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByInsolvency === "datePublished",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencySortLabels.datePublished}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByInsolvency: "dateLastChange" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByInsolvency === "dateLastChange",
                  "text-gray-700": !active
                })}
              >
                {insolvencySortLabels.dateLastChange}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByInsolvency: "fbnr" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByInsolvency === "fbnr",
                  "text-gray-700": !active
                })}
              >
                {insolvencySortLabels.fbnr}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByInsolvency: "company" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByInsolvency === "company",
                  "text-gray-700": !active
                })}
              >
                {insolvencySortLabels.company}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByInsolvency: "propertyCount" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByInsolvency === "propertyCount",
                  "text-gray-700": !active
                })}
              >
                {insolvencySortLabels.propertyCount}
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
