import {
  CubeIcon,
  KeyIcon,
  UsersIcon
} from "@heroicons/react/24/solid";
import { camelCase } from "lodash-es";
import { useParams } from "react-router-dom";

import { companyRoleLabels } from "~/src/modules/labels";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

/**
 *
 * @example
 */
const ProjectOverviewCompanyRoles = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    automated,
    company_roles: companyRoles
  } = project;

  return (
    <>
      {
        [
          ["owner", <KeyIcon />],
          ...(
            automated
              ? []
              : [["builder", <CubeIcon />], ["marketer", <UsersIcon />]]
          )
        ]
          .map(([roleKey, icon]) => {
            const key = camelCase(["companyRole", roleKey].join("_"));
            const title = automated ? "Verkäufer" : companyRoleLabels[roleKey];
            const currentCompanyRoles = companyRoles.filter(({ name }) => name === roleKey);

            return (
              <ContentSection icon={icon} id={key} key={roleKey} title={title} >
                {
                  currentCompanyRoles.length === 0
                    ? "-"
                    : (
                      <ul className="flex flex-col gap-0.5">
                        {
                          currentCompanyRoles
                            .filter((currentCompanyRole) => currentCompanyRole.company !== null)
                            .map(({
                              id: companyRoleId,
                              company: {
                                city: companyCity,
                                country: companyCountry,
                                house_number: companyHouseNumber,
                                is_zvr: companyIsZvr,
                                name: companyName,
                                reg_number: companyRegNumber,
                                street: companyStreet,
                                zipcode: companyZipcode
                              },
                              private: companyRolePrivate
                            }) => (
                              <li
                                className="[font-variant-ligatures:no-contextual]"
                                key={companyRoleId}
                              >
                                {
                                  companyRolePrivate
                                    ? "Privat"
                                    : [
                                      [
                                        companyName,
                                        companyRegNumber
                                          ? (companyRegNumber && ["", null].includes(companyCountry))
                                            ? companyIsZvr
                                              ? `(ZVR: ${companyRegNumber})`
                                              : `(FN: ${companyRegNumber})`
                                            : `(${companyRegNumber})`
                                          : ""
                                      ]
                                        .filter(Boolean)
                                        .join(" "),
                                      [companyZipcode, companyCity]
                                        .filter(Boolean)
                                        .join(" "),
                                      [companyStreet, companyHouseNumber]
                                        .filter(Boolean)
                                        .join(" ")
                                    ]
                                      .filter(Boolean)
                                      .join(", ")
                                }
                              </li>
                            ))
                        }
                      </ul>
                    )
                }

              </ContentSection>
            );
          })
      }
    </>
  );
};

export default ProjectOverviewCompanyRoles;
