/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable import/max-dependencies */

import {
  useEffect, useRef, useState
} from "react";

import Pagination from "~/src/features/pagination/index.new";
import ProjectList from "~/src/features/project-list";

import useCount from "~/src/hooks/use-count";
import useMediaQuery from "~/src/hooks/use-media-query";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useStore from "~/src/hooks/use-store";

import SelectOnlyMarkedProjects from "~/src/ui/filters/only-marked-projects-filter";
import SelectAllProjectsFilter from "~/src/ui/filters/select-all-projects-filter";

import SortDirectionButton from "../sort-direction-button/index.new";
import SortProjectsDropdown from "../sort-projects-dropdown/index.new";

/**
 *
 * @example
 */
const ProjectsListTile = () => {
  const { query, setQuery } = useProjectQueryParams();

  const isHybrid = useMediaQuery("(max-width: 1380px)");

  const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  const selectionLoading = useStore((state) => state.selectionLoading);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);
  const setChange = useStore((state) => state.setChange);
  const projectListReference = useRef(null);

  const onlyMarkedProjects = useStore((state) => state.onlyMarkedProjects);
  const setOnlyMarkedProjects = useStore((state) => state.setOnlyMarkedProjects);

  const {
    mutate: mutateCount,
    projects: {
      filtered: projectCountFiltered,
      selected: projectCountSelected
    } = {}
  } = useCount({ query });

  const [isHandleItemClickDisabled, setIsHandleItemClickDisable] = useState(false);

  useEffect(() => {
    if (projectCountSelected === 0) {
      setOnlyMarkedProjects(false);
    }
  }, [projectCountSelected]);

  const handleItemClickTimeoutInterval = 1_250;

  const [currentTotal, setCurrentTotal] = useState();

  useEffect(() => {
    setQuery({
      marked: onlyMarkedProjects
    });
  }, [onlyMarkedProjects]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectionStatus, setSelectionStatus] = useState(null);

  const handleSortChange = (sort) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setQuery({
      page: 1,
      sort
    });

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const handleSortDirectionChange = (direction) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setQuery({
      direction,
      page: 1
    });

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const handlePageChange = (page) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setQuery({ page });

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const updateSelection = (update) => {
    setChange(update);
  };

  const setAll = (value) => {
    const newMainType = value ? "addAll" : "removeAll";

    setMainSelectionType(newMainType);

    updateSelection({
      source: "list",
      sourcePage: query.page,
      type: newMainType
    });

    if (newMainType === "removeAll") {
      setOnlyMarkedProjects(false);
    }
  };

  const handleAllChecked = async (value) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    if (!selectionLoading) {
      const checkAll = !(projectCountSelected === projectCountFiltered || projectCountSelected !== 0);

      await setAll(checkAll);
    }

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  const handleDisplayOnlyMarked = (value) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisable(true);

    setOnlyMarkedProjects(value);

    setTimeout(() => {
      setIsHandleItemClickDisable(false);
    }, handleItemClickTimeoutInterval);
  };

  return (
    <div className="relative flex h-full grow flex-col overflow-hidden rounded border-gray-200 bg-white lg:border">
      <div className="top-0 z-10 flex flex-col items-center justify-between gap-2 border-b border-gray-200 bg-white p-4 text-sm lg:flex-row">
        <div className="flex w-full justify-between gap-2 sm:hidden">
          <Pagination count={currentTotal} isLoading={isLoading && !currentTotal} onPageChange={handlePageChange} page={query.page} reducedPagination={isHybrid} siblingCount={(isHybrid ? 0 : 1)} />

          <div className="flex h-full items-center gap-0.5 lg:gap-2">
            <SortProjectsDropdown hasStreet={Boolean(query.street)} isHandleItemClickDisabled={isHandleItemClickDisabled} onSortChange={handleSortChange} sort={query.sort} />

            <SortDirectionButton direction={query.direction} isHandleItemClickDisabled={isHandleItemClickDisabled} onSortDirectionChange={handleSortDirectionChange} />
          </div>
        </div>

        <div className="flex w-full items-center justify-between">
          <div className="flex w-full justify-between gap-2 sm:flex-col lg:w-auto lg:flex-col xl:flex-row xl:justify-start">
            <SelectAllProjectsFilter
              disabled={selectionLoading || projectCountFiltered === 0 || isHandleItemClickDisabled}
              handleCheck={handleAllChecked}
              selectionStatus={(query.marked && currentSelectionStatus !== "none")
                ? "all"
                : currentSelectionStatus}
            />

            <SelectOnlyMarkedProjects
              disabled={selectionLoading || isHandleItemClickDisabled}
              handleCheck={handleDisplayOnlyMarked}
              onlyMarkedProjects={onlyMarkedProjects}
            />
          </div>

          <div className="hidden h-[30px] items-center gap-2 sm:flex">
            <div className="flex h-full items-center gap-2">
              <SortProjectsDropdown hasStreet={Boolean(query.street)} isHandleItemClickDisabled={isHandleItemClickDisabled} onSortChange={handleSortChange} sort={query.sort} />

              <SortDirectionButton direction={query.direction} isHandleItemClickDisabled={isHandleItemClickDisabled} onSortDirectionChange={handleSortDirectionChange} />
            </div>

            <div className="sm:flex">
              <Pagination count={currentTotal} isHandleItemClickDisabled={isHandleItemClickDisabled} isLoading={isLoading && !currentTotal} onPageChange={handlePageChange} page={query.page} reducedPagination={isHybrid} siblingCount={(isHybrid) ? 0 : 1} />
            </div>
          </div>
        </div>
      </div>

      <div className="h-full overflow-y-scroll overscroll-y-contain" ref={projectListReference}>
        <ProjectList
          setCurrentTotal={setCurrentTotal}
          setIsLoading={setIsLoading}
          setSelectionStatus={setSelectionStatus}
        />
      </div>
    </div>
  );
};

export default ProjectsListTile;
