import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @example
 */
export default function useEsgCertificates() {
  const searchParameters = new URLSearchParams(location.search);

  const userId = searchParameters.get("userId");

  const {
    data, error, mutate
  } = useSWR(`/esg-certificates${userId ? `?userId=${userId}` : ""}`, fetcher);

  return {
    certificates: data?.payload?.certificates,
    isError: error,
    isLoading: !error && !data,
    mutate
  };
}
