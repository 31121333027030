/**
 *
 * @param map
 * @param max
 * @param cnt
 * @param marker
 * @example
 */
const smoothZoomAndPan = (map, max, cnt, marker) => {
  const newCenter = marker.getPosition();

  if (cnt <= max) {
    const z = window.google.maps.event.addListener(map, "zoom_changed", (event) => {
      google.maps.event.removeListener(z);
      smoothZoomAndPan(map, max, cnt + 1, marker);
    });

    setTimeout(() => {
      map.setZoom(cnt);
      map.panTo(newCenter);
    }, 80);
  }
};

export default smoothZoomAndPan;
