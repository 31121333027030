import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

import ProjectLocation from "~/src/ui/displays/project-location/index.jsx";

const ProjectLocations = ({ project }) => {
  const [showMultipleLocations, setShowMultipleLocations] = useState(false);

  return (
    <div className="flex w-full flex-col gap-1">
      {
        project?.locations.length > 0 && (
          <>
            <div className="flex">
              <ProjectLocation
                className="flex items-center gap-1 text-sm text-gray-500"
                size="small"
                location={
                  project.locations
                    .find(({ default_location }) => default_location) ?? project.locations[0]
                }
              />

              {
                project.locations.length > 1 && (
                  <button
                    type="button"
                    onClick={() => {
                      setShowMultipleLocations(!showMultipleLocations);
                    }}
                  >
                    {
                      showMultipleLocations
                        ? (
                          <ChevronUpIcon className="-my-[6.5px] size-6 cursor-pointer text-gray-500" />
                        )
                        : (
                          <ChevronDownIcon className="-my-[6.5px] size-6 cursor-pointer text-gray-500" />
                        )
                    }
                  </button>
                )
              }
            </div>

            {
              project.locations.length > 1 && (
                <Transition
                  as="div"
                  className="flex flex-col gap-1"
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={showMultipleLocations}
                >
                  {
                    (
                      project.locations
                        .some(({ default_location }) => default_location)
                        ? project.locations.slice(1)
                        : project.locations.filter(({ default_location }) => !default_location)
                    )
                      .map((location) => (
                        <ProjectLocation
                          className="flex items-center gap-1 text-sm text-gray-500"
                          key={location.id}
                          location={location}
                          size="small"
                        />
                      ))
                  }
                </Transition>
              )
            }
          </>

        )
      }
    </div>
  );
};

export default ProjectLocations;
