/* eslint-disable no-magic-numbers */

/* eslint-disable import/prefer-default-export */
import { Temporal } from "@js-temporal/polyfill";

import { priceLabels } from "~/src/modules/labels";

const { PlainYearMonth } = Temporal;

/**
 *
 * @param category
 * @param statistic
 * @example
 */
const generateLineData = (category, statistic) => {
  let maxTotal = 0;

  const chartData = Object.entries(statistic[category].data)
    .map(([areaGroupKey, areaGroup], index) => {
      const result = {
        id: areaGroupKey,
        color: `hsl(${380 - (7 * index)}, 49%, ${80 - (6 * index)}%)`,

        min: 0,
        data: Object.entries(areaGroup)
          .filter(([, { min, max }]) => max !== 0 && Number.isFinite(min))
          .map(([
            yearMonthString,
            {
              min, max, average, count
            }
          ]) => {
            if (maxTotal < max) {
              maxTotal = max;
            }

            return ({
              count,
              x: yearMonthString,
              y: typeof average === "number" ? Math.round(average * 100) / 100 : average,
              ymax: max,
              ymin: Number.isFinite(min) ? min : 0
            });
          })
          .toSorted(({ x: x1 }, { x: x2 }) => PlainYearMonth.compare(x1, x2)),
        label: priceLabels[category],
        legendLabel: areaGroupKey
      };

      result.max = maxTotal * 10_000;

      return result;
    })
    .filter(({ data }) => data.length > 0);

  const legendData = chartData.map((dataPoint) => ({
    id: dataPoint.id,
    color: dataPoint.color,
    label: `${dataPoint.legendLabel}m²`
  }));

  return {
    chartData,
    legendData
  };
};

export { generateLineData };
