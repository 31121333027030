/**
 *
 */
class hsl2Argb {

  /**
   *
   * @param hue
   * @param saturation
   * @param intensity
   * @example
   */
  static #convert(hue, saturation, intensity) {
    const rgbAsFractionValues = this.#hsl2rgb(hue, saturation / 100, intensity / 100);

    const rgbAsHexValues = rgbAsFractionValues
      .map(this.#fractionOf255ToHex);

    const argb = ["ff"].concat(rgbAsHexValues);

    return argb.join("")
      .toUpperCase();
  }

  /**
   *
   * @param x
   * @example
   */
  static #fractionOf255ToHex(x) {
    const completeValue = Number.parseInt(255 * x);

    return completeValue.toString(16);
  }

  /**
   *
   * @param hslString
   * @example
   */
  static #getParamsFromCssHslString(hslString) {
    const parameterTokens = hslString.match(
      /(\d{1,3}), (\d{1,3})%, (\d{1,3})%/u
    )
      .slice(1);

    return parameterTokens.map((token) => Number.parseInt(token));
  }

  // https://stackoverflow.com/a/54014428
  // input: h in [0,360] and s,v in [0,1] - output: r,g,b in [0,1]

  /**
   *
   * @param h
   * @param s
   * @param l
   * @example
   */
  static #hsl2rgb(h, s, l) {
    const a = s * Math.min(l, 1 - l);
    const f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    return [
      f(0),
      f(8),
      f(4)
    ];
  }

  /**
   *
   * @param cssHslString
   * @example
   */
  static cssString2Hex(cssHslString) {
    const hslParameters = this.#getParamsFromCssHslString(cssHslString);

    return this.#convert(...hslParameters);
  }

}

export { hsl2Argb };
