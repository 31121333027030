/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { createHttpError, errorHasHttpStatus } from "../lib/error";
import httpStatus from "../lib/http-status";

const baseUrl = window.settings.REACT_APP_API_ENDPOINT;
const withCredentials = true;

const instance = {
  connect: async (endpoint, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        credentials: withCredentials ? "include" : "omit",
        method: "CONNECT",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  },
  delete: async (endpoint, data = {}, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        body: JSON.stringify(data),
        credentials: withCredentials ? "include" : "omit",
        headers: new Headers({
          "Content-Type": "application/json",
          accept: "application/json, text/plain, */*"
        }),
        method: "DELETE",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  },
  get: async (endpoint, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        contentType: "application/json",
        credentials: withCredentials ? "include" : "omit",
        headers: new Headers({
          accept: "application/json, text/plain, */*"
        }),
        method: "GET",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    const body = contentType && contentType.includes("application/json")
      ? { data: await response.json() }
      : response.text();

    if (response?.status === httpStatus.forbidden) {
      throw createHttpError({
        relativeUrl: endpoint,
        responseBody: body,
        status: httpStatus.forbidden
      });
    }

    return body;
  },
  head: async (endpoint, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        credentials: withCredentials ? "include" : "omit",
        method: "HEAD",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  },
  options: async (endpoint, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        credentials: withCredentials ? "include" : "omit",
        method: "OPTIONS",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  },
  patch: async (endpoint, data = {}, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        body: JSON.stringify(data),
        credentials: withCredentials ? "include" : "omit",
        method: "PATCH",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  },
  post: async (endpoint, data = {}, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        body: JSON.stringify(data),
        credentials: withCredentials ? "include" : "omit",
        headers: new Headers({
          "Content-Type": "application/json",
          accept: "application/json, text/plain, */*"
        }),
        method: "POST",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  },
  put: async (endpoint, data = {}, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        body: JSON.stringify(data),
        credentials: withCredentials ? "include" : "omit",
        headers: new Headers({
          "Content-Type": "application/json",
          accept: "application/json, text/plain, */*"
        }),
        method: "PUT",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  },
  trace: async (endpoint, config = {}) => {
    const request = new Request(
      `${baseUrl}${endpoint}`,
      {
        credentials: withCredentials ? "include" : "omit",
        method: "TRACE",
        ...config
      }
    );

    const response = await fetch(request);

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
      return { data: await response.json() };
    }

    return response.text();
  }
};

/**
 *
 * @param url
 * @example
 */
const fetcher = (url) => instance.get(url)
  .then((res) => res.data)
  .catch((error) => {
    if (errorHasHttpStatus({
      error,
      status: httpStatus.forbidden
    })) {
      throw error;
    }

    const errorToThrow = new Error("An error occurred while fetching the data.");

    errorToThrow.status = error?.response?.status;

    throw errorToThrow;
  });

/**
 *
 * @param headers
 * @example
 */
const getFetcherWithHeaders = (headers) => (url) => instance
  .get(
    url,
    {
      headers
    }
  )
  .then((res) => {
    if (res?.data?.error) {
      throw new Error(`HTTP Error ${res?.data?.error}`);
    }
    else {
      return res.data;
    }
  })
  .catch((error) => {
    throw new Error("An error occurred while fetching the data.");
  });

/**
 *
 * @param url
 * @param data
 * @example
 */
const postFetcher = (url, data = {}) => instance.post(url, data = {})
  .then((res) => res.data)
  .catch((error) => {
    const errorToThrow = new Error("An error occurred while fetching the data.");

    errorToThrow.status = error.response.status;

    throw errorToThrow;
  });

export {
  fetcher,
  getFetcherWithHeaders,
  postFetcher
};

export default instance;
