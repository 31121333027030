import Leaflet from "leaflet";
import { useMapEvents } from "react-leaflet";

import useMediaQuery from "~/src/hooks/use-media-query";

import { roundToDecimals } from "../../_common/export.js";

/**
 *
 * @param root0 - The root object
 * @param root0.setClusterState - The root object
 * @param root0.setUserManipulated - The root object
 * @param root0.setMapViewState - The root object
 * @param root0.isSimple - The root object
 * @param root0.setQuery - The root object
 * @param root0.query - The root object
 * @param root0.setHandleByMove - The root object
 * @param root0.handleByMove - The root object
 * @example
 */
const MapEvents = ({
  handleByMove, isSimple, query, setClusterState, setHandleByMove, setMapViewState, setQuery
}) => {
  const isPrint = useMediaQuery("print");

  useMapEvents({
    moveend: (event) => {
      if (!isSimple || (isPrint && !query.activeBBox)) {
        if (handleByMove === false) {
          setHandleByMove(true);
        }

        const map = event.target;

        const center = map.getCenter();
        const zoom = map.getZoom();
        const bounds = map.getBounds();

        const {
          _northEast,
          _southWest
        } = bounds;

        const boundingBoxString = Leaflet.latLngBounds(
          [[_southWest.lat, _southWest.lng], [_northEast.lat, _northEast.lng]]
        ).toBBoxString();

        setQuery({
          bBox: boundingBoxString,
          centerLat: center.lat,
          centerLng: center.lng,
          mapZoom: zoom
        });

        setMapViewState({
          lat: center.lat,
          lng: center.lng,
          zoom
        });

        setClusterState([boundingBoxString, zoom].join("_"));
      }
    }
  });

  return null;
};

export default MapEvents;
