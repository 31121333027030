/**
 *
 * @param outerColor
 * @example
 */
const markerIconCircle = (outerColor) => `
  <g transform="translate(34, 13) scale(0.32)">
    <circle fill="${outerColor}" cx="50" cy="50" r="50"/>
    <circle fill="#FFFFFF" cx="50" cy="50" r="25"/>
  </g>
`;

export default markerIconCircle;
