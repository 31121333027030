import cn from "classnames";

/**
 *
 * @param props - The root object
 * @param props.title - The root object
 * @param props.icon - The root object
 * @param props.children - The root object
 * @param props.className - The root object
 * @example
 */
const ContentSection = ({
  children,
  className,
  icon,
  title,
  ...properties
}) => (
  <article className={cn("flex flex-col gap-x-2 gap-y-0.5 w-full text-sm items-start", className)} {...properties}>
    {
      title && (
        <h2 className="flex items-center gap-2 font-medium text-gray-500">
          {icon && (
            <span
              className={cn(
                "w-5 h-5",
                {
                  "text-primary": properties.id !== "stats"
                }
              )}
            >
              {icon}
            </span>
          )}

          <span
            className={cn(
              "font-semibold text-lg",
              {
                "text-primary": properties.id !== "stats"
              }
            )}
          >
            {title}
          </span>
        </h2>
      )
    }

    {children}
  </article>
);

export default ContentSection;
