import { Link } from "react-router-dom";

import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 * @example
 */
export default function NotFoundPage() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div>
        <div className="md:hidden">
          <Link to="/">
            <LogoColor />
          </Link>
        </div>

        <div className="hidden md:block">
          <Link to="/">
            <LogoColor className="h-[48px] w-[416px]" />
          </Link>
        </div>

        <div className="mt-6 text-xl font-light leading-6">
          <p>Ein Fehler ist aufgetreten.</p>
        </div>
      </div>
    </div>
  );
}
