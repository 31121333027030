import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function ObjectTypeModal({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const hasWohnung = items.includes("wohnung");
  const hasEinfamilienhaus = items.includes("einfamilienhaus");
  const hasDoppelhaushaelfte = items.includes("doppelhaushaelfte");
  const hasReihenhaus = items.includes("reihenhaus");
  const hasTownHouse = items.includes("townhouse");
  const hasServicedApartment = items.includes("servicedapartment");
  const hasStudentenheimplatz = items.includes("studentenheimplatz");
  const hasBetreutesWohnen = items.includes("betreuteswohnen");

  const hasTemporaryWohnung = temporaryItems.includes("wohnung");
  const hasTemporaryEinfamilienhaus = temporaryItems.includes("einfamilienhaus");
  const hasTemporaryDoppelhaushaelfte = temporaryItems.includes("doppelhaushaelfte");
  const hasTemporaryReihenhaus = temporaryItems.includes("reihenhaus");
  const hasTemporaryTownHouse = temporaryItems.includes("townhouse");
  const hasTemporaryServicedApartment = temporaryItems.includes("servicedapartment");
  const hasTemporaryStudentenheimplatz = temporaryItems.includes("studentenheimplatz");
  const hasTemporaryBetreutesWohnen = temporaryItems.includes("betreuteswohnen");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("objekttyp", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Objektarten
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasWohnung, hasTemporaryWohnung)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "wohnung")} type="checkbox" />

            <span className="ml-3">Wohnung</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasEinfamilienhaus, hasTemporaryEinfamilienhaus)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "einfamilienhaus")} type="checkbox" />

            <span className="ml-3">Einfamilienhaus</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasDoppelhaushaelfte, hasTemporaryDoppelhaushaelfte)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "doppelhaushaelfte")} type="checkbox" />

            <span className="ml-3">Doppelhaushälfte</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasReihenhaus, hasTemporaryReihenhaus)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "reihenhaus")} type="checkbox" />

            <span className="ml-3">Reihenhaus</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasTownHouse, hasTemporaryTownHouse)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "townhouse")} type="checkbox" />

            <span className="ml-3">Town House</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasServicedApartment, hasTemporaryServicedApartment)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "servicedapartment")} type="checkbox" />

            <span className="ml-3">Serviced Apartment</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasStudentenheimplatz, hasTemporaryStudentenheimplatz)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "studentenheimplatz")} type="checkbox" />

            <span className="ml-3">Studentenheimplatz</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasBetreutesWohnen, hasTemporaryBetreutesWohnen)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "betreuteswohnen")} type="checkbox" />

            <span className="ml-3">Betreutes Wohnen</span>
          </label>
        </div>
      </div>
    </div>
  );
}
