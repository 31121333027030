import { useEffect, useState } from "react";

import ChartError from "~/src/ui/charts/error";
import ChartLegend from "~/src/ui/charts/legend";
import MetaTooltip from "~/src/ui/charts/meta-tooltip";
import PieChart from "~/src/ui/charts/pie-chart";

import { transformToPieData } from "../../helpers";

/**
 *
 * @param props - The root object
 * @param props.statistic - The root object
 * @param props.isLoading - The root object
 * @param props.isError - The root object
 * @example
 */
export default function BuyersAgeChart({
  isError, isLoading, statistic
}) {
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (statistic !== undefined) {
      setPieData(statistic && transformToPieData(statistic, "buyers"));
    }
  }, [statistic]);

  if (isError) {
    return (
      <div className="flex flex-col gap-4 rounded border border-gray-200 bg-white p-4 shadow-sm">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-medium">Altersgruppen</h2>
        </div>

        <ChartError />
      </div>
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 rounded border border-gray-200 bg-white p-4 shadow-sm">
      <div className="flex items-center gap-2">
        <h2 className="text-xl font-medium">Altersgruppen</h2>

        <MetaTooltip
          {...((pieData) && {
            meta: statistic.meta
          })}
        />
      </div>

      <div className="h-[450px] w-full">
        <PieChart
          data={pieData}
          sortByValue={false}
        />
      </div>

      <div className="w-full">
        <ChartLegend data={pieData} />
      </div>
    </div>
  );
}
