import { useState } from "react";

import AreaSettingsButton from "~/src/features/area-settings-button/index.jsx";
import ExcelExportProjectButton from "~/src/features/excel-export-project-button/index.jsx";
import FeedbackModal from "~/src/features/feedback-modal/index.jsx";
import Tabs from "~/src/features/project-tabs/index.jsx";
import WatchlistDropdown from "~/src/features/watchlist-dropdown/index.jsx";

import useMediaQuery from "~/src/hooks/use-media-query.js";
import useProject from "~/src/hooks/use-project.js";
import useProjectVisit from "~/src/hooks/use-project-visit.js";
import useWatchlists from "~/src/hooks/use-watchlists.js";

import ProjectLocations from "~/src/ui/displays/project-locations/index.jsx";
import TabLink from "~/src/ui/links/tab-link/index.jsx";
import Page from "~/src/ui/page/index.jsx";
/* eslint-disable import/max-dependencies */
import {
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Tooltip } from "react-tooltip";

/**
 * Rendering of a specific project page.
 * The page contains the tab menu to switch between:
 * Übersicht, erfasste Objekte, Statistiken, Unternehmen, Liegenschaften, Timeline, Sonstiges
 * Based on the selection the Tabs component allows to render the route.
 *
 * @returns {React.Element} Returns the whole project page from a specified ID.
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function ProjectPage() {
  const [showFeedback, setShowFeedback] = useState(false);

  const { id } = useParams();
  const { path, url } = useRouteMatch();

  const {
    error,
    isLoading,
    project
  } = useProject(id);

  const {
    automated,
    name,
    slug
  } = isLoading ? {} : project;

  useProjectVisit(slug);

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const { mutate: mutateWatchlists, watchlists } = useWatchlists();

  if (error) {
    return <Redirect to="/not-found" />;
  }

  if (!project) {
    return null;
  }

  return (
    <>
      <FeedbackModal
        close={() => setShowFeedback(false)}
        entity_id={project?.slug}
        feedback_type="project"
        feedback_type_label={`${project?.name}`}
        show={showFeedback}
      />

      <Page className="flex min-h-[calc(100vh-3.5rem)] flex-col items-center" id="project-details" title={name || "Projektdetails"}>
        <div className="container flex flex-col items-start gap-8 px-0 pb-16 pt-4 lg:px-16">
          <div className="flex w-full flex-col gap-8 px-4 sm:px-0">
            {isMobile
              ? (
                <div className="flex w-full flex-col items-center justify-between gap-2">
                  <div className="flex w-full flex-row items-start justify-between gap-2">
                    <h1 className="text-3xl font-bold text-gray-600">{name}</h1>

                    <div className="mt-[6px] flex flex-col gap-2 self-start">
                      <div className="flex gap-4">
                        <WatchlistDropdown
                          buttonClassName="h-8"
                          mutateWatchlists={mutateWatchlists}
                          watchlists={watchlists}
                          project={{
                            name: project.name,
                            slug: project.slug
                          }}
                        />

                        <button
                          className="h-8 rounded border border-primary bg-primary px-3 text-sm text-white hover:bg-primary-lighter focus:outline-none"
                          onClick={() => setShowFeedback(true)}
                          type="button"
                        >
                          Feedback
                        </button>
                      </div>

                      <div className="flex justify-end gap-2">
                        <ExcelExportProjectButton />

                        <AreaSettingsButton />
                      </div>
                    </div>
                  </div>

                  <div className="flex self-start">
                    <ProjectLocations isLoading={isLoading} loadedProject={project} />
                  </div>
                </div>
              )
              : (
                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col items-start gap-2">
                    <h1 className="text-3xl font-bold text-gray-600">{name}</h1>

                    <ProjectLocations isLoading={isLoading} loadedProject={project} />
                  </div>

                  <div className="mt-[6px] flex flex-col gap-2 self-start">
                    <div className="flex gap-4">
                      <ExcelExportProjectButton />

                      <AreaSettingsButton />

                      <button
                        className="h-8 rounded border border-primary bg-primary px-3 text-sm text-white hover:bg-primary-lighter focus:outline-none"
                        onClick={() => setShowFeedback(true)}
                        type="button"
                      >
                        Feedback
                      </button>
                    </div>

                    <WatchlistDropdown
                      buttonClassName="h-8 w-14"
                      className="flex self-end"
                      mutateWatchlists={mutateWatchlists}
                      watchlists={watchlists}
                      project={{
                        name,
                        slug
                      }}
                    />
                  </div>
                </div>
              )}

          </div>

          <div className="flex w-full items-center justify-between px-4 sm:px-0">
            <div className="grid w-full grid-cols-2 gap-4 lg:w-auto lg:grid-cols-4">
              <TabLink label="Übersicht" to={`${url}`} />

              <TabLink label="Erfasste Objekte" to={`${url}/objekte`} />

              <TabLink activeOnlyWhenExact={false} label="Erweiterte Statistik" to={`${url}/statistiken`} />

              <TabLink activeOnlyWhenExact={false} label="Timeline" to={`${url}/timeline`} />
            </div>

            {
              automated && (
                <div className="flex items-center gap-2">
                  <div>
                    <svg
                      className="size-6 text-gray-500 hover:text-gray-600 focus:outline-none"
                      data-tooltip-html="Historische Projekte kennzeichnen sich durch mindestens drei<br>Transaktionen desselben Unternehmens innerhalb einer Einlage<br>bis zum Release von „IMMOdeveloper“ (2021)."
                      data-tooltip-id="tooltip-historic-projects"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" fillRule="evenodd" />
                    </svg>

                    <Tooltip
                      className="z-50"
                      delayShow={500}
                      id="tooltip-historic-projects"
                      place="top"
                    />
                  </div>

                  <span className="font-medium text-gray-600">Historisches Projekt</span>
                </div>
              )
            }
          </div>

          <div className="flex w-full">
            <Tabs path={path} />
          </div>

        </div>

      </Page>
    </>
  );
}
