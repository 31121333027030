import {
  formatDate, formatEuro, formatNumber, formatNumberInt
} from "~/src/modules/formatters";

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.tooltipStructure - The root object
 * @param props.xyz - The root object
 * @example
 */
export default function HoverTooltip({
  data,
  tooltipStructure,
  xyz
}) {
  const getTooltipElement = (tooltipPart) => {
    switch (tooltipPart) {
      case "amount":
        return `Anzahl: ${formatNumberInt(data.value)}`;

      case "area":
        return `Fläche: ${data[xyz.zKey] ? `${formatNumber(data[xyz.zKey])} m²` : "unbekannt"}`;

      case "averageSquareMeterPrice":
        return `ø Preis/m² Netto: ${data.averageSquareMeterPrice ? `€ ${formatNumber(data.averageSquareMeterPrice)}` : "unbekannt"}`;

      case "date":
        return `Datum: ${formatDate({ date: data[xyz.xKey] })}`;

      case "label":
        return data.label;

      case "percentage":
        return `Anteil: ${formatNumber(data.percentage)}%`;

      case "price":
        return `Preis: ${formatEuro(data[xyz.yKey])}`;

      case "top":
        return `${data.name}`;

      case "totalPercentage":
        return `Anteil: ${formatNumber(data.totalPercentage)}%`;

      default:
    }
  };

  return (
    <div
      className="absolute flex w-max flex-col gap-1 rounded border border-gray-200 bg-white px-3 py-2 text-sm shadow-sm"
      style={{ transform: "translate(-50%, -120%)" }}
    >
      {tooltipStructure.map((tooltipPart) => {
        switch (tooltipPart) {
          case "label":
            return (
              <div className="flex items-center gap-2 pt-1" key={tooltipPart}>
                <span
                  className="inline-block size-3 rounded-full"
                  style={{ backgroundColor: data.color }}
                />

                <span>{getTooltipElement(tooltipPart)}</span>
              </div>
            );

          default:
            return (
              <span className="flex gap-1" key={tooltipPart}>
                {getTooltipElement(tooltipPart)}
              </span>
            );
        }
      })}

    </div>

  );
}
