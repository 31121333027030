import { constructionPhaseLabels } from "~/src/modules/labels";
import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function ConstructionPhaseSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const hasPurchase = items.includes("construction_phase_purchase");
  const hasConstructionActive = items.includes("construction_phase_active");
  const hasConstructionCompleted = items.includes("construction_phase_completed");
  const hasUnknown = items.includes("unknown");

  const hasTemporaryPurchase = temporaryItems.includes("construction_phase_purchase");
  const hasTemporaryConstructionActive = temporaryItems.includes("construction_phase_active");
  const hasTemporaryConstructionCompleted = temporaryItems.includes("construction_phase_completed");
  const hasTemporaryUnknown = temporaryItems.includes("unknown");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("construction_phase", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Bauphase
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasPurchase, hasTemporaryPurchase)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "construction_phase_purchase")} type="checkbox" />

            <span className="ml-3">{constructionPhaseLabels.construction_phase_purchase}</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasConstructionActive, hasTemporaryConstructionActive)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "construction_phase_active")} type="checkbox" />

            <span className="ml-3">{constructionPhaseLabels.construction_phase_active}</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasConstructionCompleted, hasTemporaryConstructionCompleted)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "construction_phase_completed")} type="checkbox" />

            <span className="ml-3">{constructionPhaseLabels.construction_phase_completed}</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasUnknown, hasTemporaryUnknown)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "unknown")} type="checkbox" />

            <span className="ml-3">{constructionPhaseLabels.unknown}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
