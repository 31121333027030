/**
 *
 * @param props - The root object
 * @param props.children - The root object
 * @param props.image - The root object
 * @example
 */
const ContentContainer = ({
  children,
  image,
  ...properties
}) => (
  <article className="relative flex w-full flex-col gap-6 overflow-hidden border border-gray-200 bg-white p-4 sm:rounded" {...properties}>
    {
      image && (
        <figure className="right-4 top-4 self-center md:absolute">
          {image}
        </figure>
      )
    }

    {children}
  </article>
);

export default ContentContainer;
