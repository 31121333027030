import cn from "classnames";

import MarkerIconCircle from "./icons/marker-icon-circle";
import MarkerIconCompletion from "./icons/marker-icon-completion";
import MarkerIconConstruction from "./icons/marker-icon-construction";
import MarkerIconEmpty from "./icons/marker-icon-empty";
import MarkerIconNone from "./icons/marker-icon-none";
import MarkerIconPurchase from "./icons/marker-icon-purchase";
import { innerIcon } from "./styles.module.css";

const getIcon = (iconName) => {
  switch (iconName) {
    case "circle":
      return MarkerIconCircle;
    case "completion":
      return MarkerIconCompletion;
    case "construction":
      return MarkerIconConstruction;
    case "empty":
      return MarkerIconEmpty;
    case "none":
      return MarkerIconNone;
    case "purchase":
      return MarkerIconPurchase;
    default:
      break;
  }
};

/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @param root0.icon - The root object
 * @example
 */
export default function MapMarker({ className, icon }) {
  const Icon = getIcon(icon);

  return (
    <div className={cn("relative flex justify-center", className)}>
      <svg className="absolute" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M50,0C34.1,0,21.2,12.9,21.2,28.8C21.2,54.9,50,100,50,100s28.8-46.8,28.8-71.2C78.8,12.9,65.9,0,50,0z M50,54.5c-13.8,0-25-11.1-25-25c0-13.8,11.1-25,25-25s25,11.1,25,25S63.8,54.5,50,54.5z" fill="currentColor" />

        {Icon && <path d="M50,54.5c-13.8,0-25-11.1-25-25c0-13.8,11.1-25,25-25s25,11.1,25,25S63.8,54.5,50,54.5z" fill="white" />}
      </svg>

      {
        Icon && (
          <Icon
            className={cn(
              innerIcon,
              "absolute top-[0.43rem] w-4",
              {
                "!h-auto": icon === "circle"
              }
            )}
          />
        )
      }
    </div>
  );
}
