import { useEffect } from "react";

import useRegions from "~/src/hooks/use-regions";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @param root0.onClick - The root object
 * @example
 */
export default function RegionFilterTag({
  onClick, queryItems, submit
}) {
  const {
    isLoading,
    mutate,
    regions,
    states
  } = useRegions();

  useEffect(() => {
    mutate();
  }, [queryItems]);

  if (!queryItems || isLoading) {
    return null;
  }

  const regionQueryItems = queryItems;

  /**
   *
   * @param regionIds
   * @example
   */
  function handleClick(regionIds) {
    const newRegionQueryItems = regionQueryItems.filter((item) => !regionIds.includes(Number.parseInt(item)));

    submit("region", newRegionQueryItems);
  }

  /**
   *
   * @example
   */
  function handleModal() {
    onClick();
  }

  const regionStates = regionQueryItems?.sort((a, b) => a - b).map((queryRegion) => {
    const region = regions?.find((region) => region.id === queryRegion);

    return region || null;
  });

  const grouped = regionStates?.reduce((accumulator, value) => {
    if (accumulator[value?.state?.code]) {
      accumulator[value?.state?.code].regions.push(value);
    }
    else {
      accumulator[value?.state?.code] = {
        code: value?.state?.code,
        name: value?.state?.name,
        numberOfRegions: Number.parseInt(states?.find((state) => state.code === value?.state?.code)?.numberOfRegions),
        regions: [value]
      };
    }

    return accumulator;
  }, {});

  const totalRegionsSelected = Object.values(grouped).reduce((accumulator, current) => (accumulator + ((current.numberOfRegions === current.regions.length) ? 1 : current.regions.length)), 0);

  const tags = (totalRegionsSelected > 10)
    ? (
      <DefaultFilterTag handleClick={() => handleModal()} key="filter_region_more_than">
        <span>Region: mehr als 10 ausgewählt</span>
      </DefaultFilterTag>
    )
    : Object.keys(grouped).map((key) => {
      const group = grouped[key];

      if (group.numberOfRegions === group.regions.length) {
        return (
          <DefaultFilterTag handleClick={() => handleClick(group.regions.map((region) => region.id))} key={`filter_region_state_${group.code}`}>
            <span>Region: {group.name}</span>
          </DefaultFilterTag>
        );
      }

      return group.regions.map((region) => (
        <DefaultFilterTag handleClick={() => handleClick([region.id])} key={`filter_region_${region?.id}`}>
          <span>Region: {region?.name}</span>
        </DefaultFilterTag>
      ));
    });

  return (
    <>
      {tags}
    </>
  );
}
