const statsKeys = {
  byArea: {
    priceSqm: "squareMeterPriceByArea"
  },
  byMonth: {
    priceSqm: "marketTrend"
  },
  byMonthAndArea: {
    priceSqm: "marketTrendAreaGroups"
  },
  byYear: {
    wohnungenCount: "countWohnungenByYear"
  }
};

/**
 *
 */
const DetailStatsTools = {

  /**
   *
   * @param chart
   * @param stats
   * @param statsForVariable
   * @example
   */
  variableHasData(statsForVariable) {
    return Boolean(statsForVariable.meta.total);
  },

  /**
   *
   * @param stats
   * @example
   */
  someVariableHasData(stats) {
    for (const variable in stats) {
      const statsForVariable = stats[variable];

      if (DetailStatsTools.variableHasData(statsForVariable)) {
        return true;
      }
    }

    return false;
  },

  /**
   *
   * @param stats
   * @example
   */
  getVariablesWithData(stats) {
    return Object.keys(stats).filter(
      (variable) => DetailStatsTools.variableHasData(stats[variable])
    );
  },

  //  static hasData(detailStatsStore) {
  //    for (const parameter in detailStatsStore) {
  //      for (const variable in detailStatsStore[parameter]) {
  //        if (detailStatsStore[parameter][variable].hasData) {
  //          return true;
  //        }
  //      }
  //    }
  //
  //    return false;
  //  }
  //
  // }

  /**
   *
   * @param statsByParameters
   * @param stats
   * @example
   */
  hasData(stats) {
    const wohnungenCountByYear = stats[statsKeys.byYear.wohnungenCount];

    if (DetailStatsTools.wohnungenCountHasData(wohnungenCountByYear)) {
      return true;
    }

    for (const parameters of [
      "byMonth",
      "byMonthAndArea",
      "byArea"
    ]) {
      const statsByVariable = stats[statsKeys[parameters].priceSqm];

      if (DetailStatsTools.someVariableHasData(statsByVariable)) {
        return true;
      }
    }

    return false;
  },

  /**
   *
   * @param statsByYear
   * @example
   */
  // TODO it's easy to replace this with a meta field in the API
  wohnungenCountHasData(statsByYear) {
    for (const { year, ...statsForYear } of statsByYear) {
      for (const variable in statsForYear) {
        const countForVariable = statsForYear[variable];

        if (countForVariable > 0) {
          return true;
        }
      }
    }

    return false;
  }

};

export default DetailStatsTools;
