/* eslint-disable no-magic-numbers */

/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { groupBy } from "lodash-es";

import {
  buildCell, buildUnitColumns, setUnitData, unitHeaders
} from "./builders";

const GRAY = "FFD9D9D9";
const WHITE = "FFFFFFFF";
const PURPLE = "FF706FD3";
const GREEN = "FF218C74";

/**
 *
 * @param workbook
 * @param userSettings
 * @param projects
 * @param units
 * @param sheetLabel
 * @example
 */
export default function unitSheetGenerator(
  workbook,
  userSettings,
  projects,
  units,
  sheetLabel = "Exportierte Wohneinheiten"
) {
  const { use_custom_area_ratios: customArea } = userSettings;

  const groupedUnits = Object.values(groupBy(units, "unit_category_id"))
    .flat()
    .sort((unitA, unitB) => unitA.unit_category_id - unitB.unit_category_id);

  const unitSheet = workbook.addWorksheet(sheetLabel, {
    properties: {
      tabColor: {
        argb: PURPLE
      }
    }
  });

  const row1 = unitSheet.addRow();
  const row2 = unitSheet.addRow();

  buildUnitColumns(unitSheet);

  buildCell({
    bgColor: PURPLE,
    cellIndex: 1,
    font: { color: WHITE },
    row: row1,
    value: "Angebotsdaten (A)"
  });
  buildCell({
    bgColor: GREEN,
    cellIndex: 1,
    font: { color: WHITE },
    row: row2,
    value: "Verkaufsdaten (V)"
  });

  buildCell({
    bgColor: GRAY,
    borders: { left: true },
    cellIndex: 3,
    font: { bold: true },
    row: row1,
    value: "Loggia %"
  });
  buildCell({
    alignment: "left",
    bgColor: GRAY,
    borders: {
      bottom: true,
      left: true
    },
    cellIndex: 3,
    row: row2,
    value: customArea ? userSettings.custom_loggia_ratio : 100
  });

  buildCell({
    bgColor: GRAY,
    cellIndex: 4,
    font: { bold: true },
    row: row1,
    value: "Balkon %"
  });
  buildCell({
    alignment: "left",
    bgColor: GRAY,
    borders: { bottom: true },
    cellIndex: 4,
    row: row2,
    value: customArea ? userSettings.custom_balkon_ratio : 0
  });

  buildCell({
    bgColor: GRAY,
    cellIndex: 5,
    font: { bold: true },
    row: row1,
    value: "Terrasse %"
  });
  buildCell({
    alignment: "left",
    bgColor: GRAY,
    borders: { bottom: true },
    cellIndex: 5,
    row: row2,
    value: customArea ? userSettings.custom_terrasse_ratio : 0
  });

  buildCell({
    bgColor: GRAY,
    cellIndex: 6,
    font: { bold: true },
    row: row1,
    value: "Dachterrasse %"
  });
  buildCell({
    alignment: "left",
    bgColor: GRAY,
    borders: { bottom: true },
    cellIndex: 6,
    row: row2,
    value: customArea ? userSettings.custom_dachterrasse_ratio : 0
  });

  buildCell({
    bgColor: GRAY,
    cellIndex: 7,
    font: { bold: true },
    row: row1,
    value: "Garten %"
  });
  buildCell({
    alignment: "left",
    bgColor: GRAY,
    borders: { bottom: true },
    cellIndex: 7,
    row: row2,
    value: customArea ? userSettings.custom_garten_ratio : 0
  });

  buildCell({
    bgColor: GRAY,
    borders: { right: true },
    cellIndex: 8,
    font: { bold: true },
    row: row1,
    value: "Keller %"
  });
  buildCell({
    alignment: "left",
    bgColor: GRAY,
    borders: { bottom: true },
    cellIndex: 8,
    row: row2,
    value: customArea ? userSettings.custom_keller_ratio : 0
  });

  buildCell({
    bgColor: GRAY,
    borders: {
      bottom: true,
      top: true
    },
    cellIndex: 9,
    row: row2,
    value: "Prozentwerte anpassen für individuelle Flächengewichtung"
  });
  buildCell({
    bgColor: GRAY,
    borders: {
      bottom: true,
      top: true
    },
    cellIndex: 10,
    row: row2,
    value: null
  });
  buildCell({
    bgColor: GRAY,
    borders: {
      bottom: true,
      right: true,
      top: true
    },
    cellIndex: 11,
    row: row2,
    value: null
  });

  unitSheet.addRow();
  unitSheet.addRow();

  const headerRow = unitSheet.addRow();

  for (const [index, column] of unitHeaders.entries()) {
    const cell = headerRow.getCell(index + 1);

    cell.value = column.title;
    cell.font = { size: 12 };

    if (column.type === "offer") {
      cell.fill = {
        fgColor: { argb: PURPLE },
        pattern: "solid",
        type: "pattern"
      };
      cell.font = {
        color: { argb: WHITE }
      };
    }

    if (column.type === "sale") {
      cell.fill = {
        fgColor: { argb: GREEN },
        pattern: "solid",
        type: "pattern"
      };
      cell.font = {
        color: { argb: WHITE }
      };
    }
  }

  for (const [index, unit] of groupedUnits.entries()) {
    // 1 based index + starting from 6. row
    const adjustedIndex = index + 1 + 5;
    const row = unitSheet.addRow();

    setUnitData(adjustedIndex, row, unit, projects);
  }

  unitSheet.views = [
    {
      state: "frozen",
      xSplit: 1,
      ySplit: 5
    }
  ];

  unitSheet.autoFilter = "A5:BB5";
}
