import { pluralize } from "~/src/modules/formatters";
import { unitCategoryLabels } from "~/src/modules/labels";

/**
 *
 * @param root0 - The root object
 * @param root0.categoryName - The root object
 * @param root0.totalRecords - The root object
 * @example
 */
export default function UnitListTitle({ categoryName, totalRecords }) {
  if (!categoryName || !totalRecords) {
    return null;
  }

  const categoryLabel = unitCategoryLabels[categoryName];

  return (
    <h2 className="px-4 text-2xl font-medium sm:px-0">{totalRecords} {pluralize(categoryLabel.singular, categoryLabel.plural, categoryLabel.plural, totalRecords)}</h2>
  );
}
