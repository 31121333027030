import useRegions from "~/src/hooks/use-regions";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryRegions - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function ShareDealRegionFilterTag({ queryRegions = [], submit }) {
  const {
    isLoading, regions, states
  } = useRegions();

  if (queryRegions.length === 0 || isLoading) {
    return null;
  }

  /**
   *
   * @param regionIds
   * @example
   */
  function handleClick(regionIds) {
    const newRegionQueryItems = queryRegions.filter((item) => !regionIds.includes(Number.parseInt(item)));

    submit({ regions: newRegionQueryItems.length > 0 ? newRegionQueryItems : undefined });
  }

  const regionStates = queryRegions?.sort((a, b) => Number.parseInt(a) - Number.parseInt(b)).map((queryRegion) => {
    const region = regions?.find((region) => region.id === Number.parseInt(queryRegion));

    return region || null;
  });

  const grouped = regionStates?.reduce((accumulator, value) => {
    if (accumulator[value.state.code]) {
      accumulator[value.state.code].regions.push(value);
    }
    else {
      accumulator[value.state.code] = {
        code: value.state.code,
        name: value.state.name,
        numberOfRegions: Number.parseInt(states?.find((state) => state.code === value.state.code)?.numberOfRegions),
        regions: [value]
      };
    }

    return accumulator;
  }, {});

  const tags = Object.keys(grouped).map((key) => {
    const group = grouped[key];

    if (group.numberOfRegions === group.regions.length) {
      return (
        <DefaultFilterTag handleClick={() => handleClick(group.regions.map((region) => region.id))} key={`filter_region_state_${group.code}`}>
          <span>{group.name}</span>
        </DefaultFilterTag>
      );
    }

    return group.regions.map((region) => (
      <DefaultFilterTag handleClick={() => handleClick([region.id])} key={`filter_region_${region.id}`}>
        <span>{region?.name}</span>
      </DefaultFilterTag>
    ));
  });

  return (
    <>
      {tags}
    </>
  );
}
