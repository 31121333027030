import { fetcher } from "~/src/modules/api";
/* eslint-disable camelcase */
import useSWR from "swr";

/**
 *
 * @example
 */
export default function useAccountRegions() {
  const {
    data, error, mutate
  } = useSWR("/account-regions", fetcher, { revalidateOnMount: true });

  return {
    account_regions: data?.payload?.account_regions,
    isError: error,
    isLoading: !error && !data,
    mutate
  };
}
