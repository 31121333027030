import cn from "classnames";
import { useEffect, useState } from "react";

import { formatAreaInt, formatDate } from "~/src/modules/formatters";

import InsolvencyPropertySlide from "~/src/features/insolvency-property-slide";

import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0 - The root object
 * @param root0.isLoading - The root object
 * @param root0.properties - The root object
 * @param root0.loadingItems - The root object
 * @param root0.query - The root object
 * @example
 */
export default function InsolvencyPropertyList({
  isLoading, loadingItems = 10, properties = [], query
}) {
  const { trackPageView } = useTracking();
  const [showInsolvencyDetails, setShowInsolvencyDetails] = useState(false);
  const [activeProperty, setActiveProperty] = useState(false);
  const [activeColumn, setActiveColumn] = useState("dateLastChange");

  /**
   *
   * @param item
   * @example
   */
  function handleRowClick(item) {
    setActiveProperty(item);
    setShowInsolvencyDetails(true);
    trackPageView(item.id, "Insolvenz Liegenschaft");
  }

  useEffect(() => {
    if (query?.sortByProperty) {
      setActiveColumn(query.sortByProperty);
    }
  }, [query.sortByProperty]);

  return (
    <div>

      <InsolvencyPropertySlide active={showInsolvencyDetails} hide={() => setShowInsolvencyDetails(false)} property={activeProperty} />

      <div className="mb-4 ml-4 flex flex-col sm:ml-0">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 shadow-sm sm:rounded">

              <div className="min-w-full divide-y divide-gray-200">

                <div className="flex grid-cols-12 items-center bg-gray-100 lg:grid">
                  <div className="col-span-3 grid grid-cols-2">
                    <div className="whitespace-nowrap px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700">
                      Öffentlich
                    </div>

                    <div className="whitespace-nowrap px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700">
                      Änderung
                    </div>
                  </div>

                  <div className="col-span-1 w-20 whitespace-nowrap px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    KG
                  </div>

                  <div className="col-span-1 w-20 whitespace-nowrap px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    EZ
                  </div>

                  <div className="col-span-1 w-28 whitespace-nowrap py-3 pr-6 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Fläche EZ
                  </div>

                  <div className="col-span-3 w-64 px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Adresse (Liegenschaft)
                  </div>

                  <div className="col-span-3 w-64 py-3 pr-6 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Schuldner
                  </div>
                </div>

                <div className="divide-y divide-gray-200 bg-white">
                  {isLoading &&
                    [...new Array(loadingItems)].map((e, index) => (
                      <div className="flex h-20 cursor-pointer grid-cols-12 items-center hover:bg-gray-50 lg:grid" key={`share_deal_properties_skeleton_${index}`}>
                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="flex h-12 w-28 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-2 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>
                      </div>
                    ))}

                  {!isLoading && properties?.length === 0 && (
                    <div className="bg-white">
                      <p className="whitespace-nowrap p-4 text-center font-light text-gray-900">keine Liegenschaften für die ausgewählten Kriterien gefunden</p>
                    </div>
                  )}

                  {properties?.map((property) => (
                    <div className="flex cursor-pointer grid-cols-12 items-center text-gray-600 hover:bg-gray-50 lg:grid" key={property.id} onClick={() => handleRowClick(property)}>

                      <div className="col-span-3 grid grid-cols-2">
                        <div
                          className={cn("px-6 py-4 whitespace-nowrap text-sm", {
                            "text-gray-900 font-medium": activeColumn !== "datePublished",
                            "text-primary font-bold": activeColumn === "datePublished"
                          })}
                        >
                          {formatDate({ date: property.insolvency.published_date })}
                        </div>

                        <div
                          className={cn("px-6 py-4 whitespace-nowrap text-sm", {
                            "text-gray-900 font-medium": activeColumn !== "dateLastChange",
                            "text-primary font-bold": activeColumn === "dateLastChange"
                          })}
                        >
                          {formatDate({ date: property.insolvency.last_change_date })}
                        </div>
                      </div>

                      <p
                        className={cn("w-20 lg:w-auto px-6 py-4 whitespace-nowrap text-sm", {
                          "text-primary font-bold": activeColumn === "kg"
                        })}
                      >
                        {property.kgnr}
                      </p>

                      <p
                        className={cn("w-20 lg:w-auto px-6 py-4 whitespace-nowrap text-sm", {
                          "text-primary font-bold": activeColumn === "ez"
                        })}
                      >
                        {property.ez}
                      </p>

                      <div
                        className={cn("w-28 lg:w-auto pr-6 py-4 whitespace-nowrap text-sm", {
                          "text-primary font-bold": activeColumn === "area"
                        })}
                      >
                        <p>{formatAreaInt(property.ez_area)}</p>
                      </div>

                      <div className="text-smtruncate col-span-3 w-64 whitespace-nowrap px-6 py-4 lg:w-auto">
                        <span
                          className={cn({
                            "text-primary font-bold": activeColumn === "street"
                          })}
                        >
                          {property.property_street} {property.property_house_number}
                        </span>

                        <br />

                        {property.property_state}
                        ,

                        <span
                          className={cn({
                            "text-primary font-bold": activeColumn === "zipcode"
                          })}
                        >
                          {property.property_zipcode} {property.property_city.replace(",", ", ")}
                        </span>
                      </div>

                      <div className="text-smtruncate col-span-3 w-64 whitespace-nowrap py-4 pr-6 lg:w-auto">
                        <p className="truncate">
                          <span
                            className={cn({
                              "text-primary font-bold": activeColumn === "fbnr"
                            })}
                          >
                            {property.insolvency.company_fbnr}
                          </span>

                          <span className="mx-1">-</span>

                          <span
                            className={cn({
                              "text-primary font-bold": activeColumn === "company"
                            })}
                          >
                            {property.insolvency.company_name}
                          </span>
                        </p>

                        <p className="truncate">{property.insolvency.company_city}, {property.insolvency.company_street}</p>
                      </div>

                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
