/**
 *
 * @param query
 * @example
 */
const getQueryDoesMatch = (query) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(query).matches;
  }

  return false;
};

/**
 *
 * @param query
 * @example
 */
const getMediaQueryList = (query) => window.matchMedia(query);

export { getMediaQueryList, getQueryDoesMatch };
