/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @param root0.styles - The root object
 * @example
 */
export default function CheckmarkCircleIcon({ className = "w-7 h-7" }) {
  return (
    <svg className={className} fill="currentColor" height={0} viewBox="0 0 20 20" width={0} xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" fillRule="evenodd" />
    </svg>
  );
}
