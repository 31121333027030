import { Tooltip } from "react-tooltip";

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.tooltipStructure - The root object
 * @param props.xyz - The root object
 * @param props.meta - The root object
 * @example
 */
export default function MetaTooltip({ meta }) {
  const dataForTooltip = (meta === undefined)
    ? ""
    : `Besteht aus ${meta.total} Datenpunkten: <br>Errechnet aus ${meta.units.used} von ${meta.units.total} erfassten Objekten`;

  return (
    (meta === undefined)
      ? null
      : (
        <div>
          <p className="flex items-center text-base font-light">
            <svg
              className="size-5 text-gray-500 hover:text-gray-600 focus:outline-none"
              data-tooltip-html={dataForTooltip}
              data-tooltip-id="tooltip-meta"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" fillRule="evenodd" />
            </svg>
          </p>

          <Tooltip
            className="z-50"
            delayShow={500}
            id="tooltip-meta"
            place="bottom"
          />
        </div>
      )
  );
}
