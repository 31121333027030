/* eslint-disable func-style */

import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

const noOptionsMessage = (data) => "Nach Projektnamen suchen...";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <BuildingOfficeIcon className="size-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: 33,
    "&:hover": {
      borderColor: state.isFocused ? "#525252" : "#a3a3a3"
    },
    background: "#f4f4f5",
    borderColor: state.isFocused ? "#a3a3a3" : "#e5e7eb",
    borderRadius: "0.25rem",
    borderWidth: state.isFocused ? "1px" : "1px",
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    height: 33
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af"
  }),

  singleValue: (base) => ({
    ...base,
    background: "#822c42",
    borderRadius: "5px",
    color: "white",
    padding: "0 6px"
  })
};

/**
 *
 * @param root0 - The root object
 * @param root0.handleUpdate - The root object
 * @param root0.placeholderStyle - The root object
 * @example
 */
export default function ProjectSelect({
  handleUpdate,
  placeholderStyle
}) {
  const handleCreate = (value) => {
    handleUpdate("name", value.trim());
  };

  return (
    <CreatableSelect
      isClearable
      blurInputOnSelect={true}
      className="react-select"
      classNames={{ placeholder: () => "text-sm" }}
      formatCreateLabel={(userinput) => `Nach "${userinput}" suchen`}
      instanceId="project-select"
      name="project-select"
      noOptionsMessage={noOptionsMessage}
      onCreateOption={handleCreate}
      placeholder="Projektname"
      styles={customStyles}
      value=""
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
    />
  );
}
