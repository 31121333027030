import { constructionPhaseLabels } from "~/src/modules/labels";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function ConstructionPhaseFilterTags({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const construction_phase = queryItems.split(",");

  /**
   *
   * @param cp
   * @example
   */
  function handleClick(cp) {
    const newCP = construction_phase.filter((item) => item !== cp).join(",");

    submit("construction_phase", newCP);
  }

  return construction_phase.map((cp, index) => (
    <DefaultFilterTag handleClick={() => handleClick(cp)} key={`cp_tag_${index}`}>
      <span>Bauphase: {constructionPhaseLabels[cp]}</span>
    </DefaultFilterTag>
  ));
}
