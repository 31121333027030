import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @example
 */
export default function MarkerIconCircle({ className }) {
  return (
    <svg className={cn("!w-[1.8rem] !top-0", className)} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" fill="currentColor" r="50" />

      <circle cx="50" cy="50" fill="white" r="25" />
    </svg>
  );
}
