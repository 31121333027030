import { errorMessage } from "~/src/modules/labels";

import AlertIcon from "~/src/ui/icons/alert-icon";
import PriceSelect from "~/src/ui/selects/price-select";

/**
 *
 * @param props0 - The root object
 * @param props0.allGraphLabels - The root object
 * @param props0.chartLabel - The root object
 * @example
 */
export default function EmptyChartContainer(
  { allGraphLabels, chartLabel }
) {
  return (
    <div className="flex w-full flex-col gap-4 rounded border border-gray-200 bg-white p-2 lg:p-4">
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2">
          <h2 className="text-md font-medium text-gray-900">{chartLabel}</h2>
        </div>

        <PriceSelect
          buttonClassName="w-80"
          className="w-80 lg:self-end"
          enabledOptions={[]}
          options={allGraphLabels ?? []}
          value={allGraphLabels?.[0]}
        />
      </div>

      <div className="flex justify-center gap-2">
        <AlertIcon className="size-5 text-gray-500" color="text-gray-400" />

        <h2 className="">{errorMessage.statsChart}</h2>
      </div>
    </div>
  );
}
