import cn from "classnames";

const ProjectFinishOrHistoricInfo = ({
  lastContractDate,
  completed,
  completedDate,
  currentSortValue,
  project
}) => (
  <div className="flex text-xs">
    <div className="w-[200px]">
      <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "finishYear" })}>
        {
          project?.automated
            ? `Historisches Projekt${lastContractDate ? ":" : ""}`
            : (completed ? "Bau abgeschlossen:" : "Geplante Fertigstellung:")
        }
      </p>
    </div>

    <div>
      <p className={cn("", { "text-primary font-semibold": currentSortValue === "finishYear" })}>
        {
          project?.automated
            ? lastContractDate || ""
            : completedDate ?? "nicht bekannt"
        }
      </p>
    </div>
  </div>
);

export default ProjectFinishOrHistoricInfo;
