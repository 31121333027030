import { saveAs } from "file-saver";

import unitSheetGenerator from "~/src/lib/excel-export-units-sheet/unit-sheet-generator";

import { datestamp } from "~/src/modules/date-formatter";

import projectsSheetGenerator from "./projects-sheet-generator";
import statsSheetGenerator from "./stats-sheet-generator";
import statsUnitsSheetGenerator from "./stats-units-sheet-generator";

/**
 *
 * @param userSettings
 * @param projects
 * @param units
 * @param stats
 * @param counts
 * @param detailStats
 * @example
 */
async function handleExcelExport(
  userSettings,
  projects,
  units,
  stats,
  counts,
  detailStats
) {
  const ExcelJS = await import("exceljs");
  const Workbook = new ExcelJS.Workbook();

  statsSheetGenerator(Workbook, {
    selection: {
      ...stats,
      projectCountSelected: counts.projects.selected,
      unitCountSelected: counts.units.selected
    }
  }, projects);
  statsUnitsSheetGenerator(Workbook, detailStats);
  projectsSheetGenerator(Workbook, projects);
  unitSheetGenerator(Workbook, userSettings, projects, units);

  Workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `excel-export_${datestamp(new Date())}.xlsx`
    );
  });
}

export { handleExcelExport };
