import cn from "classnames";

import DottedLineIcon from "~/src/ui/icons/dotted-line-icon.jsx";
import MarkerIconCompletion from "~/src/ui/markers/icons/marker-icon-completion";
import MarkerIconConstruction from "~/src/ui/markers/icons/marker-icon-construction";
import MarkerIconEmpty from "~/src/ui/markers/icons/marker-icon-empty";
import MarkerIconPurchase from "~/src/ui/markers/icons/marker-icon-purchase";
import MarkerSmall from "~/src/ui/markers/map-marker-small";

/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @param root0.forExport - The root object
 * @param root0.query - The root object
 * @example
 */
export default function LegendBox({ className, query }) {
  const marketingPhase = {
    items: [
      {
        className: "text-marker-orange",
        name: "coming soon"
      },
      {
        className: "text-marker-red",
        name: "in Vermarktung"
      },
      {
        className: "text-marker-green",
        name: "abgeschlossen"
      },
      {
        className: "text-marker-gray",
        name: "nicht bekannt"
      }
    ],
    title: "Vermarktungsphase"
  };

  const constructionPhase = {
    items: [
      {
        iconComponent: MarkerIconPurchase,
        name: "Ankauf"
      },
      {
        iconComponent: MarkerIconConstruction,
        name: "in Bau"
      },
      {
        iconComponent: MarkerIconCompletion,
        name: "abgeschlossen"
      },
      {
        iconComponent: MarkerIconEmpty,
        name: "nicht bekannt"
      }
    ],
    title: "Bauphase"
  };

  const bboxView = {
    items: [
      {
        iconComponent: DottedLineIcon,
        name: "Betrachtungsansicht"
      }
    ],
    title: "Filter"
  };

  return (
    <div
      className={cn("flex pl-2 gap-2 print:gap-8 print:p-0 text-xs text-gray-500", className, {
        "flex-row justify-between": query?.activeBBox,
        "flex-wrap": !query?.activeBBox
      })}
    >
      <div className="col-span-1 flex flex-col">
        <h3 className="leading-6 text-gray-700">{marketingPhase.title}</h3>

        <div className="grid w-max grid-cols-1 gap-x-4 gap-y-2 xs:grid-cols-2">
          {
            marketingPhase.items.map(({ className, name }, index) => (
              <div className="col-span-1 flex items-center" key={index}>
                <MarkerSmall className={cn("w-5 h-5", className)} />

                <span className="ml-2 whitespace-nowrap">{name}</span>
              </div>
            ))
          }
        </div>
      </div>

      <div className="col-span-1">
        <h3 className="leading-6 text-gray-700">{constructionPhase.title}</h3>

        <div className="grid w-max grid-cols-1 gap-x-4 gap-y-2 xs:grid-cols-2">
          {
            constructionPhase.items.map(({ iconComponent: IconComponent, name }, index) => (
              <div className="col-span-1 flex items-center" key={index}>
                <div className="flex size-5 items-center justify-center rounded-full border bg-white p-1">
                  <IconComponent className="size-full text-gray-500" />
                </div>

                <span className="ml-2 whitespace-nowrap">{name}</span>
              </div>
            ))
          }
        </div>
      </div>

      {
        (query?.activeBBox)
          ? (
            <div className="col-span-1">
              <h3 className="leading-6 text-gray-700">{bboxView.title}</h3>

              <div className="grid w-max grid-cols-1 gap-x-4 gap-y-2 xs:grid-cols-2">
                {
                  bboxView.items.map(({ iconComponent: IconComponent, name }, index) => (
                    <div className="col-span-1 flex items-center" key={index}>
                      <IconComponent className={cn("w-5 h-5", className)} />

                      <span className="ml-2 whitespace-nowrap">{name}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          )
          : null
      }
    </div>
  );
}
