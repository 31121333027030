import priceTerms from "~/src/lib/price-terms.js";

import {
  formatEuro, formatEuroHistoric, formatMonthDotSeparated
} from "~/src/modules/formatters.js";
/* eslint-disable max-statements */
import { priceLabels } from "~/src/modules/labels.js";

const {
  investment: investmentTerm,
  normal: normalTerm
} = priceTerms;

const cleanHistoricPrices = (historicPriceArray) => {
  const countHistoricPricesWithoutDate = historicPriceArray
    .filter((historicPrice) => historicPrice.date === "");

  return (countHistoricPricesWithoutDate.length > 1)
    ? historicPriceArray.filter((historicPrice) => historicPrice.date !== "")
    : historicPriceArray;
};

const addHistoricPrices = (entry, index) => ((entry.date === "")
  ? (
    <div className="grid grid-cols-1" key={`historicPrice_${index}`}>
      <p className="text-center text-xs">{formatEuroHistoric(Number.parseFloat(entry.price))}</p>
    </div>
  )
  : (
    <div className="grid grid-cols-2 gap-1" key={`historicPrice_${index}`}>
      <p className="text-right text-xs">{(entry.date) ? `bis ${formatMonthDotSeparated(entry.date)}:` : ""}</p>

      <p className="text-left text-xs">{formatEuroHistoric(Number.parseFloat(entry.price))}</p>
    </div>
  ));

/**
 *
 * @param root0
 * @param root0.unit
 * @example
 */

const UnitPrices = ({ unit }) => {
  const offerPriceNormalHistory = cleanHistoricPrices(unit.offer_price_normal_history);
  const offerPriceInvestorHistory = cleanHistoricPrices(unit.offer_price_investor_history);
  const rentNettoHistory = cleanHistoricPrices(unit.rent_netto_history);
  const rentBkHistory = cleanHistoricPrices(unit.rent_bk_history);

  const offerPrices = [];

  const offerNormalValue = unit.offer_price_normal;
  const rentBaseValue = unit.rent_netto;

  if (Number.isFinite(offerNormalValue)) {
    offerPrices.push(
      <div key={`prices_${unit?.id}_offer_normal`}>
        <p className="font-bold">{formatEuro(offerNormalValue)}</p>

        <p className="text-xs leading-none">{normalTerm}</p>

        {(offerPriceNormalHistory.length > 0)
          ? (
            <>
              <p className="pt-0.5 text-xs font-semibold">Preisentwicklung</p>

              {offerPriceNormalHistory.map(addHistoricPrices)}
            </>
          )
          : null}
      </div>
    );
  }

  const offerInvestmentValue = unit.offer_price_investor;

  if (Number.isFinite(offerInvestmentValue)) {
    offerPrices.push(
      <div key={`prices_${unit?.id}_offer_invest`}>
        <p className="font-bold">{formatEuro(offerInvestmentValue)}</p>

        <p className="text-xs leading-none">{investmentTerm}</p>

        {(offerPriceInvestorHistory.length > 0)
          ? (
            <>
              <p className="pt-0.5 text-xs font-semibold">Preisentwicklung</p>

              {offerPriceInvestorHistory.map(addHistoricPrices)}
            </>
          )
          : null}
      </div>
    );
  }

  const rentPrices = [];

  if (Number.isFinite(rentBaseValue)) {
    rentPrices.push(
      <div key={`prices_${unit?.id}_rent_base`}>
        <p className="font-bold">{formatEuro(rentBaseValue)}</p>

        <p className="text-xs leading-none">{priceLabels.rentNet}</p>

        {(rentNettoHistory.length > 0)
          ? (
            <>
              <p className="pt-0.5 text-xs font-semibold">Preisentwicklung</p>

              {rentNettoHistory.map(addHistoricPrices)}
            </>
          )
          : null}
      </div>
    );
  }

  const rentTotal = unit.rent_bk;

  if (Number.isFinite(rentTotal)) {
    rentPrices.push(
      <div key={`prices_${unit?.id}_rent_total`}>
        <p className="font-bold">{formatEuro(rentTotal)}</p>

        <p className="text-xs leading-none">{priceLabels.rentTotal}</p>

        {(rentBkHistory.length > 0)
          ? (
            <>
              <p className="pt-0.5 text-xs font-semibold">Preisentwicklung</p>

              {rentBkHistory.map(addHistoricPrices)}
            </>
          )
          : null}
      </div>
    );
  }

  const prices = [];

  if (unit.buyable) {
    prices.push(...offerPrices);
  }

  if (unit.rentable) {
    prices.push(...rentPrices);
  }

  if (prices.length === 0) {
    prices.push(<p className="font-bold">-</p>);
  }

  return (
    <div className="bg-gray-200 p-2 text-center text-sm text-gray-500">
      <p>Preis</p>

      <div className="mt-0.5 space-y-3" key={`prices_${unit?.id}`}>{prices}</div>
    </div>
  );
};

export default UnitPrices;
