/* eslint-disable max-lines-per-function */
import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

import { formatDate } from "~/src/modules/formatters.js";

import FeedbackModal from "~/src/features/feedback-modal/index.jsx";
import SlidePropertyCard from "~/src/features/slide-property-card/index.jsx";

import CloseIcon from "~/src/ui/icons/close-icon/index.jsx";

/**
 *
 * @param {object} props - The root object
 * @param {boolean} props.active - The root object
 * @param {Function} props.hide - The root object
 * @param {object} props.insolvency - The root object
 * @example
 */
export default function InsolvencySlide({
  active, hide, insolvency
}) {
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <Dialog className="fixed inset-0 z-[150] overflow-hidden" onClose={hide} open={active}>

      <FeedbackModal
        close={() => setShowFeedback(false)}
        entity_id={insolvency?.id}
        feedback_type="insolvency"
        feedback_type_label={insolvency.record_number}
        show={showFeedback}
      />

      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-4xl transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                    onClick={hide}
                  >
                    <span className="sr-only">Schließen</span>

                    <CloseIcon aria-hidden="true" className="size-6" />
                  </button>
                </div>
              </TransitionChild>

              <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">

                <div className="flex h-16 shrink-0 items-center justify-between bg-primary px-4 sm:px-12">
                  <h2 className="text-lg font-medium text-white" id="slide-over-heading">
                    Details zur Insolvenz
                  </h2>
                </div>

                <div className="mb-8 mt-4 flex-1">

                  <dl className="px-4 sm:px-8">

                    <div className="px-4">
                      <div className="flex items-center justify-between">
                        <h3 className="text-2xl font-semibold text-gray-700">
                          {insolvency?.company_name}

                          <br />

                          {insolvency?.company_fbnr}
                        </h3>

                        <button
                          className="rounded border border-primary bg-primary px-3 py-1 text-sm text-white hover:bg-primary-lighter focus:outline-none"
                          onClick={() => setShowFeedback(true)}
                          type="button"
                        >
                          Feedback
                        </button>
                      </div>

                      <div className="mt-1 text-gray-900">
                        <p className="leading-5">
                          {insolvency?.company_city}, {insolvency?.company_street}
                        </p>
                      </div>
                    </div>

                    <div>

                      <div className="mt-4 p-4">

                        <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Veröffentlichung</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                            {formatDate({ date: insolvency?.published_date })}
                          </dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Letzte Änderung</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                            {formatDate({ date: insolvency?.last_change_date })}
                          </dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Verfahrensart</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                            {insolvency?.category?.name}
                          </dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Aktenzeichen</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                            <a
                              className="inline-flex items-center hover:underline"
                              href={insolvency?.url}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <span>{insolvency?.record_number}</span>

                              <ArrowTopRightOnSquareIcon className="-mr-1 ml-1 size-5 text-primary" />
                            </a>
                          </dd>
                        </div>

                      </div>

                      {insolvency?.properties?.length > 0 && (
                        <div className="mt-10">

                          <div className="mt-3 flex items-end justify-between px-4">
                            <p className="text-xl">
                              Alle Liegenschaften des Schuldners ({insolvency?.properties?.length})
                            </p>

                            {/* <p className="text-sm leading-none">Stichtagsdaten vom 01.04.2021</p>*/}
                          </div>

                          <div className="mt-2.5 space-y-5 px-4 pb-4">
                            {insolvency?.properties?.map((property, index) => (
                              <SlidePropertyCard key={`property_${index}`} property={property} />
                            ))}
                          </div>
                        </div>
                      )}

                    </div>

                  </dl>

                </div>

              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
