import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { priceLabels, unitSortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0 - The root object
 * @param root0.sortBy - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function SortUnitsDropdown({ sortBy = "name", submit }) {


  /**
   *
   * @param value
   * @example
   */
  function handleClick(value) {
    submit(value);
  }

  return (
    <Menu as="div" className="relative -mt-0.5 ml-8">
      <Menu.Button className="inline-flex h-10 w-full items-center justify-center border-b border-secondary-light text-base font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-0">
        Sortierung:
        {" "}

        {unitSortLabels[sortBy]}

        <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-0.5 size-5 text-gray-500" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 -mt-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="w-full py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "name" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                <span>{unitSortLabels.name}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "flaecheAngebot" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {unitSortLabels.flaecheAngebot}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "priceOfferNormal" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.offerPriceNormalPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "priceOfferInvestor" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.offerPriceInvestorPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "rentNetto" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.rentNetPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "rentBk" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.rentTotalPerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "flaecheVerkauf" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {unitSortLabels.flaecheVerkauf}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => handleClick({ sortBy: "priceSale" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                {priceLabels.salePricePerSquareMeterAlt}
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
