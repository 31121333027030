import {
  createElementObject,
  createOverlayComponent
} from "@react-leaflet/core";
import { useEffect } from "react";

import {
  CustomPopup
} from "./popup/_exports.js";

const Popup = createOverlayComponent(
  (properties, context) => {
    const popup = new CustomPopup(properties, context.overlayContainer);

    return createElementObject(popup, context);
  },
  (
    element,
    context,
    { position },
    setOpen
  ) => {
    useEffect(
      () => {
        const { instance } = element;

        const onPopupOpen = (event) => {
          if (event.popup === instance) {
            instance.update();
            setOpen(true);
          }
        };

        const onPopupClose = (event) => {
          if (event.popup === instance) {
            setOpen(false);
          }
        };

        context.map.on({
          popupclose: onPopupClose,
          popupopen: onPopupOpen
        });

        if (context.overlayContainer == null) {
          // Attach to a Map
          if (position != null) {
            instance.setLatLng(position);
          }
          instance.openOn(context.map);
        }
        else {
          // Attach to container component
          context.overlayContainer.bindPopup(instance);
        }

        return function removePopup() {
          context.map.off({
            popupclose: onPopupClose,
            popupopen: onPopupOpen
          });
          context.overlayContainer?.unbindPopup();
          context.map.removeLayer(instance);
        };
      },
      [
        element,
        context,
        setOpen,
        position
      ]
    );
  }
);

export default Popup;
