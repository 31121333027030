import {
  Dialog,
  DialogBackdrop,
  DialogPanel
} from "@headlessui/react";
import {
  useEffect,
  useRef,
  useState
} from "react";

import useAccountRegions from "~/src/hooks/use-account-regions.js";

import CloseIcon from "~/src/ui/icons/close-icon/index.jsx";

import RegionFilterState from "./region-filter-state.jsx";

/**
 *
 * @param props - The root object
 * @param props.active - The root object
 * @param props.hide - The root object
 * @param props.queryRegion - The root object
 * @param props.update - The root object
 * @param props.submit - The root object
 * @param props.cancelRegionFilter - The root object
 * @param props.tempQuery - The root object
 * @param props.queryKey - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function RegionFilterModal({
  active, cancelRegionFilter, hide, queryKey = "region", queryRegion, submit, tempQuery, update
}) {
  const { account_regions: accountRegions = {}, mutate } = useAccountRegions();

  const cancelButtonReference = useRef();

  const [states, setStates] = useState([]);

  useEffect(() => {
    mutate();
  }, []);

  const accountRegionsArray = Object.values(accountRegions);

  const sortStates = (unsortedStates) => [...unsortedStates].sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)).reverse();

  const updateStateRegions = (id, regions) => {
    const stateIndex = states.map(({ id: stateId }) => stateId).indexOf(id);

    const newStates = [...states];

    const checked = regions.every(({ checked: regionChecked }) => regionChecked);
    const indeterminate = regions.some(({ checked: regionChecked }) => regionChecked) && !checked;

    const newState = {
      ...newStates[stateIndex],
      checked,
      indeterminate,
      regions
    };

    newStates[stateIndex] = newState;

    setStates(sortStates(newStates));
  };

  const setInitialStates = (regionIds) => {
    const initialStates = accountRegionsArray
      .map(({
        regions,
        stateData
      }) => {
        const updatedRegions = regions.map(({ id, ...region }) => ({
          id,
          ...region,
          checked: regionIds.includes(id)
        }));

        const checked = updatedRegions.every(({ checked: regionChecked }) => regionChecked);
        const indeterminate = updatedRegions.some(({ checked: regionChecked }) => regionChecked) && !checked;

        return {
          ...stateData,
          checked,
          indeterminate,
          regions: updatedRegions
        };
      });

    setStates(sortStates(initialStates));
  };

  useEffect(() => {
    if (accountRegionsArray?.length > 0 && tempQuery?.[queryKey]) {
      setInitialStates(tempQuery[queryKey]);
    }
  }, [tempQuery]);

  useEffect(() => {
    if (accountRegionsArray?.length > 0) {
      setInitialStates(queryRegion);
    }
  }, [accountRegions, queryRegion]);

  useEffect(() => {
    const newRegions = states
      .flatMap(({ regions }) => regions.filter(({ checked }) => checked))
      .map(({ id }) => id);

    if (
      (
        !tempQuery?.[queryKey] ||
        tempQuery[queryKey].some((id, index) => id !== newRegions[index]) ||
        (tempQuery[queryKey].length !== newRegions.length)
      )
    ) {
      update(
        queryKey,
        newRegions
      );
    }
  }, [states]);

  useEffect(() => {
    update(
      queryKey,
      queryRegion
    );
  }, []);

  const handleSubmit = function () {
    submit();
    hide();
  };

  const handleCancel = () => {
    setInitialStates(queryRegion);
    cancelRegionFilter();
    hide();
  };

  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={cancelButtonReference}
      onClose={handleCancel}
      open={active}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex size-full max-h-full flex-col bg-white sm:max-h-full sm:max-w-3xl sm:rounded-lg"
        >
          <div className="relative border-b border-gray-200 p-4 sm:px-6">
            <p className="text-center text-lg font-medium">Regionen filtern</p>

            <div className="absolute right-4 top-0 hidden h-full items-center sm:flex">
              <button
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                onClick={handleCancel}
                type="button"
              >
                <CloseIcon className="size-5 text-black" />
              </button>
            </div>
          </div>

          <div className="min-h-0 grow divide-y divide-gray-300 overflow-y-scroll bg-white px-4 sm:divide-gray-200 sm:px-12">

            {
              states.map(({
                id,
                checked,
                code,
                indeterminate,
                name,
                regions
              }) => (
                <RegionFilterState
                  key={id}
                  {...{
                    id,
                    checked,
                    indeterminate,
                    name,
                    regions,
                    updateStateRegions
                  }}
                />
              ))
            }

          </div>

          <div className="space-y-4 border-t border-gray-200 p-4 text-center sm:flex sm:flex-row-reverse sm:items-center sm:justify-between sm:px-6">
            <button
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:ml-3 sm:w-auto"
              onClick={handleSubmit}
              type="button"
            >
              Suchen
            </button>

            <button
              className="font-medium text-gray-700 underline hover:bg-gray-50 focus:outline-none"
              onClick={handleCancel}
              ref={cancelButtonReference}
              type="button"
            >
              zurück
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
