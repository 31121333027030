import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";

import ProjectsFiltersTile from "../projects-filters-tile/index.jsx";

/**
 *
 * @example
 */
const ProjectsFiltersModal = () => {
  const { query, setQuery } = useProjectQueryParams();

  const filterOpen = useStore((state) => state.filterOpen);
  const setFilterOpen = useStore((state) => state.setFilterOpen);

  const closeFilter = () => {
    setFilterOpen(false);
  };

  return (
    <Transition
      as="div"
      className="fixed inset-0 z-50 flex w-full flex-col gap-4 bg-white p-4 drop-shadow-[0px_0px_8px_#00000040]"
      enter="transition ease-in-out duration-500 transform"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transition ease-in-out duration-500 transform"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-full"
      show={filterOpen}
      unmount={false}
    >
      <div className="flex w-full justify-end">
        <button onClick={closeFilter}>
          <XMarkIcon className="size-8 text-gray-700" />
        </button>
      </div>

      <ProjectsFiltersTile
        className="!flex"
        queryAutomated={typeof query.automated === "boolean" ? query.automated : false}
        onChange={(automated) => {
          setQuery({
            automated,
            page: 1
          });
        }}
      />
    </Transition>
  );
};

export default ProjectsFiltersModal;
