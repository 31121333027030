import cn from "classnames";
import { forwardRef } from "react";

/**
 * @typedef {object} SelectAllProjectsFilterProps
 * @property {boolean} [disabled] - Whether the filter is disabled.
 * @property {Function} handleCheck - The check event handler.
 * @property {"all"|"none"|"some"} selectionStatus - The selection status.
 * @property {"normal"|"small"} [size] - The size of the filter.
 */

const SelectAllProjectsFilter = forwardRef(

  /**
   * The select all projects filter component.
   *
   * @param {SelectAllProjectsFilterProps} props - The component props.
   * @param forwardedReference
   * @returns {JSX.Element} The rendered JSX element.
   * @example
   */
  (
    {
      disabled,
      handleCheck,
      selectionStatus,
      size = "normal"
    },
    forwardedReference
  ) => {
    const checked = selectionStatus === "all";
    const indeterminate = selectionStatus === "some";

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @example
     */
    const handleCheckbox = (event) => {
      handleCheck(event.target.checked);
    };

    return (
      <label
        className="flex cursor-pointer items-center gap-2 pl-0.5"
        htmlFor="statsAll"
        ref={forwardedReference}
      >
        <input
          checked={checked}
          className="size-4 cursor-pointer rounded border border-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-default disabled:opacity-50"
          disabled={disabled}
          id="statsAll"
          onChange={handleCheckbox}
          ref={(element) => element && (element.indeterminate = indeterminate)}
          type="checkbox"
        />

        <span className={cn("font-medium text-gray-500", { "text-xs": size === "small" })}>Alle markieren</span>
      </label>
    );
  }
);

SelectAllProjectsFilter.displayName = "SelectAllProjectsFilter";

export default SelectAllProjectsFilter;
