import { RadioGroup } from "@headlessui/react";
import cn from "classnames";
import {
  Link, useLocation, useRouteMatch
} from "react-router-dom";

/**
 * Component which creates Radiobuttons based on the categoryStatistics param.
 *
 * @param {object} props - The props.
 * @param {Array} props.categoryStatistics - Array which contains the to be created RadioButtons.
 * @returns {React.ReactElement} The RadioGroup.
 * @example
 */
export default function StatisticsFilters({
  categoryStatistics
}) {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const pathArray = pathname.split("/").filter((item) => item !== "");

  const selectedStatistics = (categoryStatistics.some((item) => item.value === pathArray.at(-1)))
    ? pathArray.at(-1)
    : "preise";

  return (
    <div>
      <RadioGroup value={selectedStatistics} >
        <RadioGroup.Label className="sr-only">Objektfilter</RadioGroup.Label>

        <div className="space-y-4 md:grid md:grid-cols-6 md:gap-4 md:space-y-0">
          {categoryStatistics.map((category) => (
            <Link className={cn("md:col-span-2 relative block rounded border border-gray-200 bg-white shadow-sm px-4 py-4 cursor-pointer hover:border-gray-400 focus:outline-none", {})} key={category.value} to={`${url}/${category.value}`}>
              <RadioGroup.Option
                key={category.value}
                value={category.value}
              >
                {({ checked }) => (
                  <>
                    <div>
                      <div className="flex items-start justify-between text-sm">
                        <div className="-mt-0.5 flex items-center">
                          <div>
                            <span
                              aria-hidden="true"
                              className={cn("h-5 w-5 cursor-pointer rounded-full border flex items-center justify-center", {
                                "bg-primary border-transparent": checked,
                                "bg-white border-gray-300": !checked
                              })}
                            >
                              <span className="size-2 rounded-full bg-white" />
                            </span>
                          </div>

                          <RadioGroup.Label as="p" className="ml-3 text-base font-medium text-gray-900">
                            {category.label}
                          </RadioGroup.Label>
                        </div>

                        {

                        /*
                      <RadioGroup.Description as="div" className="text-gray-500">
                        <p>
                          {category.count} {pluralize("erfasstes Objekt", "erfasste Objekte", "erfasste Objekte", category.count)}
                        </p>
                      </RadioGroup.Description>
                        */
                        }

                      </div>
                    </div>

                    <div
                      aria-hidden="true"
                      className={cn("absolute -inset-px rounded border-2 pointer-events-none", {
                        "border-primary": checked,
                        "border-transparent": !checked
                      })}
                    />
                  </>
                )}
              </RadioGroup.Option>
            </Link>

          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
