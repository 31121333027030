import { useParams } from "react-router-dom";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

/**
 *
 * @example
 */
const ProjectOverviewNotes = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    public_notes: notes
  } = project;

  return (
    <ContentSection id="notes" title="Projektnotizen">
      <p className="w-full hyphens-auto text-justify">
        {(notes)
          ? notes
          : "-"}

      </p>
    </ContentSection>
  );
};

export default ProjectOverviewNotes;
