/* eslint-disable no-magic-numbers */
/* eslint-disable no-empty-function */

/* eslint-disable max-lines-per-function */
import cn from "classnames";
import { range } from "lodash-es";

import {
  getButtonContent,
  getButtonPage,
  getEndEllipsis,
  getRangeCount,
  getStartEllipsis
} from "./pagination-helper";

/**
 *
 * @param props - The root object
 * @param props.page - The root object
 * @param props.totalPages - The root object
 * @param props.numberOfNeighbors - The root object
 * @param props.padding - The root object
 * @param props.siblingCount - The root object
 * @param props.boundaryCount - The root object
 * @param props.count - The root object
 * @param props.onPageChange - The root object
 * @param props.isLoading - The root object
 * @param props.isHandleItemClickDisabled - The root object
 * @param props.reducedPagination - The root object
 * @example
 */
const Pagination = ({
  boundaryCount = 1,
  count = 1,
  isHandleItemClickDisabled,
  isLoading = false,
  onPageChange = () => {},
  page: currentPage = 1,
  reducedPagination,
  siblingCount = 1
}) => {
  if (isLoading) {
    return (
      <div
        className="flex animate-pulse gap-1"
      >
        {
          Array.from({ length: reducedPagination ? 7 : 9 })
            .fill()
            .map((empty, index) => (
              <div className="h-6 w-8 rounded bg-gray-300 lg:h-[1.875rem] lg:w-9" key={`skeleton_${index}`} />
            ))
        }
      </div>
    );
  }
  const startPages = range(1, Math.min(boundaryCount, count) + 1);
  const endPages = range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count + 1);

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      currentPage - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - (siblingCount * 2) - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      currentPage + siblingCount,
      // Upper boundary when page is low
      boundaryCount + (siblingCount * 2) + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1
  );

  const startEllipsis = getStartEllipsis({
    boundaryCount,
    count,
    siblingsStart
  });

  const rangeCount = getRangeCount({
    siblingsEnd,
    siblingsStart
  });

  const endEllipsis = getEndEllipsis({
    boundaryCount,
    count,
    siblingsEnd
  });

  const itemList = [
    "previous",
    ...startPages,

    // Start ellipsis

    ...startEllipsis,

    // Sibling pages
    ...rangeCount,

    // End ellipsis

    ...endEllipsis,

    ...endPages,
    "next"
  ]
    .map((type) => ({
      page: getButtonPage({
        count,
        currentPage,
        type
      }),
      type
    }));

  return (
    <nav className="flex items-center gap-1">
      {
        itemList.map(({ page, type }, index) => {
          switch (type) {
            case "end-ellipsis":
            case "start-ellipsis":
              return (
                <span
                  className="flex h-6 w-8 items-center justify-center p-2 lg:h-[1.875rem] lg:w-9"
                  key={index}
                >
                  …
                </span>
              );

            default:
              return (
                <button
                  disabled={isHandleItemClickDisabled}
                  disabled={page < 1 || page > count}
                  key={index}
                  onClick={() => onPageChange(page)}
                  className={cn(
                    "w-8 lg:w-[2.25rem] h-6 lg:h-[1.875rem] flex items-center justify-center p-2 border border-gray-200 rounded-sm disabled:opacity-50",
                    {
                      "bg-primary text-white cursor-default pointer-events-none": page === currentPage,
                      "bg-white text-gray-500 hover:bg-gray-50": page !== currentPage
                    }
                  )}
                >
                  {getButtonContent(type)}
                </button>
              );
          }
        })

      }
    </nav>
  );
};

export default Pagination;
