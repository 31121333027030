import ProjectMarker from "~/src/features/project-marker";

import ClusterMarker from "../cluster-marker";

/**
 *
 * @param props - The root object
 * @param props.points - The root object
 * @param props.simple - The root object
 * @param props.children - The root object
 * @param props.mapRef - The root object
 * @param props.groupRef - The root object
 * @param props.clusterExpansionZooms - The root object
 * @param props.leaves - The root object
 * @param props.clustersSpiderfied - The root object
 * @param props.setClustersSpiderfied - The root object
 * @param props.isSimple - The root object
 * @param props.map - The root object
 * @example
 */
const ProjectMarkerClusterGroup = ({
  children,
  clusterExpansionZooms,
  clustersSpiderfied,
  groupRef,
  isSimple: isSimpleMap,
  leaves,
  map,
  mapRef,
  points,
  setClustersSpiderfied,
  simple
}) => (
  <>
    {points?.map((point) => {
      const {
        id, geometry, properties: { cluster, slug }
      } = point;

      if (cluster) {
        return (
          <ClusterMarker
            key={id}
            {...{
              clusterExpansionZooms,
              clustersSpiderfied,
              groupRef,
              leaves,
              mapRef,
              point,
              setClustersSpiderfied,
              simple
            }}
          />
        );
      }

      return (
        <ProjectMarker
          key={slug}
          {...{
            isSimpleMap,
            map,
            point,
            simple
          }}
        />
      );
    })}

    {children}
  </>
);

export default ProjectMarkerClusterGroup;
