const areaGroups = [
  "<= 35",
  "36 - 60",
  "61 - 80",
  "81 - 100",
  "101 - 125",
  "126 - 150",
  "151 - 180",
  ">= 181"
];

export { areaGroups };
