import dayjs from "dayjs";

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatArea = function (value) {
  if (value) {
    return `${Number.parseFloat(value).toLocaleString("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })} m²`;
  }

  return "";
};

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatAreaInt = function (value) {
  if (value) {
    return `${Number.parseInt(value).toLocaleString("de")} m²`;
  }

  return "";
};

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatNumber = function (value) {
  if (value) {
    return Number.parseFloat(value).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  return "";
};

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatNumberInt = function (value) {
  if (value) {
    return Number.parseFloat(value).toLocaleString("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  }

  return "";
};

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatEuro = function (value) {
  if (typeof value === "number") {
    return `€ ${Number.parseFloat(value).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  }

  return "";
};

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatEuroHistoric = function (value) {
  if (typeof value === "number") {
    return `€ ${Number.parseFloat(value).toLocaleString("de", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
  }

  return "";
};

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatEuroPerMeterSquared = function (value) {
  return `${formatEuro(value)}/m²`;
};

/**
 *
 * @param options - The root object
 * @param options.val - The root object
 * @param options.currency - The root object
 * @example
 */
export const formatCurrency = function ({ currency = "EUR", val }) {
  if (val && currency === "EUR") {
    return `€ ${Number.parseFloat(`${val}`.replace(",", ".")).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  }
  if (val) {
    return `${Number.parseFloat(`${val}`.replace(",", ".")).toLocaleString("de", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} ${currency}`;
  }

  return "";
};

/**
 *
 * @param options - The root object
 * @param options.val - The root object
 * @param options.currency - The root object
 * @example
 */
export const formatCurrencyInt = function ({ currency = "EUR", val }) {
  if (val && currency === "EUR") {
    return `€ ${Number.parseInt(`${val}`.replace(",", ".")).toLocaleString("de")}`;
  }
  if (val) {
    return `${Number.parseInt(`${val}`.replace(",", ".")).toLocaleString("de")} ${currency}`;
  }

  return "";
};

/**
 *
 * @param val
 * @param value
 * @example
 */
export const formatPrice = function (value) {
  if (value) {
    return value.toLocaleString("de");
  }

  return "";
};

/**
 *
 * @param date
 * @example
 */
export const formatYear = function (date) {
  try {
    if (date) {
      return dayjs(date).format("YYYY");
    }
  }
  catch {
    return null;
  }

  return null;
};

/**
 *
 * @param date
 * @example
 */
export const formatMonth = function (date) {
  if (date) {
    return dayjs(date).format("MM/YYYY");
  }

  return null;
};

/**
 *
 * @param date
 * @example
 */
export const formatMonthDotSeparated = function (date) {
  if (date) {
    return dayjs(date).format("MM.YYYY");
  }

  return null;
};

/**
 *
 * @param options - The root object
 * @param options.date - The root object
 * @param options.withTime - The root object
 * @example
 */
export const formatDate = function ({ date = null, withTime = false }) {
  if (date === null) {
    return null;
  }

  if (withTime) {
    return dayjs(date).format("DD.MM.YYYY HH:mm");
  }

  return dayjs(date).format("DD.MM.YYYY");
};

/**
 *
 * @param singular
 * @param plural
 * @param none
 * @param length
 * @example
 */
export const pluralize = function (singular = "", plural = "", none = "", length = 0) {
  if (!length || length === 0) {
    return none;
  }

  return length > 1 ? plural : singular;
};

/**
 *
 * @param root0 - The root object
 * @param root0.buyerOrSeller - The root object
 * @param root0.isPrivate - The root object
 * @example
 */
export const formatBuyerOrSeller = ({ buyerOrSeller, isPrivate }) => {
  if (isPrivate) {
    return "Privat";
  }

  if (buyerOrSeller === null) {
    return "-";
  }

  const {
    country,
    is_zvr: isZvr,
    name,
    reg_number: regNumber
  } = buyerOrSeller;

  return `${name} ${
    (regNumber)
      ? (["", null].includes(country))
        ? (isZvr)
          ? `(ZVR: ${regNumber})`
          : `(FN: ${regNumber})`
        : `(${regNumber})`
      : ""
  }`;
};
