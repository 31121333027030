import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSWRConfig } from "swr";

import API from "~/src/modules/api";

import useMediaQuery from "~/src/hooks/use-media-query";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useStore from "~/src/hooks/use-store";

/**
 *
 * @example
 */
// eslint-disable-next-line max-statements, max-lines-per-function
const SelectionController = () => {
  const isPrint = useMediaQuery("print");
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  };

  const location = useLocation();

  const [isInit, setIsInit] = useState(true);

  const { query, setQuery } = useProjectQueryParams();

  const projectsSelectionBody = useStore((state) => state.projectsSelectionBody);
  const setProjectsSelectionBody = useStore((state) => state.setProjectsSelectionBody);

  const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  const setCurrentSelectionStatus = useStore((state) => state.setCurrentSelectionStatus);

  const selectionResetByFilter = useStore((state) => state.selectionResetByFilter);
  const setSelectionResetByFilter = useStore((state) => state.setSelectionResetByFilter);

  const queryString = useStore((state) => state.queryString);
  const setQueryString = useStore((state) => state.setQueryString);

  const change = useStore((state) => state.change);
  const setChange = useStore((state) => state.setChange);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const setMainSelectionType = useStore((state) => state.setMainSelectionType);

  const { cache: cacheGlobal, mutate: mutateGlobal } = useSWRConfig();

  const updateSelection = (update) => {
    setChange(update);
  };

  const setAll = async (value) => {
    if (!isPrint) {
      const newMainType = value ? "addAll" : "removeAll";

      setMainSelectionType(newMainType);

      await updateSelection({
        type: newMainType
      });
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("projekte")) {
      setAll(true);
    }
    setIsInit(false);

    if (query?.activeBBox && (isMobile && !isPrint)) {
      setQuery({
        activeBBox: false
      });
    }
  }, []);

  useEffect(() => {
    if (selectionResetByFilter) {
      setAll(true);
      setSelectionResetByFilter(false);
    }
  }, [selectionResetByFilter]);

  useEffect(() => {
    if (!selectionLoading && !isInit && !setSelectionResetByFilter) {
      const queryStringFromUrl = new URLSearchParams(
        Object.fromEntries(
          Object.entries(query).filter(([key, value]) => value !== undefined)
        )
      ).toString();

      setQueryString(queryStringFromUrl);
      setChange({
        data: [],
        type: "query"
      });
    }
  }, [query]);

  useEffect(() => {
    if (change !== "") {
      const body = {
        selection: {
          change,
          query: queryString,
          type: "project"
        }
      };

      if (change.type === "removeAll") {
        setCurrentSelectionStatus("none");
      }
      else if (change.type === "addAll") {
        setCurrentSelectionStatus("all");
      }

      setProjectsSelectionBody(JSON.stringify(body));
      setSelectionLoading(true);
    }
  }, [change]);

  useEffect(() => {
    if (selectionLoading &&
      projectsSelectionBody &&
      projectsSelectionBody !== "" &&
      !navigator.userAgent.startsWith("Bidra")
    ) {
      const body = JSON.parse(projectsSelectionBody);

      const postingSelection = async ({
        source,
        sourcePage,
        ...currentBody
      }) => {
        await API.post(
          "/selections",
          currentBody
        );

        setSelectionMutate(true);

        await mutateGlobal(
          (key) => {
            let shouldMutate = false;
            const cacheKeyValue = cacheGlobal.get(key);

            if (key.startsWith("/projects/stats")) {
              shouldMutate = (
                !(((Object.keys(cacheKeyValue).includes("data") ||
                  !cacheKeyValue?.isLoading)))
              );
            }
            else if (change?.source === "list") {
              const [restOfUrl, keyQuery] = key.split("?");

              const parameters = new URLSearchParams(keyQuery);

              const keyPage = Number(parameters.get("page"));

              shouldMutate = (
                key.startsWith("/projects/map") ||
                (key.startsWith("/project-list") && keyPage !== change.sourcePage)
              );
            }
            else if (change?.source === "map") {
              shouldMutate = key.startsWith("/project-list");
            }

            return shouldMutate;
          },

          undefined,
          {
            revalidate: false
          }
        );
      };

      postingSelection(body);
    }
  }, [selectionLoading]);

  return (
    null
  );
};

export default SelectionController;
