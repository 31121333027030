import { stringify } from "query-string";
import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @example
 */
export default function usePurchases({ query }) {
  const { data, error } = useSWR(`/purchases?${stringify(query)}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    pagination: data?.payload?.pagination,
    purchases: data?.payload?.purchases,
    sortDirection: data?.payload?.sortDirection
  };
}
