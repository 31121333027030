import { stringify } from "query-string";
import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0 - The root object
 * @param root0.id - The root object
 * @param root0.query - The root object
 * @example
 */
export default function useWatchlist({ id, query }) {
  const {
    data, error, mutate
  } = useSWR(`/watchlists/${id}?${stringify(query)}`, fetcher);

  return {
    error,
    isLoading: !error && !data,
    mutate,
    pagination: data?.payload?.pagination,
    projects: data?.payload?.projects,
    sortDirection: data?.payload?.sortDirection,
    watchlist: data?.payload?.watchlist
  };
}
