import { formatCss, hsl } from "culori";

/**
 *
 * @param d
 * @param match
 * @example
 */
const getActivatedColor = (d, match) => {
  const color = hsl(d.color);

  if (!match.includes(d.id)) {
    color.alpha = 0.15;
  }

  return formatCss(color);
};

export {
  getActivatedColor
};
