import { useLeafletContext } from "@react-leaflet/core";
import isEqual from "fast-deep-equal";
import leaflet, { Control, Map } from "leaflet";
import "leaflet-draw";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

const eventHandlers = {
  onDeleteStart: "draw:deletestart",
  onDeleteStop: "draw:deletestop",
  onDeleted: "draw:deleted",
  onDrawStart: "draw:drawstart",
  onDrawStop: "draw:drawstop",
  onDrawVertex: "draw:drawvertex",
  onEditMove: "draw:editmove",
  onEditResize: "draw:editresize",
  onEditStart: "draw:editstart",
  onEditStop: "draw:editstop",
  onEditVertex: "draw:editvertex",
  onEdited: "draw:edited"
};

/**
 *
 * @param props
 * @param properties
 * @example
 */
function EditControl(properties) {
  const context = useLeafletContext();
  const drawReference = useRef();
  const propertiesReference = useRef(properties);

  useEffect(() => {
    const { map } = context;

    if (!drawReference.current) {
      drawReference.current = createDrawElement(properties, context);

      map.addControl(drawReference.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { map } = context;
    const { onMounted } = properties;

    const onDrawCreate = (e) => {
      const { onCreated } = properties;
      const container = context.layerContainer || context.map;

      container.addLayer(e.layer);
      onCreated && onCreated(e);
    };

    for (const key in eventHandlers) {
      if (properties[key]) {
        map.on(eventHandlers[key], properties[key]);
      }
    }
    map.on(leaflet.Draw.Event.CREATED, onDrawCreate);

    onMounted && onMounted(drawReference.current);

    return () => {
      map.off(leaflet.Draw.Event.CREATED, onDrawCreate);

      for (const key in eventHandlers) {
        if (properties[key]) {
          map.off(eventHandlers[key], properties[key]);
        }
      }
    };
  }, [context, properties]);

  useEffect(() => {
    if (
      isEqual(properties.draw, propertiesReference.current.draw) &&
      isEqual(properties.edit, propertiesReference.current.edit) &&
      properties.position === propertiesReference.current.position
    ) {
    }
    else {
      const { onMounted } = properties;

      onMounted && onMounted(drawReference.current);
    }
  }, [
    properties.draw,
    properties.edit,
    properties.position,
    context,
    properties
  ]);

  return null;
}

/**
 *
 * @param properties
 * @param context
 * @example
 */
function createDrawElement(properties, context) {
  const { layerContainer } = context;
  const {
    draw, edit, position
  } = properties;
  const options = {
    edit: {
      ...edit,
      featureGroup: layerContainer
    }
  };

  if (draw) {
    options.draw = { ...draw };
  }

  if (position) {
    options.position = position;
  }

  return new Control.Draw(options);
}

EditControl.propTypes = {
  ...Object.keys(eventHandlers).reduce((accumulator, value) => {
    accumulator[value] = PropTypes.func;

    return accumulator;
  }, {}),
  draw: PropTypes.shape({
    circle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    marker: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    polygon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    polyline: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    rectangle: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }),
  edit: PropTypes.shape({
    allowIntersection: PropTypes.bool,
    edit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    poly: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    remove: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }),
  leaflet: PropTypes.shape({
    layerContainer: PropTypes.shape({
      addLayer: PropTypes.func.isRequired,
      removeLayer: PropTypes.func.isRequired
    }),
    map: PropTypes.instanceOf(Map)
  }),
  onCreated: PropTypes.func,
  onMounted: PropTypes.func,
  position: PropTypes.oneOf([
    "topright",
    "topleft",
    "bottomright",
    "bottomleft"
  ])
};

export default EditControl;
