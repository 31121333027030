/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @example
 */
export default function MarkerIconCompletion({ className }) {
  return (
    <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M99.9,5.6c-2.4-4.3-51.3,17.6-73.7,54.1c-3.1-6.3-4.7-9.4-7.4-13.7C14.5,38.9,0,42.9,0,53.4c16.5,45.1,32.9,49.8,38.8,31.3C60.3,28.8,102.3,10.3,99.9,5.6"
        fill="currentColor"
      />
    </svg>
  );
}
