import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

import useSignedSource from "~/src/hooks/use-signed-source";

/**
 *
 * @param root0 - The root object
 * @param root0.document - The root object
 * @param root0.className - The root object
 * @param root0.childElements - The root object
 * @example
 */
export default function SignedDocumentLink({
  childElements,
  className,
  document,
  ...properties
}) {
  const { src } = useSignedSource(document.file_name);

  return (
    <a
      href={src || ""}
      rel="noreferrer"
      target="_blank"
      className={cn(
        "flex items-center gap-x-1 font-medium text-primary hover:bg-gray-50 focus:outline-none pr-2",
        className
      )}
      {...properties}
    >
      {(childElements) &&
        childElements}

      <ArrowTopRightOnSquareIcon className="size-5 shrink-0" />
    </a>
  );
}
