/**
 *
 * @example
 */
function IMMObaseLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#A0D783" role="none" />

        <path d="M63.6501 63.08H23.8451C20.9951 63.08 18.6201 60.8 18.6201 57.95V17.955C18.6201 15.105 20.9951 12.825 23.8451 12.825H63.6501C66.5001 12.825 68.8751 15.105 68.8751 17.955V57.95C68.8751 60.8 66.5001 63.08 63.6501 63.08ZM23.8451 15.485C22.4201 15.485 21.2801 16.625 21.2801 18.05V58.045C21.2801 59.47 22.4201 60.61 23.8451 60.61H63.6501C65.0751 60.61 66.2151 59.47 66.2151 58.045V17.955C66.2151 16.53 65.0751 15.39 63.6501 15.39H23.8451V15.485Z" fill="white" role="none" />

        <path d="M55.8601 30.21C55.8601 33.725 53.6751 36.48 50.2551 37.62C54.6251 38.76 57.0001 42.275 57.0001 46.265C57.0001 53.39 50.4451 55.29 44.5551 55.29C39.5201 55.29 35.4351 55.29 30.4951 55.29V20.805C35.4351 20.805 39.6151 20.805 44.5551 20.805C50.3501 20.805 55.8601 23.655 55.8601 30.21ZM33.1551 36.385H44.9351C49.0201 36.385 53.3901 35.15 53.3901 30.21C53.3901 25.175 48.8301 23.085 44.5551 23.085H33.1551V36.385ZM33.1551 52.63H44.6501C49.0201 52.63 54.5301 51.585 54.5301 46.17C54.4351 40.85 49.5901 38.855 45.0301 38.855C40.9451 38.855 37.3351 38.855 33.2501 38.855V52.63H33.1551Z" fill="white" role="none" />

        <path d="M7.125 55.195V20.805H13.585V55.195H7.125Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

/**
 *
 * @example
 */
function IMMOmappingLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#64CBC8" role="none" />

        <path d="M57.5702 24.795L44.4602 44.84H43.6052L30.4002 24.795V55.195H27.7402V20.9C28.9752 20.9 29.6402 20.9 30.8752 20.9L43.9852 40.85L56.9052 20.9H60.1352V55.1H57.5702V24.795Z" fill="white" role="none" />

        <path d="M63.6501 62.985H24.0351C21.1851 62.985 18.8101 60.705 18.8101 57.95V18.05C18.8101 15.295 21.1851 13.015 24.0351 13.015H63.6501C66.5001 13.015 68.8751 15.295 68.8751 18.05V57.95C68.8751 60.705 66.5001 62.985 63.6501 62.985ZM24.0351 15.58C22.6101 15.58 21.4701 16.72 21.4701 18.05V57.95C21.4701 59.375 22.6101 60.42 24.0351 60.42H63.6501C65.0751 60.42 66.2151 59.28 66.2151 57.95V18.05C66.2151 16.625 65.0751 15.58 63.6501 15.58H24.0351Z" fill="white" role="none" />

        <path d="M7.125 55.29V20.995H13.585V55.385H7.125V55.29Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

/**
 *
 * @example
 */
function IMMOvaluationLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#7BA3DA" role="none" />

        <path d="M59.5652 20.995L45.5052 55.195H42.6552L28.5952 20.995H31.4452L44.0802 52.25L56.7152 20.995H59.5652Z" fill="white" role="none" />

        <path d="M63.65 62.89H24.225C21.375 62.89 19 60.61 19 57.855V18.145C19 15.39 21.28 13.11 24.225 13.11H63.65C66.5 13.11 68.875 15.39 68.875 18.145V57.855C68.875 60.61 66.595 62.89 63.65 62.89ZM24.225 15.675C22.8 15.675 21.66 16.815 21.66 18.145V57.855C21.66 59.28 22.8 60.325 24.225 60.325H63.65C65.075 60.325 66.215 59.185 66.215 57.855V18.145C66.215 16.72 65.075 15.675 63.65 15.675H24.225Z" fill="white" role="none" />

        <path d="M7.125 55.195V20.805H13.585V55.1H7.125V55.195Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

/**
 *
 * @example
 */
function ImabisLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#EA7600" role="none" />

        <path d="M62.415 68.875H13.585C10.07 68.875 7.125 66.025 7.125 62.605V13.395C7.125 9.975 9.975 7.125 13.585 7.125H62.51C66.025 7.125 68.97 9.975 68.97 13.395V62.605C68.875 66.025 66.025 68.875 62.415 68.875ZM13.585 10.26C11.875 10.26 10.45 11.685 10.45 13.395V62.605C10.45 64.315 11.875 65.74 13.585 65.74H62.51C64.22 65.74 65.645 64.315 65.645 62.605V13.395C65.645 11.685 64.22 10.26 62.51 10.26H13.585Z" fill="white" role="none" />

        <path d="M34.105 58.995V17.005H41.99V59.09H34.105V58.995Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

/**
 *
 * @example
 */
function IMMOstatsLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#71CB97" role="none" />

        <path d="M63.6498 62.985H24.1298C21.2798 62.985 18.9048 60.705 18.9048 57.95V18.05C18.9048 15.295 21.1848 13.015 24.1298 13.015H63.6498C66.4998 13.015 68.8748 15.295 68.8748 18.05V57.95C68.8748 60.705 66.4998 62.985 63.6498 62.985ZM24.1298 15.58C22.7048 15.58 21.5648 16.72 21.5648 18.05V57.95C21.5648 59.375 22.7048 60.42 24.1298 60.42H63.6498C65.0748 60.42 66.2148 59.28 66.2148 57.95V18.05C66.2148 16.625 65.0748 15.58 63.6498 15.58H24.1298Z" fill="white" role="none" />

        <path d="M54.5297 27.455C52.0597 23.465 48.0697 22.895 44.1747 22.8C40.1847 22.8 33.8197 24.225 33.8197 29.735C33.8197 34.105 38.4747 35.15 44.2697 36.195C50.8247 37.43 57.7597 38.665 57.7597 45.98C57.6647 53.675 49.9697 55.575 44.1747 55.575C38.8547 55.575 32.7747 53.39 30.1147 48.165L32.4897 47.025C34.5797 51.205 39.8997 53.2 44.1747 53.2C48.3547 53.2 55.0997 52.06 55.0997 45.98C55.0997 40.85 49.3997 39.71 43.7947 38.665C37.5247 37.43 31.1597 36.195 31.1597 29.83C30.9697 22.61 38.5697 20.33 44.1747 20.33C49.0197 20.33 53.2947 21.09 56.7147 26.125L54.5297 27.455Z" fill="white" role="none" />

        <path d="M7.125 55.385V21.09H13.585V55.48H7.125V55.385Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

/**
 *
 * @example
 */
function IMMOfarmingLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#47939D" role="none" />

        <path d="M63.7451 62.7H24.6051C21.7551 62.7 19.4751 60.42 19.4751 57.665V18.335C19.4751 15.58 21.7551 13.3 24.6051 13.3H63.7451C66.5951 13.3 68.8751 15.58 68.8751 18.335V57.76C68.8751 60.515 66.5951 62.7 63.7451 62.7ZM24.6051 15.77C23.1801 15.77 22.0401 16.91 22.0401 18.24V57.665C22.0401 58.995 23.1801 60.135 24.6051 60.135H63.7451C65.1701 60.135 66.3101 58.995 66.3101 57.665V18.335C66.3101 17.005 65.1701 15.865 63.7451 15.865H24.6051V15.77Z" fill="white" role="none" />

        <path d="M36.005 36.86H56.335V39.425H36.005V55.385H33.25V20.14H57V22.8H36.005V36.86Z" fill="white" role="none" />

        <path d="M7.125 55.29V20.995H13.585V55.29H7.125Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

/**
 *
 * @example
 */
function IMMOdeveloperLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#822C42" role="none" />

        <path d="M63.6498 62.985H24.1298C21.2798 62.985 18.9048 60.705 18.9048 57.95V18.05C18.9048 15.295 21.1848 13.015 24.1298 13.015H63.6498C66.4998 13.015 68.8748 15.295 68.8748 18.05V57.95C68.8748 60.705 66.4998 62.985 63.6498 62.985ZM24.1298 15.58C22.7048 15.58 21.5648 16.72 21.5648 18.05V57.95C21.5648 59.375 22.7048 60.42 24.1298 60.42H63.6498C65.0748 60.42 66.2148 59.28 66.2148 57.95V18.05C66.2148 16.625 65.0748 15.58 63.6498 15.58H24.1298Z" fill="white" role="none" />

        <path d="M59.9451 37.335C60.1351 46.36 54.3401 55.575 41.8951 55.575C37.9051 55.575 33.8201 55.575 29.8301 55.575V19.95C33.8201 19.95 37.9051 19.95 41.8951 19.95C53.9601 19.95 59.7551 28.595 59.9451 37.335ZM32.5851 22.515V52.915H41.8951C52.4401 52.915 57.3801 44.935 57.1901 37.335C57.0001 29.925 52.1551 22.515 41.8951 22.515H32.5851Z" fill="white" role="none" />

        <path d="M7.125 55.005V20.71H13.585V55.005H7.125Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

/**
 *
 * @example
 */
function WirtschaftsCompassLogo() {
  return (
    <svg className="size-full" fill="none" height="0" role="none" viewBox="0 0 76 76" width="0" xmlns="http://www.w3.org/2000/svg">
      <g role="none">
        <path d="M67.355 76H8.645C3.895 76 0 72.105 0 67.355V8.645C0 3.895 3.895 0 8.645 0H67.355C72.105 0 76 3.895 76 8.645V67.355C76 72.105 72.105 76 67.355 76Z" fill="#025A84" role="none" />

        <path d="M38 9.5C22.23 9.5 9.5 22.23 9.5 38C9.5 53.77 22.23 66.5 38 66.5C53.77 66.5 66.5 53.77 66.5 38C66.5 22.23 53.77 9.5 38 9.5ZM38 64.505C23.37 64.505 11.495 52.63 11.495 38C11.495 23.37 23.37 11.495 38 11.495C52.63 11.495 64.505 23.37 64.505 38C64.505 52.63 52.63 64.505 38 64.505Z" fill="white" role="none" />

        <path d="M46.1702 43.7L56.0502 50.635C52.0602 56.335 45.4102 60.04 37.9052 60.04C25.7452 60.04 15.8652 50.16 15.8652 38C15.8652 25.745 25.7452 15.865 37.9052 15.865C45.4102 15.865 52.0602 19.57 56.0502 25.27L46.1702 32.3C44.3652 29.735 41.3252 28.025 38.0002 28.025C32.4902 28.025 28.0252 32.49 28.0252 38C28.0252 43.51 32.4902 47.975 38.0002 47.975C41.4202 47.975 44.3652 46.265 46.1702 43.7Z" fill="white" role="none" />
      </g>
    </svg>
  );
}

export {
  IMMObaseLogo, IMMOdeveloperLogo, IMMOfarmingLogo, IMMOmappingLogo, IMMOstatsLogo, IMMOvaluationLogo, ImabisLogo, WirtschaftsCompassLogo
};
