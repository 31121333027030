import { fundingFilterLabels } from "~/src/modules/labels";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function FundingFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const funding = queryItems.split(",");

  /**
   *
   * @param fund
   * @example
   */
  function handleClick(fund) {
    const newFund = funding.filter((item) => item !== fund).join(",");

    submit("foerderung", newFund);
  }

  return funding.map((fund, index) => (
    <DefaultFilterTag handleClick={() => handleClick(fund)} key={`fund_tag_${index}`}>
      <span>Förderungen: {fundingFilterLabels[fund]}</span>
    </DefaultFilterTag>
  ));
}
