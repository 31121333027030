import { useParams } from "react-router-dom";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

import ProjectsMapTile from "../projects-map-tile";

/**
 *
 * @example
 */
const ProjectOverviewMap = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    automated, slug
  } = project;

  return (
    <ContentSection className="h-96" id="map">
      <ProjectsMapTile automated={automated} handleCheckbox={() => {}} isSimple={true} projectSlugs={slug} />
    </ContentSection>
  );
};

export default ProjectOverviewMap;
