import { formatEuro } from "~/src/modules/formatters";

const SalePrice = ({ label, value }) => (
  <div>
    <p className="mt-0.5 font-bold">{formatEuro(value)}</p>

    <p className="text-xs leading-none">{label}</p>
  </div>
);

export default SalePrice;
