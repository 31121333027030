import { permuteArrayIndex } from "~/src/lib/standard";

/**
 *
 * @param keyLabelComponents
 * @param labelComponentPositions
 * @example
 */
const getLabelComponents = (keyLabelComponents, labelComponentPositions) => {
  const labelComponents = keyLabelComponents.map(([_, labelComponent]) => labelComponent);

  return labelComponentPositions
    ? permuteArrayIndex(labelComponents, labelComponentPositions)
    : labelComponents;
};

export default getLabelComponents;
