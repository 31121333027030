import {
  Dialog, DialogBackdrop, DialogPanel, DialogTitle
} from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { useRef, useState } from "react";

import { showSuccess } from "~/src/modules/toast-helper";

import { handleCreate } from "./handlers";

/**
 *
 * @param root0 - The root object
 * @param root0.show - Indicates if the modal is shown
 * @param root0.hideModal - Function to hide the modal
 * @param root0.mutateWatchlists - Function to mutate watchlists
 * @example
 */
export default function NewWatchlistModal({
  hideModal, mutateWatchlists, show
}) {
  const cancelButtonReference = useRef(null);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  /**
   *
   * @param e
   * @example
   */
  function handleSubmit(e) {
    e.preventDefault();

    if (!isLoading && !formDisabled) {
      setIsLoading(true);
      handleCreate(name, handleSuccess, handleError);
    }
  }

  /**
   *
   * @param e
   * @example
   */
  function handleChange(e) {
    const { value } = e.target;

    if (value.length === 0) {
      setIsError(false);
      setName(value);
    }
    if (value.length === 1 && /[\dA-Za-z]/u.test(value)) {
      setIsError(false);
      setName(value);
    }

    if (value.length > 1 && /^[\d A-Za-z]+$/u.test(value)) {
      setIsError(false);
      setName(value.replaceAll(/ {2,}/gu, " "));
    }
  }

  /**
   *
   * @example
   */
  function handleHide() {
    setIsLoading(false);
    setName("");
    setIsError(false);
    hideModal();
  }

  /**
   *
   * @example
   */
  function handleSuccess() {
    showSuccess();
    mutateWatchlists();
    handleHide();
  }

  /**
   *
   * @param status
   * @example
   */
  function handleError(status) {
    setIsLoading(false);
    setIsError(status);
  }

  const formDisabled = name.length === 0;

  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={cancelButtonReference}
      onClose={handleHide}
      open={show}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex max-h-full w-full flex-col bg-white p-6 sm:max-h-full sm:max-w-xl sm:rounded-lg"
        >
          <div>
            <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
              Neue Merkliste
            </DialogTitle>

            <form onSubmit={handleSubmit}>
              <div className="mt-5">
                <div className="relative rounded-md shadow-sm">
                  <input
                    autoFocus
                    autoComplete="off"
                    id="name"
                    maxLength={60}
                    name="name"
                    onChange={handleChange}
                    placeholder="Name der Merkliste..."
                    ref={cancelButtonReference}
                    type="text"
                    value={name}
                    className={cn(
                      "block w-full pr-10 focus:ring-0 focus:outline-none sm:text-sm rounded-md",
                      {
                        "border-gray-300 focus:border-gray-300": !isError,
                        "border-primary text-primary placeholder-primary focus:border-primary": isError
                      }
                    )}
                  />

                  {isError && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon className="size-5 text-primary" />
                    </div>
                  )}
                </div>

                <p className="mt-1 text-right text-xs text-gray-700">{name.length} / 60</p>

                <p className="mt-2 h-4 text-sm text-primary">
                  {isError && isError === 409 && (
                    <span>Eine Merkliste mit diesem Namen existiert bereits!</span>
                  )}

                  {isError && isError !== 409 && (
                    <span>Merkliste konnte nicht gespeichert werden!</span>
                  )}
                </p>
              </div>
            </form>
          </div>

          <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
            <button
              disabled={formDisabled}
              onClick={handleSubmit}
              type="button"
              className={cn(
                "inline-flex w-full justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
                {
                  "cursor-not-allowed": formDisabled
                }
              )}
            >
              {isLoading
                ? (
                  <svg
                    className="size-5 animate-spin text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx={12}
                      cy={12}
                      r={10}
                      stroke="currentColor"
                      strokeWidth={4}
                    />

                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                )
                : (
                  <span>Hinzufügen</span>
                )}
            </button>

            <button
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={handleHide}
              type="button"
            >
              Abbrechen
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
