/* eslint-disable no-magic-numbers */

/* eslint-disable import/exports-last */
import { projectCols } from "./columns-spec.js";

const applyStylesAndFormatToColumn = (colKey, columns, sheet) => {
  const col = columns[colKey];
  const sheetCol = sheet.getColumn(col.number);

  sheetCol.font = { size: 12 };
  sheetCol.width = Number.parseInt((col.label.length + 5) * 1.1);
  sheetCol.alignment = {
    horizontal: "left",
    vertical: "middle"
  };
  if (col.formatType === "numFmt") {
    sheetCol.numFmt = col.formatString;
  }
};

/**
 *
 * @param workbook
 * @param projects
 * @example
 */
export default function projectsSheetGenerator(workbook, projects) {
  const projectsSheet = workbook.addWorksheet("Exportierte Projekte");

  const headerRow = projectsSheet.addRow();

  for (const colKey in projectCols) {
    const col = projectCols[colKey];

    headerRow.getCell(col.number).value = col.label;
    applyStylesAndFormatToColumn(colKey, projectCols, projectsSheet);
  }

  for (const project of projects) {
    const row = projectsSheet.addRow();

    for (const colKey in projectCols) {
      const col = projectCols[colKey];
      const cell = row.getCell(col.number);
      const value = col.get(project);

      // todo: parseFloat should be done in the getter
      if (col.formatType === "numFmt" && value !== "") {
        cell.value = Number.parseFloat(value);
      }
      else if (col.formatType === "boolean" && value !== "") {
        cell.value = (value)
          ? "Ja"
          : "Nein";
      }
      else {
        cell.value = value;
      }
    }
  }
  headerRow.font = {
    bold: true,
    size: 12
  };

  return null;
}
