import {
  Dialog, DialogBackdrop, DialogPanel, DialogTitle
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { useRef, useState } from "react";

import { showError, showSuccess } from "~/src/modules/toast-helper";

import { handleDelete } from "./handlers";

/**
 *
 * @param root0 - The root object
 * @param root0.watchlist - The watchlist object
 * @param root0.project - The project object
 * @param root0.show - Boolean indicating if the modal is shown
 * @param root0.hideModal - Function to hide the modal
 * @param root0.mutateWatchlist - Function to mutate the watchlist
 * @param root0.mutateWatchlists - Function to mutate the watchlists
 * @example
 */
export default function DeleteWatchlistItemModal({
  hideModal,
  mutateWatchlist,
  mutateWatchlists,
  project,
  show,
  watchlist
}) {
  const cancelButtonReference = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Handles the submit event when deleting an item.
   *
   * @param e - The event object
   * @example
   */
  function handleSubmit(e) {
    e.preventDefault();

    if (!isLoading) {
      setIsLoading(true);
      handleDelete(watchlist, project, handleSuccess, handleError);
    }
  }

  /**
   * Resets loading state and hides the modal.
   *
   * @example
   */
  function handleHide() {
    setIsLoading(false);
    hideModal();
  }

  /**
   * Called upon successful deletion.
   *
   * @example
   */
  function handleSuccess() {
    handleHide();
    mutateWatchlist();
    mutateWatchlists();
    showSuccess();
  }

  /**
   * Called when an error occurs during deletion.
   *
   * @example
   */
  function handleError() {
    setIsLoading(false);
    showError();
  }

  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={cancelButtonReference}
      onClose={handleHide}
      open={show}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex max-h-full w-full flex-col bg-white p-6 sm:max-h-full sm:max-w-xl sm:rounded-lg"
        >
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-primary-yellow sm:mx-0 sm:size-10">
              <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-primary-yellow-dark" />
            </div>

            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <DialogTitle as="h3" className="mt-2 text-lg font-medium leading-6 text-gray-900">
                Projekt entfernen?
              </DialogTitle>

              <div className="mt-2">
                <p className="text-gray-500">
                  Sind Sie sicher, dass dieses Projekt aus der Merkliste entfernt werden soll?
                </p>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              disabled={isLoading}
              onClick={handleSubmit}
              type="button"
              className={cn(
                "inline-flex w-full justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
                {
                  "cursor-not-allowed": isLoading
                }
              )}
            >
              {isLoading
                ? (
                  <svg
                    className="size-5 animate-spin text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx={12}
                      cy={12}
                      r={10}
                      stroke="currentColor"
                      strokeWidth={4}
                    />

                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                )
                : (
                  <span>Entfernen</span>
                )}
            </button>

            <button
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={handleHide}
              ref={cancelButtonReference}
              type="button"
            >
              Abbrechen
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
