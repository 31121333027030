import { useEffect, useState } from "react";

import API from "~/src/modules/api";

/**
 *
 * @param fileName
 * @example
 */
export default function UseSignedSource(fileName) {
  const [source, setSource] = useState(null);

  useEffect(() => {
    const controller = new AbortController();

    getSignedUrl(fileName, controller.signal)
      .then((res) => {
        setSource(res);
      })
      .catch((error) => {
      });

    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    src: source
  };
}

/**
 *
 * @param fileName
 * @param signal
 * @example
 */
function getSignedUrl(fileName, signal) {
  const file = {
    name: fileName
  };

  return new Promise((resolve, reject) => {
    API.post("/files/sign-download", { file }, {
      signal
    })
      .then((res) => {
        resolve(res.data.payload?.url);
      })
      .catch((error) => {
        resolve(null);
      });
  });
}
