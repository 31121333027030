import { formatArea } from "~/src/modules/formatters";

import { gkaNotices } from "./labels";

/**
 *
 * @param arr
 * @param array
 * @param key
 * @example
 */
const getUnique = (array, key) => [...new Map(array.map((item) => [item[key], item])).values()];

/**
 *
 * @param latLngs
 * @example
 */
const parsePolygon = (latLngs) => {
  let polygon = "";
  let polygonStart = "";

  for (const [index, latLng] of latLngs[0].entries()) {
    const coordinate = `${latLng.lng}~${latLng.lat}`;

    if (index === 0) {
      polygonStart = coordinate;
      polygon += polygonStart;

      continue;
    }
    polygon += `-${coordinate}`;
  }
  polygon += `-${polygonStart}`;

  return polygon;
};

/**
 *
 * @param project
 * @example
 */
const displayRechtsform = (project) => {
  if (project?.rentable && project?.buyable) {
    return "Eigentum, Miete";
  }
  if (project?.rentable) {
    return "Miete";
  }
  if (project?.buyable) {
    return "Eigentum";
  }

  return "nicht bekannt";
};

/**
 *
 * @param value
 * @example
 */
const castArray = (value) => (Array.isArray(value) ? value : [value]);

/**
 *
 * @param project
 * @param withBreak
 * @example
 */
const generateGkaNotice = (project, withBreak = false) => {
  const notices = [];

  const hasBaurecht = (
    project?.transaction_filter === "baurecht" ||
    project?.transactions.some(({ transaction_category: { code } }) => code === "baurecht")
  );

  if (hasBaurecht) {
    notices.push("(keine Berechnung möglich)");

    notices.push(["Eine Berechnung des Grundkostenanteil ist nicht möglich,", "da es sich bei der Projektliegenschaft um eine Baurechtsliegenschaft handelt."].join(withBreak ? "<br>" : " "));
  }
  else if (project?.gka && Number.parseFloat(project?.gka) !== 0) {
    notices.push(gkaNotices[project?.gka_from]);

    if (project?.gka_notes) {
      notices.push(project.gka_notes.replaceAll("\n", "<br>"));
    }
  }
  else if (project?.gka_notes) {
    notices.push("(keine Berechnung möglich)");
    notices.push(project.gka_notes.replaceAll("\n", "<br>"));
  }

  return notices;
};

/**
 *
 * @param props - The root object
 * @param props.area - The root object
 * @param props.toArea - The root object
 * @example
 */
const areaRange = ({ area, toArea }) => {
  if (area && toArea) {
    return `${formatArea(area)} - ${formatArea(toArea)}`;
  }

  if (area) {
    return `${formatArea(area)}`;
  }

  return "-";
};

export {
  areaRange,
  castArray,
  displayRechtsform,
  generateGkaNotice,
  getUnique,
  parsePolygon
};
