import {
  Dialog, DialogBackdrop, DialogPanel, DialogTitle
} from "@headlessui/react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { useRef, useState } from "react";

import API from "~/src/modules/api";

import useStore from "~/src/hooks/use-store";

/**
 *
 * @param root0 - The root object
 * @param root0.isOpen - Indicates if the modal is open
 * @example
 */
export default function LogoutModal({ isOpen = false }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleLogoutModal = useStore((state) => state.toggleLogoutModal);

  const initialInputReference = useRef(null);

  /**
   * Handles closing the modal.
   *
   * @example
   */
  function handleClose() {
    toggleLogoutModal();
  }

  /**
   * Handles the logout action.
   *
   * @param e - The event object
   * @example
   */
  async function handleLogout(e) {
    e.preventDefault();

    setIsSubmitting(true);
    const res = await API.post("/auth/logout");

    window.location.replace(res.data.payload.logoutUrl);
  }

  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={initialInputReference}
      onClose={handleClose}
      open={isOpen}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex max-h-full w-full flex-col bg-white p-6 sm:max-h-full sm:max-w-xl sm:rounded-lg"
        >
          <form onSubmit={handleLogout}>
            <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4">
              <div className="hidden size-12 items-center justify-center rounded-full border-2 border-primary md:flex">
                <ArrowRightOnRectangleIcon aria-hidden="true" className="size-6 text-primary" />
              </div>

              <div className="mt-1 sm:col-span-4 sm:mt-2">
                <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Logout
                </DialogTitle>

                <div className="mt-1 space-y-1">
                  <p className="text-sm text-gray-500">
                    Sind Sie sicher, dass Sie sich ausloggen möchten?
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-5 sm:mt-6">
              <div className="flex justify-end">
                <button
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={handleClose}
                  type="button"
                >
                  Abbrechen
                </button>

                <button
                  disabled={isSubmitting}
                  type="submit"
                  className={cn(
                    "ml-3 inline-flex w-44 justify-center rounded-md border border-transparent bg-primary py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-lighter focus:ring-offset-2",
                    {
                      "cursor-not-allowed": isSubmitting
                    }
                  )}
                >
                  {isSubmitting
                    ? (
                      <svg
                        className="-ml-1 mr-3 size-5 animate-spin text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />

                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    )
                    : (
                      <span>Logout</span>
                    )}
                </button>
              </div>
            </div>
          </form>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
