import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.dateType - The root object
 * @param root0.onChange - The root object
 * @param root0.checked - The root object
 * @param root0.isMobile - The root object
 * @example
 */
const PurchaseNonProfitCheckbox = ({
  checked = false, isMobile, onChange = () => null
}) => {
  const handleChange = (value) => {
    onChange({
      onlyNonProfit: value
    });
  };

  return (
    <div
      className={cn("flex flex-row w-full items-center gap-2", {
        "justify-between": !isMobile,
        "justify-start": isMobile
      })}
    >
      <input
        checked={checked}
        className="size-4 cursor-pointer rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
        id="nonProfit"
        name="nonProfit"
        onChange={() => handleChange(!checked)}
        type="checkbox"
      />

      <div className="flex items-start">
        <label className="flex cursor-pointer items-center gap-1 text-sm text-gray-600" htmlFor="nonProfit">
          <span>Nur gemeinnützige Transaktionen anzeigen</span>
        </label>
      </div>

    </div>
  );
};

export default PurchaseNonProfitCheckbox;
