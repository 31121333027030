import { useEffect, useState } from "react";

/**
 *
 * @param targetKey
 * @param cb
 * @param callback
 * @example
 */
export default function useKeyPress(targetKey, callback) {
  const [keyPressed, setKeyPressed] = useState(false);

  /**
   * If pressed key is our target key then set to true
   */

  /**
   *
   * @param options0 - The root object
   * @param options0.key - The root object
   * @example
   */
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  /**
   * If released key is our target key then set to false
   *
   * @param options0 - The root object
   * @param options0.key - The root object
   * @example
   */
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const keyAction = (callback_) => {
    if (keyPressed) {
      callback_();
    }
  };

  useEffect(() => {
    if (keyPressed) {
      callback();
    }
  }, [keyPressed, callback]);

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return keyPressed;
}
