import cn from "classnames";

const ProjectApartmentsCount = ({ currentSortValue, project }) => (
  <div className="flex text-xs">
    <div className="w-[200px]">
      <p
        className={cn(
          "font-semibold",
          {
            "text-gray-600": currentSortValue !== "countWohnungen",
            "text-primary": currentSortValue === "countWohnungen"
          }
        )}
      >
        {project.automated ? "Erfasste Transaktionen" : "Gesamtanzahl Wohneinheiten"}:
      </p>
    </div>

    <div>
      <p className={cn("", { "text-primary font-semibold": currentSortValue === "countWohnungen" })}>
        {project.count_wohnungen ?? "-"}
      </p>
    </div>
  </div>
);

export default ProjectApartmentsCount;
