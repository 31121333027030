import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";
/* eslint-disable no-magic-numbers */
import { useParams } from "react-router-dom";

/**
 *
 * @example
 */
const ProjectOverviewEnergyClasses = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    energy_classes: energyClasses
  } = project;

  return (
    <ContentSection id="energyClasses" title="Energieausweise">
      <ul className="flex flex-col gap-1.5">
        {
          energyClasses.length === 0
            ? "-"
            : energyClasses
              .map((
                {
                  description,
                  fgee_class: fgeeClass,
                  fgee_from: fgeeFromNumber,
                  fgee_to: fgeeToNumber,
                  gst_numbers: gstNumbers,
                  hwb_class: hwbClass,
                  hwb_from: hwbFromNumber,
                  hwb_to: hwbToNumber
                },
                index
              ) => {
                const [hwbFrom, hwbTo] = [hwbFromNumber, hwbToNumber].map((number) => (number ? number.toFixed(2).replace(".", ",") : null));

                const [fgeeFrom, fgeeTo] = [fgeeFromNumber, fgeeToNumber].map((number) => (number ? number.toFixed(3).replace(".", ",") : null));

                return (
                  <li className="flex flex-col" key={index}>
                    <span>
                      {
                        (hwbFrom || hwbClass) && (
                          <>
                            <span className="font-semibold">HWB: </span>

                            <span>{hwbTo ? `${hwbFrom} - ${hwbTo}` : hwbFrom || "-"} </span>

                            <span className="font-semibold">Klasse: </span>

                            <span>{hwbClass}</span>
                          </>
                        )
                      }
                    </span>

                    <span>
                      {
                        (fgeeFrom || fgeeClass) && (
                          <span>
                            <span className="font-semibold">fGEE: </span>

                            <span>{fgeeTo ? `${fgeeFrom} - ${fgeeTo}` : fgeeFrom || "-"} </span>

                            <span className="font-semibold">Klasse: </span>

                            <span>{fgeeClass}</span>
                          </span>
                        )
                      }
                    </span>

                    <span>
                      {
                        gstNumbers.length > 0 && (
                          <>
                            <span className="font-semibold">GST-Nr.: </span>

                            <span>&nbsp;</span>

                            <span>{gstNumbers.join(",")} </span>
                          </>
                        )
                      }
                    </span>

                    <span>
                      {
                        description && (
                          <>
                            <span className="font-semibold">Bereich/Objekt: </span>

                            <span className="font-semibold">{description}</span>
                          </>
                        )
                      }
                    </span>

                  </li>
                );
              })
        }
      </ul>

    </ContentSection>
  );
};

export default ProjectOverviewEnergyClasses;
