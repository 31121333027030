import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

/**
 *
 * @example
 */
const ProjectOverviewConstructionInfos = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    construction_infos: {
      construction_start_date: constructionStartDate,
      construction_start_date_format: constructionStartDateFormat,
      construction_year: constructionYear,
      estimated_finish_date: estimatedFinishDate,
      estimated_finish_date_format: estimatedFinishDateFormat,
      is_new: isNew
    }
  } = project;

  return (
    <ContentSection id="constructionInfos" title="Bauinformationen">
      <ul className="flex flex-col gap-0.5">
        <li>{isNew ? "Neubau" : "Sanierung"}</li>

        <li>
          Baubewilligung: {constructionYear ? dateFormatter(constructionYear, "year") : "-"}
        </li>

        <li>
          Geplanter Baubeginn: {constructionStartDate ? dateFormatter(constructionStartDate, constructionStartDateFormat) : "-"}
        </li>

        <li>
          Geplante Fertigstellung: {estimatedFinishDate ? dateFormatter(estimatedFinishDate, estimatedFinishDateFormat) : "-"}
        </li>
      </ul>
    </ContentSection>
  );
};

export default ProjectOverviewConstructionInfos;
