import useWatchlists from "~/src/hooks/use-watchlists";

import Skeleton from "~/src/ui/loading/item-skeleton";

import ProjectListItem from "./watchlist-project-list-item";

/**
 *
 * @param root0 - The root object
 * @param root0.isLoading - The root object
 * @param root0.projects - The root object
 * @param root0.watchlist - The root object
 * @param root0.mutateWatchlist - The root object
 * @param root0.handleSingleMapProject - The root object
 * @example
 */
export default function ProjectList({
  handleSingleMapProject = () => null, isLoading, mutateWatchlist, projects = [], watchlist
}) {
  const { mutate: mutateWatchlists } = useWatchlists();

  if (isLoading) {
    return (
      <div className="mt-2">
        <Skeleton />
      </div>
    );
  }

  if (projects.length === 0) {
    return null;
  }

  return (
    <div className="mt-2 divide-y divide-gray-300 rounded border border-gray-200 bg-white shadow-sm sm:divide-gray-300 lg:mx-12">

      {projects.map((project) => (
        <ProjectListItem
          handleSingleMapProject={handleSingleMapProject}
          key={`project_${project.slug}`}
          mutateWatchlist={mutateWatchlist}
          mutateWatchlists={mutateWatchlists}
          project={project}
          watchlist={watchlist}
        />
      ))}

    </div>
  );
}
