import React from "react";
import { useParams } from "react-router-dom";

import { formatArea } from "~/src/modules/formatters";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

/**
 *
 * @example
 */
// eslint-disable-next-line max-lines-per-function
const ProjectOverviewProperties = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    einlagen
  } = project;

  const hasBaurecht = Object.entries(einlagen).some(([, einlage]) => einlage.baurecht);

  return (

    (einlagen.length > 0)
      ? ((hasBaurecht)
        ? (
          <ContentSection id="properties" title="Liegenschaften">
            <div className="grid grid-cols-6 gap-x-8 md:grid-cols-9">
              <span className="col-span-1 font-bold md:col-span-2">BG</span>

              <span className="col-span-1 font-bold md:col-span-2">KG</span>

              <span className="col-span-1 font-bold">EZ</span>

              <span className="col-span-1 font-bold">BREZ</span>

              <span className="col-span-1 font-bold">Fläche</span>

              <span className="col-span-1 font-bold md:col-span-2">GST-Nr.</span>

              {
                einlagen
                  .map((einlage) => Object.fromEntries(Object.entries(einlage).filter(([key, value]) => value !== null)))
                  .map(({
                    id: einlageId,
                    area,
                    baurecht,
                    br_katastralgemeinde: {
                      code: brKatastralgemeindeCode,
                      name: brKatastralgemeindeName,
                      bezirksgericht: {
                        code: brBezirksgerichtCode,
                        name: brBezirksgerichtName
                      } = {}
                    } = {},
                    brez_number: brezNumber,
                    ez_number: ez,
                    gst_numbers: gstNumbers,
                    katastralgemeinde: {
                      bezirksgericht: {
                        code: bezirksgerichtCode,
                        name: bezirksgerichtName
                      },
                      code: katastralgemeindeCode,
                      name: katastralgemeindeName
                    }
                  }) => (
                    <React.Fragment key={einlageId}>
                      <span className="col-span-1 break-words md:col-span-2">
                        {bezirksgerichtName} ({bezirksgerichtCode})
                      </span>

                      <span className="col-span-1 break-words md:col-span-2">
                        {katastralgemeindeName} ({katastralgemeindeCode})
                      </span>

                      <span className="col-span-1">
                        {ez}
                      </span>

                      <span className="col-span-1">
                        {brezNumber || ""}
                      </span>

                      <span className="col-span-1 text-right md:whitespace-nowrap">
                        {formatArea(area)}
                      </span>

                      <span className="col-span-1 md:col-span-2">
                        {gstNumbers.join(", ")}
                      </span>
                    </React.Fragment>
                  ))
              }

            </div>
          </ContentSection>
        )
        : (
          <ContentSection id="properties" title="Liegenschaften">
            <div className="grid grid-cols-5 gap-x-8 md:grid-cols-8">
              <span className="col-span-1 font-bold md:col-span-2">BG</span>

              <span className="col-span-1 font-bold md:col-span-2">KG</span>

              <span className="col-span-1 font-bold">EZ</span>

              <span className="col-span-1 font-bold">Fläche</span>

              <span className="col-span-1 font-bold md:col-span-2">GST-Nr.</span>

              {
                einlagen
                  .map((einlage) => Object.fromEntries(Object.entries(einlage).filter(([key, value]) => value !== null)))
                  .map(({
                    id: einlageId,
                    area,
                    baurecht,
                    br_katastralgemeinde: {
                      code: brKatastralgemeindeCode,
                      name: brKatastralgemeindeName,
                      bezirksgericht: {
                        code: brBezirksgerichtCode,
                        name: brBezirksgerichtName
                      } = {}
                    } = {},
                    brez_number: brezNumber,
                    ez_number: ez,
                    gst_numbers: gstNumbers,
                    katastralgemeinde: {
                      bezirksgericht: {
                        code: bezirksgerichtCode,
                        name: bezirksgerichtName
                      },
                      code: katastralgemeindeCode,
                      name: katastralgemeindeName
                    }
                  }) => (
                    <React.Fragment key={einlageId}>
                      <span className="col-span-1 break-words md:col-span-2">
                        {bezirksgerichtName} ({bezirksgerichtCode})
                      </span>

                      <span className="col-span-1 break-words md:col-span-2">
                        {katastralgemeindeName} ({katastralgemeindeCode})
                      </span>

                      <span className="col-span-1">
                        {ez}
                      </span>

                      <span className="col-span-1 md:whitespace-nowrap">
                        {formatArea(area)}
                      </span>

                      <span className="col-span-1 md:col-span-2">
                        {gstNumbers.join(", ")}
                      </span>
                    </React.Fragment>
                  ))
              }

            </div>
          </ContentSection>
        ))
      : (
        <ContentSection id="properties" title="Liegenschaften">
          -
        </ContentSection>
      )
  );
};

export default ProjectOverviewProperties;
