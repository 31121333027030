import { useState } from "react";

/**
 *
 * @example
 */
export default function useFilterModals() {
  const [regionFilterModal, setRegionFilterModal] = useState(false);
  const [finishYearModal, setFinishYearModal] = useState(false);
  const [projectPhaseModal, setProjectPhaseModal] = useState(false);
  const [rechtsformModal, setRechtsformModal] = useState(false);
  const [quantityModal, setQuantityModal] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [fundingModal, setFundingModal] = useState(false);
  const [constructionTypeModal, setConstructionTypeModal] = useState(false);
  const [objectTypeModal, setObjectTypeModal] = useState(false);
  const [esgCertificatesFilterModal, setEsgCertificatesFilterModal] = useState(false);

  return {
    constructionTypeModal,
    esgCertificatesFilterModal,
    finishYearModal,
    fundingModal,
    objectTypeModal,
    projectPhaseModal,
    quantityModal,
    rechtsformModal,
    regionFilterModal,
    setConstructionTypeModal,
    setEsgCertificatesFilterModal,
    setFinishYearModal,
    setFundingModal,
    setObjectTypeModal,
    setProjectPhaseModal,
    setQuantityModal,
    setRechtsformModal,
    setRegionFilterModal,
    setTransactionModal,
    transactionModal
  };
}
