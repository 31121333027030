export const austriaBorderGeoJson = {
  features: [
    {
      id: "AUT",
      geometry: {
        coordinates: [
          [
            [0, 90],
            [180, 90],
            [180, -90],
            [0, -90],
            [-180, -90],
            [-180, 0],
            [-180, 90],
            [0, 90]
          ],
          [
            [16.945_043, 48.604_166],
            [16.954_345, 48.557_399],
            [16.949_055, 48.544_836],
            [16.946_903, 48.539_726],
            [16.930_47, 48.528_202],
            [16.913_934, 48.519_339],
            [16.906_492, 48.509_908],
            [16.901_221, 48.496_602],
            [16.875_073, 48.471_539],
            [16.864_944, 48.458_077],
            [16.860_81, 48.443_788],
            [16.851_922, 48.390_407],
            [16.849_338, 48.384_102],
            [16.846_858, 48.381_131],
            [16.845_101, 48.376_583],
            [16.844_48, 48.365_602],
            [16.847_581, 48.359_582],
            [16.855_332, 48.356_455],
            [16.875_486, 48.355_034],
            [16.881_171, 48.352_812],
            [16.891_713, 48.347_024],
            [16.901_945, 48.339_402],
            [16.906_492, 48.331_47],
            [16.904_218, 48.327_025],
            [16.900_291, 48.321_238],
            [16.898_431, 48.316_173],
            [16.902_771, 48.314_106],
            [16.905_459, 48.311_988],
            [16.908_869, 48.306_975],
            [16.912_073, 48.301_239],
            [16.913_313, 48.296_691],
            [16.916_621, 48.290_8],
            [16.924_269, 48.287_519],
            [16.933_364, 48.284_728],
            [16.944_423, 48.278_01],
            [16.950_624, 48.276_589],
            [16.954_345, 48.273_127],
            [16.955_275, 48.268_786],
            [16.953_931, 48.257_34],
            [16.954_345, 48.252_56],
            [16.969_951, 48.216_645],
            [16.974_705, 48.198_558],
            [16.974_808, 48.176_88],
            [16.981_94, 48.161_299],
            [17.007_158, 48.142_799],
            [17.020_904, 48.137_166],
            [17.036_923, 48.135_513],
            [17.047_465, 48.130_991],
            [17.062_455, 48.112_724],
            [17.067_206, 48.106_936],
            [17.080_228, 48.097_608],
            [17.069_996, 48.089_159],
            [17.064_829, 48.079_03],
            [17.059_144, 48.060_427],
            [17.063_072, 48.058_773],
            [17.075_061, 48.052_081],
            [17.069_686, 48.035_674],
            [17.092_631, 48.027_25],
            [17.124_98, 48.019_525],
            [17.148_338, 48.005_443],
            [17.085_603, 47.970_148],
            [17.096_145, 47.961_931],
            [17.095_731, 47.955_73],
            [17.090_874, 47.949_477],
            [17.088_083, 47.940_899],
            [17.086_119, 47.939_22],
            [17.076_818, 47.934_956],
            [17.074_13, 47.932_114],
            [17.075_164, 47.927_773],
            [17.080_125, 47.925_758],
            [17.085_396, 47.924_621],
            [17.087_463, 47.922_632],
            [17.083_329, 47.904_829],
            [17.077_541, 47.891_729],
            [17.067_619, 47.881_626],
            [17.051_186, 47.872_893],
            [17.016_563, 47.867_699],
            [17.004_057, 47.863_281],
            [17.004_367, 47.852_377],
            [17.010_672, 47.847_882],
            [17.031_652, 47.841_345],
            [17.039_507, 47.837_365],
            [17.049_119, 47.818_684],
            [17.055_63, 47.812_354],
            [17.040_644, 47.801_114],
            [17.041_988, 47.783_906],
            [17.048_706, 47.763_649],
            [17.050_566, 47.730_989],
            [17.055_527, 47.720_913],
            [17.064_002, 47.713_29],
            [17.075_371, 47.708_484],
            [17.054_804, 47.702_025],
            [16.981_94, 47.695_436],
            [16.902_668, 47.682_026],
            [16.864_738, 47.686_729],
            [16.850_165, 47.712_929],
            [16.836_936, 47.705_358],
            [16.817_299, 47.684_248],
            [16.806_24, 47.676_884],
            [16.797_455, 47.675_463],
            [16.741_128, 47.681_458],
            [16.730_379, 47.685_902],
            [16.719_217, 47.693_731],
            [16.711_569, 47.704_066],
            [16.707_848, 47.714_608],
            [16.702_474, 47.723_6],
            [16.689_865, 47.729_568],
            [16.609_766, 47.750_627],
            [16.567_805, 47.754_192],
            [16.531_115, 47.742_978],
            [16.525_12, 47.733_315],
            [16.526_877, 47.720_137],
            [16.521_399, 47.711_533],
            [16.512_924, 47.706_004],
            [16.473_03, 47.691_767],
            [16.461_145, 47.684_532],
            [16.454_53, 47.681_768],
            [16.449_879, 47.682_414],
            [16.444_918, 47.685_54],
            [16.439_13, 47.690_475],
            [16.431_792, 47.685_463],
            [16.416_6, 47.668_823],
            [16.407_815, 47.661_33],
            [16.425_901, 47.654_276],
            [16.481_919, 47.638_954],
            [16.509_617, 47.643_14],
            [16.575_453, 47.624_949],
            [16.608_422, 47.628_773],
            [16.630_23, 47.622_004],
            [16.647_697, 47.606_139],
            [16.656_172, 47.585_934],
            [16.650_59, 47.566_555],
            [16.667_54, 47.560_096],
            [16.681_39, 47.550_561],
            [16.689_141, 47.538_03],
            [16.688_004, 47.522_63],
            [16.677_359, 47.509_866],
            [16.648_213, 47.501_546],
            [16.636_845, 47.493_2],
            [16.640_875, 47.452_919],
            [16.626_973, 47.445_549],
            [16.589_406, 47.425_633],
            [16.481_919, 47.392_302],
            [16.469_93, 47.405_531],
            [16.456_597, 47.411_836],
            [16.444_091, 47.409_51],
            [16.433_963, 47.396_85],
            [16.436_443, 47.358_506],
            [16.434_273, 47.355_56],
            [16.429_312, 47.353_803],
            [16.424_971, 47.351_116],
            [16.424_351, 47.345_199],
            [16.427_142, 47.341_375],
            [16.431_689, 47.339_721],
            [16.436_133, 47.338_559],
            [16.438_82, 47.336_569],
            [16.469_206, 47.293_238],
            [16.473_237, 47.276_805],
            [16.466_622, 47.263_421],
            [16.452_463, 47.254_584],
            [16.421_354, 47.243_138],
            [16.421_354, 47.243_035],
            [16.424_661, 47.225_594],
            [16.409_262, 47.203_683],
            [16.412_776, 47.187_173],
            [16.418_77, 47.183_659],
            [16.426_728, 47.184_124],
            [16.435_1, 47.183_581],
            [16.441_818, 47.177_121],
            [16.442_128, 47.168_336],
            [16.433_963, 47.151_283],
            [16.433_653, 47.145_754],
            [16.447_089, 47.139_708],
            [16.480_885, 47.150_767],
            [16.497_318, 47.149_681],
            [16.509_514, 47.137_537],
            [16.504_76, 47.125_833],
            [16.481_919, 47.105_24],
            [16.460_835, 47.096_3],
            [16.454_323, 47.081_701],
            [16.461_765, 47.068_498],
            [16.481_919, 47.063_898],
            [16.493_701, 47.059_816],
            [16.497_215, 47.054_622],
            [16.493_184, 47.049_145],
            [16.481_919, 47.044_21],
            [16.437_167, 47.031_782],
            [16.424_661, 47.024_082],
            [16.453_29, 47.021_679],
            [16.467_553, 47.018_423],
            [16.481_919, 47.009_38],
            [16.486_363, 46.998_554],
            [16.467_449, 46.995_427],
            [16.441_404, 46.995_22],
            [16.424_764, 46.993_102],
            [16.424_764, 46.992_998],
            [16.420_424, 46.990_26],
            [16.415_566, 46.989_433],
            [16.410_398, 46.990_415],
            [16.405_024, 46.993_153],
            [16.387_764, 47.002_042],
            [16.366_577, 47.003_825],
            [16.325_856, 47.000_44],
            [16.288_545, 47.005_582],
            [16.274_903, 47.004_315],
            [16.265_394, 46.993_257],
            [16.261_054, 46.978_09],
            [16.252_992, 46.973_516],
            [16.243_07, 46.972_018],
            [16.232_942, 46.966_075],
            [16.230_358, 46.959_977],
            [16.231_185, 46.954_422],
            [16.230_668, 46.948_35],
            [16.223_95, 46.941_064],
            [16.217_025, 46.937_395],
            [16.196_148, 46.931_297],
            [16.170_723, 46.918_533],
            [16.159_148, 46.910_316],
            [16.122_871, 46.876_365],
            [16.110_055, 46.867_916],
            [16.094_035, 46.862_774],
            [16.052_936, 46.846_06],
            [16.032_024, 46.837_556],
            [16.028_441, 46.836_947],
            [15.987_582, 46.830_011],
            [15.981_691, 46.827_685],
            [15.971_976, 46.820_632],
            [15.972_906, 46.818_435],
            [15.977_35, 46.816_213],
            [15.978_177, 46.809_134],
            [15.971_252, 46.778_076],
            [15.970_985, 46.775_05],
            [15.969_702, 46.760_532],
            [15.970_529, 46.743_014],
            [15.982_001, 46.718_545],
            [16.003_291, 46.709_191],
            [16.014_557, 46.693_714],
            [16.016_723, 46.670_691],
            [16.016_593, 46.670_757],
            [15.997_917, 46.686_919],
            [15.986_962, 46.692_19],
            [15.946_344, 46.697_151],
            [15.878_545, 46.720_715],
            [15.850_743, 46.724_488],
            [15.822_941, 46.722_834],
            [15.784_61, 46.712_2],
            [15.755_141, 46.704_024],
            [15.728_683, 46.702_99],
            [15.652_098, 46.710_819],
            [15.635_975, 46.717_563],
            [15.632_875, 46.702_473],
            [15.632_978, 46.689_632],
            [15.626_984, 46.680_873],
            [15.621_745, 46.678_175],
            [15.616_648, 46.675_55],
            [15.603_729, 46.673_018],
            [15.588_64, 46.675_963],
            [15.567_349, 46.675_757],
            [15.545_955, 46.671_881],
            [15.530_659, 46.663_845],
            [15.520_84, 46.649_608],
            [15.520_308, 46.647_72],
            [15.517_636, 46.638_24],
            [15.513_939, 46.632_545],
            [15.511_228, 46.628_369],
            [15.492_625, 46.618_293],
            [15.462_653, 46.614_649],
            [15.440_018, 46.624_39],
            [15.435_381, 46.627_195],
            [15.417_591, 46.637_955],
            [15.388_135, 46.645_578],
            [15.332_784, 46.643_58],
            [15.204_891, 46.638_963],
            [15.172_557, 46.641_36],
            [15.105_591, 46.646_323],
            [15.085_723, 46.647_795],
            [15.061_954, 46.649_557],
            [15.004_386, 46.636_844],
            [14.967_179, 46.600_257],
            [14.947_955, 46.619_274],
            [14.933_589, 46.621_135],
            [14.919_507, 46.615_017],
            [14.897_726, 46.605_554],
            [14.894_063, 46.605_215],
            [14.877_055, 46.603_642],
            [14.862_999, 46.604_831],
            [14.850_39, 46.601_136],
            [14.833_647, 46.584_393],
            [14.822_278, 46.567_546],
            [14.814_519, 46.551_337],
            [14.807_189, 46.536_024],
            [14.796_663, 46.519_401],
            [14.788_585, 46.506_646],
            [14.783_004, 46.503_261],
            [14.760_887, 46.496_284],
            [14.735_255, 46.493_339],
            [14.726_367, 46.497_706],
            [14.709_727, 46.492_512],
            [14.703_733, 46.487_758],
            [14.698_772, 46.480_962],
            [14.687_093, 46.471_221],
            [14.679_961, 46.458_871],
            [14.672_546, 46.459_791],
            [14.666_629, 46.460_524],
            [14.662_081, 46.459_698],
            [14.642_031, 46.445_228],
            [14.631_696, 46.440_577],
            [14.621_567, 46.438_51],
            [14.599_863, 46.437_167],
            [14.590_148, 46.434_428],
            [14.575_368, 46.419_726],
            [14.566_997, 46.400_373],
            [14.562_108, 46.391_737],
            [14.557_695, 46.383_94],
            [14.540_332, 46.378_643],
            [14.527_102, 46.388_229],
            [14.515_837, 46.405_36],
            [14.502_194, 46.418_356],
            [14.467_675, 46.412_672],
            [14.450_931, 46.414_481],
            [14.437_145, 46.418_884],
            [14.420_029, 46.424_351],
            [14.414_448, 46.429_002],
            [14.411_244, 46.434_635],
            [14.406_49, 46.439_337],
            [14.395_844, 46.440_991],
            [14.362_151, 46.435_875],
            [14.242_323, 46.438_237],
            [14.149_865, 46.440_061],
            [14.147_933, 46.440_425],
            [14.137_255, 46.442_438],
            [14.081_032, 46.475_95],
            [14.066_355, 46.481_04],
            [14.050_026, 46.484_399],
            [14.032_456, 46.484_709],
            [14.014_922, 46.482_531],
            [13.998_763, 46.480_523],
            [13.982_33, 46.481_918],
            [13.890_862, 46.511_787],
            [13.860_683, 46.515_25],
            [13.795_778, 46.507_886],
            [13.782_135, 46.507_782],
            [13.716_093, 46.518_867],
            [13.700_951, 46.519_746],
            [13.685_345, 46.517_627],
            [13.670_256, 46.518_712],
            [13.549_229, 46.545_842],
            [13.506_751, 46.546_927],
            [13.499_103, 46.550_622],
            [13.484_944, 46.561_733],
            [13.478_019, 46.563_567],
            [13.417_041, 46.560_492],
            [13.373_323, 46.565_789],
            [13.271_417, 46.550_777],
            [13.231_109, 46.552_173],
            [13.210_025, 46.558_012],
            [13.146_463, 46.584_961],
            [13.064_504, 46.598_035],
            [12.830_41, 46.609_637],
            [12.773_566, 46.635_191],
            [12.748_5, 46.640_996],
            [12.739_873, 46.642_994],
            [12.732_018, 46.642_27],
            [12.715_999, 46.637_955],
            [12.706_593, 46.637_749],
            [12.697_395, 46.640_539],
            [12.679_205, 46.650_022],
            [12.671_368, 46.652_466],
            [12.669_593, 46.653_019],
            [12.620_19, 46.656_481],
            [12.562_003, 46.651_21],
            [12.547_327, 46.652_192],
            [12.530_79, 46.657_67],
            [12.499_888, 46.672_139],
            [12.469_632, 46.675_799],
            [12.445_627, 46.678_702],
            [12.405_01, 46.690_123],
            [12.370_387, 46.711_155],
            [12.351_473, 46.743_246],
            [12.342_688, 46.765_131],
            [12.326_048, 46.772_495],
            [12.305_274, 46.774_459],
            [12.284_397, 46.779_988],
            [12.274_785, 46.784_639],
            [12.269_101, 46.788_566],
            [12.266_724, 46.795_336],
            [12.266_93, 46.808_255],
            [12.269_618, 46.819_831],
            [12.273_338, 46.826_755],
            [12.276_129, 46.833_99],
            [12.275_819, 46.846_289],
            [12.266_62, 46.868_148],
            [12.250_807, 46.875_615],
            [12.208_743, 46.876_623],
            [12.195_1, 46.880_085],
            [12.189_002, 46.884_943],
            [12.183_525, 46.891_222],
            [12.172_156, 46.899_18],
            [12.160_994, 46.903_03],
            [12.137_429, 46.905_924],
            [12.126_887, 46.908_869],
            [12.141_563, 46.918_843],
            [12.141_667, 46.927_989],
            [12.134_742, 46.937_446],
            [12.128_231, 46.948_582],
            [12.122_133, 46.971_656],
            [12.117_792, 46.983_076],
            [12.111_178, 46.992_998],
            [12.121_72, 47.010_517],
            [12.182_284, 47.033_616],
            [12.203_575, 47.053_331],
            [12.203_897, 47.067_012],
            [12.204_195, 47.079_686],
            [12.180_631, 47.085_215],
            [12.133_146, 47.078_832],
            [12.116_242, 47.076_559],
            [12.014_956, 47.040_489],
            [11.943_643, 47.038_164],
            [11.899_511, 47.027_725],
            [11.857_033, 47.012_015],
            [11.822_513, 46.993_257],
            [11.777_141, 46.988_296],
            [11.766_082, 46.983_438],
            [11.746_445, 46.972_457],
            [11.734_87, 46.970_622],
            [11.716_163, 46.975_48],
            [11.683_814, 46.991_913],
            [11.664_797, 46.993_257],
            [11.657_252, 46.992_533],
            [11.649_707, 46.992_998],
            [11.648_467, 46.993_257],
            [11.647_95, 46.993_257],
            [11.596_48, 47.000_44],
            [11.572_709, 46.998_915],
            [11.543_667, 46.993_102],
            [11.543_564, 46.993_102],
            [11.543_357, 46.992_998],
            [11.543_254, 46.992_998],
            [11.533_849, 46.989_536],
            [11.524_443, 46.988_347],
            [11.515_348, 46.989_484],
            [11.506_977, 46.992_998],
            [11.501_706, 46.997_856],
            [11.495_918, 47.001_602],
            [11.489_407, 47.004_212],
            [11.471_837, 47.007_08],
            [11.461_812, 47.005_504],
            [11.452_923, 47.000_879],
            [11.445_275, 46.993_102],
            [11.445_172, 46.992_998],
            [11.411_169, 46.970_493],
            [11.380_576, 46.971_553],
            [11.349_467, 46.981_94],
            [11.313_81, 46.987_262],
            [11.243_737, 46.979_252],
            [11.174_491, 46.963_853],
            [11.156_611, 46.956_515],
            [11.091_912, 46.912_435],
            [11.083_643, 46.900_136],
            [11.073_101, 46.864_97],
            [11.054_291, 46.834_145],
            [11.053_464, 46.830_321],
            [11.054_601, 46.819_934],
            [11.052_844, 46.814_921],
            [11.048_503, 46.811_692],
            [11.037_445, 46.808_255],
            [11.033_311, 46.805_568],
            [11.010_883, 46.779_213],
            [10.996_93, 46.769_11],
            [10.982_668, 46.767_741],
            [10.965_718, 46.771_642],
            [10.930_888, 46.773_994],
            [10.913_731, 46.772_34],
            [10.880_348, 46.764_589],
            [10.870_426, 46.764_072],
            [10.860_195, 46.766_991],
            [10.843_141, 46.777_043],
            [10.834_15, 46.780_246],
            [10.823_918, 46.780_35],
            [10.804_901, 46.776_629],
            [10.795_082, 46.776_888],
            [10.766_35, 46.788_101],
            [10.754_775, 46.790_763],
            [10.733_691, 46.786_189],
            [10.722_942, 46.786_448],
            [10.716_947, 46.795_207],
            [10.721_598, 46.800_142],
            [10.743_819, 46.812_518],
            [10.748_677, 46.819_443],
            [10.738_962, 46.829_546],
            [10.662_481, 46.860_965],
            [10.647_081, 46.863_756],
            [10.629_408, 46.862_412],
            [10.527_708, 46.843_214],
            [10.486_264, 46.846_366],
            [10.453_811, 46.864_427],
            [10.451_434, 46.885_77],
            [10.463_836, 46.919_747],
            [10.458_462, 46.936_619],
            [10.449_574, 46.943_906],
            [10.415_571, 46.962_406],
            [10.394_693, 46.985_402],
            [10.384_358, 46.992_998],
            [10.384_358, 46.993_153],
            [10.384_255, 46.993_153],
            [10.378_984, 46.995_505],
            [10.373_403, 46.996_254],
            [10.367_925, 46.995_505],
            [10.338_883, 46.984_11],
            [10.313_665, 46.964_318],
            [10.296_198, 46.941_374],
            [10.295_681, 46.922_693],
            [10.270_773, 46.921_892],
            [10.251_343, 46.925_38],
            [10.235_116, 46.923_313],
            [10.219_924, 46.905_769],
            [10.215_169, 46.893_108],
            [10.214_342, 46.884_685],
            [10.211_655, 46.877_036],
            [10.201_423, 46.866_83],
            [10.157_808, 46.851_612],
            [10.131_97, 46.846_573],
            [10.125_188, 46.846_751],
            [10.111_3, 46.847_116],
            [10.068_098, 46.856_624],
            [10.045_567, 46.865_564],
            [10.006_913, 46.890_757],
            [9.899_943, 46.914_398],
            [9.875_138, 46.927_421],
            [9.862_426, 46.939_772],
            [9.860_772, 46.949_151],
            [9.863_976, 46.959_925],
            [9.866_457, 46.983_387],
            [9.870_591, 46.992_947],
            [9.870_591, 46.998_838],
            [9.866_767, 47.001_938],
            [9.860_566, 47.001_602],
            [9.856_328, 47.004_083],
            [9.857_982, 47.015_478],
            [9.669_053, 47.056_199],
            [9.652_31, 47.057_93],
            [9.599_91, 47.053_486],
            [9.581_203, 47.056_87],
            [9.608_798, 47.080_771],
            [9.615_723, 47.106_764],
            [9.605_594, 47.132_266],
            [9.581_823, 47.154_901],
            [9.552_057, 47.166_89],
            [9.551_954, 47.175_571],
            [9.561_876, 47.190_609],
            [9.562_909, 47.197_74],
            [9.554_331, 47.211_615],
            [9.544_823, 47.220_323],
            [9.540_378, 47.229_108],
            [9.547_096, 47.243_035],
            [9.530_25, 47.253_654],
            [9.521_155, 47.262_801],
            [9.553_298, 47.299_853],
            [9.587_404, 47.327_81],
            [9.591_228, 47.334_683],
            [9.596_396, 47.352_305],
            [9.601_047, 47.361_27],
            [9.639_804, 47.394_524],
            [9.649_519, 47.409_717],
            [9.650_346, 47.452_092],
            [9.621_717, 47.469_197],
            [9.584_51, 47.480_721],
            [9.554_951, 47.510_9],
            [9.553_059, 47.516_891],
            [9.547_482, 47.534_547],
            [9.549_887, 47.533_534],
            [9.554_144, 47.532_743],
            [9.612_622, 47.521_881],
            [9.676_701, 47.522_63],
            [9.703_986, 47.531_415],
            [9.718_455, 47.546_711],
            [9.730_858, 47.564_901],
            [9.752_665, 47.582_058],
            [9.767_135, 47.587_277],
            [9.782_121, 47.588_414],
            [9.796_28, 47.584_952],
            [9.807_959, 47.576_322],
            [9.811_266, 47.564_488],
            [9.809_303, 47.552_318],
            [9.813_127, 47.541_75],
            [9.833_487, 47.534_619],
            [9.841_549, 47.535_032],
            [9.853_848, 47.540_407],
            [9.858_912, 47.541_337],
            [9.888_988, 47.533_792],
            [9.918_753, 47.532_19],
            [9.933_843, 47.534_206],
            [9.945_935, 47.540_769],
            [9.948_726, 47.524_232],
            [9.959_164, 47.514_879],
            [9.971_67, 47.506_404],
            [9.980_765, 47.492_942],
            [9.980_765, 47.489_919],
            [9.981_075, 47.486_87],
            [9.982_625, 47.481_031],
            [9.993_581, 47.476_586],
            [10.002_366, 47.479_17],
            [10.011_254, 47.484_441],
            [10.022_623, 47.487_955],
            [10.071_819, 47.439_121],
            [10.080_604, 47.427_39],
            [10.075_953, 47.416_874],
            [10.053_215, 47.404_86],
            [10.064_171, 47.396_178],
            [10.066_858, 47.385_791],
            [10.067_788, 47.375_042],
            [10.073_162, 47.365_482],
            [10.082_878, 47.359_074],
            [10.089_596, 47.359_281],
            [10.097_244, 47.363_131],
            [10.110_37, 47.367_394],
            [10.115_33, 47.366_955],
            [10.125_666, 47.362_692],
            [10.130_833, 47.362_666],
            [10.135_071, 47.364_888],
            [10.146_956, 47.373_802],
            [10.190_881, 47.379_228],
            [10.209_278, 47.372_484],
            [10.208_555, 47.354_475],
            [10.190_468, 47.317_475],
            [10.192_742, 47.310_912],
            [10.193_259, 47.304_917],
            [10.190_778, 47.298_251],
            [10.185_92, 47.294_995],
            [10.172_691, 47.292_308],
            [10.168_661, 47.290_422],
            [10.159_462, 47.279_131],
            [10.155_431, 47.272_878],
            [10.159_876, 47.271_121],
            [10.174_951, 47.272_376],
            [10.239_354, 47.277_735],
            [10.261_161, 47.283_497],
            [10.305_913, 47.302_178],
            [10.325_447, 47.314_322],
            [10.343_43, 47.330_756],
            [10.365_651, 47.361_115],
            [10.371_646, 47.367_394],
            [10.381_981, 47.372_122],
            [10.403_065, 47.377_109],
            [10.411_643, 47.381_037],
            [10.428_283, 47.396_049],
            [10.442_856, 47.416_28],
            [10.451_951, 47.438_759],
            [10.451_744, 47.460_205],
            [10.447_61, 47.472_581],
            [10.442_236, 47.481_392],
            [10.433_657, 47.487_852],
            [10.419_601, 47.493_045],
            [10.429_73, 47.532_914],
            [10.430_97, 47.542_164],
            [10.424_356, 47.553_352],
            [10.415_674, 47.564_385],
            [10.414_227, 47.573_015],
            [10.429_213, 47.577_02],
            [10.445_956, 47.579_061],
            [10.453_604, 47.581_102],
            [10.457_118, 47.579_242],
            [10.461_563, 47.569_914],
            [10.460_322, 47.565_987],
            [10.455_465, 47.561_181],
            [10.451_744, 47.554_825],
            [10.453_501, 47.546_246],
            [10.458_772, 47.541_647],
            [10.466_937, 47.537_72],
            [10.482_543, 47.532_862],
            [10.525_125, 47.528_47],
            [10.536_907, 47.529_865],
            [10.549_619, 47.536_789],
            [10.569_876, 47.555_936],
            [10.583_726, 47.562_473],
            [10.607_91, 47.562_266],
            [10.739_892, 47.528_47],
            [10.743_923, 47.525_317],
            [10.747_23, 47.520_15],
            [10.752_191, 47.515_395],
            [10.760_769, 47.513_638],
            [10.790_328, 47.516_067],
            [10.830_636, 47.528_573],
            [10.844_795, 47.531_312],
            [10.858_644, 47.530_666],
            [10.869_29, 47.526_583],
            [10.892_234, 47.514_879],
            [10.883_862, 47.508_006],
            [10.851_616, 47.493_097],
            [10.858_438, 47.485_165],
            [10.909_907, 47.468_99],
            [10.959_827, 47.432_506],
            [10.962_824, 47.427_959],
            [10.963_134, 47.421_241],
            [10.960_86, 47.414_316],
            [10.957_656, 47.409_717],
            [10.955_279, 47.409_614],
            [10.965_511, 47.396_075],
            [10.979_464, 47.390_545],
            [11.083_747, 47.389_512],
            [11.103_28, 47.393_568],
            [11.168_599, 47.424_264],
            [11.193_818, 47.428_941],
            [11.215_212, 47.422_998],
            [11.213_041, 47.395_816],
            [11.237_019, 47.393_956],
            [11.258_827, 47.400_725],
            [11.273_089, 47.411_112],
            [11.286_318, 47.423_205],
            [11.305_645, 47.435_4],
            [11.324_249, 47.439_431],
            [11.367_657, 47.440_129],
            [11.383_884, 47.444_857],
            [11.392_462, 47.454_779],
            [11.388_638, 47.462_22],
            [11.377_786, 47.467_052],
            [11.365_487, 47.469_326],
            [11.412_616, 47.506_094],
            [11.435_353, 47.509_711],
            [11.459_951, 47.507_437],
            [11.482_585, 47.502_58],
            [11.529_508, 47.507_851],
            [11.551_212, 47.513_69],
            [11.569_402, 47.527_384],
            [11.574_466, 47.536_634],
            [11.582_321, 47.559_785],
            [11.589_142, 47.570_379],
            [11.617_048, 47.586_761],
            [11.620_458, 47.589_654],
            [11.638_958, 47.589_241],
            [11.682_573, 47.583_35],
            [11.764_015, 47.583_117],
            [11.820_343, 47.575_288],
            [11.830_678, 47.577_614],
            [11.840_703, 47.594_615],
            [11.851_969, 47.599_163],
            [11.935_891, 47.610_738],
            [12.165_645, 47.603_969],
            [12.173_603, 47.605_08],
            [12.202_128, 47.629_833],
            [12.205_745, 47.636_215],
            [12.206_779, 47.645_568],
            [12.205_125, 47.672_285],
            [12.202_335, 47.677_789],
            [12.197_271, 47.680_088],
            [12.189_106, 47.685_566],
            [12.181_768, 47.692_129],
            [12.178_564, 47.697_658],
            [12.176_6, 47.705_823],
            [12.192_413, 47.710_164],
            [12.216_494, 47.723_703],
            [12.225_176, 47.727_372],
            [12.239_749, 47.731_687],
            [12.242_229, 47.732_023],
            [12.239_128, 47.727_45],
            [12.236_545, 47.716_778],
            [12.233_031, 47.708_872],
            [12.226_623, 47.702_878],
            [12.223_315, 47.695_901],
            [12.228_793, 47.684_868],
            [12.238_715, 47.678_9],
            [12.249_567, 47.680_011],
            [12.271_891, 47.687_891],
            [12.293_699, 47.690_01],
            [12.351_473, 47.681_69],
            [12.368_423, 47.683_551],
            [12.407_697, 47.693_731],
            [12.424_13, 47.691_56],
            [12.428_781, 47.685_798],
            [12.439_22, 47.664_482],
            [12.444_697, 47.656_291],
            [12.452_759, 47.649_754],
            [12.482_524, 47.633_579],
            [12.496_58, 47.628_903],
            [12.517_148, 47.628_541],
            [12.538_232, 47.631_331],
            [12.553_838, 47.636_008],
            [12.563_375, 47.641_761],
            [12.598_383, 47.662_88],
            [12.617_4, 47.669_339],
            [12.653_057, 47.675_127],
            [12.688_713, 47.675_127],
            [12.744_834, 47.665_36],
            [12.761_991, 47.666_859],
            [12.751_655, 47.649_392],
            [12.767_365, 47.636_37],
            [12.793_307, 47.624_794],
            [12.813_15, 47.611_824],
            [12.785_865, 47.602_677],
            [12.773_669, 47.579_5],
            [12.778_94, 47.554_825],
            [12.803_642, 47.541_337],
            [12.819_041, 47.539_528],
            [12.828_446, 47.537_255],
            [12.836_921, 47.532_655],
            [12.882_603, 47.498_575],
            [12.931_593, 47.473_512],
            [12.942_548, 47.470_489],
            [12.951_023, 47.472_478],
            [12.959_291, 47.475_527],
            [12.969_73, 47.475_734],
            [12.979_342, 47.472_246],
            [12.984_923, 47.468_267],
            [12.991_124, 47.465_683],
            [13.001_873, 47.466_019],
            [13.008_797, 47.470_101],
            [13.037_323, 47.493_045],
            [13.037_013, 47.501_365],
            [13.034_429, 47.507_437],
            [13.031_225, 47.512_553],
            [13.028_951, 47.518_031],
            [13.028_124, 47.524_232],
            [13.028_124, 47.541_647],
            [13.030_605, 47.544_257],
            [13.040_113, 47.560_457],
            [13.041_25, 47.561_904],
            [13.039_08, 47.561_336],
            [13.038_253, 47.584_022],
            [13.040_94, 47.583_35],
            [13.057_063, 47.597_664],
            [13.057_683, 47.600_429],
            [13.069_569, 47.620_247],
            [13.072_049, 47.622_417],
            [13.074_22, 47.634_51],
            [13.074_84, 47.646_654],
            [13.072_049, 47.659_469],
            [13.064_091, 47.673_99],
            [13.044_557, 47.696_573],
            [13.032_258, 47.706_624],
            [13.019_856, 47.712_929],
            [13.004_353, 47.714_608],
            [12.979_032, 47.707_089],
            [12.964_045, 47.705_358],
            [12.908_338, 47.712_36],
            [12.892_009, 47.723_548],
            [12.910_612, 47.742_927],
            [12.910_819, 47.743_03],
            [12.917_227, 47.750_161],
            [12.918_984, 47.756_776],
            [12.919_294, 47.763_184],
            [12.921_257, 47.769_54],
            [12.926_735, 47.777_395],
            [12.991_227, 47.847_106],
            [12.986_576, 47.850_259],
            [12.964_976, 47.871_963],
            [12.931_076, 47.924_879],
            [12.914_229, 47.935_99],
            [12.906_168, 47.938_91],
            [12.886_014, 47.952_888],
            [12.862_036, 47.962_552],
            [12.853_975, 47.970_484],
            [12.848_6, 47.980_948],
            [12.848_246, 47.982_389],
            [12.845_603, 47.993_144],
            [12.830_617, 48.015_468],
            [12.760_234, 48.064_871],
            [12.750_932, 48.074_741],
            [12.742_043, 48.086_782],
            [12.736_979, 48.099_959],
            [12.738_943, 48.113_447],
            [12.745_041, 48.120_63],
            [12.751_449, 48.122_103],
            [12.757_96, 48.121_896],
            [12.764_058, 48.123_756],
            [12.778_32, 48.138_717],
            [12.782_351, 48.142_024],
            [12.822_142, 48.160_679],
            [12.835_164, 48.170_704],
            [12.853_458, 48.189_98],
            [12.862_553, 48.196_646],
            [12.877_642, 48.202_046],
            [12.933_04, 48.209_255],
            [12.931_696, 48.211_632],
            [12.947_199, 48.220_004],
            [12.979_445, 48.232_018],
            [13.016_652, 48.255_893],
            [13.032_775, 48.263_567],
            [13.136_438, 48.291_059],
            [13.274_207, 48.307_104],
            [13.307_797, 48.319_636],
            [13.405_569, 48.376_583],
            [13.419_522, 48.392_164],
            [13.425_206, 48.413_377],
            [13.427_17, 48.418_544],
            [13.431_717, 48.423_712],
            [13.436_161, 48.430_585],
            [13.438_228, 48.440_998],
            [13.436_368, 48.469_239],
            [13.438_228, 48.478_541],
            [13.442_983, 48.487_713],
            [13.456_418, 48.506_653],
            [13.459_209, 48.516_394],
            [13.457_039, 48.525_179],
            [13.447_737, 48.534_816],
            [13.443_706, 48.552_851],
            [13.440_709, 48.557_347],
            [13.440_089, 48.560_965],
            [13.445_566, 48.567_915],
            [13.454_558, 48.573_445],
            [13.487_218, 48.581_584],
            [13.520_704, 48.584_581],
            [13.624_367, 48.565_357],
            [13.641_834, 48.559_104],
            [13.658_06, 48.551_094],
            [13.673_356, 48.535_488],
            [13.714_336, 48.523_215],
            [13.716_506, 48.521_691],
            [13.725_394, 48.548_381],
            [13.733_869, 48.559_776],
            [13.737_28, 48.559_259],
            [13.758_054, 48.561_481],
            [13.766_012, 48.563_755],
            [13.774_28, 48.569_207],
            [13.779_034, 48.573_935],
            [13.796_191, 48.598_585],
            [13.802_392, 48.611_711],
            [13.805_493, 48.626_129],
            [13.806_216, 48.642_923],
            [13.800_945, 48.675_221],
            [13.803_529, 48.687_159],
            [13.816_862, 48.695_582],
            [13.804_356, 48.699_561],
            [13.799_395, 48.702_61],
            [13.788_75, 48.716_511],
            [13.783_582, 48.715_27],
            [13.785_856, 48.724_779],
            [13.815_725, 48.766_43],
            [13.855_929, 48.759_299],
            [13.874_946, 48.752_426],
            [13.893_136, 48.743_021],
            [13.915_047, 48.730_98],
            [13.982_33, 48.706_485],
            [13.991_115, 48.700_181],
            [14.007_754, 48.683_283],
            [14.014_162, 48.675_066],
            [14.019_227, 48.671_914],
            [14.026_151, 48.670_054],
            [14.032_146, 48.667_263],
            [14.034_729, 48.661_165],
            [14.032_869, 48.654_912],
            [14.028_322, 48.653_982],
            [14.023_464, 48.654_602],
            [14.020_467, 48.653_155],
            [14.013_749, 48.643_802],
            [14.008_064, 48.642_407],
            [14.006_204, 48.639_513],
            [14.010_855, 48.625_87],
            [14.015_196, 48.620_393],
            [14.032_456, 48.606_13],
            [14.040_827, 48.601_169],
            [14.074_83, 48.591_712],
            [14.216_527, 48.581_17],
            [14.315_746, 48.557_916],
            [14.325_358, 48.558_639],
            [14.333_213, 48.560_706],
            [14.353_366, 48.571_429],
            [14.405_353, 48.586_286],
            [14.421_166, 48.597_061],
            [14.443_593, 48.636_516],
            [14.458_166, 48.643_182],
            [14.482_144, 48.624_475],
            [14.522_038, 48.610_626],
            [14.533_82, 48.608_714],
            [14.548_807, 48.610_936],
            [14.578_779, 48.620_496],
            [14.594_179, 48.621_323],
            [14.600_586, 48.617_576],
            [14.601_827, 48.611_194],
            [14.601_93, 48.604_528],
            [14.605_134, 48.600_316],
            [14.612_885, 48.599_748],
            [14.628_182, 48.603_288],
            [14.634_693, 48.602_332],
            [14.645_442, 48.592_901],
            [14.651_333, 48.583_496],
            [14.659_187, 48.576_959],
            [14.675_621, 48.576_235],
            [14.691_14, 48.586_535],
            [14.695_671, 48.589_542],
            [14.700_735, 48.615_535],
            [14.700_219, 48.646_102],
            [14.703_836, 48.673_103],
            [14.722_749, 48.693_411],
            [14.775_356, 48.724_004],
            [14.780_42, 48.743_124],
            [14.790_446, 48.754_855],
            [14.794_476, 48.766_999],
            [14.800_367, 48.776_507],
            [14.815_457, 48.780_331],
            [14.867_65, 48.775_577],
            [14.919_327, 48.761_573],
            [14.939_584, 48.762_813],
            [14.951_056, 48.780_331],
            [14.948_886, 48.782_14],
            [14.944_441, 48.788_599],
            [14.940_101, 48.796_506],
            [14.938_137, 48.802_81],
            [14.939_79, 48.809_787],
            [14.947_025, 48.822_396],
            [14.968_213, 48.885_028],
            [14.968_316, 48.912_674],
            [14.964_182, 48.943_267],
            [14.964_595, 48.971_741],
            [14.978_238, 48.992_825],
            [14.977_308, 48.998_251],
            [14.977_308, 49.002_902],
            [14.978_858, 49.006_26],
            [14.982_062, 49.007_914],
            [15.003_973, 49.009_774],
            [15.059_576, 48.997_217],
            [15.137_401, 48.993_031],
            [15.144_739, 48.978_924],
            [15.148_977, 48.965_333],
            [15.148_563, 48.951_742],
            [15.141_949, 48.937_479],
            [15.161_792, 48.937_221],
            [15.238_067, 48.950_76],
            [15.243_234, 48.952_93],
            [15.257_084, 48.963_886],
            [15.260_391, 48.969_157],
            [15.262_872, 48.982_593],
            [15.266_902, 48.986_675],
            [15.274_55, 48.986_727],
            [15.279_615, 48.982_593],
            [15.283_749, 48.977_632],
            [15.288_71, 48.975_358],
            [15.335_529, 48.974_996],
            [15.357_853, 48.970_81],
            [15.405_912, 48.954_687],
            [15.450_354, 48.944_817],
            [15.471_851, 48.936_704],
            [15.521_254, 48.908_489],
            [15.544_187, 48.902_612],
            [15.603_729, 48.887_353],
            [15.681_347, 48.858_466],
            [15.703_775, 48.854_952],
            [15.726_823, 48.854_952],
            [15.743_669, 48.858_466],
            [15.779_222, 48.870_868],
            [15.787_904, 48.872_263],
            [15.818_497, 48.872_315],
            [15.824_284, 48.869_421],
            [15.828_315, 48.857_122],
            [15.833_276, 48.852_006],
            [15.840_097, 48.850_094],
            [15.859_218, 48.849_164],
            [15.870_173, 48.843_893],
            [15.877_614, 48.841_671],
            [15.885_986, 48.842_033],
            [15.875_754, 48.833_093],
            [15.888_363, 48.835_056],
            [15.899_629, 48.834_746],
            [15.907_07, 48.830_251],
            [15.908_103, 48.819_709],
            [15.925_053, 48.822_602],
            [15.930_324, 48.818_313],
            [15.930_324, 48.811_389],
            [15.931_771, 48.806_428],
            [16.032_334, 48.758_059],
            [16.085_354, 48.742_866],
            [16.177_751, 48.746_535],
            [16.317_588, 48.732_84],
            [16.318_724, 48.733_512],
            [16.339_498, 48.735_579],
            [16.352_727, 48.728_448],
            [16.358_309, 48.727_259],
            [16.374_018, 48.730_308],
            [16.384_974, 48.737_078],
            [16.435_72, 48.794_542],
            [16.453_083, 48.802_19],
            [16.481_919, 48.799_4],
            [16.492_254, 48.799_71],
            [16.510_341, 48.804_774],
            [16.519_539, 48.805_601],
            [16.528_737, 48.803_534],
            [16.545_791, 48.796_299],
            [16.605_529, 48.784_827],
            [16.624_029, 48.783_38],
            [16.643_149, 48.778_264],
            [16.651_004, 48.766_223],
            [16.654_725, 48.751_857],
            [16.661_959, 48.740_023],
            [16.675_085, 48.733_926],
            [16.691_001, 48.732_065],
            [16.729_035, 48.733_099],
            [16.744_332, 48.729_637],
            [16.780_505, 48.713_772],
            [16.798_902, 48.709_224],
            [16.818_125, 48.710_775],
            [16.856_573, 48.719_818],
            [16.873_006, 48.718_991],
            [16.896_674, 48.696_977],
            [16.910_523, 48.630_78],
            [16.945_043, 48.604_166]
          ]
        ],
        type: "Polygon"
      },
      properties: { name: "Austria" },
      type: "Feature"
    }
  ],
  type: "FeatureCollection"
};
