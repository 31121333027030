import circle from "@turf/circle";
import cn from "classnames";
import {
  LatLng, LatLngBounds, divIcon
} from "leaflet";
import {
  Fragment, useEffect, useRef, useState
} from "react";
import { Marker, Polyline } from "react-leaflet";

import ProjectMarker from "../project-marker";

/**
 *
 * @param props - The root object
 * @param props.point - The root object
 * @param props.point - The root object
 * @param props.point.geometry - The root object
 * @param props.point.geometry.coordinates - The root object
 * @param props.point.geometry.coordinates.0 - The root object
 * @param props.point.geometry.coordinates.1 - The root object
 * @param props.point.properties - The root object
 * @param props.point.properties.point_count - The root object
 * @param props.point.properties.point_count_abbreviated - The root object
 * @param props.point.properties.cluster_id - The root object
 * @param props.mapRef - The root object
 * @param props.groupRef - The root object
 * @param props.clusterExpansionZooms - The root object
 * @param props.leaves - The root object
 * @param props.clustersSpiderfied - The root object
 * @param props.setClustersSpiderfied - The root object
 * @example
 */
function ClusterMarker({
  clusterExpansionZooms,
  clustersSpiderfied,
  groupRef,
  leaves,
  mapRef,
  point,
  point: {
    geometry: {
      coordinates: [lng, lat]
    },
    properties: {
      cluster_id: clusterId,
      point_count: count,
      point_count_abbreviated: countAbbreviated
    }
  },
  setClustersSpiderfied
}) {
  const markerReference = useRef(null);

  const [additionalMarkers, setAdditionalMarkers] = useState([]);
  const [zoom, setZoom] = useState(null);

  useEffect(() => {
    setZoom(clusterExpansionZooms?.[clusterId]);
  }, [clusterExpansionZooms]);

  const toggleCluster = () => {
    try {
      const map = mapRef.current;

      const latLng = new LatLng(lat, lng);

      if (additionalMarkers.length > 0) {
        setAdditionalMarkers([]);
      }
      else {
        const newZoom = clusterExpansionZooms[clusterId];

        if (map.getZoom() > 17 && newZoom > 18) {
          setClustersSpiderfied(true);

          const distance = 0.000_4;

          const currentLeaves = leaves[clusterId];

          const { geometry: { coordinates: [newMarkerCoordinates] } } = circle([lng, lat], 0.03, { steps: currentLeaves.length === 2 ? 4 : currentLeaves.length });

          const newMarkers = currentLeaves.map((point, index, array) => {
            const { geometry, slug } = point;

            const [newLng, newLat] = newMarkerCoordinates[array.length === 2 ? 1 + (index * 2) : index];

            const newPoint = {
              ...point,
              geometry: {
                ...geometry,
                coordinates: [newLng, newLat]
              }
            };

            return (
              <Fragment key={index}>
                <ProjectMarker
                  {...{ point: newPoint }}
                />

                <Polyline
                  positions={[[lat, lng], [newLat, newLng]]}
                  pathOptions={{
                    color: "gray",
                    weight: 1
                  }}
                />
              </Fragment>
            );
          });

          setAdditionalMarkers(newMarkers);
        }
        else {
          const latLngBounds = new LatLngBounds(latLng, latLng);

          map.flyToBounds(latLngBounds.pad(0.3), { maxZoom: clusterExpansionZooms[clusterId] });
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (mapRef.current.getZoom() <= 17 && additionalMarkers.length > 0) {
      toggleCluster();
    }
  }, [mapRef.current.getZoom()]);

  return (
    <>
      <Marker
        position={[lat, lng]}
        ref={markerReference}
        eventHandlers={{
          click: (event) => {
            toggleCluster();
          }
        }}
        icon={divIcon({
          className: cn(
            "marker-cluster-custom animate-fade-in text-white font-medium text-sm w-4 h-4 rounded-full flex items-center justify-center pointer-events-all bg-primary",
            {
              "cluster-spiderfied": additionalMarkers.length > 0,
              "transition-all": mapRef?.current && mapRef.current.getZoom() > 17
            }
          ),
          html: `
            <div id="${clusterId}">
              ${additionalMarkers.length > 0
      ? "<div class=\"bg-blue-600 w-2 h-2 rounded-full\"></div>"
      : `<span>${countAbbreviated}</span>`}
            </div>
          `,
          iconSize: [40, 40]
        })}
      />

      {additionalMarkers}
    </>
  );
}

export default ClusterMarker;
