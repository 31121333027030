/* eslint-disable no-magic-numbers */

/* eslint-disable import/prefer-default-export */
import { Temporal } from "@js-temporal/polyfill";

import { priceLabels } from "~/src/modules/labels";

const { PlainYearMonth } = Temporal;

/**
 *
 * @param category
 * @param statistic
 * @example
 */
const generateLineData = (category, statistic) => {
  const maxTotal = Math.max(0, ...Object.entries(statistic[category].data.grouped)
    .map(([
      ,
      { max }
    ]) => max));

  const data = Array.from({ length: 201 })
    .fill(0)
    .map((empty, index) => {
      const [sourceArea, { average: sourceAverage, count: sourceCount }] = Object.entries(statistic[category].data.ungrouped)
        .find(([area]) => Number(area) === index) ?? [
        index,
        {
          average: null,
          count: null
        }
      ];

      return {
        count: sourceCount,
        x: Number(sourceArea),
        y: typeof sourceAverage === "number" ? Math.round(sourceAverage * 100) / 100 : sourceAverage,
        ymax: maxTotal,
        ymin: 0
      };
    });

  const chartData = [
    {
      id: "austria",
      min: 0,
      max: maxTotal,
      color: "hsl(345, 49%, 34%)",
      data,
      dataCategory: statistic[category].data.grouped,
      label: priceLabels[category]
    }
  ];

  const legendData = [
    {
      color: "hsl(345, 49%, 34%)",
      label: "Durchschnitt"
    },
    {
      color: "#27596838",
      label: "Größenklasse & Bandbreite"
    }
  ];

  return {
    chartData,
    legendData
  };
};

export { generateLineData };
