import priceTerms from "~/src/lib/price-terms.js";

import { NoValueDefinedLine } from "./_common/_exports.js";
import { SalePrice } from "./sale-prices/_exports.js";

const {
  gross: grossTerm,
  investment: investmentTerm,
  net: netTerm,
  normal: normalTerm
} = priceTerms;

/**
 *
 * @param root0 - The root object
 * @param root0.unit - The root object
 * @example
 */
const SalePrices = ({ unit }) => {
  const salePrices = [];

  const salePriceExclUst = unit.sale_price_net;
  const salePriceInclUst = unit.sale_price_gross;

  if (Number.isFinite(salePriceInclUst) && Number.isFinite(salePriceExclUst)) {
    salePrices.push(
      <SalePrice key="netto" label={`${investmentTerm} ${netTerm}`} value={salePriceExclUst} />,
      <SalePrice key="brutto" label={`${investmentTerm} ${grossTerm}`} value={salePriceInclUst} />
    );
  }
  else if (Number.isFinite(salePriceExclUst)) {
    salePrices.push(
      <SalePrice key="eigen" label={normalTerm} value={salePriceExclUst} />
    );
  }

  if (salePrices.length === 0) {
    salePrices.push(<NoValueDefinedLine />);
  }

  return salePrices;
};

export default SalePrices;
