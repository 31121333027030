import animateScrollTo from "animated-scroll-to";
import { Redirect } from "react-router-dom";
import {
  BooleanParam,
  NumberParam, StringParam, useQueryParams
} from "use-query-params";

import { pluralize } from "~/src/modules/formatters";

import PurchaseFilterTags from "~/src/features/filter-tags/purchase-filter-tags";
import Pagination from "~/src/features/pagination";
import PurchaseDateToggle from "~/src/features/purchase-date-toggle";
import PurchaseList from "~/src/features/purchase-list";
import PurchaseNonProfitCheckbox from "~/src/features/purchase-non-profit-checkbox.jsx";
import SortPurchasesDirection from "~/src/features/sort-purchases-direction";
import SortPurchasesDropdown from "~/src/features/sort-purchases-dropdown";

import useMediaQuery from "~/src/hooks/use-media-query.js";
import usePurchases from "~/src/hooks/use-purchases";

import DateInput from "~/src/ui/inputs/date-input";
import Page from "~/src/ui/page";
import CitySelect from "~/src/ui/selects/city-select";
import PurchaseTypeSelect from "~/src/ui/selects/purchase-type-select";
import StateSelect from "~/src/ui/selects/state-select";

/**
 *
 * @example
 */
export default function PurchasesPage() {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [query, setQuery] = useQueryParams({
    city: StringParam,
    cityId: NumberParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    dateType: StringParam,
    direction: StringParam,
    onlyNonProfit: BooleanParam,
    page: NumberParam,
    purchaseType: StringParam,
    purchaseTypeId: NumberParam,
    sortBy: StringParam,
    state: StringParam,
    stateId: NumberParam
  });

  const {
    isError, isLoading, pagination, purchases, sortDirection
  } = usePurchases({ query });

  /**
   *
   * @param newPage
   * @example
   */
  function handlePageChange(newPage) {
    setQuery({ page: newPage });
    setTimeout(() => {
      animateScrollTo(0, {
        speed: 120
      });
    }, 120);
  }

  /**
   *
   * @example
   */
  function handleClearFilters() {
    setQuery({
      ...query,
      city: undefined,
      cityId: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      direction: undefined,
      onlyNonProfit: undefined,
      page: 1,
      purchaseType: undefined,
      purchaseTypeId: undefined,
      state: undefined,
      stateId: undefined
    });
  }

  /**
   *
   * @param value
   * @example
   */
  function handleSubmit(value) {
    setQuery({
      ...value,
      page: 1
    });
  }

  if (isError) {
    <Redirect to="/error" />;
  }

  return (
    <Page title="Transaktionen">
      <div className="mx-auto px-4 xl:container lg:px-16">
        <h2 className="mt-8 text-3xl font-medium text-gray-700">Transaktionen</h2>
      </div>

      <div className="mx-auto mt-1 px-4 py-5 xl:container lg:px-16">

        <div className="w-full items-center justify-between lg:flex">

          <div>
            <div className="items-center lg:flex">
              <div className="w-full lg:w-80">
                <StateSelect onUpdate={handleSubmit} />
              </div>

              <div className="mt-2 w-full lg:ml-2 lg:mt-0 lg:w-80">
                <CitySelect currentStateId={query.stateId} onUpdate={handleSubmit} />
              </div>

              <div className="mt-2 w-full lg:ml-2 lg:mt-0 lg:w-80">
                <PurchaseTypeSelect onUpdate={handleSubmit} />
              </div>
            </div>

            <div className="mt-5 flex h-full flex-col items-start gap-6 lg:flex-row lg:items-center">
              <div className="flex items-center justify-start gap-x-3 lg:justify-center">
                <DateInput hasOtherDate={query?.dateTo} maxDate={query?.dateTo ? new Date(query.dateTo) : new Date()} name="dateFrom" onChange={handleSubmit} placeholder="Datum von" />

                <DateInput hasOtherDate={query?.dateFrom} maxDate={new Date()} minDate={query?.dateFrom ? new Date(query.dateFrom) : undefined} name="dateTo" onChange={handleSubmit} placeholder="Datum bis" />
              </div>

              <div >
                <PurchaseDateToggle dateType={query?.dateType} onChange={handleSubmit} />
              </div>

              <div>
                <PurchaseNonProfitCheckbox checked={query?.onlyNonProfit} isMobile={isMobile} onChange={handleSubmit} />
              </div>
            </div>
          </div>

        </div>

        <div className="mt-5">

          <PurchaseFilterTags handleClearFilters={handleClearFilters} onSubmit={handleSubmit} query={query} />

          <div className="mt-4 flex flex-col items-center justify-between lg:flex-row lg:items-end">

            {!isLoading &&
              <h3 className="mb-3 text-center text-2xl font-light text-gray-800 lg:mb-0 lg:text-left">{pagination?.totalRecords?.toLocaleString("de")} {pluralize("Transaktion", "Transaktionen", "Transaktionen", pagination?.totalRecords)}</h3>}

            {!pagination?.totalRecords && isLoading &&
              <div className="h-5 w-64 animate-pulse rounded bg-gray-300" />}

            {!isLoading && (
              <div className="items-center justify-end lg:flex">
                <SortPurchasesDropdown onSubmit={handleSubmit} sortBy={query.sortBy} />

                <div className="mb-2 ml-6 mr-1 lg:mb-0">
                  <Pagination onPageChange={handlePageChange} page={query.page} pagination={pagination} />
                </div>

                <div className="flex justify-center lg:block">
                  <SortPurchasesDirection direction={sortDirection} isLoading={isLoading} onSubmit={handleSubmit} />
                </div>
              </div>
            )}

            {isLoading &&
              <div className="mb-1 mt-4 h-5 w-96 animate-pulse rounded bg-gray-300" />}
          </div>

          <PurchaseList isLoading={isLoading} loadingItems={30} purchases={purchases} query={query.sortBy || "dateVerbuecherung"} />

          <Pagination onPageChange={handlePageChange} page={query.page} pagination={pagination} />

        </div>

      </div>

    </Page>
  );
}
