import { groupBy } from "lodash-es";

/**
 *
 * @param query
 * @example
 */
const destructQuery = (query) => {
  const splittedQuery = query.map((queryPiece) => ((queryPiece.includes("-"))
    ? queryPiece.split("-")
    : [queryPiece, null]));

  const queryAsObject = groupBy(splittedQuery, 0);

  return Object.fromEntries(Object.entries(queryAsObject).map(([key, idArray]) => [key, idArray.map((arrayEntries) => Number(arrayEntries[1]) || null)]));
};

/**
 *
 * @param certificate
 * @param query
 * @example
 */
const adjustCertificatesByQuery = (certificate, query) => {
  if (query?.length) {
    const destructedQuery = destructQuery(query);
    const certificateId = certificate.id.toString();
    const issuerInDestructedQuery = Object.keys(destructedQuery).includes(certificateId);

    if (!issuerInDestructedQuery) {
      return certificate;
    }

    if (certificate.issuerCertificates.length > 0) {
      const newCertificates = certificate.issuerCertificates.map((issuerCertificate) => {
        const certificateInDestructedQuery = destructedQuery[certificateId].includes(issuerCertificate.id);

        return {
          ...issuerCertificate,
          checked: Boolean(certificateInDestructedQuery)
        };
      });

      const checked = newCertificates.every(({ checked: certificateChecked }) => certificateChecked);
      const indeterminate = newCertificates.some(({ checked: certificateChecked }) => certificateChecked) && !checked;

      return {
        ...certificate,
        checked,
        indeterminate,
        issuerCertificates: newCertificates
      };
    }

    return {
      ...certificate,
      checked: true
    };
  }

  return certificate;
};

export { adjustCertificatesByQuery, destructQuery };
