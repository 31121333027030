import priceTerms from "~/src/lib/price-terms.js";

const priceLabels = /** @type {const} */ ({
  averageGka: `${priceTerms.average} ${priceTerms.gka}`,
  averageGkaAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.offer}`,
  averageGkaPerSquareMeter: `${priceTerms.average} ${priceTerms.gka}${priceTerms.perSquareMeter}`,
  averageGkaPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.offer}`,
  averageOfferPrice: `${priceTerms.average} ${priceTerms.offerPrice}`,
  averageOfferPriceAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.offer}`,
  averageOfferPriceGross: `${priceTerms.average} ${priceTerms.offerPrice} ${priceTerms.gross}`,
  averageOfferPriceGrossAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.gross} ${priceTerms.offer}`,
  averageOfferPriceGrossPerSquareMeter: `${priceTerms.average} ${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.gross}`,
  averageOfferPriceGrossPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.gross} ${priceTerms.offer}`,
  averageOfferPriceInvestor: `${priceTerms.average} ${priceTerms.offerPrice} ${priceTerms.investment}`,
  averageOfferPriceInvestorAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.investment} ${priceTerms.offer}`,
  averageOfferPriceInvestorGross: `${priceTerms.average} ${priceTerms.offerPrice} ${priceTerms.investment} ${priceTerms.gross}`,
  averageOfferPriceInvestorGrossAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.offer}`,
  averageOfferPriceInvestorGrossPerSquareMeter: `${priceTerms.average} ${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross}`,
  averageOfferPriceInvestorGrossPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.offer}`,
  averageOfferPriceInvestorNet: `${priceTerms.average} ${priceTerms.offerPrice} ${priceTerms.investment} ${priceTerms.net}`,
  averageOfferPriceInvestorNetAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.offer}`,
  averageOfferPriceInvestorNetPerSquareMeter: `${priceTerms.average} ${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net}`,
  averageOfferPriceInvestorNetPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.offer}`,
  averageOfferPriceInvestorPerSquareMeter: `${priceTerms.average} ${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.investment}`,
  averageOfferPriceInvestorPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.offer}`,
  averageOfferPriceNet: `${priceTerms.average} ${priceTerms.offerPrice} ${priceTerms.net}`,
  averageOfferPriceNetAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.net} ${priceTerms.offer}`,
  averageOfferPriceNetPerSquareMeter: `${priceTerms.average} ${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.net}`,
  averageOfferPriceNetPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.net} ${priceTerms.offer}`,
  averageOfferPriceNormal: `${priceTerms.average} ${priceTerms.offerPrice} ${priceTerms.normal}`,
  averageOfferPriceNormalAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.normal} ${priceTerms.offer}`,
  averageOfferPriceNormalPerSquareMeter: `${priceTerms.average} ${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.normal}`,
  averageOfferPriceNormalPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.normal} ${priceTerms.offer}`,
  averageOfferPricePerSquareMeter: `${priceTerms.average} ${priceTerms.offerPrice}${priceTerms.perSquareMeter}`,
  averageOfferPricePerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.offer}`,
  averageRent: `${priceTerms.average} ${priceTerms.rent}`,
  averageRentAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.offer}`,
  averageRentGross: `${priceTerms.average} ${priceTerms.rent} ${priceTerms.gross}`,
  averageRentGrossAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.gross} ${priceTerms.offer}`,
  averageRentGrossPerSquareMeter: `${priceTerms.average} ${priceTerms.rent}${priceTerms.perSquareMeter} ${priceTerms.gross}`,
  averageRentGrossPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.gross} ${priceTerms.offer}`,
  averageRentNet: `${priceTerms.average} ${priceTerms.rent} ${priceTerms.net}`,
  averageRentNetAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.net} ${priceTerms.offer}`,
  averageRentNetPerSquareMeter: `${priceTerms.average} ${priceTerms.rent}${priceTerms.perSquareMeter} ${priceTerms.net}`,
  averageRentNetPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.net} ${priceTerms.offer}`,
  averageRentPerSquareMeter: `${priceTerms.average} ${priceTerms.rent}${priceTerms.perSquareMeter}`,
  averageRentPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.offer}`,
  averageRentTotal: `${priceTerms.average} ${priceTerms.rent} ${priceTerms.total}`,
  averageRentTotalAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.total} ${priceTerms.offer}`,
  averageRentTotalPerSquareMeter: `${priceTerms.average} ${priceTerms.rent}${priceTerms.perSquareMeter} ${priceTerms.total}`,
  averageRentTotalPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.total} ${priceTerms.offer}`,
  averageSalePrice: `${priceTerms.average} ${priceTerms.salePrice}`,
  averageSalePriceAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.sale}`,
  averageSalePriceGross: `${priceTerms.average} ${priceTerms.salePrice} ${priceTerms.gross}`,
  averageSalePriceGrossAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.gross} ${priceTerms.sale}`,
  averageSalePriceGrossPerSquareMeter: `${priceTerms.average} ${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.gross}`,
  averageSalePriceGrossPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.gross} ${priceTerms.sale}`,
  averageSalePriceInvestor: `${priceTerms.average} ${priceTerms.salePrice} ${priceTerms.investment}`,
  averageSalePriceInvestorAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.investment} ${priceTerms.sale}`,
  averageSalePriceInvestorGross: `${priceTerms.average} ${priceTerms.salePrice} ${priceTerms.investment} ${priceTerms.gross}`,
  averageSalePriceInvestorGrossAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.sale}`,
  averageSalePriceInvestorGrossPerSquareMeter: `${priceTerms.average} ${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross}`,
  averageSalePriceInvestorGrossPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.sale}`,
  averageSalePriceInvestorNet: `${priceTerms.average} ${priceTerms.salePrice} ${priceTerms.investment} ${priceTerms.net}`,
  averageSalePriceInvestorNetAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.sale}`,
  averageSalePriceInvestorNetPerSquareMeter: `${priceTerms.average} ${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net}`,
  averageSalePriceInvestorNetPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.sale}`,
  averageSalePriceInvestorPerSquareMeter: `${priceTerms.average} ${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.investment}`,
  averageSalePriceInvestorPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.sale}`,
  averageSalePriceNet: `${priceTerms.average} ${priceTerms.salePrice} ${priceTerms.net}`,
  averageSalePriceNetAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.net} ${priceTerms.sale}`,
  averageSalePriceNetPerSquareMeter: `${priceTerms.average} ${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.net}`,
  averageSalePriceNetPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.net} ${priceTerms.sale}`,
  averageSalePriceNormal: `${priceTerms.average} ${priceTerms.salePrice} ${priceTerms.normal}`,
  averageSalePriceNormalAlt: `${priceTerms.average} ${priceTerms.price} ${priceTerms.normal} ${priceTerms.sale}`,
  averageSalePriceNormalPerSquareMeter: `${priceTerms.average} ${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.normal}`,
  averageSalePriceNormalPerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.normal} ${priceTerms.sale}`,
  averageSalePricePerSquareMeter: `${priceTerms.average} ${priceTerms.salePrice}${priceTerms.perSquareMeter}`,
  averageSalePricePerSquareMeterAlt: `${priceTerms.average} ${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.sale}`,
  gka: `${priceTerms.gka}`,
  gkaAlt: `${priceTerms.price} ${priceTerms.offer}`,
  gkaPerSquareMeter: `${priceTerms.gka}${priceTerms.perSquareMeter}`,
  gkaPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.offer}`,
  offerPrice: `${priceTerms.offerPrice}`,
  offerPriceAlt: `${priceTerms.price} ${priceTerms.offer}`,
  offerPriceGross: `${priceTerms.offerPrice} ${priceTerms.gross}`,
  offerPriceGrossAlt: `${priceTerms.price} ${priceTerms.gross} ${priceTerms.offer}`,
  offerPriceGrossPerSquareMeter: `${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.gross}`,
  offerPriceGrossPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.gross} ${priceTerms.offer}`,
  offerPriceInvestor: `${priceTerms.offerPrice} ${priceTerms.investment}`,
  offerPriceInvestorAlt: `${priceTerms.price} ${priceTerms.investment} ${priceTerms.offer}`,
  offerPriceInvestorGross: `${priceTerms.offerPrice} ${priceTerms.investment} ${priceTerms.gross}`,
  offerPriceInvestorGrossAlt: `${priceTerms.price} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.offer}`,
  offerPriceInvestorGrossPerSquareMeter: `${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross}`,
  offerPriceInvestorGrossPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.offer}`,
  offerPriceInvestorNet: `${priceTerms.offerPrice} ${priceTerms.investment} ${priceTerms.net}`,
  offerPriceInvestorNetAlt: `${priceTerms.price} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.offer}`,
  offerPriceInvestorNetPerSquareMeter: `${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net}`,
  offerPriceInvestorNetPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.offer}`,
  offerPriceInvestorPerSquareMeter: `${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.investment}`,
  offerPriceInvestorPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.offer}`,
  offerPriceNet: `${priceTerms.offerPrice} ${priceTerms.net}`,
  offerPriceNetAlt: `${priceTerms.price} ${priceTerms.net} ${priceTerms.offer}`,
  offerPriceNetPerSquareMeter: `${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.net}`,
  offerPriceNetPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.net} ${priceTerms.offer}`,
  offerPriceNormal: `${priceTerms.offerPrice} ${priceTerms.normal}`,
  offerPriceNormalAlt: `${priceTerms.price} ${priceTerms.normal} ${priceTerms.offer}`,
  offerPriceNormalPerSquareMeter: `${priceTerms.offerPrice}${priceTerms.perSquareMeter} ${priceTerms.normal}`,
  offerPriceNormalPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.normal} ${priceTerms.offer}`,
  offerPricePerSquareMeter: `${priceTerms.offerPrice}${priceTerms.perSquareMeter}`,
  offerPricePerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.offer}`,
  rent: `${priceTerms.rent}`,
  rentAlt: `${priceTerms.price} ${priceTerms.offer}`,
  rentGross: `${priceTerms.rent} ${priceTerms.gross}`,
  rentGrossAlt: `${priceTerms.price} ${priceTerms.gross} ${priceTerms.offer}`,
  rentGrossPerSquareMeter: `${priceTerms.rent}${priceTerms.perSquareMeter} ${priceTerms.gross}`,
  rentGrossPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.gross} ${priceTerms.offer}`,
  rentNet: `${priceTerms.rent} ${priceTerms.net}`,
  rentNetAlt: `${priceTerms.price} ${priceTerms.net} ${priceTerms.offer}`,
  rentNetPerSquareMeter: `${priceTerms.rent}${priceTerms.perSquareMeter} ${priceTerms.net}`,
  rentNetPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.net} ${priceTerms.offer}`,
  rentPerSquareMeter: `${priceTerms.rent}${priceTerms.perSquareMeter}`,
  rentPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.offer}`,
  rentTotal: `${priceTerms.rent} ${priceTerms.total}`,
  rentTotalAlt: `${priceTerms.price} ${priceTerms.total} ${priceTerms.offer}`,
  rentTotalPerSquareMeter: `${priceTerms.rent}${priceTerms.perSquareMeter} ${priceTerms.total}`,
  rentTotalPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.total} ${priceTerms.offer}`,
  salePrice: `${priceTerms.salePrice}`,
  salePriceAlt: `${priceTerms.price} ${priceTerms.sale}`,
  salePriceGross: `${priceTerms.salePrice} ${priceTerms.gross}`,
  salePriceGrossAlt: `${priceTerms.price} ${priceTerms.gross} ${priceTerms.sale}`,
  salePriceGrossPerSquareMeter: `${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.gross}`,
  salePriceGrossPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.gross} ${priceTerms.sale}`,
  salePriceInvestor: `${priceTerms.salePrice} ${priceTerms.investment}`,
  salePriceInvestorAlt: `${priceTerms.price} ${priceTerms.investment} ${priceTerms.sale}`,
  salePriceInvestorGross: `${priceTerms.salePrice} ${priceTerms.investment} ${priceTerms.gross}`,
  salePriceInvestorGrossAlt: `${priceTerms.price} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.sale}`,
  salePriceInvestorGrossPerSquareMeter: `${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross}`,
  salePriceInvestorGrossPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.gross} ${priceTerms.sale}`,
  salePriceInvestorNet: `${priceTerms.salePrice} ${priceTerms.investment} ${priceTerms.net}`,
  salePriceInvestorNetAlt: `${priceTerms.price} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.sale}`,
  salePriceInvestorNetPerSquareMeter: `${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net}`,
  salePriceInvestorNetPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.net} ${priceTerms.sale}`,
  salePriceInvestorPerSquareMeter: `${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.investment}`,
  salePriceInvestorPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.investment} ${priceTerms.sale}`,
  salePriceNet: `${priceTerms.salePrice} ${priceTerms.net}`,
  salePriceNetAlt: `${priceTerms.price} ${priceTerms.net} ${priceTerms.sale}`,
  salePriceNetPerSquareMeter: `${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.net}`,
  salePriceNetPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.net} ${priceTerms.sale}`,
  salePriceNormal: `${priceTerms.salePrice} ${priceTerms.normal}`,
  salePriceNormalAlt: `${priceTerms.price} ${priceTerms.normal} ${priceTerms.sale}`,
  salePriceNormalPerSquareMeter: `${priceTerms.salePrice}${priceTerms.perSquareMeter} ${priceTerms.normal}`,
  salePriceNormalPerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.normal} ${priceTerms.sale}`,
  salePricePerSquareMeter: `${priceTerms.salePrice}${priceTerms.perSquareMeter}`,
  salePricePerSquareMeterAlt: `${priceTerms.price}${priceTerms.perSquareMeter} ${priceTerms.sale}`
});

export default priceLabels;
