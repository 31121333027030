/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @example
 */
export default function MarkerIconNone({ className }) {
  return (
    <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" />
  );
}
