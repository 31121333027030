import { Disclosure, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

/**
 *
 * @param root0 - The root object
 * @param root0.id - The root object
 * @param root0.name - The root object
 * @param root0.checked - The root object
 * @param root0.indeterminate - The root object
 * @param root0.regions - The root object
 * @param root0.updateStateRegions - The root object
 * @example
 */
export default function RegionFilterState({
  id,
  checked,
  indeterminate,
  name,
  regions,
  updateStateRegions
}) {
  const [showRegions, setShowRegions] = useState(false);

  const handleStateCheck = (e, regions) => {
    updateStateRegions(id, regions.map((region) => ({
      ...region,
      checked: e.target.checked
    })));
  };

  const handleCheck = (e, regionId) => {
    const regionIndex = regions.map(({ id }) => id).indexOf(regionId);

    const newRegions = [...regions];

    newRegions[regionIndex] = {
      ...newRegions[regionIndex],
      checked: e.target.checked
    };

    updateStateRegions(id, newRegions);
  };

  return (
    <div className="py-6">
      <Disclosure>
        <div className="flex items-center justify-between">
          <label className="inline-flex cursor-pointer items-center text-xl font-medium">
            <input checked={checked || indeterminate} className="size-6 cursor-pointer rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => handleStateCheck(e, regions)} ref={(element) => element && (element.indeterminate = indeterminate)} type="checkbox" />

            <span className="ml-4">{name}</span>
          </label>

          <div className="pl-2">
            <Disclosure.Button as={Fragment}>
              <button className="flex items-center font-light underline focus:outline-none" onClick={() => setShowRegions((previous) => !previous)} type="button">
                {!showRegions && (
                  <>
                    <span>Bezirke anzeigen</span>

                    <svg className="ml-1 size-4 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 9l-7 7-7-7" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                    </svg>
                  </>
                )}

                {showRegions && (
                  <>
                    <span>Bezirke zuklappen</span>

                    <svg className="ml-1 size-4 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 15l7-7 7 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                    </svg>
                  </>
                )}
              </button>
            </Disclosure.Button>
          </div>
        </div>

        <Transition
          as="div"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          show={showRegions}
        >
          <Disclosure.Panel>
            <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
              {
                regions.map(({
                  id,
                  checked,
                  name
                }) => (
                  <div className="pl-2" key={`region_${id}`}>
                    <label className="inline-flex cursor-pointer items-center">
                      <input checked={checked} className="size-6 cursor-pointer rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => handleCheck(e, id)} type="checkbox" />

                      <span className="ml-3">{name}</span>
                    </label>
                  </div>
                ))
              }
            </div>
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </div>
  );
}
