import cn from "classnames";
import { useState } from "react";

import useMediaQuery from "~/src/hooks/use-media-query";

import { getActivatedColor } from "../helpers";

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.simple - The root object
 * @param props.activeAreaGroups - The root object
 * @param props.setActiveAreaGroups - The root object
 * @param props.hoveredAreaGroup - The root object
 * @param props.setHoveredAreaGroup - The root object
 * @param props.displayAreaGroup - The root object
 * @param props.displayAreaGroups - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function ChartLegend({
  activeAreaGroups,
  data,
  displayAreaGroups,
  setActiveAreaGroups = () => {},
  setHoveredAreaGroup = () => {},
  simple = false
}) {
  const isPrint = useMediaQuery("print");
  const [removeByClick, setRemoveByClick] = useState(false);

  const toggleActiveAreaGroup = (id) => {
    if (
      activeAreaGroups
    ) {
      if (activeAreaGroups.includes(id)) {
        setActiveAreaGroups(activeAreaGroups.filter((activeId) => activeId !== id));
        setHoveredAreaGroup(null);
        setRemoveByClick(true);
      }
      else {
        setActiveAreaGroups([...activeAreaGroups, id]);
      }
    }
  };

  const toggleHoveredAreaGroupActive = (id) => {
    if (!removeByClick) {
      setHoveredAreaGroup(id);
    }
  };

  const toggleHoveredAreaGroupInactive = (id) => {
    setHoveredAreaGroup(null);
    setRemoveByClick(false);
  };

  if (!data) {
    return null;
  }

  return (
    <div
      style={{ gridTemplateColumns: `repeat(auto-fit, minmax(${isPrint ? "180px" : "200px"}, 1fr))` }}
      className={cn(
        {
          "flex flex-wrap justify-center gap-4": simple && (!isPrint || data?.length < 3),
          "grid gap-2": isPrint && data?.length >= 3,
          "grid gap-4 px-16": !simple && (!isPrint || data?.length < 3)
        }
      )}
    >
      {data.map(({
        id,
        color,
        label,
        stroke
      }, index) => {
        let activatedColor = color;

        if (displayAreaGroups && !isPrint) {
          activatedColor = getActivatedColor(
            {
              id,
              color
            },
            displayAreaGroups
          );
        }

        return (
          <div
            key={index}
            className={cn(
              "flex items-center gap-2",
              {
                "cursor-pointer": displayAreaGroups
              }
            )}
            onClick={() => {
              toggleActiveAreaGroup(id);
            }}
            onMouseEnter={() => {
              toggleHoveredAreaGroupActive(id);
            }}
            onMouseLeave={() => {
              toggleHoveredAreaGroupInactive();
            }}
          >
            {
              id === "dataWithoutZKey"
                ? (
                  <svg
                    className="size-5"
                    viewBox="0 0 11.547 10"
                  >
                    <polygon fill={activatedColor} points="0,10 5.7735,0 11.547,10" stroke={stroke} />
                  </svg>
                )
                : (
                  <div
                    className="size-4 rounded-full"
                    style={{
                      backgroundColor: activatedColor,
                      border: `1px solid ${stroke}`
                    }}
                  />
                )
            }

            <span className="text-xs">{label}</span>
          </div>
        );
      })}
    </div>
  );
}
