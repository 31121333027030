/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";
import cn from "classnames";
import { useState } from "react";

import {
  formatCurrency, formatDate, formatMonth
} from "~/src/modules/formatters";

import FeedbackModal from "~/src/features/feedback-modal";
import ShareDealOwnerCard from "~/src/features/share-deal-owner-card";
import SlidePropertyCard from "~/src/features/slide-property-card";

import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param dateList
 * @example
 */
function renderDateList(dateList) {
  return dateList?.split(", ").map((date) => formatDate({
    date,
    withTime: false
  })).join(", ");
}

/**
 *
 * @param root0 - The root object
 * @param root0.active - The root object
 * @param root0.hide - The root object
 * @param root0.transaction - The root object
 * @example
 */
export default function ShareDealTransactionSlide({
  active, hide, transaction
}) {
  const [showProperties, setShowProperties] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  /**
   *
   * @example
   */
  function handleHide() {
    setShowProperties(false);
    hide();
  }

  return (
    <Dialog className="relative z-[150]" onClose={handleHide} open={active}>
      <FeedbackModal close={() => setShowFeedback(false)} entity_id={transaction?.id} feedback_type="share-deal-transaction" feedback_type_label={`${formatMonth(transaction?.change_date)} - ${transaction?.company_fbnr}`} show={showFeedback} />

      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-4xl transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={handleHide}
                    type="button"
                  >
                    <span className="absolute -inset-2.5" />

                    <span className="sr-only">Schließen</span>

                    <CloseIcon aria-hidden="true" className="size-6" />
                  </button>
                </div>
              </TransitionChild>

              <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">

                <div className="flex h-16 shrink-0 items-center justify-between bg-primary px-4 sm:px-12">
                  <h2 className="text-lg font-medium text-white" id="slide-over-heading">
                    Details zum Share Deal
                  </h2>
                </div>

                <div className="mb-8 mt-4 flex-1">

                  <dl className="px-4 sm:px-8">

                    <div className="px-4">
                      <div className="flex items-center justify-between">
                        <h3 className="text-2xl font-semibold text-gray-700">{transaction?.company_name}<br />{transaction?.company_fbnr}</h3>

                        <button className="rounded border border-primary bg-primary px-3 py-1 text-sm text-white hover:bg-primary-lighter focus:outline-none" onClick={() => setShowFeedback(true)} type="button">Feedback</button>
                      </div>

                      <div className="mt-1 text-gray-900">
                        {/* <p>{shareDeal.state}</p> */}
                        <p className="leading-5">{transaction?.company_zipcode} {transaction?.company_city}, {transaction?.company_street}</p>
                      </div>
                    </div>

                    <div>

                      <div className="mt-4 p-4">

                        <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Datum</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">{transaction?.date_list ? renderDateList(transaction.date_list) : formatMonth(transaction?.change_date)}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Art der Änderung</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">{transaction?.category?.name}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Stammkapital</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                            {transaction?.company_capital
                              ? formatCurrency({
                                currency: transaction?.capital_currency,
                                val: transaction?.company_capital
                              })
                              : "keine Angabe"}
                          </dd>
                        </div>

                      </div>

                      <nav className="mt-10 flex space-x-4 px-4">
                        <button
                          onClick={() => setShowProperties(false)}
                          type="button"
                          className={cn("px-3 py-2 font-medium text-sm rounded-md focus:outline-none", {
                            "bg-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-700": showProperties,
                            "bg-primary text-white cursor-default": !showProperties
                          })}
                        >
                          Personen- und Anteilsänderungen ({transaction?.owners?.length})
                        </button>

                        <button
                          onClick={() => setShowProperties(true)}
                          type="button"
                          className={cn("px-3 py-2 font-medium text-sm rounded-md focus:outline-none", {
                            "bg-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-700": !showProperties,
                            "bg-primary text-white cursor-default": showProperties
                          })}
                        >
                          Liegenschaften ({transaction?.properties?.length})
                        </button>
                      </nav>

                      {!showProperties && (
                        <div className="my-1 px-4">

                          <div className="mt-1">
                            <p className="mr-1 text-right text-sm text-gray-800">PersNr.</p>
                          </div>

                          <div className="mt-2 space-y-5 px-4 sm:px-0">
                            {transaction?.owners?.map((owner, index) => (
                              <ShareDealOwnerCard key={`owner_${index}`} owner={owner} />
                            ))}
                          </div>

                        </div>
                      )}

                      {showProperties && (
                        <div className="my-1 px-4">
                          <div className="mt-8 space-y-5">
                            {transaction?.properties?.map((property, index) => (
                              <SlidePropertyCard key={`property_${index}`} property={property} />
                            ))}
                          </div>
                        </div>
                      )}

                    </div>

                  </dl>

                </div>

              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
