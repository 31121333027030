import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { insolvencyPropertySortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0 - The root object
 * @param root0.sortByProperty - The root object
 * @param root0.onSubmit - The root object
 * @example
 */
export default function SortInsolvencyPropertiesDropdown({ onSubmit, sortByProperty = "dateLastChange" }) {


  /**
   *
   * @example
   */
  function label() {
    return insolvencyPropertySortLabels[sortByProperty];
  }

  return (
    <Menu as="div" className="relative ml-8">
      <Menu.Button className="inline-flex h-10 w-full items-center justify-center border-b border-secondary-light text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {label()}

        <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-0.5 size-5 text-gray-500" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 -mt-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="w-full py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "datePublished" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "datePublished",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.datePublished}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "dateLastChange" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "dateLastChange",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.dateLastChange}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "kg" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "kg",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.kg}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "ez" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "ez",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.ez}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "area" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "area",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.area}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "zipcode" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "zipcode",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.zipcode}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "street" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "street",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.street}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "company" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "company",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.company}</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortByProperty: "fbnr" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortByProperty === "fbnr",
                  "text-gray-700": !active
                })}
              >
                <span>{insolvencyPropertySortLabels.fbnr}</span>
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
