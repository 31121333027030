import {
  Route, useLocation
} from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

import useAuthInternal from "~/src/hooks/use-auth-internal";

/**
 *
 * @param props - The root object
 * @param props.children - The root object
 * @example
 */
const ProtectedInternalRoute = ({ children, ...rest }) => {
  const location = useLocation();

  const {
    isError, isLoading
  } = useAuthInternal({ location });

  const authorized = !isError;

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={() => {
        if (authorized) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: "/not-found"
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedInternalRoute;
