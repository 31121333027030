/* eslint-disable import/prefer-default-export */

/**
 *
 * @param unitToCheck
 * @example
 */
export const unitHasNoPrice = (unitToCheck) => (
  !unitToCheck.buyable ||
  (
    unitToCheck.buyable &&
    !unitToCheck.offer_price_normal &&
    !unitToCheck.offer_price_investor
  )
) &&
(
  !unitToCheck.rentable ||
  (
    unitToCheck.rentable &&
    !unitToCheck.rent_netto &&
    !unitToCheck.rent_bk
  )
);
