import LinkIcon from "~/src/ui/icons/link-icon";
import SignedDocumentLink from "~/src/ui/links/signed-document-link";

/**
 *
 * @param props - The root object
 * @param props.documents - The root object
 * @example
 */
export default function ProjectDocuments({ documents = [] }) {
  const createChildElement = (document) => (
    <li className="flex w-full items-center justify-between gap-2 py-2 pl-3 pr-4" key={`document_${document.id}`}>
      <div className="flex items-center gap-2 truncate">
        <LinkIcon />

        <span className="truncate font-normal text-black">
          {document.public_name}
        </span>
      </div>
    </li>
  );

  return (
    <div className="max-w-full lg:max-w-prose">
      {documents.length === 0 &&
        <p>-</p>}

      {documents.length > 0 && (
        <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
          {documents.map((document) => (
            <SignedDocumentLink childElements={createChildElement(document)} document={document} key={`document_link_${document.id}`} />
          ))}
        </ul>
      )}
    </div>
  );
}
