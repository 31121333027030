import cn from "classnames";
/* eslint-disable no-magic-numbers */
/* eslint-disable no-unused-expressions */

import { useEffect, useState } from "react";

/**
 *
 * @param root0 - The root object
 * @param root0.changeKey - The root object
 * @param root0.title - The root object
 * @param root0.showCustom - The root object
 * @param root0.hasChanges - The root object
 * @param root0.defaultValue - The root object
 * @param root0.storedValue - The root object
 * @param root0.changedValue - The root object
 * @param root0.updateChangedValue - The root object
 * @example
 */
export default function AreaSettingsSliderContainer({
  changeKey,
  changedValue,
  defaultValue,
  hasChanges = false,
  showCustom = false,
  storedValue,
  title,
  updateChangedValue = () => null
}) {
  const [value, setValue] = useState(defaultValue);
  const [textValue, setTextValue] = useState(null);

  const disabled = !showCustom;

  const handleSliderChange = (event) => {
    updateChangedValue(changeKey, event.target.value);
  };

  const handleTextChange = (event) => {
    const newValue = event.target.value;

    if (newValue === "") {
      setTextValue("");

      return;
    }

    const numericValue = Number.parseFloat(newValue);

    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
      setTextValue(numericValue);
    }
  };

  const handleTextBlur = (event) => {
    event.target.value === ""
      ? updateChangedValue(changeKey, 0)
      : updateChangedValue(changeKey, event.target.value || 0);
    setTextValue(null);
  };

  useEffect(() => {
    if (showCustom) {
      hasChanges
        ? setValue(changedValue)
        : setValue(storedValue);
    }
    else {
      setValue(defaultValue);
    }
  }, [
    showCustom,
    hasChanges,
    defaultValue,
    storedValue,
    changedValue
  ]);

  const evaluateValue = () => {
    if (value || value === 0) {
      return value;
    }

    return showCustom
      ? (changedValue || storedValue)
      : storedValue;
  };

  return (
    <div className="grid h-20 grid-cols-8 items-center rounded bg-gray-100 px-4 sm:h-12">

      <div className="col-span-8 sm:col-span-2">
        <p>{title}</p>
      </div>

      <div className="col-span-6 mt-1 flex items-center sm:col-span-5">
        <input
          disabled={disabled}
          max="100"
          min="0"
          onChange={handleSliderChange}
          step={1}
          type="range"
          value={evaluateValue()}
          className={cn("appearance-none w-full outline-none focus:ring-offset-primary focus:ring-2 h-1 bg-secondary-dark rounded", {
            "disabled-slider cursor-not-allowed": disabled,
            "slider cursor-pointer": !disabled
          })}
        />
      </div>

      <div className="col-span-2 mt-0.5 flex justify-end sm:col-span-1">
        <div className="flex items-center text-xl font-medium text-gray-500">
          {disabled
            ? (
              <span>{value}</span>
            )
            : (
              <input
                className="text-md block h-8 w-12 rounded-md border-gray-300 py-0 pl-1 pr-1.5 text-right font-medium shadow-sm focus:border-primary focus:ring-primary"
                max={100}
                min={0}
                onBlur={handleTextBlur}
                onChange={handleTextChange}
                type="number"
                value={(textValue || textValue === "") ? textValue : value}
              />
            )}

          <span className="ml-2 text-sm">%</span>
        </div>
      </div>

    </div>
  );
}
