import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

/**
 *
 * @param id
 * @param message
 * @param duration
 * @example
 */
export function showSuccess(id = null, message = "Erfolgreich gespeichert!", duration = 900) {
  toast.dismiss(id);
  toast.success(message, {
    autoClose: duration,
    bodyClassName: "text-sm font-medium text-gray-800",
    icon: <div className="-mt-0.5"><CheckCircleIcon className=" size-6 text-emerald-500" /></div>,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    progressClassName: "bg-emerald-500",
    toastId: id
  });
}

/**
 *
 * @param id
 * @param message
 * @param duration
 * @example
 */
export function showError(id = null, message = "Fehler! Änderung nicht gespeichert.", duration = 5_000) {
  toast.dismiss(id);
  toast.error(message, {
    autoClose: duration,
    bodyClassName: "text-sm font-medium text-primary",
    icon: <div className="-mt-0.5"><CheckCircleIcon className=" size-6 text-primary" /></div>,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    progressClassName: "bg-primary",
    toastId: id
  });
}
