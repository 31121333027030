import useStore from "~/src/hooks/use-store";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function PolygonFilterTags({ queryItems, submit }) {
  const setReadPolygonsFromQuery = useStore((state) => state.setReadPolygonsFromQuery);

  if (!queryItems) {
    return null;
  }

  const polygons = queryItems;

  /**
   *
   * @param polygon
   * @example
   */
  function handleClick(polygon) {
    const newPolygon = polygons.filter((item) => item !== polygon);

    setReadPolygonsFromQuery(true);

    submit("polygon", newPolygon);
  }

  return polygons.map((polygon, index) => (
    <DefaultFilterTag handleClick={() => handleClick(polygon)} key={`polygon_${index}`}>
      <span>Polygon: {index + 1}</span>
    </DefaultFilterTag>
  ));
}
