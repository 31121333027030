import { objektTypFilterLabels } from "~/src/modules/labels";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function ObjektTypFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const objekttypen = queryItems.split(",");

  /**
   *
   * @param typ
   * @example
   */
  function handleClick(typ) {
    const newTypen = objekttypen.filter((item) => item !== typ).join(",");

    submit("objekttyp", newTypen);
  }

  return objekttypen.map((typ, index) => (
    <DefaultFilterTag handleClick={() => handleClick(typ)} key={`objekt_typ_tag_${index}`}>
      <span>Objektart: {objektTypFilterLabels[typ]}</span>
    </DefaultFilterTag>
  ));
}
