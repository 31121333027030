/**
 *
 * @param certificate
 * @example
 */
const expandCertificates = (certificate) => {
  const {
    id,
    issuer_certificates: issuerCertificates,
    name
  } = certificate;

  return {
    id,
    checked: false,
    indeterminate: false,
    issuerCertificates: issuerCertificates.map((issuerCertificate) => ({
      ...issuerCertificate,
      checked: false
    }))
      .sort((certA, certB) => {
        if (certA?.rank && certB?.rank) {
          return certA?.rank - certB?.rank;
        }
        if (certA?.rank === undefined || certA?.rank === null) {
          return 1;
        }
        if (certB?.rank === undefined || certB?.rank === null) {
          return -1;
        }

        return 0;
      }),
    name
  };
};

export default expandCertificates;
