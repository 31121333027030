import { getInnerIcon } from "./get-inner-icon-by-phase/_exports.js";

/**
 *
 * @param options0 - The root object.
 * @param options0.currentConstructionPhase - The root object.
 * @param options0.outerColor - The root object.
 * @example
 */
const getInnerIconByPhase = ({ currentConstructionPhase, outerColor }) => {
  switch (currentConstructionPhase) {
    case "construction_phase_active":
      return getInnerIcon("construction", outerColor);
    case "construction_phase_completed":
      return getInnerIcon("completion", outerColor);
    case "construction_phase_purchase":
      return getInnerIcon("purchase", outerColor);
    default:
      return getInnerIcon("empty", outerColor);
  }
};

export default getInnerIconByPhase;
