const createHttpError = ({
  relativeUrl, responseBody, status
}) => {
  const description = "Cannot access HTTP resource.";
  const data = {
    request: { relativeUrl },
    response: {
      body: responseBody,
      status
    }
  };

  const message = JSON.stringify({
    data,
    description
  });

  return new Error(message);
};

const getHttpErrorResponseBody = ({ error }) => {
  try {
    const message = JSON.parse(error?.message);

    return message?.data?.response?.body;
  }
  catch (error_) {
    if (error_.name === "SyntaxError") {
      return false;
    }
    throw error_;
  }
};

const getHttpErrorRelativeUrl = ({ error }) => {
  try {
    const message = JSON.parse(error?.message);

    return message?.data?.request?.relativeUrl;
  }
  catch (error_) {
    if (error_.name === "SyntaxError") {
      return false;
    }
    throw error_;
  }
};

const errorHasHttpStatus = ({ error, status }) => {
  try {
    const message = JSON.parse(error?.message);

    return message?.data?.response?.status === status;
  }
  catch (error_) {
    if (error_.name === "SyntaxError") {
      return false;
    }
    throw error_;
  }
};

export {
  createHttpError,
  errorHasHttpStatus,
  getHttpErrorRelativeUrl,
  getHttpErrorResponseBody
};
