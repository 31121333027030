import { colorPaletteWohnungenByYearArgbHex } from "~/src/modules/color-palletes.js";
import { excelProjectsLabels } from "~/src/modules/labels.js";

/* eslint-disable no-magic-numbers */
import { colGetters } from "./col-getters.js";
import { addLabelsToCols, getColumnsFromSpec } from "./helpers/get-columns-from-spec.js";

const VIOLET = "FF706FD3";
const GREEN = "FF218C74";
const YELLOW = "FFE7A127";

const excelJsEuroFormat = "\"€ \"#,##0.00\"/m²\"";

const projectColsSpec = [
  {
    key: "name"
  },
  {
    key: "addresses"
  },
  // this is used as the company label for automated projects in projects
  // list, but was decided to move these to "owners" column
  //  {
  //    key: "sellers",
  //  },
  {
    key: "owners"
  },
  {
    key: "builders"
  },
  {
    key: "marketers"
  },
  {
    formatType: "boolean",
    key: "automated"
  },
  {
    key: "completedDate"
  },
  {
    key: "projectCertificates"
  },
  {
    key: "lastContractDate"
  },
  {
    key: "constructionPhase"
  },
  {
    key: "marketingPhase"
  },
  {
    key: "transactions"
  },
  {
    key: "toRentOrPurchase"
  },
  {
    key: "unitCountAutomated"
  },
  {
    key: "unitCount"
  },
  {
    key: "fundingType"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    key: "gka"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    key: "avgOfferPrice"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    key: "avgOfferPriceInvestor"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    key: "avgRent"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    key: "avgRentBk"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    key: "avgSalePrice"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    key: "avgSalePriceInvestor"
  }
];

const statsColsSpec = [
  {
    key: "projectCountSelected"
  },
  {
    key: "unitCountSelected"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    headerBackgroundColor: VIOLET,
    key: "offerPriceEigennutzerAverage"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    headerBackgroundColor: VIOLET,
    key: "offerPriceInvestorAverage"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    headerBackgroundColor: VIOLET,
    key: "rentAverage"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    headerBackgroundColor: GREEN,
    key: "salePriceEigennutzerAverage"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    headerBackgroundColor: GREEN,
    key: "salePriceInvestorAverage"
  },
  {
    formatString: excelJsEuroFormat,
    formatType: "numFmt",
    headerBackgroundColor: YELLOW,
    key: "gkaAverage"
  }
];

const statsUnitsColsSpec = Object.entries(
  excelProjectsLabels.statsDetails
).map(
  ([key]) => ({
    color: colorPaletteWohnungenByYearArgbHex[
      key.replace(/_project$/u, "")
    ],
    key
  })
)
  .filter(({ key }) => ![
    "finish_year",
    "projects_sum",
    "units_sum"
  ].includes(key));

statsUnitsColsSpec.splice(0, 0, { key: "finish_year" });
statsUnitsColsSpec.splice(7, 0, { key: "units_sum" });
// statsUnitsColsSpec.push({ key: "projects_sum" });

const projectCols = getColumnsFromSpec(
  projectColsSpec,
  colGetters.project
);
const statsCols = getColumnsFromSpec(statsColsSpec);
const statsUnitsCols = getColumnsFromSpec(
  statsUnitsColsSpec,
  colGetters.statsUnits
);

addLabelsToCols(projectCols, excelProjectsLabels.projects);
addLabelsToCols(statsCols, excelProjectsLabels.stats);
addLabelsToCols(statsUnitsCols, excelProjectsLabels.statsDetails);

export {
  projectCols, statsCols, statsUnitsCols
};
