const getPricePerSqm = (price, area) => {
  const hasValue = area > 0 && Number.isFinite(price);

  if (!hasValue) {
    return null;
  }

  return price / area;
};

export { getPricePerSqm };
