import { useContext } from "react";

import { NotificationContext } from "~/src/contexts/notification-context";

/**
 *
 * @example
 */
export default function useNotification() {
  return useContext(NotificationContext);
}
