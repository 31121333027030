/**
 *
 * @param root0 - The root object
 * @param root0.styles - The root object
 * @param root0.className - The root object
 * @example
 */
export default function CheckmarkIcon({ styles, className = `w-7 h-7 text-white ${styles}` }) {
  return (
    <svg className={className} fill="none" height={0} stroke="currentColor" viewBox="0 0 24 24" width={0}>
      <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
    </svg>
  );
}
