import {
  Listbox, ListboxButton, ListboxOption, ListboxOptions
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { useState } from "react";

import { groupModeLabels } from "~/src/modules/labels";
import { finishYearLabels } from "~/src/modules/labels.js";

import useMediaQuery from "~/src/hooks/use-media-query";

import BarChart from "~/src/ui/charts/bar-chart/index.jsx";
import ChartError from "~/src/ui/charts/error/index.jsx";
import ChartLegend from "~/src/ui/charts/legend/index.jsx";
import MetaTooltip from "~/src/ui/charts/meta-tooltip/index.jsx";

import { transformToBarData } from "../helpers.js";

/**
 *
 * @param props0 - The root object
 * @param props0.isError - The root object
 * @param props0.statistic - The root object
 * @example
 */
export default function CountWohnungenByYearChart({ statistic }) {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const isPrint = useMediaQuery("print");

  const groupModes = ["grouped", "stacked"];
  const [groupMode, setGroupMode] = useState(groupModes[(isMobile ? 1 : 0)]);

  const barData = transformToBarData({ barData: statistic });

  barData.chartData = barData.chartData
    .map(({ year, ...rest }) => ({
      ...rest,
      year: finishYearLabels[year]
    }));

  if (barData?.chartData?.length === 0) {
    return <ChartError />;
  }

  if (barData === null) {
    return <div className="h-[560px] w-full bg-white" />;
  }

  if (isPrint && barData?.chartData?.length > 0) {
    return groupModes.map((groupModeOption) => (
      <div
        className="w-full break-inside-avoid space-y-4 bg-white"
        key={groupModeOption}
      >
        <h2 className="text-md font-medium text-gray-900">Fertiggestellte Wohneinheiten pro Jahr ({groupModeLabels[groupModeOption]})</h2>

        <div className="h-80">
          <BarChart data={barData?.chartData} groupMode={groupModeOption} />
        </div>

        <ChartLegend simple data={barData?.legendData} />
      </div>
    ));
  }

  return (
    <div className="flex flex-col gap-4 rounded border border-gray-200 bg-white p-2 lg:p-4">
      <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
        <h2 className="text-md font-medium text-gray-900">Fertiggestellte Wohneinheiten pro Jahr</h2>

        <MetaTooltip
          {...((barData) && {
            meta: barData.meta
          })}
        />

        <Listbox as="div" className="relative hidden w-28 cursor-pointer flex-row rounded lg:flex" onChange={setGroupMode} value={groupMode}>
          <ListboxButton className="flex w-28 items-center justify-between rounded border border-gray-300 bg-white px-2 py-1">
            <span className="text-sm text-gray-900">{groupModeLabels[groupMode]}</span>

            <ChevronDownIcon className="size-5 text-gray-400" />
          </ListboxButton>

          <ListboxOptions as="ul" className="absolute z-10 mt-8 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" modal={false}>
            {groupModes.map((groupModeOption) => (
              <ListboxOption
                as="li"
                key={groupModeOption}
                value={groupModeOption}
                className={cn(
                  "cursor-pointer select-none relative px-2 py-1 text-gray-700 ui-active:text-gray-900 ui-active:bg-gray-100"
                )}
              >

                {({ active, selected }) => (
                  <>
                    <span className={`${selected ? "font-medium" : "font-normal"} block truncate`}>
                      {groupModeLabels[groupModeOption]}
                    </span>
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Listbox>

      </div>

      <div className="relative h-[450px] w-full">
        <div className="absolute h-[450px] w-full">
          <BarChart data={barData?.chartData} groupMode={groupMode} />
        </div>
      </div>

      <div className="w-full">
        <ChartLegend simple data={barData?.legendData} />
      </div>

    </div>
  );
}
