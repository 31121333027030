import cn from "classnames";

import { formatEuro } from "~/src/modules/formatters.js";
import {
  priceLabels
} from "~/src/modules/labels.js";

const projectPricesStructure = /** @type {const} */ ([
  [
    "gka",
    "gka",
    "gka"
  ],
  [
    "avg_offer_price_eigennutzer",
    "averageOfferPriceNormal",
    "avgOfferPriceEigennutzer"
  ],
  [
    "avg_offer_price_investor",
    "averageOfferPriceInvestorNet",
    "avgOfferPriceEigennutzer"
  ],
  [
    "avg_miete",
    "averageRentNet",
    "avgMiete"
  ],
  [
    "avg_rent_bk",
    "averageRentTotal",
    "avgMiete"
  ],
  [
    "avg_sale_price_eigennutzer",
    "averageSalePriceNormal",
    "avgSalePriceEigennutzer"
  ],
  [
    "avg_sale_price_investor",
    "averageSalePriceInvestorNet",
    "avgSalePriceEigennutzer"
  ]
]);

const ProjectPrices = ({ currentSortValue, project }) => (
  <>
    {
      projectPricesStructure
        .filter(([valueKey]) => project?.[valueKey])
        .map(([
          valueKey,
          labelKey,
          sortKey
        ]) => (
          <div className="flex text-xs" key={valueKey}>
            <div className="w-[200px]">
              <p
                className={cn("font-semibold", {
                  "text-gray-600": currentSortValue !== sortKey,
                  "text-primary": currentSortValue === sortKey
                })}
              >
                {priceLabels[labelKey]}:
              </p>
            </div>

            <div>
              <p className={cn({ "text-primary font-semibold": currentSortValue === sortKey })}>
                {formatEuro(project?.[valueKey])}/m²
              </p>
            </div>
          </div>
        ))
    }
  </>
);

export default ProjectPrices;
