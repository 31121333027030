import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import "dayjs/locale/de";

dayjs.extend(quarterOfYear);
dayjs.locale("de");

/**
 *
 * @param date
 * @param format
 * @example
 */
export default function dateFormatter(date, format = "day") {
  if (!date) {
    return null;
  }

  if (format === "day") {
    return dayjs(date).format("DD.MM.YYYY");
  }

  if (format === "month") {
    return dayjs(date).format("MMMM YYYY");
  }

  if (format === "quarter") {
    const quarter = dayjs(date).quarter();

    return `Q${quarter}-${dayjs(date).format("YYYY")}`;
  }

  if (format === "year") {
    return dayjs(date).format("YYYY");
  }

  return null;
}

/**
 *
 * @param date
 * @example
 */
export function datestamp(date) {
  if (!date) {
    return null;
  }

  return dayjs(date).format("YYYY-MM-DD");
}

/**
 *
 * @param date
 * @example
 */
export function timestamp(date) {
  if (!date) {
    return null;
  }

  return dayjs(date).format("YYYY-MM-DD_THH-mm-ss");
}
