import cn from "classnames";
import {
  groupBy,
  sum,
  uniqBy
} from "lodash-es";
import {
  forwardRef,
  useEffect,
  useState
} from "react";
import { useParams } from "react-router-dom";

import useProject from "~/src/hooks/use-project";
import useStore from "~/src/hooks/use-store";
import useUnitCategories from "~/src/hooks/use-unit-categories";

import CategoryDropdown from "~/src/ui/charts/dropdown";
import ChartError from "~/src/ui/charts/error";
import ChartLegend from "~/src/ui/charts/legend";
import MetaTooltip from "~/src/ui/charts/meta-tooltip";
import ScatterPlot from "~/src/ui/charts/scatter-plot";

import { transformToScatterData } from "../../helpers";

import BuyableForm from "./form";

/**
 *
 */
// eslint-disable-next-line max-lines-per-function, max-statements
const DetailBuyableChart = forwardRef((properties, reference) => {
  const setErrorFlagGlobal = useStore(
    (state) => state.setProjectPurchasePriceChartHasError
  );

  setErrorFlagGlobal(false);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [timelineDates, setTimelineDates] = useState({});
  const [type, setType] = useState("offer");
  const [scatterData, setScatterData] = useState([]);

  const [offerPriceOptions, setOfferPriceOptions] = useState([]);
  const [salePriceOptions, setSalePriceOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  const {
    areaTypes, dateTypes, isError, isLoading, labels, priceTypes, statistics
  } = properties;

  const groupedPriceTypes = groupBy(priceTypes, (priceType) => (priceType.startsWith("units-offer") ? "offer" : "sale"));

  const [xType, setXType] = useState(dateTypes[0]);
  const [yType, setYType] = useState(priceTypes[0]);
  const [zType, setZType] = useState(areaTypes[0]);

  const [showTimelineBuyable, setShowTimeline] = useState(true);
  const [isDataError, setIsDataError] = useState(false);

  const [counts, setCounts] = useState({});

  const { unit_categories: unitCategories } = useUnitCategories();

  const { id: projectId } = useParams();
  const { project } = useProject(projectId);

  useEffect(() => {
    if (project?.timeline) {
      const {
        timeline: {
          construction_phase_purchase_date: constructionPhasePurchaseDate,
          construction_phase_purchase_date_format: constructionPhasePurchaseDateFormat,

          construction_phase_active_date: constructionPhaseActiveDate,
          construction_phase_active_date_format: constructionPhaseActiveDateFormat,

          construction_phase_completed_date: constructionPhaseCompletedDate,
          construction_phase_completed_date_format: constructionPhaseCompletedDateFormat,

          marketing_phase_active_date: marketingPhaseActiveDate,
          marketing_phase_active_date_format: marketingPhaseActiveDateFormat
        }
      } = project;

      const [
        purchase,
        constructionStart,
        constructionFinish,
        marketingStart
      ] = [
        [constructionPhasePurchaseDate, constructionPhasePurchaseDateFormat],
        [constructionPhaseActiveDate, constructionPhaseActiveDateFormat],
        [constructionPhaseCompletedDate, constructionPhaseCompletedDateFormat],
        [marketingPhaseActiveDate, marketingPhaseActiveDateFormat]
      ]
        .map(([date, dateFormat]) => {
          if (date !== null && dateFormat !== null) {
            switch (dateFormat) {
              case "day":
                return new Date(date);

              case "month": {
                const currentDate = new Date(date);

                return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
              }

              case "quarter": {
                const currentDate = new Date(date);

                const quarter = Math.floor((currentDate.getMonth() / 3));

                return new Date(currentDate.getFullYear(), quarter * 3, 1);
              }

              case "year": {
                const currentDate = new Date(date);

                return new Date(currentDate.getFullYear(), 0, 1);
              }

              default:
                // do nothing
                break;
            }
          }

          return null;
        });

      setTimelineDates({
        constructionFinish: showTimelineBuyable ? constructionFinish : null,
        constructionStart: showTimelineBuyable ? constructionStart : null,
        marketingStart: showTimelineBuyable ? marketingStart : null,
        purchase: showTimelineBuyable ? purchase : null
      });
    }
  }, [project, showTimelineBuyable]);

  useEffect(() => {
    if (statistics !== undefined) {
      const { data } = statistics;

      const newCategoriesEntriesWithMetadata = Object.entries(data)
        .filter(([categoryKey, { length }]) => length > 0)
        .map(([categoryKey, categoryValue]) => {
          let filterOut = false;

          const allData = dateTypes
            .map((dateType) => [
              dateType === "units-dates-verwertetDate" ? "offer" : "sale",
              (groupedPriceTypes[dateType === "units-dates-verwertetDate" ? "offer" : "sale"]).map((priceType) => transformToScatterData(
                statistics.data[categoryKey],
                {
                  color: type === "offer" ? "red" : "green",
                  xKey: dateType.split("-").at(-1),
                  yKey: priceType.split("-").at(-1),
                  zKey: dateType === "units-dates-verwertetDate" ? "offerArea" : "saleArea"
                }
              ))
            ]);

          const countsForCategpry = Object.fromEntries(
            allData
              .map(([typeKey, dataByType]) => {
                const innerData = dataByType.flat().flatMap(({ data }) => data);

                return [typeKey, uniqBy(innerData, ({ id }) => id).length];
              })
          );

          const lengthsSum = sum(
            allData
              .map(([typeKey, dataByType]) => dataByType)
              .flat(2)
              .map(({ data: { length } }) => length)
          );

          filterOut = lengthsSum === 0;

          return [
            categoryKey,
            [
              categoryValue,
              filterOut,
              countsForCategpry
            ]
          ];
        });

      const newCounts = Object.fromEntries(
        newCategoriesEntriesWithMetadata
          .map(([
            categoryKey,
            [
              categoryValue,
              filterOut,
              countsForCategpry
            ]
          ]) => [categoryKey, countsForCategpry])
      );

      setCounts(newCounts);

      const filteredNewCategoriesEntriesWithMetadata = newCategoriesEntriesWithMetadata
        .filter(([categoryKey, [categoryValue, filterOut]]) => !filterOut);

      const newCategoriesEntries = filteredNewCategoriesEntriesWithMetadata.map(([categoryKey, [categoryValue]]) => [categoryKey, categoryValue]);

      const newCategories = newCategoriesEntries
        .map(([categoryKey]) => categoryKey)
        .sort((categoryCodeA, categoryCodeB) => {
          const sortingA = unitCategories ? Number(unitCategories?.find(({ code }) => code === categoryCodeA).sorting) : 0;

          const sortingB = unitCategories ? Number(unitCategories?.find(({ code }) => code === categoryCodeB).sorting) : 0;

          return sortingA - sortingB;
        });

      setCategories(newCategories);
    }
  }, [statistics]);

  useEffect(() => {
    const newDateOptions = dateTypes
      .map((innerType) => ({
        color: innerType === "units-dates-verwertetDate" ? "red" : "green",
        label: labels[innerType],
        shown: true,
        value: innerType
      }));

    setDateOptions(
      newDateOptions
    );
  }, []);

  useEffect(() => {
    if (statistics !== undefined && activeCategory !== null) {
      const { data: { [activeCategory]: categoryData } } = statistics;

      const newOfferPriceOptions = groupedPriceTypes.offer
        .filter((innerType) => {
          const key = innerType.split("-").at(-1);

          const dataHasNoPricesWithDate = categoryData
            .every((datum) => datum[key] === null);

          return !dataHasNoPricesWithDate;
        })
        .map((innerType) => ({
          label: labels[innerType],
          shown: true,
          value: innerType
        }));

      setOfferPriceOptions(
        newOfferPriceOptions
      );

      const newSalePriceOptions = groupedPriceTypes.sale
        .filter((innerType) => {
          const key = innerType.split("-").at(-1);

          const dataHasNoPricesWithDate = categoryData
            .every((datum) => datum[key] === null);

          return !dataHasNoPricesWithDate;
        })
        .map((innerType) => ({
          label: labels[innerType],
          shown: true,
          value: innerType
        }));

      setSalePriceOptions(
        newSalePriceOptions
      );
    }
  }, [
    dateOptions,
    activeCategory,
    xType
  ]);

  useEffect(() => {
    if (
      offerPriceOptions.length > 0 ||
      salePriceOptions.length > 0
    ) {
      const newTypeOptions = ["offer", "sale"]
        .map((innerType) => {
          const shown = innerType === "offer"
            ? offerPriceOptions.length > 0
            : salePriceOptions.length > 0;

          return {
            color: innerType === "offer" ? "red" : "green",
            label: labels[innerType],
            shown: true,
            tag: counts?.[activeCategory]?.[innerType],
            value: innerType
          };
        });

      setTypeOptions(
        newTypeOptions
      );
    }
  }, [
    offerPriceOptions,
    salePriceOptions,
    counts,
    activeCategory
  ]);

  useEffect(() => {
    const newDateOptions = dateTypes
      .map((innerType) => ({
        color: innerType === "units-dates-verwertetDate" ? "red" : "green",
        label: labels[innerType],
        shown: (type === "offer" && innerType === "units-dates-verwertetDate") || (type === "sale" && innerType === "units-dates-saleDate"),
        value: innerType
      }));

    setDateOptions(
      newDateOptions
    );
  }, [type]);

  useEffect(() => {
    if (categories.length > 0 && activeCategory === null) {
      setActiveCategory(categories[0]);
    }
  }, [categories]);

  useEffect(
    () => {
      if (activeCategory !== null && xType && yType) {
        const xKey = xType.split("-").at(-1);
        const yKey = yType.split("-").at(-1);
        const zKey = zType.split("-").at(-1);

        setScatterData(
          transformToScatterData(
            statistics.data[activeCategory],
            {
              color: type === "offer" ? "red" : "green",
              xKey,
              yKey,
              zKey
            }
          )
        );
      }
    },
    [
      activeCategory,
      xType,
      yType
    ]
  );

  const onChange = ({
    type: newType, xType: newXType, yType: newYType
  }) => {
    setXType(newXType);
    setYType(newYType);
    setZType(`units-${newType}-${newType}Area`);
    if (newType !== "") {
      setType(newType);
    }
  };

  const handleShowTimelineCheckbox = (event) => {
    setShowTimeline(event.target.checked);
  };

  useEffect(() => {
    if (
      statistics !== undefined &&
      dateOptions.filter(({ shown }) => shown).length === 0 &&
      offerPriceOptions.length === 0 &&
      salePriceOptions.length === 0 &&
      typeOptions.filter(({ shown }) => shown).length === 0
    ) {
      setIsDataError(true);
    }
    else {
      setIsDataError(false);
    }
  }, [
    typeOptions,
    dateOptions,
    offerPriceOptions,
    salePriceOptions
  ]);

  if (isError || isDataError) {
    setErrorFlagGlobal(true);

    return null;
  }

  if (isLoading) {
    return null;
  }

  const scatterDataEmpty = scatterData.flatMap(({ data }) => data).length === 0 || ((type === "offer" && offerPriceOptions.length === 0) || (type === "sale" && salePriceOptions.length === 0));

  return (
    <div className="flex flex-col rounded border border-gray-200 bg-white p-4 shadow-sm" ref={reference}>
      <div className="flex flex-col gap-8">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-medium">Eigentum</h2>

            <MetaTooltip
              {...((scatterData) && {
                meta: statistics.meta
              })}
            />
          </div>

          <CategoryDropdown
            activeCategory={activeCategory}
            categories={categories}
            className="self-end"
            setActiveCategory={setActiveCategory}
          />
        </div>

        <div className="flex w-full flex-col gap-8 md:flex-row">
          <div className="w-full md:w-5/12 lg:w-4/12">
            <BuyableForm
              dateOptions={dateOptions}
              offerPriceOptions={offerPriceOptions}
              onChange={onChange}
              salePriceOptions={salePriceOptions}
              typeOptions={typeOptions}
            />
          </div>

          <div className="flex items-center gap-2 md:h-11">
            <label className="flex cursor-pointer items-end" htmlFor="showTimelineBuyable">
              <span className="flex cursor-pointer items-end">Projektphasen anzeigen</span>
            </label>

            <input
              checked={typeof showTimelineBuyable === "boolean" ? showTimelineBuyable : true}
              id="showTimelineBuyable"
              name="showTimelineBuyable"
              onChange={handleShowTimelineCheckbox}
              type="checkbox"
              className={cn(
                "w-5 h-5 rounded border-gray-200 border shadow-sm text-primary cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0",
                {
                  "hover:border-gray-400": !showTimelineBuyable
                }
              )}
            />
          </div>
        </div>

        {
          scatterDataEmpty
            ? (
              <ChartError className="mt-4" ref={reference} type={type} />
            )
            : (
              <>
                <div className="relative h-[450px] w-full">
                  <div className="size-full">
                    <ScatterPlot
                      data={scatterData}
                      timeline={timelineDates}
                      {...((xType && yType) && {
                        legend: {
                          x: labels[xType],
                          y: labels[yType]
                        }
                      })}
                    />
                  </div>
                </div>

                <div className="flex h-16 w-full items-center justify-center">
                  <ChartLegend simple data={scatterData} />
                </div>
              </>
            )
        }
      </div>
    </div>
  );
});

export default DetailBuyableChart;
