import { snakeCase } from "lodash-es";
import { useParams } from "react-router-dom";

import { formatArea, pluralize } from "~/src/modules/formatters";
import { areaRange } from "~/src/modules/helpers";
import { constructionTypeLabels } from "~/src/modules/labels";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

/**
 *
 * @example
 */
const ProjectOverviewConstructionTypes = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    construction_types: constructionTypes
  } = project;

  const contructionTypeContent = [
    "neubau",
    "dgAusbau",
    "sanierung",
    "unsaniert",
    "fremdbestand"
  ]
    .filter((categoryKey) => constructionTypes[snakeCase(categoryKey)])
    .map((categoryKey) => {
      const snakeCaseKey = snakeCase(categoryKey);
      const name = constructionTypeLabels[snakeCaseKey];
      const count = constructionTypes[`${snakeCaseKey}_count`];
      const area = constructionTypes[`${snakeCaseKey}_area_range_from`];
      const toArea = constructionTypes[`${snakeCaseKey}_area_range_to`];
      const unitAreaTotal = constructionTypes[`${snakeCaseKey}_unit_area_total`];
      const areaTotal = constructionTypes[`${snakeCaseKey}_area_total`];

      return (
        <li className="flex gap-1" key={categoryKey}>
          {
            [
              count
                ? [name, count && `${count} ${pluralize("Einheit", "Einheiten", "Einheiten", count)}`]
                  .join(": ")
                : name,
              area && areaRange({
                area,
                toArea
              }),
              unitAreaTotal && `Gesamtfläche (Wohneinheiten): ${formatArea(unitAreaTotal)}`,
              areaTotal && `Gesamtfläche (inkl. Gewerbe): ${formatArea(areaTotal)}`
            ]
              .filter(Boolean)
              .join(", ")
          }
        </li>
      );
    });

  return (
    <ContentSection id="constructionTypes" title="Bautypen">
      <ul className="flex flex-col gap-0.5">
        {
          (contructionTypeContent.length > 0)
            ? contructionTypeContent
            : "-"
        }
      </ul>
    </ContentSection>
  );
};

export default ProjectOverviewConstructionTypes;
