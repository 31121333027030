import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { usePopper } from "react-popper";
import { useLocation } from "react-router-dom";

/**
 *
 * @param props - The root object
 * @param props.isLoading - The root object
 * @param props.loadedProject - The root object
 * @param props.handleLocationClick - The root object
 * @example
 */
export default function ProjectLocations({
  handleLocationClick,
  isLoading,
  loadedProject
}) {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  const { state } = useLocation();

  let project;

  project = isLoading && state?.project ? state.project : loadedProject;

  if (project && project.locations.length > 1) {
    return (
      <>
        <Popover>
          <div className="flex">
            <p className="text-base font-medium text-gray-500">
              {project.locations[0].city?.zipcode} {project.locations[0].city?.name}, {project.locations[0].street.name} {project.locations[0].housenumber}
            </p>

            <Popover.Button className="text-gray-500 hover:text-gray-600" ref={setReferenceElement}>
              <ChevronDownIcon className="size-6" />
            </Popover.Button>
          </div>

          <Popover.Panel
            className="z-10 space-y-2 rounded-lg border bg-white p-4 shadow"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {project.locations.map((location, index) => {
              if (index === 0) {
                return null;
              }

              return (
                <p className="text-base font-medium text-gray-500" key={`location_${index}`}>
                  {location.city?.zipcode} {location.city?.name}, {location.street.name} {location.housenumber}
                </p>
              );
            })}
          </Popover.Panel>

        </Popover>
      </>
    );
  }

  if (project && project.locations.length === 1) {
    return (
      <>
        {project.locations.map((location, index) => (
          <p className="text-base font-medium text-gray-500" key={index}>
            {location.city?.zipcode} {location.city?.name}, {location.street.name} {location.housenumber}
          </p>
        ))}
      </>
    );
  }

  return null;
}
