import { Temporal } from "@js-temporal/polyfill";

const dynamicYearRange = () => {
  return {
    endYear: Temporal.Now.plainDateISO().year + 3, //current year + 3 [IDEV-783]
    startYear: 2_021 //hardcoded set to 2021
  };
};

export default dynamicYearRange;
