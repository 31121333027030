import { saveAs } from "file-saver";

import unitSheetGenerator from "~/src/lib/excel-export-units-sheet/unit-sheet-generator";

import { datestamp } from "~/src/modules/date-formatter";

import projectSheetGenerator from "./project-sheet-generator";

/**
 *
 * @param userSettings
 * @param project
 * @param units
 * @example
 */
export async function handleExcelExport(userSettings, project, units) {
  const ExcelJS = await import("exceljs");

  const Workbook = new ExcelJS.Workbook();

  projectSheetGenerator(Workbook, project);
  unitSheetGenerator(
    Workbook,
    userSettings,
    [project],
    units,
    "Erfasste Objekte"
  );

  Workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `${project.name}_${datestamp(new Date())}.xlsx`
    );
  });
}
