import { useEffect, useState } from "react";

import { getMediaQueryList, getQueryDoesMatch } from "./media-query-helpers";

/**
 *
 * @param query
 * @example
 */
const useMediaQuery = (query) => {
  const [queryDoesMatch, setQueryDoesMatch] = useState(
    getQueryDoesMatch(query)
  );

  const onMediaQueryListChange = (matchQueryChangeEvent) => {
    setQueryDoesMatch(matchQueryChangeEvent?.matches ?? false);
  };

  useEffect(() => {
    setQueryDoesMatch(getQueryDoesMatch(query));

    const mediaQueryList = getMediaQueryList(query);

    mediaQueryList.addEventListener("change", onMediaQueryListChange);

    return () => {
      mediaQueryList.removeEventListener("change", onMediaQueryListChange);
    };
  }, [query]);

  if (query === "print" && localStorage.getItem("bidra-print-hack") === "true") {
    return true;
  }

  return queryDoesMatch;
};

export default useMediaQuery;
