import API from "~/src/modules/api";

/**
 *
 * @param watchlistId
 * @param name
 * @param handleSuccess
 * @param handleError
 * @example
 */
export function handleUpdate(watchlistId, name, handleSuccess, handleError) {
  API.post(`/watchlists/${watchlistId}`, {
    watchlist: {
      name
    }
  }).then((res) => {
    handleSuccess();
  }).catch((error) => {
    handleError(error.response.status);
  });
}
