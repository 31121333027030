import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const trackingId = window.settings.REACT_APP_MATOMO_ID;

/**
 *
 * @example
 */
export default function useMatomo() {
  const { listen } = useHistory();

  useEffect(() => listen((location) => {
    if (!window._paq) {
      return;
    }
    if (!trackingId) {
      console.log(
        "Tracking not enabled, as `trackingId` was not given"
      );

      return;
    }

    window._paq.push(["setCustomUrl", location.pathname + location.search], ["trackPageView"]);
  }), [listen]);
}
