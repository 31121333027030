import { useRef } from "react";

import PopupContent from "./popup-content/index.jsx";
import { Popup } from "./project-marker-popup/_exports.js";

/**
 *
 * @param root0 - The root object
 * @param root0.project - The root object
 * @param root0.selected - The root object
 * @param root0.handleCheckbox - The root object
 * @param root0.checkboxDisabled - The root object
 * @example
 */
export default function ProjectMarkerPopup({
  checkboxDisabled,
  handleCheckbox,
  project,
  selected
}) {
  const popupReference = useRef(null);

  const location = project?.locations?.find((innerLocation) => innerLocation.default_location) ??
    project?.locations?.[0];

  const displayPicture = (project?.show_image_public && project?.image_file_name);

  return (
    <Popup
      autoPan={false}
      className={`${displayPicture ? "with-picture" : "without-picture"}`}
      ref={popupReference}
    >
      {
        project
          ? (
            <PopupContent
              checkboxDisabled={checkboxDisabled}
              className={`${displayPicture ? "with-picture" : "without-picture"}`}
              displayPicture={displayPicture}
              handleCheckbox={handleCheckbox}
              location={location}
              project={project}
              selected={selected}
            />
          )
          : null
      }
    </Popup>
  );
}
