import { useParams } from "react-router-dom";

import useProject from "~/src/hooks/use-project";

import ProjectQuantity from "~/src/ui/repeatables/project-quantity";
import ContentSection from "~/src/ui/sections/content-section";

/**
 *
 * @example
 */
const ProjectOverviewQuantities = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    quantities
  } = project;

  return (
    <ContentSection id="quantities" title="Einheiten">
      <dl className="grid grid-cols-2-auto gap-x-4 gap-y-0.5">
        {(quantities.length > 0)
          ? [...quantities]
            .sort(({ category: { id: idA } }, { category: { id: idB } }) => idA - idB)
            .map((quantity, index) => (
              <ProjectQuantity index={index} key={index} quantity={quantity} />
            ))
          : "-"}
      </dl>

    </ContentSection>
  );
};

export default ProjectOverviewQuantities;
