import { quantityLabels } from "~/src/modules/labels";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function QuantityFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const quantity = queryItems.split(",");

  /**
   *
   * @param qu
   * @example
   */
  function handleClick(qu) {
    const newQu = quantity.filter((item) => item !== qu).join(",");

    submit("quantity", newQu);
  }

  return quantity.map((qu, index) => (
    <DefaultFilterTag handleClick={() => handleClick(qu)} key={`qu_tag_${index}`}>
      <span>Projektgröße: {quantityLabels[qu]}</span>
    </DefaultFilterTag>
  ));
}
