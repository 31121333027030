import StatisticsHeader from "~/src/features/project-statistics-header";
import StatisticsTabs from "~/src/features/project-statistics-tabs";

/**
 * Entry point to render the project statistics page.
 *
 * @returns {React.Element} Project statistics page.
 * @example
 */
export default function ProjectStatisticsPage() {
  return (
    <div className="flex w-full flex-col gap-6">
      <h2 className="px-4 text-xl font-medium sm:px-0">Statistiken</h2>

      <StatisticsHeader />

      <StatisticsTabs />
    </div>
  );
}
