import dateFormatter from "~/src/modules/date-formatter.js";
import { constructionPhaseLabels, marketingPhaseLabels } from "~/src/modules/labels.js";

import { nullValues } from "./null-values.js";

const projectStageLabels = {
  construction: constructionPhaseLabels,
  marketing: marketingPhaseLabels
};

/**
 *
 * @param location
 * @example
 */
const getAddressStringFromLocation = (location) => [`${location.city?.zipcode ?? ""} ${location.city?.name ?? ""}`.trim(), `${location.street?.name ?? ""} ${location.housenumber ?? ""}`.trim()]
  .filter(Boolean)
  .join(", ")
  .replaceAll(";", ",") ??
  nullValues.notRegistered;

/**
 *
 * @param _roleName
 * @param root0 - The root object
 * @param root0.forAutomated - The root object
 * @example
 */
const getCompaniesByRoleFunction = (_roleName, { forAutomated } = {}) => (project) => {
  const isAutomated = Boolean(project.automated);
  //    const canHaveThisRole = forAutomated ? isAutomated : !isAutomated;
  //    if (roleName === "owner" && !canHaveThisRole) {
  //      return nullValues.empty;
  //    }
  const roleName = isAutomated && _roleName === "seller"
    ? "owner"
    : _roleName;

  return project
    .company_roles?.filter?.((role) => role.name === roleName)
    .map((role) => (role.private ? "Private" : role?.company?.name))
    .join(";");
};

/**
 *
 * @param project
 * @example
 */
const getCompletedDate = (project) => {
  if (project.automated) {
    // is it empty or not applies?
    return nullValues.empty;
  }

  const completed = project
    ?.timeline
    ?.current_construction_phase === "construction_phase_completed";

  if (completed) {
    return (
      project?.timeline?.construction_phase_completed_date !== null &&
      project?.timeline?.construction_phase_completed_date_format !== null
    )
      ? dateFormatter(
        project?.timeline?.construction_phase_completed_date,
        project?.timeline?.construction_phase_completed_date_format
      )
      : project.finish_year;
  }

  if (project.construction_infos?.estimated_finish_date) {
    return dateFormatter(
      project.construction_infos.estimated_finish_date,
      project.construction_infos?.estimated_finish_date_format
    );
  }

  return nullValues.notRegistered;
};

/**
 *
 * @param project
 * @example
 */
const getLastContractDate = (project) => {
  if (!project.automated) {
    // is it empty or in fact not applies?
    return nullValues.empty;
  }

  const lastContractDate = project.timeline?.last_contract_date;

  if (!lastContractDate) {
    // is it empty or in fact not registered?
    return nullValues.empty;
  }

  return dateFormatter(lastContractDate, "year");
};

/**
 *
 * @param category
 * @example
 */
const getProjectStageByCategoryFunction = (category) => (project) => {
  const projectStage = project.timeline?.[`current_${category}_phase`];

  if (!projectStage) {
    return nullValues.empty;
  }
  if (projectStage === "uknown") {
    return nullValues.empty;
  }

  return projectStageLabels[category][projectStage] ?? nullValues.empty;
};

/**
 *
 * @param project
 * @example
 */
const displayRechtsform = (project) => {
  if (project.rentable && project.buyable) {
    return "Eigentum, Miete";
  }
  if (project.rentable) {
    return "Miete";
  }
  if (project.buyable) {
    return "Eigentum";
  }

  return "nicht bekannt";
};

/**
 *
 * @param key
 * @param condition
 * @example
 */
const getValueByKeyConditionallyFunction = (key, condition) => (item) => {
  const value = item[key];

  if (!condition(item) || !value) {
    return nullValues.empty;
  }

  return value;
};

const invalidValue = (valueToCheck) => [null, undefined].includes(valueToCheck);

/**
 * Get the funding type of a project.
 *
 * @param {object} project - The project object.
 * @returns {string} The funding type of the project.
 * @example
 */
const getFundingType = (project) => {
  const { funding } = project;

  if (!funding) {
    return nullValues.empty;
  }

  const { normal_funded: normalFunded, not_funded: notFunded } = funding;

  if (invalidValue(notFunded) && invalidValue(normalFunded)) {
    return nullValues.empty;
  }

  if (normalFunded && notFunded) {
    return "gemischt";
  }
  else if (normalFunded && !notFunded) {
    return "gefördert";
  }
  else if (!normalFunded && notFunded) {
    return "freifinanziert";
  }

  return nullValues.empty;
};

/**
 *
 * @param key
 * @param formatter
 * @param emptyVals
 * @example
 */
const getFormattedValueByKeyFunction = (
  key,
  formatter = (x) => x,
  emptyVals = [null, undefined]
) => (project) => {
  const value = project[key];

  if (emptyVals.includes(value)) {
    return nullValues.empty;
  }

  return formatter(value);
};

export {
  displayRechtsform,
  getAddressStringFromLocation,
  getCompaniesByRoleFunction as getCompaniesByRoleFn,
  getCompletedDate,
  getFormattedValueByKeyFunction as getFormattedValueByKeyFn,
  getFundingType,
  getLastContractDate,
  getProjectStageByCategoryFunction as getProjectStageByCategoryFn,
  getValueByKeyConditionallyFunction as getValueByKeyConditionallyFn
};
