import { useQueryParams } from "use-query-params";

import { ApiModel } from "~/src/lib/models/projects";

/**
 *
 * @example
 */
export default function useProjectQueryParameters() {
  const [query, setQuery] = useQueryParams(
    ApiModel.schemaForUseQueryParams
  );

  return {
    query,
    setQuery
  };
}
