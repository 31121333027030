import { useLocation } from "react-router-dom";

/**
 *
 * @example
 */
export default function useTracking() {
  const location = useLocation();

  /**
   *
   * @param recordId
   * @param title
   * @example
   */
  function trackPageView(recordId, title) {
    if (!window._paq) {
      return;
    }

    window._paq.push(["setCustomUrl", `${location.pathname}/docId=${recordId}${location.search}`], ["setDocumentTitle", `${title} Id:${recordId}`], ["trackPageView"]);
  }

  return {
    trackPageView
  };
}
