import { Switch } from "@headlessui/react";
import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.dateType - The root object
 * @param root0.onChange - The root object
 * @example
 */
export default function PurchaseDateToggle({ dateType = "dateVerbuecherung", onChange = () => null }) {
  const right = dateType === "dateVerbuecherung";

  /**
   *
   * @param value
   * @example
   */
  function handleChange(value) {
    const query = value ? "dateKV" : "dateVerbuecherung";

    onChange({
      dateType: query,
      sortBy: query
    });
  }

  return (
    <Switch.Group as="div" className="flex items-center lg:justify-between">
      <span className="mr-3 flex flex-col lg:grow">
        <button
          onClick={() => handleChange(false)}
          className={cn("text-sm font-medium text-gray-600", {
            "cursor-default pointer-events-none": right
          })}
        >
          Verbücherung
        </button>
      </span>

      <Switch
        checked={!right}
        onChange={handleChange}
        className={cn(
          right ? "bg-gray-200" : "bg-secondary-dark",
          "relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-transparent"
        )}
      >
        <span
          aria-hidden="true"
          className={cn(
            right ? "translate-x-0" : "translate-x-5",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>

      <span className="ml-3 flex flex-col lg:grow">
        <button
          onClick={() => handleChange(true)}
          className={cn("focus:outline-none text-sm font-medium text-gray-600", {
            "cursor-default pointer-events-none": !right
          })}
        >
          KV-Datum
        </button>
      </span>
    </Switch.Group>
  );
}
