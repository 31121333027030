import { Temporal } from "@js-temporal/polyfill";
import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

const {
  Now,
  PlainYearMonth
} = Temporal;

/**
 *
 * @param options - The root object
 * @param options.query - The root object
 * @param options.shouldFetch - The root object
 * @param swrOptions
 * @example
 */
const useCount = ({ query, shouldFetch = true }, swrOptions) => {
  const {
    direction,
    layout,
    marked,
    page,
    polygon,
    radius,
    sort,
    street,
    ...restQuery
  } = query;

  const newQuery = {
    street,
    ...restQuery,
    polygon: polygon?.join("_"),
    ...(street && {
      radius
    })
  };

  if (!newQuery.activeBBox) {
    delete newQuery.bBox;
    delete newQuery.mapZoom;
    delete newQuery.centerLat;
    delete newQuery.centerLng;
  }

  const parameters = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newQuery).filter(([key, value]) => value !== undefined)
    )
  );

  const {
    data,
    error,
    isLoading,
    isValidating,
    mutate
  } = useSWR(() => (shouldFetch ? `/count?${parameters}` : null), fetcher, swrOptions);

  return {
    ...data?.payload,
    isError: error,
    isLoading,
    isValidating,
    mutate,
    updateTime: Now.instant().epochMilliseconds
  };
};

export default useCount;
