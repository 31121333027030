import { stringify } from "query-string";
import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @example
 */
export default function useShareDeals({ query }) {
  const { data, error } = useSWR(query.type === "liegenschaft" ? `/share-deal-properties?${stringify(query, { arrayFormat: "comma" })}` : `/share-deal-transactions?${stringify(query, { arrayFormat: "comma" })}`, fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    pagination: data?.payload?.pagination,
    shareDeals: data?.payload?.shareDeals,
    sortDirection: data?.payload?.sortDirection
  };
}
