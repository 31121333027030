/* eslint-disable no-magic-numbers */

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import useStore from "~/src/hooks/use-store";

import ImmoUnitedDropdown from "../dropdowns/immo-united-dropdown";
import SettingsDropdown from "../dropdowns/settings-dropdown";

const tabsLeft = [
  {
    current: false,
    href: "/karte",
    name: "Karte",
    pageKey: "karte"
  },
  {
    current: false,
    href: "/liste",
    name: "Liste",
    pageKey: "liste"
  },
  {
    current: false,
    href: "/statistik",
    name: "Statistik",
    pageKey: "statistik"
  }
];

const tabsRight = [
  // { name: "Dashboard", href: "/", pageKey: "dashboard" },
  {
    current: false,
    href: "/transaktionen",
    name: "Transaktionen",
    pageKey: "transactions"
  },
  {
    current: false,
    href: "/share-deals",
    name: "Share Deals",
    pageKey: "sharedeals"
  },
  {
    current: false,
    href: "/insolvenzen",
    name: "Insolvenzen",
    pageKey: "insolvencies"
  }
  // { name: "Merklisten", href: "/merklisten", pageKey: "watchlist" },
];

const tabs = [...tabsLeft, ...tabsRight];

// TODO: Add project list export
// const PdfExportProjectListButton = lazy(() => import("~/src/features/pdf-export-project-list-button"));

/**
 *
 * @param props - The root object
 * @param props.pageKey - The root object
 * @param props.hideMobile - The root object
 * @param props.projectList - The root object
 * @param props.projectDetail - The root object
 * @param props.showMenu - The root object
 * @param props.showReduced - The root object
 * @param props.onlyMenu - The root object
 * @param props.searchKey - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function SubNav({
  onlyMenu,
  pageKey = false,
  projectDetail = false,
  projectList = false,
  searchKey = "",
  showReduced = false
}) {
  const history = useHistory();

  const [showScrollTop, setShowScrollTop] = useState(false);

  const toggleProjectsFiltersTile = useStore((state) => state.toggleProjectsFiltersTile);

  const toggleAreaModal = useStore((state) => state.toggleAreaModal);
  const toggleProjectSuggestionModal = useStore((state) => state.toggleProjectSuggestionModal);

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = Math.abs(currPos.y) > 800;

    if (isShow !== showScrollTop) {
      setShowScrollTop(isShow);
    }
  }, [showScrollTop]);

  const handleChange = (event) => {
    const { value } = event.target;

    history.push(tabs.find((tab) => tab.pageKey === value).href);
  };

  const buttonNavigationLeft = ({ className }) => (
    <nav className={className}>
      {tabsLeft.map((tab) => (
        <Link
          key={tab.name}
          title={tab.name}
          className={cn(
            "text-center text-white break-keep whitespace-nowrap text-sm select-none px-2 py-0.5 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white before:h-0 before:block before:content-[attr(title)] before:font-bold before:overflow-hidden before:visibility-hidden",
            {
              "bg-white !text-primary font-bold": ((tab?.href === pageKey) || (pageKey === "/" && tab?.href === "/karte")),
              "border-transparent hover:text-primary hover:bg-white": tab?.href !== pageKey
            }
          )}
          to={`${tab.href}${[
            "/",
            "/karte",
            "/liste",
            "/statistik"
          ].includes(pageKey)
            ? searchKey
            : ""}`}
        >
          {tab.name}
        </Link>
      ))}
    </nav>
  );

  const buttonNavigationRight = ({ className }) => (
    <nav className={`${className} mr-[0.05rem]`}>
      {tabsRight.map((tab) => (
        <Link
          key={tab.name}
          title={tab.name}
          to={tab.href}
          className={cn(
            "text-center break-keep whitespace-nowrap text-sm select-none px-2 py-0.5 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white before:h-0 before:block before:content-[attr(title)] before:font-bold before:overflow-hidden before:visibility-hidden",
            {
              "bg-white text-primary font-bold": tab?.href === pageKey,
              "text-white border-transparent hover:text-primary hover:bg-white": tab?.href !== pageKey
            }
          )}
        >
          {tab.name}
        </Link>
      ))}

      <div className="flex flex-row gap-4 pl-3">
        <ImmoUnitedDropdown />

        <SettingsDropdown />
      </div>

    </nav>
  );

  const buttonNavigation = ({ className }) => (
    <div className="flex w-full flex-row justify-between">
      {buttonNavigationLeft({ className: `${className} justify-start` })}

      {buttonNavigationRight({ className: `${className} justify-end` })}
    </div>
  );

  const dropdownNavigation = ({ className }) => (
    <div className={className}>
      <select
        className="block size-full rounded border-gray-300 pl-3 pr-10 text-sm text-gray-500 focus:border-primary focus:outline-none focus:ring-primary"
        id="current-tab"
        name="current-tab"
        onChange={handleChange}
        value={tabs.find((tab) => tab?.href === pageKey)?.pageKey || ""}
      >
        {tabsLeft.map((tab) => (
          <option key={tab.pageKey} value={tab.pageKey || null}>{tab.name}</option>
        ))}

        {tabsRight.map((tab) => (
          <option key={tab.pageKey} value={tab.pageKey || null}>{tab.name}</option>
        ))}
      </select>
    </div>
  );

  if (onlyMenu) {
    return (
      <div className="sticky top-0 z-10 flex size-full max-h-[36px] justify-end">
        <div className="flex max-h-[36px] flex-row gap-4 pl-3">
          <ImmoUnitedDropdown />

          <SettingsDropdown />
        </div>
      </div>
    );
  }

  return (
    <div className="sticky top-0 z-10 size-full">
      <div className="flex size-full max-h-[36px] items-center">
        {(showReduced)
          ? (
            <>
              {buttonNavigation({ className: "hidden xl:flex justify-between items-center gap-x-3 w-full" })}

              {dropdownNavigation({ className: "flex xl:hidden bg-white rounded h-full" })}
            </>
          )
          : (
            <>
              {buttonNavigation({ className: "hidden md:flex items-center gap-x-3 w-full" })}

              {dropdownNavigation({ className: "flex md:hidden bg-white rounded h-full" })}
            </>
          )}
      </div>
    </div>
  );
}
