import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function QuantitySearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const has1 = items.includes("1");
  const has10 = items.includes("10");
  const has25 = items.includes("25");
  const has50 = items.includes("50");
  const has100 = items.includes("100");

  const hasTemporary1 = temporaryItems.includes("1");
  const hasTemporary10 = temporaryItems.includes("10");
  const hasTemporary25 = temporaryItems.includes("25");
  const hasTemporary50 = temporaryItems.includes("50");
  const hasTemporary100 = temporaryItems.includes("100");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("quantity", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Anzahl <span className="font-light">(Wohneinheiten)</span>
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has1, hasTemporary1)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "1")} type="checkbox" />

            <span className="ml-3">1-9</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has10, hasTemporary10)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "10")} type="checkbox" />

            <span className="ml-3">10-24</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has25, hasTemporary25)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "25")} type="checkbox" />

            <span className="ml-3">25-49</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has50, hasTemporary50)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "50")} type="checkbox" />

            <span className="ml-3">50-99</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(has100, hasTemporary100)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "100")} type="checkbox" />

            <span className="ml-3">+100</span>
          </label>
        </div>
      </div>
    </div>
  );
}
