import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import useAnalytics from "~/src/hooks/use-analytics.js";
import useProject from "~/src/hooks/use-project";
import useUnitsExport from "~/src/hooks/use-units-export";
import useUserSettings from "~/src/hooks/use-user-settings";

import ExcelIcon from "~/src/ui/icons/excel-icon";

import { handleExcelExport } from "./helpers";

/**
 *
 * @example
 */
const ExcelExportProjectButton = () => {
  const [shouldFetch, setShouldFetch] = useState(false);

  const { id: projectId } = useParams();
  const { project } = useProject(projectId);
  const { units } = useUnitsExport({
    project,
    shouldFetch
  });
  const { userSettings } = useUserSettings();

  const { addAnalytics } = useAnalytics();

  const handleClick = () => {
    setShouldFetch(true);

    addAnalytics({
      action: "export-project-excel",
      data: {
        userSettings
      }
    });
  };

  useEffect(() => {
    if (shouldFetch && project && units) {
      setShouldFetch(false);
      handleExcelExport(userSettings, project, units);
    }
  }, [
    shouldFetch,
    project,
    units,
    userSettings
  ]);

  return (
    <div>
      <button
        className="flex size-8 cursor-pointer items-center justify-center rounded-full bg-excel-green p-1 hover:bg-excel-green-dark disabled:cursor-wait disabled:grayscale"
        data-tooltip-content="Excel-Export"
        data-tooltip-id="tooltip-excel-export"
        onClick={handleClick}
      >
        <ExcelIcon />
      </button>

      <Tooltip
        className="z-50"
        delayShow={500}
        id="tooltip-excel-export"
        place="bottom"
      />
    </div>
  );
};

export default ExcelExportProjectButton;
