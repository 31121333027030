import { useParams } from "react-router-dom";

import {
  areaTypesBuyable,
  areaTypesRentable,
  dateTypesBuyable,
  dateTypesRentable,
  labelsBuyable,
  labelsRentable,
  priceTypesBuyable,
  priceTypesRentable
} from "~/src/modules/labels";

import DetailBuyableChart from "~/src/features/charts/detail/buyable";
import DetailRentableChart from "~/src/features/charts/detail/rentable";

import useProjectStatistics from "~/src/hooks/use-project-statistics";
import useStore from "~/src/hooks/use-store";

import ChartError from "~/src/ui/charts/error";
import ChartContainer from "~/src/ui/containers/chart-container";

/**
 *
 * @example
 */
export default function DetailStatistics() {
  const bothInnerChartComponentsHaveError =
    useStore((state) => state.bothProjectPriceChartsHaveErrors);

  const { id: projectId } = useParams();

  const structureBuyable = "composition";
  const typesBuyable = [
    "units-main-category",
    "units-main-name",
    ...priceTypesBuyable,
    ...dateTypesBuyable,
    ...areaTypesBuyable
  ];

  const {
    isError: isErrorBuyable,
    isLoading: isLoadingBuyable,
    statistics: statisticsBuyable
  } = useProjectStatistics({
    projectId,
    structure: structureBuyable,
    types: typesBuyable
  });

  const structureRentable = "composition";
  const typesRentable = [
    "units-main-category",
    "units-main-name",
    ...priceTypesRentable,
    ...dateTypesRentable,
    ...areaTypesRentable
  ];

  const {
    isError: isErrorRentable,
    isLoading: isLoadingRentable,
    statistics: statisticsRentable
  } = useProjectStatistics({
    projectId,
    structure: structureRentable,
    types: typesRentable
  });

  const getBuyableChart = () => (
    <DetailBuyableChart
      areaTypes={areaTypesBuyable}
      dateTypes={dateTypesBuyable}
      isError={isErrorBuyable}
      isLoading={isLoadingBuyable}
      key="detail-buyable-chart"
      labels={labelsBuyable}
      priceTypes={priceTypesBuyable}
      statistics={statisticsBuyable}
    />
  );
  const getRentableChart = () => (
    <DetailRentableChart
      areaTypes={areaTypesRentable}
      dateTypes={dateTypesRentable}
      isError={isErrorRentable}
      isLoading={isLoadingRentable}
      key="detail-rentable-chart"
      labels={labelsRentable}
      priceTypes={priceTypesRentable}
      statistics={statisticsRentable}
    />
  );

  const charts = [
    {
      getChart: getBuyableChart,
      hasError: isErrorBuyable,
      isEmpty: !statisticsBuyable
    },
    {
      getChart: getRentableChart,
      hasError: isErrorRentable,
      isEmpty: !statisticsRentable
    }
  ];

  const hasDataError = (chart) => chart.hasError || chart.isEmpty;
  const allChartsHaveDataError = charts.every((chart) => hasDataError(chart));

  const noChartsAreDisplayed =
    allChartsHaveDataError || bothInnerChartComponentsHaveError;

  if (noChartsAreDisplayed) {
    charts.push({
      getChart: () => <ChartError key="project-price-chart-error" />
    });
  }

  const chartComponents = charts.map((chart) => chart.getChart());

  return (
    <ChartContainer>{chartComponents}</ChartContainer>);
}
