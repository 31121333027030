/* eslint-disable no-empty-function */
/* eslint-disable import/max-dependencies */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */

import { useVirtualizer } from "@tanstack/react-virtual";
import { omit } from "radashi";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import useProjectList from "~/src/hooks/use-project-list.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";
import useWatchlists from "~/src/hooks/use-watchlists.js";

import ItemSkeleton from "~/src/ui/loading/item-skeleton/index.jsx";

import { ProjectMiniListItem } from "./project-mini-list/_exports.js";

const pageSize = 50;

/**
 * @typedef {object} ProjectListProps
 * @property {Function} setCurrentTotal - The current total setter.
 * @property {Function} setIsLoading - The loading setter.
 * @property {Function} setSimulatedPage - The simulated page setter.
 */

/**
 * The project mini list component.
 *
 * @param {ProjectListProps} props - The component props.
 * @returns {JSX.Element|null} The rendered JSX element.
 * @example
 */
const ProjectMiniList = ({
  setCurrentTotal,
  setIsLoading,
  setSimulatedPage
}) => {
  const swrOptions = {
    revalidate: true,
    revalidateOnFocus: false
  };

  const { query: rawQuery, setQuery } = useProjectQueryParams();

  const ignoredQueryKeys = rawQuery.activeBBox
    ? []
    : [
      "bBox",
      "centerLat",
      "centerLng",
      "mapZoom"
    ];

  const filteredRawQuery = omit(rawQuery, ignoredQueryKeys);

  const query = useMemo(() => filteredRawQuery, [JSON.stringify(filteredRawQuery)]);

  const {
    isLoading,
    isLoadingMore,
    isReachingEnd,
    mutate: mutateProjectList = () => {},
    projects,
    selectionStatus,
    selectionType,
    setSize = () => {},
    size: swrSize,
    total
  } = useProjectList(
    {
      perPage: pageSize,
      query,
      size: "small",
      swrOptions
    }
  );

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const skeletonReference = useRef(null);

  useEffect(() => {
    setIsLoading(isLoading || isLoadingMore);
  }, [isLoading, isLoadingMore]);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const setChange = useStore((state) => state.setChange);

  const [fromHook, setFromHook] = useState(false);

  const setCurrentSelectionStatus = useStore((state) => state.setCurrentSelectionStatus);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const projectListReference = useRef(null);

  // TODO[2025-01-01]: Avoid setting state in useEffect
  useEffect(() => {
    if (selectionType) {
      setMainSelectionType(selectionType);
      if (!fromHook) {
        setFromHook(true);
      }
    }
  }, [selectionType]);

  // TODO[2025-01-01]: Avoid setting state in useEffect
  useEffect(() => {
    if (selectionStatus) {
      setCurrentSelectionStatus(selectionStatus);
      if (!fromHook) {
        setFromHook(true);
      }
    }
  }, [selectionStatus]);

  const loadingTimeout = 250;

  useEffect(() => {
    if (fromHook) {
      const settingSelectionLoading = () => {
        const timeoutId = setTimeout(() => {
          setSelectionLoading(false);
          setFromHook(false);
        }, loadingTimeout);

        return () => {
          clearTimeout(timeoutId);
        };
      };

      settingSelectionLoading();
    }
  }, [fromHook]);

  useEffect(() => {
    if (total !== undefined) {
      setCurrentTotal(total.totalPages);
    }
  });

  useEffect(() => {
    if (projects && projects.length === 0 && !Number.isNaN(total?.totalPages)) {
      setQuery({
        page: Math.max(total?.totalPages, 1)
      });
    }
  }, [projects]);

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutateProjectList();
        // await mutateCount();
        setSelectionMutate(false);
        if (selectionLoading) {
          setSelectionLoading(false);
        }
      };

      mutating();
    }
  }, [selectionMutate]);

  /**
   *
   * @param {object} update
   * @param {string} update.source
   * @param {string} update.type
   * @param {string[]} update.data
   * @example
   */
  const updateSelection = (update) => {
    setChange(update);
  };

  /**
   *
   * @param {boolean} value
   * @param {string} currentSlug
   * @example
   */
  const handleProjectSelectedCheckbox = async (value, currentSlug) => {
    if (!selectionLoading) {
      const updateType = (value)
        ? "add"
        : "remove";

      const update = {
        data: [currentSlug],
        source: "list",
        type: updateType
      };

      await updateSelection(update);
    }
  };

  const { mutate: mutateWatchlists, watchlists } = useWatchlists();

  const fetchMore = useCallback(() => {
    if (!isReachingEnd && !isLoadingMore) {
      setSize((previousSize) => previousSize + 1);
    }
  }, [
    isReachingEnd,
    isLoadingMore,
    setSize
  ]);

  const itemCount = projects === undefined
    ? 0
    : (isReachingEnd ? projects.length : projects.length + 1);

  useEffect(() => {
    if (swrSize !== undefined) {
      setSimulatedPage(swrSize);
    }
  }, [swrSize]);

  const estimatedHeightOfListItem = 230;

  const rowVirtualizer = useVirtualizer({
    count: itemCount,
    estimateSize: () => estimatedHeightOfListItem,
    getItemKey: (index) => projects?.[index]?.slug ?? index,
    getScrollElement: () => projectListReference.current,
    overscan: 10
  });

  useEffect(() => {
    rowVirtualizer.scrollToIndex(0);

    if (skeletonReference.current !== null) {
      skeletonReference.current.scrollTo(0, 0);
    }
  }, [query, isLoading]);

  useEffect(() => {
    const virtualItems = rowVirtualizer.getVirtualItems();
    const lastItem = virtualItems.at(-1);

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= projects.length - 1 &&
      !isLoadingMore &&
      !isReachingEnd
    ) {
      fetchMore();
    }
  }, [
    projects?.length,
    isLoadingMore,
    isReachingEnd,
    fetchMore,
    rowVirtualizer.getVirtualItems().length
  ]);

  if (isLoading) {
    // Show loading skeletons initially
    return (
      <div className="h-full overflow-y-auto contain-strict" ref={skeletonReference}>
        {
          Array.from(
            { length: swrSize === undefined ? pageSize : Math.min(swrSize * pageSize, 200) },
            (empty, index) => (
              <ItemSkeleton
                className="relative w-full border-b border-gray-200 last:border-b-0"
                key={index}
                size="small"
              />
            )
          )
        }
      </div>
    );
  }

  if (projects === undefined || projects.length === 0) {
    return (
      <span className="ml-[10px] mt-5">Keine Ergebnisse gefunden…</span>
    );
  }

  const items = rowVirtualizer.getVirtualItems();

  return (
    <div
      className="h-full overflow-y-auto contain-strict"
      ref={projectListReference}
    >
      <div
        className="relative w-full"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`
        }}
      >
        <div
          className="absolute left-0 top-0 w-full"
          style={{
            transform: `translateY(${items[0]?.start ?? 0}px)`
          }}
        >
          {items.map((virtualRow) => {
            const { index, key } = virtualRow;
            const project = projects[index];

            return (
              <div
                className="relative"
                data-index={index}
                key={key}
                ref={rowVirtualizer.measureElement}
              >
                {
                  project
                    ? (
                      <ProjectMiniListItem
                        disabled={selectionLoading}
                        handleCheckbox={handleProjectSelectedCheckbox}
                        mutateWatchlists={mutateWatchlists}
                        project={project}
                        watchlists={watchlists}
                      />
                    )
                    : (
                      <ItemSkeleton
                        className="absolute w-full border-b border-gray-200 last:border-b-0"
                        size="small"
                      />
                    )
                }
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
};

export default ProjectMiniList;
