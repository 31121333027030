import { nullFn } from "~/src/lib/standard";

import { priceLabels } from "~/src/modules/labels";

import ChartLegend from "~/src/ui/charts/legend";
import MultiLineChart from "~/src/ui/charts/multi-line-chart";

/* eslint-disable func-style */

/* eslint-disable import/prefer-default-export */

import { generateLineData } from "./tools";

/**
 *
 * @param root0 - The root object
 * @param root0.graphLabels - The root object
 * @param root0.statistic - The root object
 * @param root0.areaGroupsWithoutUnselected - The root object
 * @example
 */
function MarketTrendByAreaOfferChartForPrint({
  areaGroupsWithoutUnselected,
  graphLabels,
  statistic

}) {
  return graphLabels
    .map((graphLabel) => {
      const lineData = generateLineData(graphLabel, statistic);

      return (
        <div className="w-full break-inside-avoid space-y-4 bg-white" key={graphLabel}>
          <h2 className="text-md font-medium text-gray-900">{priceLabels[graphLabel]} Trend nach Fläche</h2>

          <div className="h-[19rem]">
            <MultiLineChart
              forPrint
              activeAreaGroups={areaGroupsWithoutUnselected}
              data={lineData.chartData}
              x={{ type: "month" }}
            />
          </div>

          <ChartLegend
            simple
            activeAreaGroups={areaGroupsWithoutUnselected}
            data={lineData.legendData}
            displayAreaGroups={areaGroupsWithoutUnselected}
            setActiveAreaGroups={nullFn}
            setHoveredAreaGroup={nullFn}
          />
        </div>
      );
    });
}

export { MarketTrendByAreaOfferChartForPrint };
