/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Temporal } from "@js-temporal/polyfill";
import React from "react";
import { useParams } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter.js";
import {
  formatArea, formatBuyerOrSeller, formatEuro
} from "~/src/modules/formatters.js";

import useProject from "~/src/hooks/use-project.js";

import ContentSection from "~/src/ui/sections/content-section.jsx";

import NoteElement from "./note-element.jsx";

/**
 * @typedef {import("react").FunctionComponent} FunctionComponent
 */

/**
 * The ProjectOverviewTransactions component.
 *
 * @type {FunctionComponent}
 * @example
 */
const ProjectOverviewTransactions = ({ historic }) => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }
  const {
    transactions
  } = project;

  const transactionContent =
    transactions
      .filter(({ historic: isHistoric }) => isHistoric === historic)
      .sort((transactionA, transactionB) => {
        const dateA = new Date(transactionA.transaction_date).toTemporalInstant();
        const dateB = new Date(transactionB.transaction_date).toTemporalInstant();

        return Temporal.Instant.compare(dateA, dateB) * -1;
      })
      .sort((transactionA, transactionB) => {
        if (transactionA.sorting === null && transactionB.sorting === null) {
          return 0;
        }
        else if (transactionA.sorting === null) {
          return 1;
        }
        else if (transactionB.sorting === null) {
          return -1;
        }

        return transactionA.sorting - transactionB.sorting;
      })
      .map(({
        id: transactionId,
        tz_number: tzNumber,
        tz_year: tzYear,

        kv_id: kvId,

        bauzins_gross: bauzinsGross,
        bauzins_net: bauzinsNet,
        sale_price_net: salePriceNet,
        transaction_category: {
          code: transactionCategoryCode,
          name: transactionCategoryName
        },
        transaction_date: transactionDate,

        kg: {
          bezirksgericht: {
            code: bezirksgerichtCode,
            name: bezirksgerichtName
          },
          code: kgCode,
          name: kgName

        },

        buyer,
        buyer_private,
        seller,
        seller_private,

        baurechtsdauer,
        baurechtsdauer_date: baurechtsdauerDate,
        brez,

        ez_numbers: ezNumbers,
        gst_numbers: gstNumbers,
        shares,

        build_area: buildArea,
        green_area: greenArea,
        property_area: propertyArea,
        usable_area: usableArea,

        notes
      }) => {
        const isBaurecht = transactionCategoryCode === "baurecht";

        return (
          <div className="flex w-full flex-col justify-between gap-6 xl:flex-row" key={`parent_${transactionId}`}>
            <div className="jusitfy-between flex w-full flex-col xl:min-w-[60%] xl:max-w-[60%]">
              <div className="flex w-full flex-row justify-between">
                <div className="flex flex-col gap-5">
                  <div>
                    <span className="text-sm font-semibold">
                      Objektkategorie:
                      {" "}

                      <span className="text-sm font-normal">
                        {transactionCategoryName}
                      </span>
                    </span>
                  </div>

                  <div>
                    <div className="flex gap-3">
                      {
                        [
                          (bezirksgerichtName && bezirksgerichtCode) && ["BG", `${bezirksgerichtName}`],
                          (kgName && kgCode) && ["KG", `${kgName} (${kgCode})`],
                          (ezNumbers.length > 0) && ["EZ", `${ezNumbers.join(", ")}`]
                        ]
                          .filter((value) => (value !== false && value !== null))
                          .map(([label, value], index, array) => (
                            <React.Fragment key={`${label}_${transactionId}`}>
                              <span className="font-semibold" key={`${label}_${transactionId}`}>{`${label}: `} <span className="font-normal" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span> </span>
                            </React.Fragment>
                          ))
                      }
                    </div>

                    {(isBaurecht) && (
                      <div className="flex gap-3">
                        {
                          [
                            ["Baurechtsdauer", baurechtsdauer ? `${baurechtsdauer} Jahre` : "-"],
                            ["Baurecht bis", baurechtsdauerDate ? dateFormatter(baurechtsdauerDate) : "-"],
                            ["BREZ", brez || "-"]
                          ]
                            .map((
                              [label, value]
                            ) => (
                              <span key={`${label}_${transactionId}`}>
                                <span className="font-semibold">
                                  {label}:
                                </span>

                                <span> </span>

                                <span className="whitespace-nowrap font-normal">
                                  {value}
                                </span>
                              </span>
                            ))
                        }
                      </div>
                    )}

                    <div className="flex gap-3">
                      {
                        [["Baufläche (Gebäude)", buildArea ? formatArea(buildArea) : "-"], ["Baufläche (begrünt)", greenArea ? formatArea(greenArea) : "-"]]
                          .map(([label, value], index, array) => (
                            <React.Fragment key={`${label}_${transactionId}`}>
                              <span className="font-semibold" key={`${label}_${transactionId}`}>{`${label}: `} <span className="whitespace-nowrap font-normal" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span> </span>
                            </React.Fragment>
                          ))
                      }
                    </div>

                    {
                      (usableArea) && (
                        <div>
                          {
                            [["Nutzfläche", usableArea ? formatArea(usableArea) : "-"]]
                              .map(([label, value], index, array) => (
                                <React.Fragment key={`${label}_${transactionId}`}>
                                  <span className="font-semibold" key={`${label}_${transactionId}`}>{`${label}: `} <span className="font-normal" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span></span>
                                </React.Fragment>
                              ))
                          }
                        </div>
                      )
                    }

                    <div>
                      {
                        [(gstNumbers.length > 0) && ["GST-Nr.", `${gstNumbers.join(", ")}`]]
                          .filter((value) => (value !== false && value !== null))
                          .map(([label, value], index, array) => (
                            <React.Fragment key={`${label}_${transactionId}`}>
                              <span className="font-semibold" key={`${label}_${transactionId}`}>{`${label}: `}</span>

                              <span key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1) && ", "}</span>
                            </React.Fragment>
                          ))
                      }
                    </div>

                    <div className="max-w-[40rem]">
                      <span className="font-semibold">
                        {((isBaurecht)
                          ? "Bauberechtigter: "
                          : "Käufer: ")}
                      </span>

                      {
                        formatBuyerOrSeller({
                          buyerOrSeller: buyer,
                          isPrivate: buyer_private
                        })
                      }
                    </div>

                    <div className="max-w-[40rem]">
                      <span className="font-semibold">
                        {((isBaurecht)
                          ? "Baurechtsgeber: "
                          : "Verkäufer: ")}
                      </span>

                      {
                        formatBuyerOrSeller({
                          buyerOrSeller: seller,
                          isPrivate: seller_private
                        })
                      }
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-between">
                  <div className="flex flex-col items-end">
                    <div>
                      <span className="font-semibold" key={`vom_${transactionId}`}>{dateFormatter(transactionDate)}</span>
                    </div>

                    <div>
                      {(kvId && !isBaurecht)
                        ? (
                          <a
                            className="inline-flex cursor-pointer items-center gap-1 text-right font-bold hover:underline"
                            href={`https://www.immomapping.com/immomapping/Default.aspx?mapmode=1&contractOfPurchaseId=${kvId}`}
                            key={`tzNrLink_${transactionId}`}
                            target="_blank"
                          >
                            <span key={`tzNr_${transactionId}`}>TZ: <span className="font-normal">{tzNumber}/{dateFormatter(tzYear, "year")}</span></span>

                            <ArrowTopRightOnSquareIcon className="inline size-4 text-primary" key={`icon_${transactionId}`} />
                          </a>
                        )
                        : (
                          <span className="inline-flex items-center gap-1 text-right font-bold" key={`tzNrParent_${transactionId}`}>
                            <span key={`tzNr_${transactionId}`}>TZ: <span className="font-normal">{tzNumber}/{dateFormatter(tzYear, "year")}</span></span>
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="flex flex-col items-end text-right">
                    {
                      [
                        ["GST-Fläche", propertyArea ? formatArea(propertyArea) : "-"],
                        (Boolean(shares)) && ["Anteile", `${shares}`],
                        (typeof salePriceNet === "number") && ["Kaufpreis", `${formatEuro(salePriceNet)}`],
                        (typeof bauzinsNet === "number" || typeof bauzinsGross === "number") && ["Bauzins / Monat", ""],
                        (typeof bauzinsNet === "number") && ["Netto", `${formatEuro(bauzinsNet)}`],
                        (typeof bauzinsGross === "number") && ["Brutto", `${formatEuro(bauzinsGross)}`]
                      ]
                        .filter((value) => (value !== false && value !== null))
                        .map(([label, value], index, array) => (
                          <React.Fragment key={`${label}_${transactionId}`}>
                            <span className="font-semibold" key={`${label}_${transactionId}`}>{(label === "Bauzins / Monat") ? label : `${label}: `} <span className="whitespace-nowrap font-normal" key={`${index}_${transactionId}`} >{value}{index !== (array.length - 1)}</span></span>

                          </React.Fragment>
                        ))
                    }

                  </div>
                </div>
              </div>
            </div>

            <NoteElement id={`notes_${transactionId}`} notes={notes} />
          </div>

        );
      });

  return (
    (transactionContent.length === 0 && historic)
      ? null
      : (
        <ContentSection id="transactions" title={`${(historic) ? "Historische Liegenschaftsankäufe" : "Liegenschaftsankäufe"}`}>
          <ul className="flex w-full flex-col gap-6" key="ul">
            {
              (transactionContent.length > 0)
                ? transactionContent
                : "-"
            }
          </ul>
        </ContentSection>
      )
  );
};

export default ProjectOverviewTransactions;
