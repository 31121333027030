import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @example
 */
export default function useUnitCategories() {
  const {
    data, error, mutate
  } = useSWR(() => "/unit-categories", fetcher);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    unit_categories: data?.payload?.unit_categories
  };
}
