import DetailStatistics from "~/src/features/projects-statistic-tile/chart";

/**
 *
 * @example
 */
const PrintStatisticsPage = () => (
  <>
    <style>
      {`
        *:not(html, #print, #print *) {
          all: unset !important;
        }
        *:not(html, html > body, html > body > div#root, #print, #print *) {
          display: none !important;
        }
      `}
    </style>

    <div className="mx-auto my-0 w-[200mm] space-y-16 space-y-reverse bg-white" id="print">
      <section>
        <DetailStatistics />
      </section>

    </div>
  </>
);

export default PrintStatisticsPage;
