import { liegenschaftLabels } from "~/src/modules/labels";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function TransactionFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const liegenschaft = queryItems.split(",");

  /**
   *
   * @param li
   * @example
   */
  function handleClick(li) {
    const newLi = liegenschaft.filter((item) => item !== li).join(",");

    submit("liegenschaft", newLi);
  }

  return liegenschaft.map((li, index) => (
    <DefaultFilterTag handleClick={() => handleClick(li)} key={`li_tag_${index}`}>
      <span>Liegenschaftsankauf: {liegenschaftLabels[li]}</span>
    </DefaultFilterTag>
  ));
}
