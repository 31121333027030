import API from "~/src/modules/api.js";

const castArray = (value) => (Array.isArray(value) ? value : [value]);

const addAnalytics = async ({ analytics, url }) => {
  for (const { action, data = {} } of castArray(analytics)) {
    await API.put(
      "/analytics",
      {
        action,
        data,
        url
      }
    );
  }
};

const useAnalytics = () => {
  const url = globalThis.location.href;

  return {
    addAnalytics: (analytics) => addAnalytics({
      analytics,
      url
    })
  };
};

export default useAnalytics;
