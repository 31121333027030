import { useParams } from "react-router-dom";

import { identityFn, operator } from "~/src/lib/standard";

import BuyersAgeChart from "~/src/features/charts/buyers/age";
import BuyersGenderChart from "~/src/features/charts/buyers/gender";
import BuyersGraduatedChart from "~/src/features/charts/buyers/graduated";
import BuyersProvenanceChart from "~/src/features/charts/buyers/provenance";

import useProjectStatistics from "~/src/hooks/use-project-statistics";

import ChartError from "~/src/ui/charts/error";
import ChartContainer from "~/src/ui/containers/chart-container";

/**
 *
 * @example
 */
export default function DetailStatistics() {
  const { id: projectId } = useParams();

  const typesGender = ["units-buyers-gender", "units-buyers-partnership"];
  const structureGender = "composition";
  const {
    isError: isErrorGender,
    isLoading: isLoadingGender,
    statistics: buyersGender
  } = useProjectStatistics({
    projectId,
    structure: structureGender,
    types: typesGender
  });

  const typesAge = ["units-buyers-age"];
  const structureAge = "composition";
  const {
    isError: isErrorAge,
    isLoading: isLoadingAge,
    statistics: buyersAge
  } = useProjectStatistics({
    projectId,
    structure: structureAge,
    types: typesAge
  });

  const typesProvenance = ["units-buyers-type", "units-buyers-provenance"];
  const structureProvenance = "composition";
  const {
    isError: isErrorProvenance,
    isLoading: isLoadingProvenance,
    statistics: buyersProvenance
  } = useProjectStatistics({
    projectId,
    structure: structureProvenance,
    types: typesProvenance
  });

  const typesGraduated = ["units-buyers-graduated"];
  const structureGraduated = "composition";
  const {
    isError: isErrorGraduated,
    isLoading: isLoadingGraduated,
    statistics: buyersGraduated
  } = useProjectStatistics({
    projectId,
    structure: structureGraduated,
    types: typesGraduated
  });

  const isError = [
    isErrorAge,
    isErrorGender,
    isErrorGraduated,
    isErrorProvenance
  ].every(identityFn);

  const isEmpty = [
    buyersAge,
    buyersGender,
    buyersGraduated,
    buyersProvenance
  ].every(operator["!"]);

  const showNoResultsMessage = isError || isEmpty;

  if (showNoResultsMessage) {
    return (
      <ChartError />
    );
  }

  return (
    <ChartContainer cols={2}>
      <BuyersGenderChart isError={isErrorGender} isLoading={isLoadingGender} statistic={buyersGender} />

      <BuyersAgeChart isError={isErrorAge} isLoading={isLoadingAge} statistic={buyersAge} />

      <BuyersProvenanceChart isError={isErrorProvenance} isLoading={isLoadingProvenance} statistic={buyersProvenance} />

      <BuyersGraduatedChart isError={isErrorGraduated} isLoading={isLoadingGraduated} statistic={buyersGraduated} />
    </ChartContainer>
  );
}
