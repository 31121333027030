import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";

import { formatEuro, pluralize } from "~/src/modules/formatters";
import { priceLabels } from "~/src/modules/labels.js";

import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param {object} props - The root object
 * @param {boolean} props.active - The root object
 * @param {Function} props.hide - The root object
 * @param {object} props.stats - The root object
 * @example
 */
export default function StatsSlide({
  active, hide, stats
}) {
  if (!stats) {
    return null;
  }

  return (
    <Dialog
      className="fixed inset-0 z-[150] overflow-hidden"
      onClose={hide}
      open={active}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <DialogPanel
            transition
            className="pointer-events-auto relative w-screen max-w-lg transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
          >
            <TransitionChild>
              <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                <button
                  className="focus:ring-none rounded-md text-white hover:text-gray-200 focus:outline-none"
                  onClick={hide}
                >
                  <span className="sr-only">Close panel</span>

                  <CloseIcon aria-hidden="true" className="size-6" />
                </button>
              </div>
            </TransitionChild>

            <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
              <div className="relative flex-1">
                <div className="bg-gray-white p-4 sm:px-6">
                  <Dialog.Title className="text-lg text-gray-800 sm:text-2xl">Statistik</Dialog.Title>

                  {stats.project_count && stats.project_count > 0
                    ? (
                      <div className="mt-2 text-lg font-light text-gray-800">
                        <p className="leading-tight">
                          aus {stats.project_count?.toLocaleString("de")} {pluralize("Projekt", "Projekten", "Projekten", stats.gka_count)} {stats.unit_count ? "" : "berechnet"}
                        </p>

                        {stats.unit_count && (
                          <p className="leading-tight">
                            und {stats.unit_count?.toLocaleString("de")} {pluralize("Wohneinheit", "Wohneinheiten", "Wohneinheiten", stats.unit_count)} berechnet
                          </p>
                        )}
                      </div>
                    )
                    : (
                      <p className="mt-2 text-lg text-gray-800">Keine Projekte ausgewählt</p>
                    )}
                </div>

                <div className="mx-6 mt-4 space-y-8">

                  <div className="bg-secondary-light">
                    <div className="border-l-4 border-gray-500 p-8">
                      <p className="text-lg font-medium text-gray-500">
                        <span>{priceLabels.averageGka}</span>
                      </p>

                      {stats.gka_avg
                        ? (
                          <p className="mt-2 text-4xl font-medium text-gray-800">
                            {formatEuro(stats.gka_avg)}

                            {" "}

                            / m²
                            {" "}

                            <span className="text-base font-light">
                              ({stats.gka_count} {pluralize("Projekt", "Projekte", "Projekte", stats.gka_count)})
                            </span>
                          </p>
                        )
                        : (
                          <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                        )}
                    </div>
                  </div>

                  <div>
                    <p className="-ml-1 flex items-center">
                      <span className="inline-block size-3 bg-primary" />

                      <span className="ml-2">Angebotsdaten</span>
                    </p>

                    <div className="mt-1 divide-y-2 divide-gray-200 border-l-4 border-primary bg-secondary-light">

                      <div className="p-8">
                        <p className="text-lg font-medium text-gray-500">
                          <span>{priceLabels.averageOfferPriceNormal}</span>
                        </p>

                        {stats.avg_offer_price_eigennutzer
                          ? (
                            <p className="mt-2 text-4xl font-medium text-gray-800">
                              {formatEuro(stats.avg_offer_price_eigennutzer)}

                              {" "}

                              / m²
                              {" "}

                              <span className="text-base font-light">
                                ({stats.avg_offer_price_eigennutzer_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_offer_price_eigennutzer_count)})
                              </span>
                            </p>
                          )
                          : (
                            <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                          )}
                      </div>

                      <div className="p-8">
                        <p className="text-lg font-medium text-gray-500">
                          <span>{priceLabels.averageOfferPriceInvestorNet}</span>
                        </p>

                        {stats.avg_offer_price_investor
                          ? (
                            <p className="mt-2 text-4xl font-medium text-gray-800">
                              {formatEuro(stats.avg_offer_price_investor)}

                              {" "}

                              / m²
                              {" "}

                              <span className="text-base font-light">
                                ({stats.avg_offer_price_investor_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_offer_price_investor_count)})
                              </span>
                            </p>
                          )
                          : (
                            <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                          )}
                      </div>

                      <div className="p-8">
                        <p className="text-lg font-medium text-gray-500">
                          <span>{priceLabels.averageRentNet}</span>
                        </p>

                        {stats.rent_avg
                          ? (
                            <p className="mt-2 text-4xl font-medium text-gray-800">
                              {formatEuro(stats.rent_avg)}

                              {" "}

                              / m²
                              {" "}

                              <span className="text-base font-light">
                                ({stats.rent_count} {pluralize("Projekt", "Projekte", "Projekte", stats.rent_count)})
                              </span>
                            </p>
                          )
                          : (
                            <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                          )}
                      </div>

                    </div>
                  </div>

                  <div>
                    <p className="-ml-1 flex items-center">
                      <span className="inline-block size-3 bg-primary-green" />

                      <span className="ml-2">Verkaufsdaten</span>
                    </p>

                    <div className="mt-1 divide-y-2 divide-gray-200 border-l-4 border-primary-green bg-secondary-light">
                      <div className="p-8">
                        <p className="text-lg font-medium text-gray-500">
                          <span>{priceLabels.averageSalePriceNormal}</span>
                        </p>

                        {stats.avg_sale_price_eigennutzer
                          ? (
                            <p className="mt-2 text-4xl font-medium text-gray-800">
                              {formatEuro(stats.avg_sale_price_eigennutzer)}

                              {" "}

                              / m²
                              {" "}

                              <span className="text-base font-light">
                                ({stats.avg_sale_price_eigennutzer_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_sale_price_eigennutzer_count)})
                              </span>
                            </p>
                          )
                          : (
                            <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                          )}
                      </div>
                    </div>

                    <div className="mt-1 divide-y-2 divide-gray-200 border-l-4 border-primary-green bg-secondary-light">
                      <div className="p-8">
                        <p className="text-lg font-medium text-gray-500">
                          <span>{priceLabels.averageSalePriceInvestorNet}</span>
                        </p>

                        {stats.avg_sale_price_investor
                          ? (
                            <p className="mt-2 text-4xl font-medium text-gray-800">
                              {formatEuro(stats.avg_sale_price_investor)}

                              {" "}

                              / m²
                              {" "}

                              <span className="text-base font-light">
                                ({stats.avg_sale_price_investor_count} {pluralize("Projekt", "Projekte", "Projekte", stats.avg_sale_price_investor_count)})
                              </span>
                            </p>
                          )
                          : (
                            <p className="mt-1 text-xl font-medium text-gray-800">-</p>
                          )}
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
