import { rgbTools } from "~/src/modules/helpers/rgb-tools";

import { statsUnitsCols } from "./columns-spec";

const COLUMN_INTERVALS = {
  UNITS: ["B", "G"]
  // PROJECTS: ["I", "N"]
};
const FONT_BRIGHTNESS_THRESHOLD = 0x80;
const FONT_SIZE = 12;
const COL_WIDTH = 18;

/**
 *
 * @param workbook
 * @param projects - The root object
 * @param detailStats
 * @param projects.countWohnungenByYear
 * @example
 */
function statsUnitsSheetGenerator(workbook, { countWohnungenByYear }) {
  const statsUnitsSheet = workbook.addWorksheet("Wohneinheiten");

  const headerRow = statsUnitsSheet.addRow();

  for (const yearRecord of countWohnungenByYear) {
    const row = statsUnitsSheet.addRow();
    const rowHeader = row.getCell(1);

    for (const colKey in statsUnitsCols) {
      setCellValuesForRow(row, colKey, yearRecord, statsUnitsCols);
    }
  }

  setHeaderRowValuesAndStyles(headerRow, statsUnitsCols, statsUnitsSheet);

  applyStylesToRowHeaderColumn(statsUnitsSheet);
}

const setHeaderRowValuesAndStyles = (headerRow, dataCols, sheet) => {
  for (const colKey in dataCols) {
    const col = dataCols[colKey];
    const headerRowCell = headerRow.getCell(col.number);

    headerRowCell.value = col.label;
    applyStylesAndFormatToColumn(colKey, dataCols, sheet);

    if (col.color) {
      headerRowCell.fill = {
        fgColor: { argb: col.color },
        pattern: "solid",
        type: "pattern"
      };
    }
    headerRowCell.font = {
      bold: true,
      color: {
        argb: rgbTools.applyThreshold(col.color, FONT_BRIGHTNESS_THRESHOLD)
      },
      size: FONT_SIZE
    };
  }
};

const applyStylesToRowHeaderColumn = (statsUnitsSheet) => {
  const rowHeaderCol = statsUnitsSheet.getColumn(1);

  rowHeaderCol.font = {
    bold: true,
    size: FONT_SIZE
  };
  rowHeaderCol.alignment = {
    horizontal: "left",
    vertical: "middle"
  };
};

const applyStylesAndFormatToColumn = (colKey, columns, sheet) => {
  const col = columns[colKey];
  const sheetCol = sheet.getColumn(col.number);

  sheetCol.alignment = {
    horizontal: "left",
    vertical: "middle"
  };
  sheetCol.width = COL_WIDTH;
  if (col.formatType === "numFmt") {
    sheetCol.numFmt = col.formatString;
  }
};

const setCellValuesForRow = (row, colKey, yearRecord, columns) => {
  const col = columns[colKey];
  const cell = row.getCell(col.number);
  const rowNumber = cell._row._number;

  switch (colKey) {
    case "units_sum":
      setSumFormula("UNITS", rowNumber, cell);
      break;
      //    case "projects_sum":
      //      setSumFormula("PROJECTS", rowNumber, cell);
      //      break;
    default:
      cell.value = col.get(yearRecord) ?? 0;
  }
  cell.font = {
    size: FONT_SIZE
  };
};

const setSumFormula = (quantifier, rowNumber, cell) => {
  const startCell = `${COLUMN_INTERVALS[quantifier][0]}${rowNumber}`;
  const endCell = `${COLUMN_INTERVALS[quantifier][1]}${rowNumber}`;

  cell.value = {
    formula: `SUM(${startCell}:${endCell})`
  };
};

export default statsUnitsSheetGenerator;
