import { useEffect } from "react";

import API from "~/src/modules/api";

import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 * @example
 */
export default function LoginPage() {
  useEffect(() => {
    API
      .get("/auth/sso-url")
      .then((res) => {
        window.location.replace(res.data.data.authUrl);
      })
      .catch((error) => {
        const currentUrl = new URL(window.location.href);

        currentUrl.pathname = "/fehler";

        currentUrl.search = "";

        currentUrl.hash = "";

        window.location.replace(currentUrl);
      });
  }, []);

  return (
    <div className="flex h-screen items-center justify-center">
      <div>
        <div className="md:hidden">
          <LogoColor />
        </div>

        <div className="hidden md:block">
          <LogoColor className="h-[48px] w-[416px]" />
        </div>

        <div className="mt-6 flex justify-center">
          <svg className="size-11 animate-spin text-primary" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />

            <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
          </svg>
        </div>
      </div>
    </div>
  );
}
