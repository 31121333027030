import { Link } from "react-router-dom";

import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 * @example
 */
export default function NotFoundPage() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div>
        <Link to="/">
          <LogoColor className="h-[48px] w-[416px]" />
        </Link>

        <div className="mt-6 text-xl font-light leading-6">
          <p>Fehler 404 - Seite nicht gefunden</p>
        </div>
      </div>
    </div>
  );
}
