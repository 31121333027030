import {
  createContext, useCallback, useState
} from "react";

export const NotificationContext = createContext({
  changeLoadingText: () => { },
  error: null,
  hideError: () => { },
  hideLoading: () => { },
  hideSuccess: () => { },
  loading: null,
  loadingText: "Lädt...",
  showError: () => { },
  showLoading: () => { },
  showSuccess: () => { },
  success: null
});

/**
 *
 * @param root0 - The root object
 * @param root0.children - The root object
 * @example
 */
const NotificationProvider = ({ children }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);

  const contextValue = {
    changeLoadingText: useCallback((text) => setLoadingText(text), []),
    error,
    hideError: useCallback(() => setError(false), []),
    hideLoading: useCallback(() => setLoading(false), []),
    hideSuccess: useCallback(() => setSuccess(false), []),
    loading,
    loadingText,
    showError: useCallback(() => setError(true), []),
    showLoading: useCallback(() => setLoading(true), []),
    showSuccess: useCallback(() => setSuccess(true), []),
    success
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
