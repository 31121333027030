import API from "~/src/modules/api";

/**
 *
 * @param watchlistId
 * @param handleSuccess
 * @param handleError
 * @example
 */
export function handleDelete(watchlistId, handleSuccess, handleError) {
  API.delete(`/watchlists/${watchlistId}`).then((res) => {
    handleSuccess();
  }).catch((error) => {
    handleError(error.response.status);
  });
}
