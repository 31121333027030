/**
 *
 * @example
 */
const createPopupClass = () =>

  /**
   *
   */
  class extends window.google.maps.OverlayView {

    bounds;

    div;

    image;

    marker;

    /**
     * Constructor.
     *
     * @param {object} options - The root object
     * @param options.bounds - The root object
     * @param options.content - The root object
     * @param options.position - The root object
     * @param options.marker - The root object
     * @example
     */
    constructor({
      bounds,
      content,
      marker,
      position
    }) {
      super();
      this.bounds = bounds;
      this.content = content;
      this.position = position;
      this.marker = marker;
    }

    /**
     *
     * @override
     * @example
     */
    draw() {
      const { position } = this;
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        position
      );

      const { width } = this.content.getBoundingClientRect();

      if (this.div) {
        this.div.style.left = `${divPosition.x - (width / 2)}px`;
        this.div.style.bottom = `${-divPosition.y + 52}px`;
      }
    }

    /**
     *
     * @override
     * @example
     */
    onAdd() {
      this.div = document.createElement("div");
      this.div.style.borderStyle = "none";
      this.div.style.borderWidth = "0px";
      this.div.style.width = "auto";
      this.div.style.position = "absolute";

      this.div.append(this.content);

      const panes = this.getPanes();

      panes.floatPane.append(this.div);
    }

    /**
     *
     * @override
     * @example
     */
    onRemove() {
      if (this.div) {
        document.querySelector("#contentParent").append(this.content);
        this.div.remove();
        delete this.div;
      }
    }

  };

export default createPopupClass;
