/* eslint-disable no-underscore-dangle */
/* eslint-disable max-statements */

import {
  latLng
} from "leaflet";

/**
 *
 * @param options - The root object
 * @param options.map - The root object
 * @param options.container - The root object
 * @param options.tipContainer - The root object
 * @param options.pointCoordinates - The root object
 * @param options.anchor - The root object
 * @param options.event - The root object
 * @param options.event.zoom - The root object
 * @param options.event.center - The root object
 * @example
 */
const adjustPoint = ({
  anchor,
  container,
  event: {
    center,
    zoom
  } = {},
  map,
  pointCoordinates,
  tipContainer
}) => {
  const layerPoint = zoom && center
    ? map._latLngToNewLayerPoint(pointCoordinates, zoom, center)
    : map.latLngToLayerPoint(pointCoordinates);

  const containerPoint = map.latLngToContainerPoint(pointCoordinates);

  const point = layerPoint.add(anchor);

  let verticalDirection = "north";

  const markerHeight = 50;

  const popupHeight = container.clientHeight;
  const popupWidth = container.clientWidth;

  const mapBounds = map.getBounds();

  const mapPointSouthEastLatLng = latLng(mapBounds.getSouth(), mapBounds.getEast());
  const mapSouthEastPoint = map.latLngToContainerPoint(mapPointSouthEastLatLng);

  const mapBoundsWidth = mapSouthEastPoint.x;
  const mapBoundsHeight = mapSouthEastPoint.y;

  const tipHeight = tipContainer.clientHeight;

  const fitsNorth = containerPoint.y - markerHeight - tipHeight - popupHeight >= 0;

  const fitsSouth = mapBoundsHeight - (containerPoint.y + tipHeight + popupHeight) >= 0;

  if (!fitsNorth && fitsSouth) {
    verticalDirection = "south";
  }

  let horizontalDirection = "center";

  const fitsWest = containerPoint.x - (popupWidth / 2) >= 0;
  const fitsEast = containerPoint.x + (popupWidth / 2) <= mapBoundsWidth;

  if (!fitsWest) {
    horizontalDirection = "east";
  }
  else if (!fitsEast) {
    horizontalDirection = "west";
  }

  const direction = `${verticalDirection}-${horizontalDirection}`;

  tipContainer.classList.add(direction);

  const newPoint = {
    ...point
  };

  if (verticalDirection === "north") {
    newPoint.y -= tipHeight;
  }
  else if (verticalDirection === "south") {
    newPoint.y += markerHeight + tipHeight + popupHeight;
  }

  if (horizontalDirection === "west") {
    newPoint.x -= (popupWidth / 2) + 0;
  }
  else if (horizontalDirection === "east") {
    newPoint.x += (popupWidth / 2) - 0;
  }

  return newPoint;
};

export default adjustPoint;
