import { useParams } from "react-router-dom";

import RoomsOfferChart from "~/src/features/charts/rooms/offer";
import RoomsSaleChart from "~/src/features/charts/rooms/sale";

import useProjectStatistics from "~/src/hooks/use-project-statistics";

import ChartError from "~/src/ui/charts/error";
import ChartContainer from "~/src/ui/containers/chart-container";

/**
 *
 * @example
 */
export default function DetailStatistics() {
  const { id: projectId } = useParams();

  const typesOffer = ["units-main-category", "units-offer-rooms"];
  const structureOffer = "composition";
  const {
    isError: isErrorOffer,
    isLoading: isLoadingOffer,
    statistics: offerRooms
  } = useProjectStatistics({
    projectId,
    structure: structureOffer,
    types: typesOffer
  });

  const typesSale = ["units-main-category", "units-sale-rooms"];
  const structureSale = "composition";
  const {
    isError: isErrorSale,
    isLoading: isLoadingSale,
    statistics: saleRooms
  } = useProjectStatistics({
    projectId,
    structure: structureSale,
    types: typesSale
  });

  const isError = isErrorOffer && isErrorSale;
  const isEmpty = !saleRooms && !offerRooms;
  const showNoResultsMessage = isError || isEmpty;

  if (showNoResultsMessage) {
    return <ChartError />;
  }

  if (isErrorOffer && isErrorSale) {
    return (<ChartError />);
  }

  return (
    <ChartContainer cols={2} >
      <RoomsOfferChart isError={isErrorOffer} isLoading={isLoadingOffer} statistic={offerRooms} />

      <RoomsSaleChart isError={isErrorSale} isLoading={isLoadingSale} statistic={saleRooms} />
    </ChartContainer>
  );
}
