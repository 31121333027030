import { constructionTypeLabels } from "~/src/modules/labels";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function ConstructionTypeFilterTag({ queryItems, submit }) {
  if (!queryItems) {
    return null;
  }

  const construction_types = queryItems.split(",");

  /**
   *
   * @param ct
   * @example
   */
  function handleClick(ct) {
    const newCT = construction_types.filter((item) => item !== ct).join(",");

    submit("construction_type", newCT);
  }

  return construction_types.map((ct, index) => (
    <DefaultFilterTag handleClick={() => handleClick(ct)} key={`ct_tag_${index}`}>
      <span>Bautyp: {constructionTypeLabels[ct]}</span>
    </DefaultFilterTag>
  ));
}
