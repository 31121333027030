import { InformationCircleIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { ScanEye } from "lucide-react";
import { Tooltip } from "react-tooltip";

import useProjectQueryParameters from "../hooks/use-project-query-params.js";

const MapBBoxToggle = () => {
  const { query, setQuery } = useProjectQueryParameters();

  return (
    <div className="flex items-start">
      <button
        type="button"
        className={`${cn("flex items-center justify-center rounded-md transition-colors", {
          "bg-white text-gray-500 hover:text-gray-600": !query.activeBBox,
          "bg-white text-primary hover:text-primary-dark": query.activeBBox
        })}`}
        onClick={() => {
          setQuery({ activeBBox: !query.activeBBox });
        }}
      >
        <ScanEye
          className="size-8 focus:outline-none"
          data-tooltip-id="tooltip-map-bbox-toggle"
          data-tooltip-html={
            query.activeBBox
              ? "Betrachtungsansicht deaktivieren"
              : "Betrachtungsansicht aktivieren"
          }
        />

        <Tooltip
          className="z-50"
          delayShow={500}
          id="tooltip-map-bbox-toggle"
          place="top"
        />
      </button>

      <div>
        <InformationCircleIcon
          className="-mt-1 size-4 text-gray-500 hover:text-gray-600 focus:outline-none"
          data-tooltip-html="Die Betrachtungsansicht ermöglicht es, die Projektauswahl auf die aktuelle Kartenperspektive zu beschränken."
          data-tooltip-id="tooltip-map-bbox-toggle-info"
        />

        <Tooltip
          className="z-50"
          delayShow={500}
          id="tooltip-map-bbox-toggle-info"
          place="top"
        />
      </div>
    </div>

  );
};

export default MapBBoxToggle;
