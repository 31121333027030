import { isEqual } from "lodash-es";
import {
  useEffect,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import { useSWRConfig } from "swr";

import DetailStatsTools from "~/src/lib/detail-stats-tools";

import { parseUrlParams } from "~/src/features/filters/filter-url-parser/index.js";

import useMediaQuery from "~/src/hooks/use-media-query";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useProjectsDetailStats from "~/src/hooks/use-projects-detail-stats";
import useStore from "~/src/hooks/use-store";

import ChartContainer from "~/src/ui/containers/chart-container";

import { loadingIcon } from "../_common/export.js";

import ChartsToDisplay from "./charts-to-display.jsx";
import StatisticsHeader from "./statistics-header.jsx";

/**
 *
 * @example
 */
export default function DetailStatistics() {
  const { query, setQuery } = useProjectQueryParams();
  const {
    detailStats,
    isLoading,
    mutate: mutateProjectsDetailStats
  } = useProjectsDetailStats({ query });

  const location = useLocation();

  const isPrint = useMediaQuery("print");
  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const [chartToDisplay, setChartToDisplay] = useState("countWohnungen");

  const {
    cache: globalCache,
    mutate: mutateGlobal
  } = useSWRConfig();

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutateProjectsDetailStats();
        setSelectionMutate(false);

        if (selectionLoading) {
          setSelectionLoading(false);
        }
      };

      mutating();
    }
  }, [selectionMutate]);

  useEffect(() => {
    const { search } = location;

    const urlSearch = new URLSearchParams(search);

    const parsedSearch = Object.fromEntries(
      [...urlSearch.entries()]
        .map(parseUrlParams)
        .filter(([key, value]) => {
          if (query?.activeBBox) {
            return true;
          }

          return (!([
            "bBox",
            "centerLat",
            "centerLng",
            "mapZoom",
            "page"
          ].includes(key)));
        })
    );

    mutateGlobal(
      (swrCacheKey) => {
        if (swrCacheKey.startsWith("/projects/detail-stats")) {
          const cacheKeyValue = globalCache.get(swrCacheKey);

          const cleanedSwrCacheKey = swrCacheKey.replace("/projects/detail-stats?", "");

          const urlParsedSwrCacheKey = new URLSearchParams(cleanedSwrCacheKey);

          const parsedSwrCacheKey = Object.fromEntries(
            [...urlParsedSwrCacheKey.entries()].map(parseUrlParams)
          );

          const relevantSwrCacheKeys = Object.fromEntries(Object.entries(parsedSwrCacheKey)
            .filter(([filterKey, _filterValue]) => (Object.keys(parsedSearch).includes(filterKey))));

          if (cacheKeyValue?.data && !cacheKeyValue?.isLoading) {
            mutateProjectsDetailStats();
          }

          return (
            !isEqual(relevantSwrCacheKeys, parsedSearch) &&
            (Object.keys(cacheKeyValue).includes("data") ||
              !cacheKeyValue?.isLoading)
          );
        }

        return false;
      },

      undefined,
      {
        revalidate: false
      }
    );
  }, [location]);

  if (isLoading) {
    return (
      <div className="flex size-full flex-col gap-4">

        <StatisticsHeader chartToDisplay={chartToDisplay} setChartToDisplay={setChartToDisplay} />

        <ChartContainer className="flex flex-col gap-4 overflow-y-auto rounded">
          {Array.from({ length: (chartToDisplay === "countWohnungen") ? 1 : 2 }).map((_, index) => (
            <div className="h-[450px] w-full bg-white py-3" key={index}>
              {loadingIcon}
            </div>
          ))}

          <div className="h-[30px] w-full opacity-0" />
        </ChartContainer>

      </div>
    );
  }

  const hasData = DetailStatsTools.hasData(detailStats);

  const countWohnungenByYear = detailStats?.countWohnungenByYear
    ? detailStats?.countWohnungenByYear
    : [];

  const marketTrendOffer = detailStats?.marketTrend
    ? Object.fromEntries(Object.entries(detailStats.marketTrend).filter(([key]) => !key.includes("Sale")))
    : [];

  const marketTrendSale = detailStats?.marketTrend
    ? Object.fromEntries(Object.entries(detailStats.marketTrend).filter(([key]) => key.includes("Sale")))
    : [];

  const marketTrendAreaGroupsOffer = detailStats?.marketTrendAreaGroups
    ? Object.fromEntries(Object.entries(detailStats.marketTrendAreaGroups).filter(([key]) => !key.includes("Sale")))
    : {};

  const marketTrendAreaGroupsSale = detailStats?.marketTrendAreaGroups
    ? Object.fromEntries(Object.entries(detailStats.marketTrendAreaGroups).filter(([key]) => key.includes("Sale")))
    : {};

  const squareMeterPriceByAreaOffer = detailStats?.squareMeterPriceByArea
    ? Object.fromEntries(Object.entries(detailStats.squareMeterPriceByArea).filter(([key]) => !key.includes("Sale")))
    : [];

  const squareMeterPriceByAreaSale = detailStats?.squareMeterPriceByArea
    ? Object.fromEntries(Object.entries(detailStats.squareMeterPriceByArea).filter(([key]) => key.includes("Sale")))
    : [];

  return (
    <div className="flex h-full flex-col gap-4">
      {isPrint && hasData && <h2 className="text-lg font-medium">Statistiken:</h2>}

      {!isPrint && <StatisticsHeader chartToDisplay={chartToDisplay} setChartToDisplay={setChartToDisplay} />}

      <ChartContainer className="rounded lg:overflow-y-auto print:block print:h-full print:space-y-16 print:divide-neutral-500 print:overflow-y-hidden">
        <ChartsToDisplay
          chartToDisplay={chartToDisplay}
          countWohnungenByYear={countWohnungenByYear}
          isPrint={isPrint}
          marketTrendAreaGroupsOffer={marketTrendAreaGroupsOffer}
          marketTrendAreaGroupsSale={marketTrendAreaGroupsSale}
          marketTrendOffer={marketTrendOffer}
          marketTrendSale={marketTrendSale}
          squareMeterPriceByAreaOffer={squareMeterPriceByAreaOffer}
          squareMeterPriceByAreaSale={squareMeterPriceByAreaSale}
        />

        <div className="h-[30px] w-full opacity-0" />
      </ChartContainer>
    </div>
  );
}
