import LogoutButton from "~/src/ui/buttons/logout-button";
import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 * @example
 */
export default function NotAuthorizedPage() {
  return (
    <div className="relative flex h-screen items-center justify-center">
      <div className="absolute right-8 top-8">
        <LogoutButton />
      </div>

      <div>
        <div className="md:hidden">
          <LogoColor />
        </div>

        <div className="hidden md:block">
          <LogoColor className="h-[48px] w-[416px]" />
        </div>

        <div className="mt-6 text-lg font-light leading-6">
          <p>Dieses Nutzerkonto ist nicht freigeschaltet.</p>

          <p>Bitte kontaktieren Sie unseren Support.</p>
        </div>
      </div>
    </div>
  );
}
