/* eslint-disable func-style */
/* eslint-disable no-magic-numbers */
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import Select from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

import API from "~/src/modules/api.js";

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <UserGroupIcon className="size-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const loadOptions = async (inputValue, callback) => {
  try {
    const res = await API.post("/search/company-suggestions", {
      company: inputValue
    });

    const options = res.data.payload.companies.map((company) => ({
      body: {
        company
      },
      label: `${company.name}`,
      value: company.slug
    }));

    callback(options);
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
  }
};

const loadOptionsDebounced = debounce(500, loadOptions);
const loadOptionsThrottled = throttle(500, loadOptions);

const handleLoadOptions = (inputValue) => new Promise((resolve, reject) => {
  if (inputValue.length < 4) {
    loadOptionsThrottled(inputValue.trim(), (options) => {
      resolve(options);
    });
  }
  else {
    loadOptionsDebounced(inputValue.trim(), (options) => {
      resolve(options);
    });
  }
});

const noOptionsMessage = (data) => {
  if (data.inputValue.length > 0) {
    return "Kein Unternehmen gefunden...";
  }

  return "Unternehmen suchen...";
};

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: 33,
    "&:hover": {
      borderColor: state.isFocused ? "#525252" : "#a3a3a3"
    },
    background: "#f4f4f5",
    borderColor: state.isFocused ? "#a3a3a3" : "#e5e7eb",
    borderRadius: "0.25rem",
    borderWidth: state.isFocused ? "1px" : "1px",
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    height: 33
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af"
  }),

  singleValue: (base) => ({
    ...base,
    background: "#822c42",
    borderRadius: "5px",
    color: "white",
    padding: "0 6px"
  })
};

/**
 *
 * @param root0 - The root object
 * @param root0.company - The root object
 * @param root0.handleUpdate - The root object
 * @example
 */
export default function CompanySelect({ company, handleUpdate }) {
  const handleSelect = (selected) => {
    handleUpdate("company", selected);
  };

  return (
    <Select
      isClearable
      blurInputOnSelect={true}
      cacheOptions={false}
      className="react-select"
      classNames={{ placeholder: () => "text-sm" }}
      instanceId="company-select"
      loadOptions={handleLoadOptions}
      name="company"
      noOptionsMessage={noOptionsMessage}
      onChange={handleSelect}
      placeholder="Unternehmen"
      styles={customStyles}
      value={company || ""}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      inputProps={{
        autoComplete: "none",
        autoCorrect: "off",
        spellCheck: "off"
      }}
    />
  );
}
