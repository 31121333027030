import { cartesianProduct } from "~/src/lib/standard";

import getKeyComponents from "./define-labels-given-key-and-label-components/get-key-components";
import getLabelComponents from "./define-labels-given-key-and-label-components/get-label-components";

/**
 *
 * @param options - The root object
 * @param options.components - The root object
 * @param options.keyComponentPositions - The root object
 * @param options.labelComponentPositions - The root object
 * @param options.labelFormatter - The root object
 * @example
 */
const defineLabelsGivenKeyAndLabelComponents = ({
  components,
  keyComponentPositions,
  labelComponentPositions,
  labelFormatter: _labelFormatter
}) => {
  const labelFormatter = _labelFormatter ??
    (
      (currentComponents) => currentComponents.join(" ")
    );

  const componentsByKey = cartesianProduct(...components);

  const concattedKeysAndLabels = componentsByKey
    .map((keyAndLabelComponents) => {
      const key = getKeyComponents(keyAndLabelComponents, keyComponentPositions).join("_").replace(/_$/u, "");
      const labelComponents = getLabelComponents(keyAndLabelComponents, labelComponentPositions);
      const label = labelFormatter(labelComponents);

      return [key, label];
    });

  return Object.fromEntries(concattedKeysAndLabels);
};

export default defineLabelsGivenKeyAndLabelComponents;
