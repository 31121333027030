import cn from "classnames";

const ChartContainer = ({
  children, className = "", cols = 1
}) => (
  <div
    className={cn(
      `grid grid-cols-1 gap-x-4 gap-y-4 ${className}`,
      {
        "2xl:grid-cols-1": cols === 1,
        "2xl:grid-cols-2": cols === 2
      }
    )}
  >
    {children}
  </div>
);

export default ChartContainer;
