const priceTerms = /** @type {const} */ ({
  average: "ø",
  gka: "Grundkostenanteil",
  gross: "Brutto",
  investment: "Investment",
  net: "Netto",
  normal: "Eigennutzung",
  offer: "Angebot",
  offerPrice: "Angebotspreis",
  perSquareMeter: "/m²",
  price: "Preis",
  rent: "Miete",
  sale: "Verkauf",
  salePrice: "Verkaufspreis",
  total: "Gesamtbelastung"
});

export default priceTerms;
