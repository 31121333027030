import { getMarkerDesign } from "./get-icon/_exports.js";

/**
 *
 * @param options0 - The root object.
 * @param options0.isCluster - The root object.
 * @param options0.opacity - The root object.
 * @param options0.point - The root object.
 * @example
 */
const getIcon = ({
  isCluster, opacity, point
}) => {
  if (isCluster) {
    const brightness = 1;

    const clusterSvgString = "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 100 100\" width=\"40\" height=\"40\"><circle cx=\"50\" cy=\"50\" r=\"50\" fill=\"#822c42\" /></svg>";

    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
        clusterSvgString
      )}`
    };
  }

  const {
    innerColor, innerIcon, outerColor
  } = getMarkerDesign({
    currentConstructionPhase: point.properties.currentConstructionPhase,
    currentMarketingPhase: point.properties.currentMarketingPhase
  });

  const opacityValue = (opacity) ? 0.65 : 1;

  const markerSvgString = `<svg xmlns="http://www.w3.org/2000/svg\" opacity=\"${opacityValue}\" viewBox=\"0 0 100 100\" width=\"50\" height=\"50\"><path fill=\"${outerColor}\" d=\"M50,0C34.1,0,21.2,12.9,21.2,28.8C21.2,54.9,50,100,50,100s28.8-46.8,28.8-71.2C78.8,12.9,65.9,0,50,0z M50,54.5c-13.8,0-25-11.1-25-25c0-13.8,11.1-25,25-25s25,11.1,25,25S63.8,54.5,50,54.5z\"/><path fill=\"${innerColor}\" d=\"M50,54.5c-13.8,0-25-11.1-25-25c0-13.8,11.1-25,25-25s25,11.1,25,25S63.8,54.5,50,54.5z\"/>${innerIcon}</svg>`;

  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
      markerSvgString
    )}`
  };
};

export default getIcon;
