import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function ConstructionTypeSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const hasNeubau = items.includes("neubau");
  const hasDGAusbau = items.includes("dg_ausbau");
  const hasSanierung = items.includes("sanierung");
  const hasUnsaniert = items.includes("unsaniert");

  const hasTemporaryNeubau = temporaryItems.includes("neubau");
  const hasTemporaryDGAusbau = temporaryItems.includes("dg_ausbau");
  const hasTemporarySanierung = temporaryItems.includes("sanierung");
  const hasTemporaryUnsaniert = temporaryItems.includes("unsaniert");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("construction_type", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Bautyp
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasNeubau, hasTemporaryNeubau)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "neubau")} type="checkbox" />

            <span className="ml-3">Neubau</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasSanierung, hasTemporarySanierung)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "sanierung")} type="checkbox" />

            <span className="ml-3">Sanierung Altbestand</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasDGAusbau, hasTemporaryDGAusbau)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "dg_ausbau")} type="checkbox" />

            <span className="ml-3">DG Ausbau</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasUnsaniert, hasTemporaryUnsaniert)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "unsaniert")} type="checkbox" />

            <span className="ml-3">unsaniert</span>
          </label>
        </div>
      </div>
    </div>
  );
}
