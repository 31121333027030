import { sortProjectLabels } from "~/src/modules/labels";

import useProjectList from "~/src/hooks/use-project-list";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";

import ProjectListItemPrint from "../project-list-item-print";

/**
 *
 * @example
 */

const ProjectsListPrintTile = () => {
  const { query } = useProjectQueryParams();

  const {
    projects = []
  } = useProjectList({
    query: {
      ...query,
      forExport: true
    }
  });
  const sortLabel = sortProjectLabels.get(query.sort) ?? "Name";

  const directionLabel = query.direction === "desc" ? "absteigend" : "aufsteigend";

  if (projects.length === 0) {
    return null;
  }

  return (
    <>
      <h2 className="mb-4 text-lg font-medium">
        <span>
          Ihre {projects.length} ausgewählten Projekte, {directionLabel} sortiert nach
        </span>

        <span className="text-primary"> {sortLabel}</span>

        <span>:</span>
      </h2>

      <ul className="w-full">
        {
          projects.map((project) => (
            <ProjectListItemPrint key={project.slug} project={project} />
          ))
        }
      </ul>
    </>
  );
};

export default ProjectsListPrintTile;
