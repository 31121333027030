import cn from "classnames";
import { useEffect, useState } from "react";

import { formatAreaInt, formatMonth } from "~/src/modules/formatters";

import ShareDealPropertySlide from "~/src/features/share-deal-property-slide";

import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0 - The root object
 * @param root0.isLoading - The root object
 * @param root0.properties - The root object
 * @param root0.loadingItems - The root object
 * @param root0.query - The root object
 * @example
 */
export default function ShareDealsPropertyList({
  isLoading, loadingItems = 10, properties = [], query
}) {
  const { trackPageView } = useTracking();
  const [showShareDealDetails, setShowShareDealDetails] = useState(false);
  const [activeProperty, setActiveProperty] = useState(false);
  const [activeColumn, setActiveColumn] = useState("date");

  /**
   *
   * @param shareDeal
   * @example
   */
  function handleShareDealClick(shareDeal) {
    setActiveProperty(shareDeal);
    setShowShareDealDetails(true);
    trackPageView(shareDeal.doc_id, "ShareDeal Liegenschaft");
  }

  /**
   *
   * @example
   */
  function hideShareDealDetails() {
    setShowShareDealDetails(false);
  }

  useEffect(() => {
    if (query.sortBy) {
      setActiveColumn(query.sortBy);
    }
  }, [query.sortBy]);

  return (
    <div>
      <ShareDealPropertySlide active={showShareDealDetails} hide={hideShareDealDetails} property={activeProperty} />

      <div className="mb-4 ml-4 flex flex-col sm:ml-0">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 shadow-sm sm:rounded">

              <div className="min-w-full divide-y divide-gray-200">

                <div className="flex grid-cols-12 items-center bg-gray-100 lg:grid">
                  <div className="col-span-1 w-20 px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Datum
                  </div>

                  <div className="col-span-1 w-20 whitespace-nowrap px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    KG
                  </div>

                  <div className="col-span-1 w-20 whitespace-nowrap px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    EZ
                  </div>

                  <div className="col-span-3 w-64 px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Adresse (Liegenschaft)
                  </div>

                  <div className="col-span-3 w-64 px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Eigentümer
                  </div>

                  <div className="col-span-1 w-28 whitespace-nowrap px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Fläche EZ
                  </div>

                  <div className="col-span-2 w-64 px-4 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Änderung
                  </div>
                </div>

                <div className="divide-y divide-gray-200 bg-white">
                  {isLoading &&
                    [...new Array(loadingItems)].map((e, index) => (
                      <div className="flex h-20 cursor-pointer grid-cols-12 items-center hover:bg-gray-50 lg:grid" key={`share_deal_properties_skeleton_${index}`}>
                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="flex h-12 w-28 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-2 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>
                      </div>
                    ))}

                  {!isLoading && properties?.length === 0 && (
                    <div className="bg-white">
                      <p className="whitespace-nowrap p-4 text-center font-light text-gray-900">keine Liegenschaften für die ausgewählten Kriterien gefunden</p>
                    </div>
                  )}

                  {properties?.map((property) => (
                    <div className="flex cursor-pointer grid-cols-12 items-center text-sm hover:bg-gray-50 lg:grid" key={property.id} onClick={() => handleShareDealClick(property)}>

                      <p
                        className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap text-sm", {
                          "text-gray-900 font-medium": activeColumn !== "date",
                          "text-primary font-bold": activeColumn === "date"
                        })}
                      >
                        {formatMonth(property.change_date)}
                      </p>

                      <p
                        className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap", {
                          "text-gray-600": activeColumn !== "kg",
                          "text-primary font-bold": activeColumn === "kg"
                        })}
                      >
                        {property.kgnr}
                      </p>

                      <p
                        className={cn("w-20 lg:w-auto px-4 py-4 whitespace-nowrap ", {
                          "text-gray-600": activeColumn !== "ez",
                          "text-primary font-bold": activeColumn === "ez"
                        })}
                      >
                        {property.ez}
                      </p>

                      <p className="col-span-3 w-64 truncate whitespace-nowrap p-4 lg:w-auto">
                        <span
                          className={cn({
                            "text-gray-600": activeColumn !== "street",
                            "text-primary font-bold": activeColumn === "street"
                          })}
                        >
                          {property.property_street}

                          {" "}

                          {property.property_house_number}
                        </span>

                        <br />

                        <span className="text-gray-600">{property.property_state}, </span>

                        <span
                          className={cn({
                            "text-gray-600": activeColumn !== "zipcode",
                            "text-primary font-bold": activeColumn === "zipcode"
                          })}
                        >
                          {property.property_zipcode}

                          {" "}

                          {property.property_city?.replace(",", ", ")}
                        </span>
                      </p>

                      <p className="col-span-3 w-64 truncate  whitespace-nowrap p-4 lg:w-auto">
                        <span
                          className={cn({
                            "text-gray-600": activeColumn !== "company",
                            "text-primary font-bold": activeColumn === "company"
                          })}
                        >
                          {property.new_owner_name}
                        </span>

                        <br />

                        <span
                          className={cn({
                            "text-gray-600": activeColumn !== "fbnr",
                            "text-primary font-bold": activeColumn === "fbnr"
                          })}
                        >
                          {property.new_owner_fbnr}
                        </span>
                      </p>

                      <p
                        className={cn("w-28 lg:w-auto px-4 py-4 whitespace-nowrap", {
                          "text-gray-600": activeColumn !== "area",
                          "text-primary font-bold": activeColumn === "area"
                        })}
                      >
                        {formatAreaInt(property.ez_area)}
                      </p>

                      <p className="col-span-2 w-64 p-4 text-gray-600 lg:w-auto">{property.category?.name}</p>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
