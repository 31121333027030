/**
 *
 */
class rgbTools {

  /**
   *
   * @param root0 - The root object
   * @param root0."0" - The root object
   * @param root0."1" - The root object
   * @param root0."2" - The root object
   * @param threshold
   * @example
   */
  static #isAboveIntensityThreshold([
    red,
    green,
    blue
  ], threshold) {
    return this.#toMono(red, green, blue) > threshold;
  }

  /**
   *
   * @param red
   * @param green
   * @param blue
   * @example
   */
  static #toMono(red, green, blue) {
    return (0.212_5 * red) + (0.715_4 * green) + (0.072_1 * blue);
  }

  // https://stackoverflow.com/a/14331

  /**
   *
   * @param rgbHex
   * @param argbHex
   * @param threshold
   * @example
   */
  static applyThreshold(argbHex, threshold) {
    const BLACK = "FF000000";
    const WHITE = "FFFFFFFF";

    if (!argbHex) {
      return BLACK;
    }
    const [
      red,
      green,
      blue
    ] = argbHex
      .match(/(.{2})(.{2})(.{2})$/).splice(1)
      .map((_) => Number.parseInt(_, 16));

    if (this.#isAboveIntensityThreshold([
      red,
      green,
      blue
    ], threshold)) {
      return BLACK;
    }

    return WHITE;
  }

}
export { rgbTools };
