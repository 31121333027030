import { getInnerIconByPhase, getMarkerOuterColor } from "./get-marker-design/_exports.js";

/**
 *
 * @param options0 - The root object.
 * @param options0.currentConstructionPhase - The root object.
 * @param options0.currentMarketingPhase - The root object.
 * @example
 */
const getMarkerDesign = ({ currentConstructionPhase, currentMarketingPhase }) => {
  const outerColor = getMarkerOuterColor({ currentMarketingPhase });
  const innerIcon = getInnerIconByPhase({
    currentConstructionPhase,
    outerColor
  });

  return {
    innerColor: "#FFFFFF",
    innerIcon,
    outerColor
  };
};

export default getMarkerDesign;
