/**
 *
 * @example
 */
export default function BackIcon() {
  return (
    <svg className="mr-2 size-5 shrink-0 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
      <path clipRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" fillRule="evenodd" />
    </svg>
  );
}
