/**
 *
 * @example
 */
export default function UnitsSkeleton() {
  return (
    <div className="space-y-12">

      {Array.from({ length: 12 }).map((e, index) => (
        <div className="animate-pulse rounded-sm border border-gray-200 bg-white p-4 sm:px-4" key={`block_0_${index}`}>
          <div className="grid grid-cols-1 space-x-4 md:grid-cols-8">
            <div className="col-span-1 h-4 rounded bg-gray-300" />
          </div>

          <div className="mt-3 hidden grid-cols-1 space-x-4 md:grid md:grid-cols-8">
            <div className="col-span-3 h-4 rounded bg-gray-300" />

            <div className="col-span-3 h-4 rounded" />

            <div className="col-span-2 h-4 rounded bg-gray-300" />
          </div>

          <div className="mt-5 hidden grid-cols-1 space-x-4 md:grid md:grid-cols-12">
            <div className="col-span-1 h-16 rounded bg-gray-300 md:col-span-2" />

            <div className="h-26 col-span-1 rounded bg-gray-300 md:col-span-2" />

            <div className="col-span-1 h-16 rounded bg-gray-300 md:col-span-2" />

            <div className="col-span-6 hidden grid-cols-6 space-x-4 md:grid">
              <div className="col-span-1 ml-4 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />

              <div className="col-span-1 h-4 rounded bg-gray-300" />
            </div>
          </div>

          <div className="mt-3 grid grid-cols-8 space-x-4">
            <div className="col-span-2 h-4 rounded bg-gray-300" />
          </div>

          <div className="mt-3 grid grid-cols-8 space-x-4">
            <div className="col-span-2 h-4 rounded bg-gray-300" />
          </div>
        </div>
      ))}

    </div>
  );
}
