import cn from "classnames";

import { formatCurrencyInt } from "~/src/modules/formatters";
import { hasDiffValues } from "~/src/modules/share-deal-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.owner - The root object
 * @param root0.type - The root object
 * @param root0.hasCurrentOwner - The root object
 * @param root0.hasLegacyOwner - The root object
 * @param root0.diff - The root object
 * @example
 */
export default function ShareDealOwnerCardItem({
  diff, hasCurrentOwner, hasLegacyOwner, owner, type
}) {
  const hasDiff = hasDiffValues(diff);

  const unchanged = hasCurrentOwner && hasLegacyOwner && !hasDiff;

  if (unchanged && type === "legacy") {
    return null;
  }

  const ownerShares = owner[`owner_shares_${type}`]?.toString();

  return (
    <div className="pt-4">

      <div className="grid grid-cols-8">
        <div className="col-span-2">
          {type === "current" && unchanged &&
            <p className="inline-block rounded bg-gray-300 px-2 font-semibold uppercase text-gray-800 ">unverändert</p>}

          {type === "current" && !unchanged && (
            <p
              className={cn("inline-block rounded uppercase font-semibold px-2", {
                "bg-amber-200 text-amber-800": !(hasCurrentOwner && !hasLegacyOwner),
                "bg-emerald-200 text-emerald-800": hasCurrentOwner && !hasLegacyOwner
              })}
            >
              {hasCurrentOwner && !hasLegacyOwner ? "neu" : "änderung"}
            </p>
          )}

          {type === "legacy" && !unchanged && (
            <p
              className={cn("inline-block rounded uppercase font-semibold px-2", {
                "bg-red-200 text-red-800": !hasCurrentOwner && hasLegacyOwner,
                "text-gray-700": !(!hasCurrentOwner && hasLegacyOwner)
              })}
            >
              {!hasCurrentOwner && hasLegacyOwner ? "gelöscht" : "vorher"}
            </p>
          )}
        </div>

        <div className="col-span-5">
          <p className="truncate text-gray-800">{owner[`owner_function_${type}`]}</p>
        </div>

        <div className="text-right">
          <span className="rounded bg-gray-300 px-2 py-0.5 text-center font-semibold text-gray-700">{owner[`owner_number_${type}`] ? owner[`owner_number_${type}`] : "-"}</span>
        </div>
      </div>

      <div className="mt-3 grid grid-cols-8">
        <div className="col-span-2 pl-2">
          <p>
            Firmenanteil:
            {" "}

            <span
              className={cn("", {
                "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_shares,
                "text-emerald-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_shares && type === "current",
                "text-red-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_shares && type === "legacy"
              })}
            >
              {ownerShares
                ? `${(Number.parseFloat(ownerShares.replace(",", ".")) * 100).toLocaleString("de", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} %`
                : ""}
            </span>
          </p>

          <p
            className={cn("", {
              "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_capital,
              "text-emerald-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_capital && type === "current",
              "text-red-600": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_capital && type === "legacy"
            })}
          >
            {owner[`owner_capital_${type}`]
              ? formatCurrencyInt({
                currency: owner[`owner_currency_${type}`],
                val: owner[`owner_capital_${type}`]
              })
              : "nicht bekannt"}
          </p>
        </div>

        <div className="col-span-5">
          <p
            className={cn("", {
              "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_name
            })}
          >
            {owner[`owner_name_${type}`]}
          </p>

          <p
            className={cn("", {
              "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_fbnr
            })}
          >
            {owner[`owner_fbnr_${type}`]}
          </p>

          <p
            className={cn("", {
              "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && (diff?.owner_title || diff?.owner_first_name || diff?.owner_last_name)
            })}
          >
            {owner[`owner_title_${type}`] && `${owner[`owner_title_${type}`]} `}

            {owner[`owner_first_name_${type}`] && `${owner[`owner_first_name_${type}`]} `}

            {owner[`owner_last_name_${type}`]}
          </p>

          <p
            className={cn("", {
              "font-bold": !(hasCurrentOwner && !hasLegacyOwner) && diff?.owner_birthday
            })}
          >
            {owner[`owner_birthday_${type}`]}
          </p>
        </div>
      </div>

    </div>
  );
}
