import { create } from "zustand";

import {
  setProjectPurchasePriceChartHasError,
  setProjectRentPriceChartHasError
} from "./helpers/set-price-chart-has-error.js";

const centerOfAustria = {
  lat: 47.650_731_457_039_42,
  lng: 13.245_450_349_650_449
};
const defaultMapViewState = {
  zoom: 6,
  ...centerOfAustria
};
const defaultSimpleMapViewState = {
  zoom: 5,
  ...centerOfAustria
};

const defaultClusterState = "6.668701171875001%2C44.855868807357275%2C19.830322265625004%2C50.31039245071915_7";

const useStore = create((set) => ({
  // logout modal
  showLogoutModal: false,
  toggleLogoutModal: () => set((state) => ({ showLogoutModal: !state.showLogoutModal })),

  // projects map view state
  mapViewState: defaultMapViewState,

  clusterState: defaultClusterState,

  setClusterState: (value) => set({ clusterState: value }),

  autoZoomed: false,
  setAutoZoomed: (value) => set({ autoZoomed: value }),

  prevQuery: null,
  setPrevQuery: (value) => set({ prevQuery: value }),

  // projects map modal
  showProjectsFilters: false,

  resetMapViewState: () => set({ mapViewState: defaultMapViewState }),
  setMapViewState: (value) => set({ mapViewState: value }),
  toggleProjectsFiltersTile: () => set(
    (state) => (
      { showProjectsFilters: !state.showProjectsFilters })
  ),

  setSimpleMapViewState: (value) => set({ simpleMapViewState: value }),

  resetSimpleMapViewState: () => set({ simpleMapViewState: defaultSimpleMapViewState }),

  setSimpleMapViewStateFresh: (value) => set({ simpleMapViewStateFresh: value }),
  // simple map view state
  simpleMapViewState: defaultSimpleMapViewState,
  simpleMapViewStateFresh: false,

  readPolygonsFromQuery: false,
  setReadPolygonsFromQuery: (value) => set({ readPolygonsFromQuery: value }),

  // area modal
  showAreaModal: false,
  toggleAreaModal: () => set((state) => ({ showAreaModal: !state.showAreaModal })),

  // project suggestion modal
  showProjectSuggestionModal: false,
  toggleProjectSuggestionModal: () => set((state) => ({ showProjectSuggestionModal: !state.showProjectSuggestionModal })),

  // project suggestion modal
  showFilterBehaviorModal: false,
  toggleFilterBehaviorModal: () => set((state) => ({ showFilterBehaviorModal: !state.showFilterBehaviorModal })),

  showPdfExportProjectModal: false,
  togglePdfExportProjectModal: () => set((state) => ({ showPdfExportProjectModal: !state.showPdfExportProjectModal })),

  pdfExportProjectExportUnits: true,
  setPdfExportProjectExportUnits: (value) => set({ pdfExportProjectExportUnits: value }),

  // checked projects for stats
  projectsSelectionBody: "",
  setProjectsSelectionBody: (value) => set({ projectsSelectionBody: value }),

  // current selection state for projects
  currentSelectionStatus: "all",
  setCurrentSelectionStatus: (value) => set({ currentSelectionStatus: value }),

  selectionLoading: false,
  setSelectionLoading: (value) => set({ selectionLoading: value }),

  selectionMutate: false,
  setSelectionMutate: (value) => set({ selectionMutate: value }),

  selectionResetByFilter: false,
  setSelectionResetByFilter: (value) => set({ selectionResetByFilter: value }),

  mainSelectionType: "addAll",
  setMainSelectionType: (value) => set({ mainSelectionType: value }),

  queryString: "",
  setQueryString: (value) => set({ queryString: value }),

  change: "",
  setChange: (value) => set({ change: value }),

  delayedLayout: "",
  setDelayedLayout: (value) => set({ delayedLayout: value }),

  filterOpen: false,
  setFilterOpen: (value) => set({ filterOpen: value }),

  onlyMarkedProjects: true,
  setOnlyMarkedProjects: (value) => set({ onlyMarkedProjects: value }),

  projectListEmpty: false,
  setProjectListEmpty: (value) => set({ projectListEmpty: value }),

  exporting: false,
  setExporting: (value) => set({ exporting: value }),

  projectRentPriceChartHasError: false,
  setProjectRentPriceChartHasError: setProjectRentPriceChartHasError(set),

  projectPurchasePriceChartHasError: false,
  setProjectPurchasePriceChartHasError: setProjectPurchasePriceChartHasError(set),

  bothProjectPriceChartsHaveErrors: false,

  setStatsBoxCollapsed: (value) => set({ statsBoxCollapsed: value }),
  statsBoxCollapsed: false,

  filterTileCollapsed: false,
  setFilterTileCollapsed: (value) => set({ filterTileCollapsed: value }),

  miniListTileCollapsed: false,
  setMiniListTileCollapsed: (value) => set({ miniListTileCollapsed: value }),

  setUserClickedFilterTileCollapse: (value) => set({ userClickedFilterTileCollapse: value }),
  userClickedFilterTileCollapse: false,

  setUserClickedMiniListTileCollapse: (value) => set({ userClickedFilterTileCollapse: value }),
  userClickedMiniListTileCollapse: false,

  setUserClickedStatsBoxCollapse: (value) => set({ userClickedStatsBoxCollapse: value }),
  userClickedStatsBoxCollapse: false,

  loggingOut: false,
  setLoggingOut: (value) => set({ loggingOut: value })
}));

export default useStore;
