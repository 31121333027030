import EsgIcon from "~/src/ui/icons/esg-icon/index.jsx";
import ProjectLink from "~/src/ui/links/project-link/index.jsx";

const PictureContent = ({ project }) => (
  <div className="w-full">
    <ProjectLink
      backlink="projects"
      className="h-full"
      project={project}
      target="_blank"
    >
      <div className="pb-2/3 relative h-[175px] w-full overflow-hidden rounded-t-lg">
        <img
          alt={project?.name}
          className="size-full scale-100 object-cover transition duration-500 hover:scale-110"
          src={`https://immodeveloper.fra1.digitaloceanspaces.com/${project?.image_file_name}`}
        />
      </div>
    </ProjectLink>
  </div>
);

const ProjectContent = ({
  checkboxDisabled, className = "", handleCheckbox, location, project, selected
}) => (
  <div className={`flex w-full justify-between gap-3  font-sans ${className}`}>
    <div className="flex w-[10%] pt-[3px]">
      {
        handleCheckbox
          ? (
            <input
              checked={selected}
              className="size-4 cursor-pointer rounded border border-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-default disabled:opacity-50"
              disabled={checkboxDisabled}
              onChange={(event) => handleCheckbox(event.target.checked, project.slug)}
              type="checkbox"
            />
          )
          : null
      }
    </div>

    <div className="flex size-full max-w-9/12 flex-col justify-between gap-1.5">
      <ProjectLink backlink="projects" className="group flex pb-0.5" project={project} target="_blank">
        <p className="inline-block text-sm font-medium text-primary group-hover:text-primary-dark">
          <span className="whitespace-pre-line break-words border-b-2 border-primary group-hover:border-primary-dark">
            {project.name}
          </span>
        </p>
      </ProjectLink>

      <div className="flex flex-col gap-px">
        <p className="text-sm leading-none text-gray-500">
          {location.city?.zipcode} {location.city?.name}
        </p>

        <p className="text-sm leading-none text-gray-500">
          {location.street.name} {location.housenumber}
        </p>
      </div>

      {project.count_wohnungen === 0
        ? null
        : (
          <p className="text-sm leading-none text-gray-800">
            {project.count_wohnungen} Wohneinheiten
          </p>
        )}
    </div>

    <div className="flex w-[10%] pt-[3px]">
      {
        (project?.project_certificates && project?.project_certificates.length > 0)
          ? (
            <EsgIcon
              className="max-h-[18px] max-w-[18px]"
              innerClassName="max-h-3 max-w-3"
            />
          )
          : null
      }
    </div>
  </div>
);

/**
 *
 * @param root0 - The root object
 * @param root0.project - The root object
 * @param root0.handleCheckbox - The root object
 * @param root0.selected - The root object
 * @param root0.checkboxDisabled - The root object
 * @param root0.location - The root object
 * @param root0.displayImage - The root object
 * @param root0.displayPicture - The root object
 * @param root0.className - The root object
 * @example
 */
export default function PopupContent({
  checkboxDisabled,
  displayPicture,
  handleCheckbox,
  location,
  project,
  selected
}) {
  return displayPicture
    ? (
      <div className="flex w-full flex-col items-center justify-between">
        <PictureContent project={project} />

        <ProjectContent
          checkboxDisabled={checkboxDisabled}
          className="p-3"
          handleCheckbox={handleCheckbox}
          location={location}
          project={project}
          selected={selected}
        />
      </div>
    )
    : (
      <ProjectContent
        checkboxDisabled={checkboxDisabled}
        className="p-3"
        handleCheckbox={handleCheckbox}
        location={location}
        project={project}
        selected={selected}
      />
    );
}
