/**
 *
 * @param owner
 * @param type
 * @example
 */
export function hasActiveOwnerFields(owner, type) {
  let active = false;
  const ownerObject = {};

  for (const [key, value] of Object.entries(owner)) {
    if (key.includes(type)) {
      ownerObject[key.replace(`_${type}`, "")] = value;
    }

    if (key.includes(type) && value !== null) {
      active = true;
    }
  }

  return {
    active,
    ownerObj: ownerObject
  };
}

/**
 *
 * @param diffObj
 * @param diffObject
 * @example
 */
export function hasDiffValues(diffObject) {
  return Object.keys(diffObject).length > 0;
}
