import {
  Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild
} from "@headlessui/react";
import { Fragment } from "react";

import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param root0 - The root object
 * @param root0.children - The root object
 * @param root0.cancelButtonRef - The root object
 * @param root0.isActive - The root object
 * @param root0.handleCancel - The root object
 * @param root0.handleSubmit - The root object
 * @example
 */
export default function SearchFilterContainer({
  cancelButtonRef, children, handleCancel, handleSubmit, isActive
}) {
  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={cancelButtonRef}
      onClose={handleCancel}
      open={isActive}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex max-h-full w-full flex-col bg-white sm:max-h-full sm:max-w-xl sm:rounded-lg"
        >
          <div className="relative border-b border-gray-200 p-4 sm:px-6">
            <p className="text-center text-lg font-medium">Projekte filtern nach...</p>

            <div className="absolute right-4 top-0 hidden h-full items-center sm:flex">
              <button
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                onClick={handleCancel}
                type="button"
              >
                <CloseIcon className="size-5 text-black" />
              </button>
            </div>
          </div>

          <div className="divide-y divide-gray-300 overflow-y-scroll bg-white px-4 sm:divide-gray-200 sm:px-12">

            {children}

          </div>

          <div className="space-y-4 border-t border-gray-200 p-4 text-center sm:flex sm:flex-row-reverse sm:items-center sm:justify-between sm:space-y-0 sm:px-6">
            <button
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:ml-3 sm:w-auto"
              onClick={handleSubmit}
              type="button"
            >
              Suchen
            </button>

            <button
              className="-ml-2.5 rounded-md px-2.5 py-1.5 font-medium text-gray-700 underline hover:bg-gray-100 focus:outline-none"
              onClick={handleCancel}
              ref={cancelButtonRef}
              type="button"
            >
              Schließen
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
