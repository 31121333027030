/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable no-empty-function */
/* eslint-disable max-lines-per-function */

import cn from "classnames";
import { Triangle } from "lucide-react";
import {
  useEffect,
  useState
} from "react";
import { Tooltip } from "react-tooltip";

const tooltipDelay = 500;

/**
 * @typedef {object} CollapsibleContainerProps
 * @property {JSX.Element|JSX.Element[]} props.children - The child element(s).
 * @property {boolean} [props.collapsed] - Whether the container is collapsed.
 * @property {Function} [props.onChange] - The change event handler, fired when collapse button is
 * clicked.
 * @property {"left"|"right"|"top"|"bottom"} props.position - The position of the collapse button.
 * @property {string} props.className - The class name of the container.
 * @property {string} props.collapsedClassName - The class name when the container is collapsed.
 * @property {object} [props.tooltipTexts] - The tooltip texts.
 * @property {string} [props.tooltipTexts.collapsed] - The tooltip text when collapsed.
 * @property {string} [props.tooltipTexts.expanded] - The tooltip text when expanded.
 * @property {boolean} [props.active] - Whether the collapsible functionality is active at all.
 * @property {boolean} [props.custom] - Whether the collapsible functionality is custom. Children will not be hidden when collapsed.
 */

const defaultOnChange = () => { };
const defaultTooltipTexts = {
  collapsed: "anzeigen",
  expanded: "ausblenden"
};

/**
 * Collapsible container component.
 *
 * @param {CollapsibleContainerProps} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 * @example
 */
const CollapsibleContainer = ({
  active = true,
  children,
  className,
  collapsed: defaultCollapsed = false,
  collapsedClassName,
  custom = false,
  onChange = defaultOnChange,
  position,
  tooltipTexts: {
    collapsed: collapsedTooltipText = "anzeigen",
    expanded: expandedTooltipText = "ausblenden"
  } = defaultTooltipTexts,
  ...properties
}) => {
  const tooltipId = `tooltip-collapsible-${crypto.randomUUID()}`;
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const [hovered, setHovered] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const handleClick = () => {
    // this is to make the border also immediately unhovered (otherwise chart animations are blocking this setHovered)
    setHovered(false);

    setCollapsed(!collapsed);
    onChange(!collapsed);
  };

  const [previousCollapsed, setPreviousCollapsed] = useState(defaultCollapsed);

  if (defaultCollapsed !== previousCollapsed) {
    setPreviousCollapsed(defaultCollapsed);
    setCollapsed(defaultCollapsed);
  }

  // TODO[2025-01-01]: This is scuffed, not all cases return a clean up function and setting state in useEffect is not recommended.
  useEffect(() => {
    if (tooltipIsOpen) {
      const timeoutId = setTimeout(() => {
        if (tooltipIsOpen) {
          setDisplayTooltip(true);
        }
        else {
          setDisplayTooltip(false);
        }
      }, tooltipDelay);

      return () => clearTimeout(timeoutId);
    }
    else if (!tooltipIsOpen) {
      setDisplayTooltip(false);
    }
  }, [tooltipIsOpen]);

  const showChildren = !collapsed || custom;

  return (
    <>
      <div
        className={cn(
          "relative h-full",
          {
            "border-b-4": position === "bottom" && active,
            "border-b-primary": hovered && position === "bottom" && active,
            "border-b-zinc-300": !hovered && position === "bottom" && active,
            "border-l-4": position === "left" && active,
            "border-l-primary": hovered && position === "left" && active,
            "border-l-zinc-300": !hovered && position === "left" && active,
            "border-r-4": position === "right" && active,
            "border-r-primary": hovered && position === "right" && active,
            "border-r-zinc-300": !hovered && position === "right" && active,
            "border-t-4": position === "top" && active,
            "border-t-primary": hovered && position === "top" && active,
            "border-t-zinc-300": !hovered && position === "top" && active,
            [className]: className === collapsedClassName || !collapsed,
            [collapsedClassName]: className === collapsedClassName || collapsed
          }
        )}
        {...properties}
      >
        <button
          data-tooltip-id={tooltipId}
          onClick={handleClick}
          type="button"
          className={cn(
            "bg-zinc-300 hover:bg-primary rounded-full w-8 h-8 lg:w-5 lg:h-5 p-[0.4rem] lg:p-1 flex items-center justify-center absolute z-10",
            {
              "-bottom-3 right-3 lg:right-1/4": position === "bottom",
              "-left-3 top-1/4": position === "left",
              "-right-3 top-1/4": position === "right",
              "-top-3 right-3 lg:right-1/4": position === "top",
              hidden: !active
            }
          )}
          data-tooltip-content={
            collapsed
              ? collapsedTooltipText
              : expandedTooltipText
          }
          onPointerOut={(event) => {
            if (event.pointerType !== "touch") {
              setTooltipIsOpen(false);
              setHovered(false);
            }
          }}
          onPointerOver={(event) => {
            if (event.pointerType !== "touch") {
              setTooltipIsOpen(true);

              setHovered(true);
            }
          }}
        >
          <Triangle
            fill="white"
            stroke="transparent"
            className={cn(
              "pb-0.5",
              {
                "-rotate-90": (position === "right" && !collapsed) || (position === "left" && collapsed),
                "-rotate-180": (position === "top" && !collapsed) || (position === "bottom" && collapsed),
                "rotate-90": (position === "right" && collapsed) || (position === "left" && !collapsed)
              }
            )}
          />
        </button>

        {showChildren && children}
      </div>

      <Tooltip
        className="z-50"
        delayHide={0}
        delayShow={tooltipDelay}
        id={tooltipId}
        isOpen={displayTooltip}
        place={position}
        positionStrategy="absolute"
        globalCloseEvents={{
          clickOutsideAnchor: true,
          escape: true,
          resize: true,
          scroll: true
        }}
      />
    </>
  );
};

export default CollapsibleContainer;
