import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param options - The root object
 * @param options.query - The root object
 * @param options.isSimple - The root object
 * @param swrOptions
 * @param isSimple
 * @example
 */
export default function useProjectsMap({ query }, swrOptions, isSimple = false) {
  const {
    centerLat,
    centerLng,
    direction,
    layout,
    mapZoom,
    page,
    polygon,
    sort,
    ...restQuery
  } = query;

  const newQuery = {
    ...restQuery,
    isSimple,
    polygon: polygon?.join("_")
  };

  if (!newQuery.street) {
    delete newQuery.radius;
  }

  delete newQuery.page;
  delete newQuery.direction;
  delete newQuery.sort;
  delete newQuery.selection;

  if (!newQuery.street) {
    delete newQuery.radius;
  }

  if (!newQuery.activeBBox) {
    delete newQuery.bBox;
    delete newQuery.mapZoom;
    delete newQuery.centerLat;
    delete newQuery.centerLng;
  }

  const parameters = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newQuery)
        .filter(([key, value]) => value !== undefined)
    )
  );

  const {
    data,
    data: {
      payload: {
        selectionSlugs = [],
        selectionType = "addAll"
      } = {}
    } = {},
    error,
    isValidating,
    mutate
  } = useSWR(`/projects/map?${parameters}`, fetcher, swrOptions);

  if (data?.payload?.points) {
    for (const slug of selectionSlugs) {
      const index = data
        ?.payload
        ?.points
        ?.findIndex(({
          properties: {
            cluster,
            slug: pointSlug
          }
        }) => !cluster && pointSlug === slug);

      if (index !== -1) {
        if (selectionType === "addAll") {
          data.payload.points[index].properties.unselected = true;
        }
        else {
          data.payload.points[index].properties.selected = true;
        }
      }
    }
  }

  return {
    bounds: data?.payload?.bounds,
    clusterExpansionZooms: data?.payload?.cluster_expansion_zooms,
    isError: error,
    isLoading: !error && !data,
    leaves: data?.payload?.leaves,
    mutate,
    points: data?.payload?.points,
    selectionStatus: data?.payload?.selectionStatus,
    selectionType: data?.payload?.selectionType
  };
}
