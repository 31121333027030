/**
 *
 * @param name
 * @param event
 * @param tempQuery
 * @param temporaryQuery
 * @param items
 * @param tempItems
 * @param temporaryItems
 * @param key
 * @param update
 * @example
 */
export const handleCheck = function (name, event, temporaryQuery, items, temporaryItems, key, update) {
  const isChecked = event.target.checked;

  let updatedItems = [];

  if (temporaryQuery === undefined) {
    updatedItems = isChecked ? [...items, key] : items.filter((item) => item !== key);
  }

  if (temporaryItems.length > 0) {
    updatedItems = isChecked ? [...temporaryItems, key] : temporaryItems.filter((item) => item !== key);
  }

  if (temporaryItems.length === 0 && temporaryQuery !== undefined) {
    updatedItems = isChecked ? [...new Set([...temporaryItems, key])] : temporaryItems.filter((item) => item !== key) || [];
  }

  let updateValue = null;

  if (updatedItems.length > 0) {
    updateValue = updatedItems.sort().join(",");
  }

  update(name, updateValue);
};

/**
 *
 * @param query
 * @param item
 * @param tempQuery
 * @param temporaryQuery
 * @param tempItem
 * @param temporaryItem
 * @example
 */
export const handleCheckedStatus = function (query, item, temporaryQuery, temporaryItem) {
  if (temporaryQuery === null) {
    return false;
  }
  if (temporaryQuery) {
    return temporaryItem;
  }
  if (query) {
    return item;
  }

  return false;
};

/**
 *
 * @param query
 * @example
 */
export const parseActiveFilters = function (query) {
  const activeFilters = [];

  for (const key of Object.keys(query)) {
    const value = query[key];

    if (!value) {
      continue;
    }

    if (Array.isArray(value) && value?.length === 0) {
      continue;
    }

    if (![
      "activeBBox",
      "automated",
      "bBox",
      "centerLat",
      "centerLng",
      "direction",
      "mapZoom",
      "marked",
      "page",
      "radius",
      "sort",
      "stats"
    ].includes(key)) {
      activeFilters.push(key);
    }
  }

  return activeFilters;
};
