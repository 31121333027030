import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import RadioGroup from "~/src/ui/forms/radio-group";
import Select from "~/src/ui/forms/select";

/**
 *
 * @param props - The root object
 * @param props.onChange - The root object
 * @param props.offerPriceOptions - The root object
 * @param props.salePriceOptions - The root object
 * @param props.dateOptions - The root object
 * @param props.typeOptions - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function BuyableForm({
  dateOptions,
  offerPriceOptions,
  onChange,
  salePriceOptions,
  typeOptions
}) {
  const [priceOptions, setPriceOptions] = useState([]);

  const {
    control,
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: {
      type: "",
      xType: "",
      yType: ""
    },
    mode: "onChange"
  });

  const xType = useWatch({
    control,
    name: "xType"
  });
  const yType = useWatch({
    control,
    name: "yType"
  });

  const type = useWatch({
    control,
    name: "type"
  });

  useEffect(() => {
    setValue("type", typeOptions.find(({ shown }) => shown)?.value);
  }, [JSON.stringify(typeOptions.map(({ shown }) => shown))]);

  useEffect(() => {
    setValue("xType", dateOptions.find(({ shown }) => shown)?.value);
  }, [JSON.stringify(dateOptions)]);

  useEffect(() => {
    setPriceOptions(
      (type === "offer")
        ? offerPriceOptions
        : salePriceOptions
    );
  }, [
    type,
    salePriceOptions,
    offerPriceOptions
  ]);

  useEffect(() => {
    if (priceOptions.length > 0) {
      setValue(
        "yType",
        (type === "offer")
          ? offerPriceOptions[0]?.value
          : salePriceOptions[0]?.value
      );
    }
  }, [priceOptions]);

  useEffect(() => {
    onChange({
      type,
      xType,
      yType
    });
  }, [
    xType,
    yType,
    type
  ]);

  return (
    <form className="flex flex-col justify-between gap-8 lg:flex-row" onSubmit={handleSubmit(onChange)}>
      <div className="flex w-full flex-col gap-4">
        <RadioGroup
          key="type"
          label="Angebot/Verkauf"
          name="type"
          options={typeOptions}
          {...{ control }}
        />

        <Select
          color={type === "offer" ? "red" : "green"}
          key="yType"
          label=""
          name="yType"
          options={priceOptions}
          {...{ control }}
        />
      </div>

      <div className="flex hidden w-full flex-col gap-4 lg:w-1/2 xl:w-1/3">
        <div className="flex flex-row items-center gap-1 ">
          <h3 className="flex text-lg font-medium">Datum</h3>

          <ArrowRightIcon className="size-5" />
        </div>

        <RadioGroup
          key="xType"
          label="Datum"
          name="xType"
          options={dateOptions}
          {...{ control }}
        />
      </div>

    </form>
  );
}
