/* eslint-disable max-lines-per-function */
/* eslint-disable no-empty-function */

import {
  Menu, MenuButton, MenuItem, MenuItems
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ListOrderedIcon } from "lucide-react";
import {
  useEffect, useState
} from "react";
import { Tooltip } from "react-tooltip";

import { sortProjectLabels } from "~/src/modules/labels.js";

import useMediaQuery from "~/src/hooks/use-media-query.js";

const tooltipDelay = 500;

/**
 * @typedef {object} SortProjectsDropdownProps
 * @property {boolean} [hasStreet] - Whether a street filter is set.
 * @property {boolean} [isHandleItemClickDisabled] - Whether the item click is disabled.
 * @property {Function} [onSortChange] - The sort change event handler.
 * @property {"normal"|"small"} [size] - The size of the filter component.
 * @property {string} [sort] - The sort
 */

const defaultOnSortChange = () => {};

/**
 * The select all projects filter component.
 *
 * @param {SortProjectsDropdownProps} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 * @example
 */
const SortProjectsDropdown = ({
  hasStreet = false,
  isHandleItemClickDisabled,
  onSortChange = defaultOnSortChange,
  size = "normal",
  sort = "name"
}) => {
  const isHybrid = useMediaQuery("(max-width: 1150px)");
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const [hovered, setHovered] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const shouldDisplaySmallButton = isHybrid || size === "small";

  // TODO[2025-01-01]: This is scuffed, not all cases return a clean up function and setting state in useEffect is not recommended.
  useEffect(() => {
    if (tooltipIsOpen) {
      const timeoutId = setTimeout(() => {
        if (tooltipIsOpen) {
          setDisplayTooltip(true);
        }
        else {
          setDisplayTooltip(false);
        }
      }, tooltipDelay);

      return () => clearTimeout(timeoutId);
    }
    else if (!tooltipIsOpen) {
      setDisplayTooltip(false);
    }
  }, [tooltipIsOpen]);

  return (
    <Menu as="div" className="relative">
      {
        shouldDisplaySmallButton
          ? (
            <>
              <MenuButton
                className="flex h-6 w-8 cursor-pointer items-center justify-center rounded-sm border border-gray-200 bg-white p-0.5 text-gray-500 hover:bg-gray-50 lg:h-[1.875rem] lg:w-9"
                id="sort-projects-button"
                onPointerOut={(event) => {
                  if (event.pointerType !== "touch") {
                    setTooltipIsOpen(false);
                    setHovered(false);
                  }
                }}
                onPointerOver={(event) => {
                  if (event.pointerType !== "touch") {
                    setTooltipIsOpen(true);

                    setHovered(true);
                  }
                }}
              >
                <ListOrderedIcon aria-hidden="true" className="block h-full w-5 text-gray-500" />
              </MenuButton>

              {
                !isMobile && (
                  <Tooltip
                    anchorSelect="#sort-projects-button"
                    className="z-50"
                    content="Sortierung auswählen"
                    delayHide={0}
                    delayShow={tooltipDelay}
                    id="sort-projects-button-tooltip"
                    isOpen={displayTooltip}
                    place="bottom"
                    positionStrategy="fixed"
                    globalCloseEvents={{
                      clickOutsideAnchor: true,
                      escape: true,
                      resize: true,
                      scroll: true
                    }}
                  />
                )
              }
            </>

          )
          : (
            <MenuButton className="size-full items-center gap-1 font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0 lg:flex">
              <span>{sortProjectLabels.get(sort)}</span>

              <ChevronDownIcon aria-hidden="true" className="h-full w-5 text-gray-500" />
            </MenuButton>
          )
      }

      <MenuItems
        className="absolute right-0 z-10 -mt-0.5 flex w-56 origin-top-right flex-col items-stretch overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
      >
        {
          [...sortProjectLabels]
            .filter(([key]) => (key === "radius" && hasStreet) || (key !== "radius"))
            .map(([key, label]) => (
              <MenuItem key={key}>
                <button
                  className="w-full bg-white px-4 py-2 text-left text-gray-700 ui-active:bg-gray-100 ui-active:text-gray-900"
                  disabled={isHandleItemClickDisabled}
                  onClick={() => onSortChange(key)}
                  type="button"
                >
                  {label}
                </button>
              </MenuItem>
            ))
        }
      </MenuItems>
    </Menu>
  );
};

export default SortProjectsDropdown;
