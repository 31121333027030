import { animated, to } from "@react-spring/web";
import { useCallback } from "react";

const generateArray = (min, max, steps) => {
  const deltaMinMax = max - min;
  const stepValue = deltaMinMax / steps;
  const powerOfSteps = Math.floor(Math.log10(stepValue));
  const stepValueRounded = Math.round(stepValue / (10 ** powerOfSteps));
  const stepSize = stepValueRounded * (10 ** powerOfSteps);
  const result = [];

  let index = 1;

  while ((index * stepSize) < max * 1.1) {
    result.push(stepSize * index);
    index++;
  }

  return result;
};

const getMinMaxWithTimeline = (min, max, timeline) => ({
  minWithTimeline: Math.min(min, ...Object.values(timeline).filter(Boolean)),
  maxWithTimeline: Math.max(max, ...Object.values(timeline).filter(Boolean))
});

const timelineSettings = {
  constructionFinish: {
    color: "#c20c36",
    label: "Bau abgeschlossen"
  },
  constructionStart: {
    color: "#0891b2",
    label: "Baubeginn"
  },
  marketingStart: {
    color: "#ea7600",
    label: "Vermarktungsbeginn"
  },
  purchase: {
    color: "#14b8a6",
    label: "Ankauf"
  }
};

/**
 *
 * @param min
 * @param max
 * @example
 */
export const getYMin = (min, max) => min - ((max - min) * 0.1);

/**
 *
 * @param min
 * @param max
 * @example
 */
export const getYMax = (min, max) => max + ((max - min) * 0.1);

// add respect to timelines and add + and - 3 months

/**
 *
 * @param min
 * @param max
 * @param timeline
 * @example
 */
export const getXMin = (min, max, timeline) => {
  const { minWithTimeline, maxWithTimeline } = getMinMaxWithTimeline(min, max, timeline);

  return minWithTimeline - ((maxWithTimeline - minWithTimeline) * 0.1);
};

/**
 *
 * @param min
 * @param max
 * @param timeline
 * @example
 */
export const getXMax = (min, max, timeline) => {
  const { minWithTimeline, maxWithTimeline } = getMinMaxWithTimeline(min, max, timeline);

  return maxWithTimeline + ((maxWithTimeline - minWithTimeline) * 0.1);
};

/**
 *
 * @param min
 * @param max
 * @example
 */
export const getTickValues = (min, max) => generateArray(min, max, 5);

/**
 *
 * @param timeline
 * @example
 */
export const createMarkers = (timeline) => [...Object.entries(timeline)]
  .map(([phase, date], index) => ({
    axis: "x",
    legend: timelineSettings[phase].label,
    legendOffsetY: index === 0 ? 8 : index === 1 ? 8 : index === 2 ? 18 : 18,
    legendPosition: index % 2 === 0 ? "bottom" : "top",
    lineStyle: {
      opacity: 1,
      stroke: timelineSettings[phase].color,
      strokeDasharray: [4, 4],
      strokeDashoffset: index * 2,
      strokeWidth: 2
    },
    textStyle: {
      fill: timelineSettings[phase].color,
      fontSize: 10.25,
      fontWeight: 600,
      letterSpacing: 0.25,
      textAnchor: "middle"
    },
    value: date
  }))
  .sort(({ date: dateA }, { date: dateB }) => dateA - dateB)
  .filter(({ value }) => value !== null);

/**
 *
 * @param value
 * @example
 */
export const formatCurrency = (value) => `€ ${value.toLocaleString("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}`;

/**
 *
 * @param value
 * @example
 */
export const formatCurrencyPerSquaremeter = (value) => `€ ${value.toLocaleString("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})} / m²`;

/**
 *
 * @param hasSquaremeter
 * @param yMax
 * @example
 */
export const getAxisLeftLegendOffset = (hasSquaremeter, yMax) => {
  const offsetFromYMax = (5.5 * Math.floor(Math.log10(yMax)));

  return (hasSquaremeter)
    ? (-90 - offsetFromYMax)
    : (-75 - offsetFromYMax);
};

const interpolateRadius = (size) => size / 2;

const AnimatedCircle = ({
  blendMode,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleMouseMove,
  isInteractive,
  style: {
    color,
    size,
    x,
    y,
    ...rest
  },
  ...restProperty
}) => (
  <animated.circle
    cx={x}
    cy={y}
    fill={color}
    onClick={isInteractive ? handleClick : undefined}
    onMouseEnter={isInteractive ? handleMouseEnter : undefined}
    onMouseLeave={isInteractive ? handleMouseLeave : undefined}
    onMouseMove={isInteractive ? handleMouseMove : undefined}
    r={size.to(interpolateRadius)}
    style={{ mixBlendMode: blendMode }}
  />
);

const AnimatedTriangle = ({
  blendMode,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  handleMouseMove,
  isInteractive,
  style: {
    color,
    size,
    x,
    y
  }
}) => {
  const getTrianglePointsString = (currentSize, currentX, currentY) => {
    const xNumber = currentX;
    const yNumber = currentY;

    const {
      x: xAbove,
      y: yAbove
    } = {
      x: xNumber,
      y: yNumber - (currentSize * 1.5)
    };

    const angles = [
      0,
      120,
      240
    ];

    const trianglePoints = angles
      .map((angle) => {
        const radians = (Math.PI / 180) * angle;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);

        return {
          x: (cos * (xAbove - xNumber)) + (sin * (yAbove - yNumber)) + xNumber,
          y: (cos * (yAbove - yNumber)) - (sin * (xAbove - xNumber)) + yNumber
        };
      });

    return Object.values(trianglePoints)
      .map(({ x, y }) => `${x},${y}`)
      .join(" ");
  };

  return (
    <>
      <animated.polygon
        fill={color}
        onClick={isInteractive ? handleClick : undefined}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onMouseMove={isInteractive ? handleMouseMove : undefined}
        style={{ mixBlendMode: blendMode }}
        points={to([
          size,
          x,
          y
        ], getTrianglePointsString)}
      />
    </>

  );
};

/**
 *
 * @param props
 * @param properties
 * @example
 */
export const ZKeyAwareNode = (properties) => {
  const {
    node,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onMouseMove
  } = properties;

  const handleMouseEnter = useCallback((event) => onMouseEnter?.(node, event), [node, onMouseEnter]);
  const handleMouseMove = useCallback((event) => onMouseMove?.(node, event), [node, onMouseMove]);
  const handleMouseLeave = useCallback((event) => onMouseLeave?.(node, event), [node, onMouseLeave]);
  const handleClick = useCallback((event) => onClick?.(node, event), [node, onClick]);

  return node.serieId === "dataWithZKey"
    ? (
      <AnimatedCircle
        {...{
          ...properties,
          handleClick,
          handleMouseEnter,
          handleMouseLeave,
          handleMouseMove
        }}
      />
    )
    : (
      <AnimatedTriangle
        {...{
          ...properties,
          handleClick,
          handleMouseEnter,
          handleMouseLeave,
          handleMouseMove
        }}
      />
    );
};
