import ConstructionTypeModal from "./modals/construction-type-modal";
import FinishYearModal from "./modals/finish-year-modal";
import FundingModal from "./modals/funding-modal";
import ObjectTypeModal from "./modals/object-type-modal";
import ProjectPhaseModal from "./modals/project-phase-modal";
import QuantityModal from "./modals/quantity-modal";
import RechstformModal from "./modals/rechtsform-modal";
import TransactionModal from "./modals/transaction-modal";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @param root0.submit - The root object
 * @param root0.cancelSearchFilter - The root object
 * @param root0.filterModals - The root object
 * @example
 */
export default function SearchFilterModals({
  cancelSearchFilter, filterModals, query, submit, tempQuery, update
}) {
  const {
    constructionTypeModal, finishYearModal,
    fundingModal, objectTypeModal,
    projectPhaseModal, quantityModal,
    rechtsformModal, setConstructionTypeModal,
    setFinishYearModal, setFundingModal,
    setObjectTypeModal, setProjectPhaseModal,
    setQuantityModal, setRechtsformModal,
    setTransactionModal, transactionModal
  } = filterModals;

  return (
    <>
      <FinishYearModal cancelSearchFilter={cancelSearchFilter} finish_year={query.finish_year} hide={() => setFinishYearModal(false)} isActive={finishYearModal} submit={submit} tempQuery={tempQuery} update={update} />

      <ProjectPhaseModal cancelSearchFilter={cancelSearchFilter} hide={() => setProjectPhaseModal(false)} isActive={projectPhaseModal} query={query} submit={submit} tempQuery={tempQuery} update={update} />

      <QuantityModal cancelSearchFilter={cancelSearchFilter} hide={() => setQuantityModal(false)} isActive={quantityModal} query={query} submit={submit} tempQuery={tempQuery} update={update} />

      <RechstformModal cancelSearchFilter={cancelSearchFilter} hide={() => setRechtsformModal(false)} isActive={rechtsformModal} query={query} submit={submit} tempQuery={tempQuery} update={update} />

      <FundingModal cancelSearchFilter={cancelSearchFilter} hide={() => setFundingModal(false)} isActive={fundingModal} query={query} submit={submit} tempQuery={tempQuery} update={update} />

      <TransactionModal cancelSearchFilter={cancelSearchFilter} hide={() => setTransactionModal(false)} isActive={transactionModal} query={query} submit={submit} tempQuery={tempQuery} update={update} />

      <ConstructionTypeModal cancelSearchFilter={cancelSearchFilter} hide={() => setConstructionTypeModal(false)} isActive={constructionTypeModal} query={query} submit={submit} tempQuery={tempQuery} update={update} />

      <ObjectTypeModal cancelSearchFilter={cancelSearchFilter} hide={() => setObjectTypeModal(false)} isActive={objectTypeModal} query={query} submit={submit} tempQuery={tempQuery} update={update} />
    </>
  );
}
