/* eslint-disable import-x/max-dependencies */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */

import cn from "classnames";
import {
  useEffect,
  useState
} from "react";

import useCount from "~/src/hooks/use-count.js";
import useMediaQuery from "~/src/hooks/use-media-query.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";

import CollapsibleContainer from "~/src/ui/containers/collapsible-container.jsx";
import SelectOnlyMarkedProjects from "~/src/ui/filters/only-marked-projects-filter/index.jsx";
import SelectAllProjectsFilter from "~/src/ui/filters/select-all-projects-filter/index.jsx";

import ProjectMiniList from "../project-mini-list.jsx";
import SortDirectionButton from "../sort-direction-button/index.new.jsx";
import SortProjectsDropdown from "../sort-projects-dropdown/index.new.jsx";

/**
 * @typedef {Readonly<{className?: string}>} Props
 */

/**
 *
 * @param {Props} props - The root object
 * @example
 */
const ProjectsMiniListTile = ({ className = "hidden lg:flex" }) => {
  const { query, setQuery } = useProjectQueryParams();
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const currentSelectionStatus = useStore((state) => state.currentSelectionStatus);
  const selectionLoading = useStore((state) => state.selectionLoading);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);
  const setChange = useStore((state) => state.setChange);

  const onlyMarkedProjects = useStore((state) => state.onlyMarkedProjects);
  const setOnlyMarkedProjects = useStore((state) => state.setOnlyMarkedProjects);

  const [isHandleItemClickDisabled, setIsHandleItemClickDisabled] = useState(false);

  const filterTileCollapsed = useStore((state) => state.filterTileCollapsed);
  const miniListTileCollapsed = useStore((state) => state.miniListTileCollapsed);
  const setMiniListTileCollapsed = useStore((state) => state.setMiniListTileCollapsed);

  const setUserClickedMiniListTileCollapse = useStore(
    (state) => state.setUserClickedMiniListTileCollapse
  );

  const [simulatedPage, setSimulatedPage] = useState(1);

  const isHybrid = useMediaQuery("(max-width: 1380px)");

  useEffect(() => {
    if (!filterTileCollapsed && !miniListTileCollapsed && isHybrid) {
      setMiniListTileCollapsed(true);
    }
  }, [isHybrid]);

  useEffect(() => {
    if (!filterTileCollapsed && !miniListTileCollapsed && isHybrid) {
      setMiniListTileCollapsed(true);
    }
  }, [filterTileCollapsed]);

  const {
    projects: {
      filtered: projectCountFiltered,
      selected: projectCountSelected
    } = {}
  } = useCount({ query });

  useEffect(() => {
    if (projectCountSelected === 0) {
      setOnlyMarkedProjects(false);
    }
  }, [projectCountSelected]);

  const handleItemClickTimeoutInterval = 1_250;

  const [currentTotal, setCurrentTotal] = useState();

  useEffect(() => {
    setQuery({
      marked: onlyMarkedProjects
    });
  }, [onlyMarkedProjects]);

  /**
   *
   * @param {string} sort - The sort key.
   * @returns {void}
   * @example
   */
  const handleSortChange = (sort) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisabled(true);

    setQuery({
      sort
    });

    setTimeout(() => {
      setIsHandleItemClickDisabled(false);
    }, handleItemClickTimeoutInterval);
  };

  /**
   *
   * @param {string} direction
   * @returns {void}
   * @example
   */
  const handleSortDirectionChange = (direction) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisabled(true);

    setQuery({
      direction
    });

    setTimeout(() => {
      setIsHandleItemClickDisabled(false);
    }, handleItemClickTimeoutInterval);
  };

  /**
   *
   * @param {object} update - The update object.
   * @param {string} update.source - The source.
   * @param {number} update.sourcePage - The source page.
   * @param {string} update.type - The type.
   * @example
   */
  const updateSelection = (update) => {
    setChange(update);
  };

  /**
   *
   * @param {boolean} value - The value.
   * @example
   */
  const setAll = (value) => {
    const nextMainType = value ? "addAll" : "removeAll";

    setMainSelectionType(nextMainType);

    updateSelection({
      source: "list",
      sourcePage: simulatedPage,
      type: nextMainType
    });

    if (nextMainType === "removeAll") {
      setOnlyMarkedProjects(false);
    }
  };

  const handleAllChecked = () => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisabled(true);

    if (!selectionLoading) {
      const checkAll = !(
        projectCountSelected === projectCountFiltered ||
        projectCountSelected !== 0
      );

      setAll(checkAll);
    }

    setTimeout(() => {
      setIsHandleItemClickDisabled(false);
    }, handleItemClickTimeoutInterval);
  };

  /**
   *
   * @param {boolean} value - The value.
   * @returns
   * @example
   */
  const handleDisplayOnlyMarked = (value) => {
    if (isHandleItemClickDisabled) {
      return;
    }

    setIsHandleItemClickDisabled(true);

    setOnlyMarkedProjects(value);

    setTimeout(() => {
      setIsHandleItemClickDisabled(false);
    }, handleItemClickTimeoutInterval);
  };

  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @param {boolean} collapsed
   * @example
   */
  const handleChange = (collapsed) => {
    setMiniListTileCollapsed(collapsed);

    setUserClickedMiniListTileCollapse(true);
  };

  return (
    <CollapsibleContainer
      active={!isMobile}
      collapsed={isMobile ? false : miniListTileCollapsed}
      collapsedClassName="h-full w-0"
      onChange={handleChange}
      position="left"
      className={cn(
        "h-full shrink-0",
        {
          "bg-white border border-zinc-200 rounded w-[360px]": !isMobile
        },
        className
      )}
      tooltipTexts={{
        collapsed: "Liste anzeigen",
        expanded: "Liste ausblenden"
      }}
    >
      <div className="flex size-full flex-col justify-items-stretch text-sm" id="mini-list-tile-flex-container">
        <div className="top-0 z-10 flex flex-col items-center justify-between gap-2 border-b border-gray-200 bg-white p-3.5 text-sm lg:flex-row">
          <div className="flex w-full items-center justify-between">
            <SelectAllProjectsFilter
              disabled={selectionLoading || projectCountFiltered === 0 || isHandleItemClickDisabled}
              handleCheck={handleAllChecked}
              size="small"
              selectionStatus={(query.marked && currentSelectionStatus !== "none")
                ? "all"
                : currentSelectionStatus}
            />

            <div className="flex h-full items-center gap-2">
              <SelectOnlyMarkedProjects
                disabled={selectionLoading || isHandleItemClickDisabled}
                handleCheck={handleDisplayOnlyMarked}
                onlyMarkedProjects={onlyMarkedProjects}
                size="small"
              />

              <SortProjectsDropdown
                hasStreet={Boolean(query.street)}
                isHandleItemClickDisabled={isHandleItemClickDisabled}
                onSortChange={handleSortChange}
                size="small"
                sort={query.sort}
              />

              <SortDirectionButton
                direction={query.direction}
                isHandleItemClickDisabled={isHandleItemClickDisabled}
                onSortDirectionChange={handleSortDirectionChange}
              />

            </div>
          </div>

        </div>

        <ProjectMiniList
          setCurrentTotal={setCurrentTotal}
          setIsLoading={setIsLoading}
          setSimulatedPage={setSimulatedPage}
        />
      </div>
    </CollapsibleContainer>
  );
};

export default ProjectsMiniListTile;
