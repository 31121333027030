/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @example
 */
const LogoIcon = ({ className }) => (
  <svg className={className} fill="none" viewBox="0 0 312 312" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_801_2581)">
      {/* <path d="M276.51 312H35.49C15.99 312 0 296.01 0 276.51V35.49C0 15.99 15.99 0 35.49 0H276.51C296.01 0 312 15.99 312 35.49V276.51C312 296.01 296.01 312 276.51 312Z" fill="#822C42"/> */}
      <path d="M261.299 258.57H99.0594C87.3594 258.57 77.6094 249.21 77.6094 237.9V74.1C77.6094 62.79 86.9694 53.43 99.0594 53.43H261.299C272.999 53.43 282.749 62.79 282.749 74.1V237.9C282.749 249.21 272.999 258.57 261.299 258.57ZM99.0594 63.96C93.2094 63.96 88.5294 68.64 88.5294 74.1V237.9C88.5294 243.75 93.2094 248.04 99.0594 248.04H261.299C267.149 248.04 271.829 243.36 271.829 237.9V74.1C271.829 68.25 267.149 63.96 261.299 63.96H99.0594Z" fill="white" />

      <path d="M246.091 153.27C246.871 190.32 223.081 228.15 171.991 228.15C155.611 228.15 138.841 228.15 122.461 228.15V81.9C138.841 81.9 155.611 81.9 171.991 81.9C221.521 81.9 245.311 117.39 246.091 153.27ZM133.771 92.43V217.23H171.991C215.281 217.23 235.561 184.47 234.781 153.27C234.001 122.85 214.111 92.43 171.991 92.43H133.771Z" fill="white" />

      <path d="M29.25 225.81V85.02H55.77V225.81H29.25Z" fill="white" />
    </g>

    <defs>
      <clipPath id="clip0_801_2581">
        <rect fill="white" height="312" width="312" />
      </clipPath>
    </defs>
  </svg>
);

export default LogoIcon;
