import { RadioGroup } from "@headlessui/react";
import cn from "classnames";

const chartTypes = [
  {
    name: "Fertiggestellte Wohneinheiten",
    type: "countWohnungen"
  },
  {
    name: "Preis/m² Trend",
    type: "marketTrend"
  },
  {
    name: "Preis/m² nach Fläche",
    type: "marketTrendByArea"
  }
];

/**
 * Creates Radiobuttons for predefined chart types.
 *
 * @param {object} props - The props.
 * @param {string} props.chartToDisplay - Name of the chart to display.
 * @param {Function} props.setChartToDisplay - Setter for the active chart.
 * @returns {React.ReactElement} The RadioGroup.
 * @example
 */
export default function StatisticsHeader({ chartToDisplay, setChartToDisplay }) {
  return (
    <RadioGroup onChange={setChartToDisplay} value={chartToDisplay}>
      <div className="space-y-4 md:grid md:grid-cols-6 md:gap-4 md:space-y-0">
        {chartTypes.map(({ name, type }) => (
          <RadioGroup.Option
            key={type}
            value={type}
            className={({ checked }) => cn(
              "md:col-span-2 relative flex items-center justify-start rounded border border-gray-200 bg-white shadow-sm px-4 py-4 cursor-pointer hover:border-gray-400 focus:outline-none ",
              {
                "border-gray-200": !checked,
                "border-primary": checked
              }
            )}
          >
            {({ checked }) => (
              <>
                <div>
                  <div className="flex items-start justify-between text-sm">
                    <div className="-mt-0.5 flex items-center">
                      <div>
                        <span
                          aria-hidden="true"
                          className={cn(
                            "h-5 w-5 cursor-pointer rounded-full border flex items-center justify-center",
                            {
                              "bg-primary border-transparent": checked,
                              "bg-white border-gray-300": !checked
                            }
                          )}
                        >
                          <span className="size-2 rounded-full bg-white" />
                        </span>
                      </div>

                      <RadioGroup.Label
                        as="p"
                        className="ml-3 text-base font-medium text-gray-900"
                      >
                        {name}
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>

                <div
                  aria-hidden="true"
                  className={cn(
                    "absolute -inset-px rounded border-2 pointer-events-none",
                    {
                      "border-primary": checked,
                      "border-transparent": !checked
                    }
                  )}
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
