import { formatArea } from "~/src/modules/formatters";

/**
 *
 * @param props - The root object
 * @param props.area - The root object
 * @param props.toArea - The root object
 * @example
 */
export default function AreaRange({ area, toArea }) {
  if (area && toArea) {
    return `${formatArea(area)} - ${formatArea(toArea)}`;
  }

  if (area) {
    return `${formatArea(area)}`;
  }

  return "-";
}
