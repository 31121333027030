import StatisticsFilters from "../project-statistics-filter";

/**
 * Header Component, which allows the user to switch between the different routes
 * to see the corresponding statistics.
 *
 * @returns {React.ReactElement} Returns the StatisticFilters, which contains a RadioButtonGroup
 * @example
 */
export default function StatisticsHeader() {
  const categoryStatistics = [
    {
      label: "Preise",
      value: "preise"
    },
    {
      label: "Zielgruppen",
      value: "zielgruppen"
    },
    {
      label: "Zimmer",
      value: "zimmer"
    }
  ];

  return (
    <StatisticsFilters
      categoryStatistics={categoryStatistics}
    />
  );
}
