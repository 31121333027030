import useEsgCertificates from "~/src/hooks/use-esg-certificates.js";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag/index.jsx";
/* eslint-disable camelcase */
import { useEffect } from "react";

import { destructQuery } from "../../esg-certificate-filter-modal/_common/_exports.js";

/**
 *
 * @param root0 - The root object
 * @param root0.queryItems - The root object
 * @param root0.submit - The root object
 * @param root0.onClick - The root object
 * @example
 */
export default function CertificateFilterTag({
  onClick, queryItems, submit
}) {
  const {
    certificates,
    isLoading,
    mutate
  } = useEsgCertificates();

  useEffect(() => {
    mutate();
  }, [queryItems]);

  if (!queryItems || isLoading) {
    return null;
  }

  const queryItemsAsObject = destructQuery(queryItems);

  const handleClick = (id) => {
    const newCertificateQueryItems = queryItems
      .filter((item) => !String(item).startsWith(String(id)));

    submit("certificates", newCertificateQueryItems);
  };

  const handleModal = () => {
    onClick();
  };

  const activeIssuerFilters = Object.entries(queryItemsAsObject).map(([issuerId, certificateIds]) => {
    const issuerIdAsNumber = Number(issuerId);
    const certificateIdsAsNumber = new Set(certificateIds.map(Number));

    const issuerAndCertificateIndex = certificates
      .map(({ id: issuerAndCertificateId }) => issuerAndCertificateId)
      .indexOf(issuerIdAsNumber);

    const activeIssuer = certificates[issuerAndCertificateIndex];

    if (activeIssuer.issuer_certificates.length > 0) {
      return {
        ...activeIssuer,
        issuer_certificates: activeIssuer.issuer_certificates
          .map((cert) => ((certificateIdsAsNumber.has(cert.id))
            ? cert
            : null))
      };
    }

    return activeIssuer;
  });

  const displayedTags = activeIssuerFilters
    .flatMap((issuer) => {
      if (issuer.issuer_certificates.length === 0) {
        return {
          id: issuer.id,
          name: issuer.name
        };
      }

      if (issuer.issuer_certificates.every((tag) => tag !== null)) {
        return {
          id: issuer.id,
          name: issuer.name
        };
      }

      return issuer.issuer_certificates.map((tag) => {
        if (tag !== null) {
          return {
            id: `${issuer.id}-${tag.id}`,
            name: tag.name
          };
        }

        return null;
      })
        .filter((value) => value !== null);
    });

  const tags = (displayedTags.length > 10)
    ? (
      <DefaultFilterTag handleClick={() => handleModal()} key="filter_certificates_more_than">
        <span>Gebäudezertifikate: mehr als 10</span>
      </DefaultFilterTag>
    )
    : displayedTags.map((tag, index) => (
      <DefaultFilterTag handleClick={() => handleClick(tag.id)} key={tag.id}>
        <span>Gebäudezertifikat: {tag.name}</span>
      </DefaultFilterTag>
    ));

  return (
    <>
      {tags}
    </>
  );
}
