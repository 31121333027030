import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param query.query
 * @param query - The root object
 * @param query.shouldFetch - The root object
 * @param swrOptions
 * @example
 */
export default function useProjectsDetailStats({ query, shouldFetch = true }, swrOptions) {
  const {
    direction,
    layout,
    page,
    polygon,
    radius,
    sort,
    street,
    ...restQuery
  } = query;

  const newQuery = {
    ...restQuery,
    polygon: polygon?.join("_"),
    ...(street && {
      radius,
      street
    })
  };

  if (!newQuery.activeBBox) {
    delete newQuery.bBox;
    delete newQuery.mapZoom;
    delete newQuery.centerLat;
    delete newQuery.centerLng;
  }

  const parameters = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newQuery).filter(([key, value]) => value !== undefined)
    )
  );

  const {
    data, error, mutate
  } = useSWR(() => (shouldFetch ? `/projects/detail-stats?${parameters}` : null), fetcher, swrOptions);

  return {
    detailStats: data?.payload?.detailStats,
    isError: error,
    isLoading: !error && !data,
    mutate
  };
}
