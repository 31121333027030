import {
  createContext,
  useCallback,
  useState
} from "react";

export const DashboardContext = createContext({
  hideAnimation: () => {},
  showAnimation: true
});

/**
 *
 * @param props - The root object
 * @param props.children - The root object
 * @example
 */
const DashboardProvider = ({ children }) => {
  const [showAnimation, setShowAnimation] = useState(true);

  const hideAnimation = () => setShowAnimation(false);

  const contextValue = {
    hideAnimation: useCallback(() => hideAnimation(), []),
    showAnimation
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
