import cn from "classnames";

import MapMarker from "~/src/ui/markers/map-marker";

/**
 *
 * @param currentMarketingPhase
 * @example
 */
function getIconClass(currentMarketingPhase) {
  switch (currentMarketingPhase) {
    case "marketing_phase_active":
      return "text-marker-red";
    case "marketing_phase_coming_soon":
      return "text-marker-orange";
    case "marketing_phase_completed_all":
    case "marketing_phase_completed_apartments":
    case "marketing_phase_project_finished":
      return "text-marker-green";
    default:
      return "text-marker-gray";
  }
}

/**
 *
 * @param currentConstructionPhase
 * @example
 */
function getIconName(currentConstructionPhase) {
  switch (currentConstructionPhase) {
    case "construction_phase_active":
      return "construction";
    case "construction_phase_completed":
      return "completion";
    case "construction_phase_purchase":
      return "purchase";
    default:
      return "empty";
  }
}

/**
 *
 * @param root0 - The root object
 * @param root0.currentMarketingPhase - The root object
 * @param root0.currentConstructionPhase - The root object
 * @param root0.selected - The root object
 * @param root0.active - The root object
 * @example
 */
export function getIconHtml({
  active,
  currentConstructionPhase,
  currentMarketingPhase,
  selected
}) {
  return (
    <MapMarker
      icon={getIconName(currentConstructionPhase)}
      className={cn(
        getIconClass(currentMarketingPhase),
        "w-full h-full",
        {
          "opacity-60": !selected,
          "scale-105 -translate-y-[1px]": active
        }
      )}
    />
  );
}
