import { isEqual } from "lodash-es";

import { parseUrlParams } from "~/src/features/filters/filter-url-parser";

import useProjectList from "~/src/hooks/use-project-list";
import useProjectQueryParams from "~/src/hooks/use-project-query-params";
import useStore from "~/src/hooks/use-store";
import useWatchlists from "~/src/hooks/use-watchlists";

import ItemSkeleton from "~/src/ui/loading/item-skeleton";
/* eslint-disable import/max-dependencies */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  useEffect,
  useRef,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import { useSWRConfig } from "swr";

import ProjectListItem from "./project-list-item";

/**
 *
 * @param setCurrentTotal.setCurrentTotal
 * @param setCurrentTotal - The root object
 * @param setIsLoading
 * @param setSelectionStatus
 * @param className
 * @param setCurrentTotal.setIsLoading
 * @param setCurrentTotal.setSelectionStatus
 * @param setCurrentTotal.className
 * @param setCurrentTotal.handlePageOnEmptyProjectList
 * @example
 */
export default function ProjectList({
  className,
  setCurrentTotal,
  setIsLoading,
  setSelectionStatus
}) {
  const swrOptions = {
    revalidateOnFocus: false
  };

  const location = useLocation();

  const {
    cache: globalCache,
    mutate: mutateGlobal
  } = useSWRConfig();

  const { query, setQuery } = useProjectQueryParams();

  const {
    isLoading,
    mutate: mutateProjectList,
    projects,
    selectionStatus,
    selectionType,
    total,
    updateTime
  } = useProjectList({
    from: true,
    query
  }, swrOptions);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setSelectionStatus(selectionStatus);
  }, [isLoading]);

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const setChange = useStore((state) => state.setChange);

  const [fromHook, setFromHook] = useState(false);

  const setCurrentSelectionStatus = useStore((state) => state.setCurrentSelectionStatus);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const projectListReference = useRef(null);

  const [projectIsLoading, setProjectIsLoading] = useState(false);

  useEffect(() => {
    const { search } = location;

    const urlSearch = new URLSearchParams(search);

    const parsedSearch = Object.fromEntries(
      [...urlSearch.entries()]
        .map(parseUrlParams)
        .filter(([key, value]) => {
          if (query?.activeBBox) {
            return true;
          }

          return (!([
            "bBox",
            "centerLat",
            "centerLng",
            "mapZoom"
          ].includes(key)));
        })
    );

    mutateGlobal(
      (swrCacheKey) => {
        if (swrCacheKey.startsWith("/project-list")) {
          const cacheKeyValue = globalCache.get(swrCacheKey);

          const cleanedSwrCacheKey = swrCacheKey.replace("/project-list?", "");

          const urlParsedSwrCacheKey = new URLSearchParams(cleanedSwrCacheKey);

          const parsedSwrCacheKey = Object.fromEntries(
            [...urlParsedSwrCacheKey.entries()].map(parseUrlParams)
          );

          const relevantSwrCacheKeys = Object.fromEntries(Object.entries(parsedSwrCacheKey)
            .filter(([filterKey, _filterValue]) => (Object.keys(parsedSearch).includes(filterKey))));

          if (cacheKeyValue?.data && !cacheKeyValue?.isLoading) {
            // setSelectionMutate(true);
            mutateProjectList();
          }

          return (
            !isEqual(relevantSwrCacheKeys, parsedSearch) &&
            (Object.keys(cacheKeyValue).includes("data") ||
              !cacheKeyValue?.isLoading)
          );
        }

        return false;
      },

      undefined,
      {
        revalidate: false
      }
    );
  }, [location]);

  useEffect(() => {
    if (projectListReference.current) {
      projectListReference.current.scrollTop = 0;
    }
    setProjectIsLoading(true);
  }, [query]);

  useEffect(() => {
    if (projectIsLoading && projects !== undefined) {
      setProjectIsLoading(false);
    }
  }, [updateTime]);

  useEffect(() => {
    if (selectionType) {
      setMainSelectionType(selectionType);
      if (!fromHook) {
        setFromHook(true);
      }
    }
  }, [selectionType]);

  useEffect(() => {
    if (selectionStatus) {
      setCurrentSelectionStatus(selectionStatus);
      if (!fromHook) {
        setFromHook(true);
      }
    }
  }, [selectionStatus]);

  const loadingTimeout = 250;

  useEffect(() => {
    if (fromHook) {
      const settingSelectionLoading = () => {
        const timeoutId = setTimeout(() => {
          setSelectionLoading(false);
          setFromHook(false);
        }, loadingTimeout);

        return () => {
          clearTimeout(timeoutId);
        };
      };

      settingSelectionLoading();
    }
  }, [fromHook]);

  useEffect(() => {
    if (total !== undefined) {
      setCurrentTotal(total.totalPages);
    }
  });

  useEffect(() => {
    if (projects && projects.length === 0) {
      setQuery({
        page: Math.max(total.totalPages, 1)
      });
    }
  }, [projects]);

  useEffect(() => {
    if (selectionMutate) {
      const mutating = async () => {
        await mutateProjectList();
        // await mutateCount();
        setSelectionMutate(false);
        if (selectionLoading) {
          setSelectionLoading(false);
        }
      };

      mutating();
    }
  }, [selectionMutate]);

  const updateSelection = (update) => {
    setChange(update);
  };

  const handleProjectSelectedCheckbox = async (value, currentSlug) => {
    if (!selectionLoading) {
      const updateType = (value)
        ? "add"
        : "remove";

      await updateSelection({
        data: [currentSlug],
        source: "list",
        sourcePage: query.page,
        type: updateType
      });
    }
  };

  const { mutate: mutateWatchlists, watchlists } = useWatchlists();

  const maxPageSize = 25;

  if (isLoading || !projects) {
    return (
      <div className="h-full overflow-y-scroll overscroll-y-contain">
        {
          Array.from({ length: maxPageSize }).fill().map((empty, index) => (
            <ItemSkeleton
              className="relative w-full border-b border-gray-200 last:border-b-0"
              key={index}
            />
          ))
        }
      </div>
    );
  }

  if (projects && projects.length === 0) {
    return (
      <span className="ml-[10px] mt-5">Keine Ergebnisse gefunden…</span>
    );
  }

  return (
    <div
      className={className}
    >
      {projects.map((project) => (
        <ProjectListItem
          disabled={selectionLoading}
          handleCheckbox={handleProjectSelectedCheckbox}
          key={`project_${project.slug}`}
          mutateWatchlists={mutateWatchlists}
          project={project}
          watchlists={watchlists}
        />
      ))}

    </div>
  );
}
