/* eslint-disable no-magic-numbers */
/* eslint-disable import/prefer-default-export */
import { Temporal } from "@js-temporal/polyfill";

import { priceLabels } from "~/src/modules/labels";

const { PlainYearMonth } = Temporal;

/**
 *
 * @param category
 * @param statistic
 * @example
 */
const generateLineData = (category, statistic) => {
  let maxTotal = 0;

  const data = Object.entries(statistic[category].data)
    .filter(([, { min, max }]) => max !== 0 && Number.isFinite(min))
    .map(([
      yearMonthString,
      {
        min,
        max,
        average,
        count
      }
    ]) => {
      if (maxTotal < max) {
        maxTotal = max;
      }

      return ({
        count,
        x: yearMonthString,
        y: average,
        ymax: max,
        ymin: Number.isFinite(min) ? min : 0
      });
    })
    .toSorted(({ x: x1 }, { x: x2 }) => PlainYearMonth.compare(x1, x2));

  const chartData = [
    {
      id: "austria",
      min: 0,
      max: maxTotal * 1.1,
      color: "hsl(345, 49%, 34%)",
      data,
      label: priceLabels[category]
    }
  ];

  const legendData = [
    {
      color: "hsl(345, 49%, 34%)",
      label: "Durchschnitt"
    },
    {
      color: "#27596838",
      label: "Bandbreite",
      stroke: "#00000080"
    }
  ];

  return {
    chartData,
    legendData
  };
};

export { generateLineData };
