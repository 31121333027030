import cn from "classnames";
import { useEffect, useState } from "react";

import { formatDate } from "~/src/modules/formatters";

import InsolvencySlide from "~/src/features/insolvency-slide";

import useTracking from "~/src/hooks/use-tracking";

/**
 *
 * @param root0 - The root object
 * @param root0.isLoading - The root object
 * @param root0.insolvencies - The root object
 * @param root0.loadingItems - The root object
 * @param root0.query - The root object
 * @example
 */
export default function InsolvencyList({
  insolvencies = [], isLoading, loadingItems = 10, query
}) {
  const { trackPageView } = useTracking();
  const [showInsolvencyDetails, setShowInsolvencyDetails] = useState(false);
  const [activeInsolvency, setActiveInsolvency] = useState(false);
  const [activeColumn, setActiveColumn] = useState("dateLastChange");

  /**
   *
   * @param insolvency
   * @example
   */
  function handleInsolvencyClick(insolvency) {
    setActiveInsolvency(insolvency);
    setShowInsolvencyDetails(true);
    trackPageView(insolvency.id, "Insolvenz");
  }

  useEffect(() => {
    if (query?.sortByInsolvency) {
      setActiveColumn(query.sortByInsolvency);
    }
  }, [query.sortByInsolvency]);

  return (
    <div>
      <InsolvencySlide active={showInsolvencyDetails} hide={() => setShowInsolvencyDetails(false)} insolvency={activeInsolvency} />

      <div className="mb-4 ml-4 flex flex-col sm:ml-0">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" id="scrollable-table">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 shadow-sm sm:rounded">

              <div className="min-w-full divide-y divide-gray-200">

                <div className="flex grid-cols-12 items-center bg-gray-100 lg:grid">
                  <div className="col-span-3 grid grid-cols-2">
                    <div className="w-96 whitespace-nowrap px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                      Öffentlich
                    </div>

                    <div className="w-96 whitespace-nowrap px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                      Änderung
                    </div>
                  </div>

                  <div className="col-span-3 w-48 px-6 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Verfahrensart
                  </div>

                  <div className="col-span-5 w-64 whitespace-nowrap py-3 pr-6 text-left text-xs font-semibold uppercase tracking-wider text-gray-700 lg:w-auto">
                    Schuldner
                  </div>

                  <div className="col-span-1 w-48 whitespace-nowrap px-6 py-3 text-right text-xs font-semibold uppercase tracking-wider text-gray-700 sm:ml-0 lg:-ml-16 lg:w-auto">
                    <span className="hidden sm:block xl:hidden">Liegenschaften</span>

                    <span className="sm:hidden xl:block">Liegenschaften</span>
                  </div>
                </div>

                <div className="divide-y divide-gray-200 bg-white">
                  {isLoading &&
                    [...new Array(loadingItems)].map((e, index) => (
                      <div className="flex h-20 cursor-pointer grid-cols-12 items-center hover:bg-gray-50 lg:grid" key={`share_deal_transactions_skeleton_${index}`}>
                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="flex h-12 w-20 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-64 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-3 flex h-12 w-56 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>

                        <div className="col-span-1 flex h-12 w-48 animate-pulse flex-col justify-center space-y-2 px-4 lg:w-auto">
                          <span className="block h-4 w-full rounded bg-gray-300" />

                          <span className="block h-4 w-full rounded bg-gray-300" />
                        </div>
                      </div>
                    ))}

                  {!isLoading && insolvencies?.length === 0 && (
                    <div className="bg-white">
                      <p className="whitespace-nowrap p-4 text-center font-light text-gray-900">keine Insolvenzen für die ausgewählten Kriterien gefunden</p>
                    </div>
                  )}

                  {insolvencies?.map((insolvency) => (
                    <div className="flex cursor-pointer grid-cols-12 items-center hover:bg-gray-50 lg:grid" key={insolvency.id} onClick={() => handleInsolvencyClick(insolvency)}>

                      <div className="col-span-3 grid grid-cols-2">
                        <div
                          className={cn("w-96 lg:w-auto px-6 py-4 whitespace-nowrap text-sm", {
                            "text-gray-900 font-medium": activeColumn !== "datePublished",
                            "text-primary font-bold": activeColumn === "datePublished"
                          })}
                        >
                          {formatDate({ date: insolvency.published_date })}
                        </div>

                        <div
                          className={cn("w-96 lg:w-auto px-6 py-4 whitespace-nowrap text-sm", {
                            "text-gray-900 font-medium": activeColumn !== "dateLastChange",
                            "text-primary font-bold": activeColumn === "dateLastChange"
                          })}
                        >
                          {formatDate({ date: insolvency.last_change_date })}
                        </div>
                      </div>

                      <div className="col-span-3 w-64 truncate whitespace-nowrap px-6 py-4 text-sm text-gray-600 lg:w-auto">
                        {insolvency.category?.name}
                      </div>

                      <div className="col-span-5 w-64 truncate whitespace-nowrap py-4 pr-4 text-sm text-gray-600 lg:w-auto">
                        <p className="truncate">
                          <span
                            className={cn("", {
                              "text-primary font-bold": activeColumn === "fbnr"
                            })}
                          >
                            {insolvency.company_fbnr}
                          </span>

                          <span className="mx-1">-</span>

                          <span
                            className={cn("", {
                              "text-primary font-bold": activeColumn === "company"
                            })}
                          >
                            {insolvency.company_name}
                          </span>
                        </p>

                        <p className="truncate">{insolvency.company_city}, {insolvency.company_street}</p>
                      </div>

                      <div className="col-span-1 w-48 whitespace-nowrap px-6 py-4 text-center text-sm text-gray-600 lg:w-auto">
                        <span
                          className={cn("", {
                            "text-primary font-bold": activeColumn === "propertyCount"
                          })}
                        >
                          {insolvency.propertyCount}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
