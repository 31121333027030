import { divIcon } from "leaflet";

import { getIconHtml } from "~/src/features/maps/projects-map/map-locations/helpers.js";
import ProjectMarkerPopup from "~/src/features/project-marker-popup.jsx";

import useProject from "~/src/hooks/use-project.js";
import useStore from "~/src/hooks/use-store.js";

import MapMarker from "~/src/ui/markers/map-marker.jsx";
/* eslint-disable max-lines-per-function */
/* eslint-disable no-magic-numbers */
import {
  memo, useEffect, useState
} from "react";
import { renderToString } from "react-dom/server";
import { Marker } from "react-leaflet";

const ProjectMarker = ({
  isSimpleMap,
  map,
  point,
  point: {
    geometry: {
      coordinates: [lng, lat]
    },
    properties: {
      currentConstructionPhase,
      currentMarketingPhase,
      selected,
      slug,
      unselected
    }
  },
  simple
}) => {
  const [opened, setOpened] = useState(false);

  const [active, setActive] = useState(false);

  const [icon, setIcon] = useState(null);

  const mainSelectionType = useStore((state) => state.mainSelectionType);
  const selectionLoading = useStore((state) => state.selectionLoading);
  const setChange = useStore((state) => state.setChange);

  const [currentSelected, setCurrentSelected] = useState(true);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  useEffect(() => {
    if (mainSelectionType === "addAll") {
      setCurrentSelected(!unselected);
    }
    else if (mainSelectionType === "removeAll") {
      setCurrentSelected(Boolean(selected));
    }
  }, [
    selected,
    unselected,
    mainSelectionType
  ]);

  const {
    isError, isLoading, mutate, project
  } = useProject(opened ? slug : null);

  useEffect(() => {
    const markerHtml = simple
      ? renderToString(<MapMarker className="size-full text-marker-blue" icon="circle" />)
      : renderToString(getIconHtml({
        active,
        currentConstructionPhase,
        currentMarketingPhase,
        selected: currentSelected
      }));

    setIcon(divIcon({
      html: markerHtml,
      iconAnchor: [25, 50],
      iconSize: [50, 50],
      popupAnchor: [0, -50]
    }));
  }, [currentSelected, active]);

  const handleCheckbox = (checked, projectSlug) => {
    if (!selectionLoading) {
      const updateType = (checked)
        ? "add"
        : "remove";

      setCurrentSelected(checked);
      setCheckboxDisabled(true);

      setChange({
        data: [projectSlug],
        source: "map",
        type: updateType
      });
    }
  };

  useEffect(() => {
    const settingCheckboxDisabled = () => {
      const timeoutId = setTimeout(() => {
        setCheckboxDisabled(false);
      }, 250);

      return () => {
        clearTimeout(timeoutId);
      };
    };

    if (checkboxDisabled && !selectionLoading) {
      settingCheckboxDisabled();
    }
  }, [selectionLoading]);

  if (!icon) {
    return null;
  }

  return (
    <Marker
      icon={icon}
      position={[lat, lng]}
      eventHandlers={{
        popupclose: () => {
          // setOpened(false);
          setActive(false);
        },
        popupopen: () => {
          setOpened(true);
          setActive(true);
        }
      }}
    >
      {(!isSimpleMap) && (
        <ProjectMarkerPopup
          {...{
            checkboxDisabled,
            handleCheckbox: simple ? null : handleCheckbox,
            map,
            opened,
            point,
            project,
            selected: simple || currentSelected
          }}
        />
      )}

    </Marker>
  );
};

export default memo(ProjectMarker, () => false);
