/* eslint-disable max-lines-per-function */
import {
  Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild
} from "@headlessui/react";
import { ExclamationCircleIcon, MegaphoneIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import {
  Fragment, useRef, useState
} from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

import API from "~/src/modules/api";

import useAuth from "~/src/hooks/use-auth";
import useStore from "~/src/hooks/use-store";

const schema = object({
  comment: string().min(1, "Eingabe zu kurz!").max(2_500, "Eingabe zu lang!")
    .required("Pflichtfeld"),
  email: string().email("Kein korrektes Email Format!").required("Pflichtfeld"),
  phone: string().max(25, "Eingabe zu lang!").notRequired()
}).required();

/**
 *
 * @param root0 - The root object
 * @param root0.isOpen - The root object
 * @example
 */
export default function ProjectSuggestionModal({ isOpen = false }) {
  const { user } = useAuth();
  const [isRequestSubmitting, setIsRequestSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const {
    formState: { errors, isSubmitted }, handleSubmit, register, reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  const toggleProjectSuggestionModal = useStore((state) => state.toggleProjectSuggestionModal);

  const initialInputReference = useRef(null);
  const { ref: initialHookFormReference, ...rest } = register("phone");

  /**
   *
   * @example
   */
  function handleClose() {
    toggleProjectSuggestionModal();
    setTimeout(() => {
      reset();
      setShowSuccess(false);
      setIsRequestSubmitting(false);
    }, 400);
  }

  /**
   *
   * @param data
   * @example
   */
  function onSubmit(data) {
    setIsRequestSubmitting(true);

    const suggestion = {
      ...data
    };

    API.post("/project-suggestion", {
      suggestion
    })
      .then((res) => {
        setShowSuccess(true);
      }).catch((error) => {
      })
      .finally(() => {
        setIsRequestSubmitting(false);
        handleClose();
      });
  }

  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={initialInputReference}
      onClose={handleClose}
      open={isOpen}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex max-h-full w-full flex-col bg-white p-6 sm:max-h-full sm:max-w-xl sm:rounded-lg"
        >
          {showSuccess && (
            <>
              <h4 className="text-lg font-medium text-gray-600">Vielen Dank für Ihren wichtigen Vorschlag!</h4>

              <p className="mt-6 text-sm text-gray-600">Wir werden das Projekt prüfen und gegebenenfalls <br /> in die IMMOdeveloper-Datenbank aufnehmen.<br /><br />Bei Rückfragen werden wir uns erlauben, Sie direkt zu kontaktieren.</p>

              <p className="mt-4 text-sm text-gray-600">
                Mit freundlichen Grüßen, Ihr
                {" "}

                <strong><span style={{ color: "#006b55" }}>IMMO</span><span style={{ color: "#000" }}>united</span></strong>

                <br />

                Research-Team
                <br />
                Kostenlose Infohotline: 0800 80 90 80
              </p>

              <div className="flex justify-end">
                <button
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={handleClose}
                  type="button"
                >
                  Schließen
                </button>
              </div>
            </>
          )}

          {!showSuccess && (
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4">
                <div className="hidden size-12 items-center justify-center rounded-full bg-secondary-darker md:flex">
                  <MegaphoneIcon aria-hidden="true" className="size-6 text-secondary-darkest" />
                </div>

                <div className="mt-1 sm:col-span-4 sm:mt-2">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Neues Projekt vorschlagen
                  </Dialog.Title>

                  <div className="mt-1 space-y-1">
                    <p className="text-sm text-gray-500">Sie möchten uns ein neues oder fehlendes Projekt melden?</p>

                    <p className="text-sm text-gray-500">Unser Research-Team wird umgehend die Erfassung Ihres vorgeschlagenen Projekts prüfen.</p>
                  </div>
                </div>
              </div>

              <div className="mt-10 space-y-6 sm:space-y-4">

                <div className="sm:grid sm:grid-cols-5 sm:items-center sm:gap-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="username">
                    Benutzer
                  </label>

                  <div className="mt-1 sm:col-span-4 sm:mt-0">
                    <p className="pl-1 text-sm font-semibold text-gray-900">{user?.username}</p>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4">
                  <label className="mt-2 block text-sm font-medium text-gray-700" htmlFor="email">
                    E-Mail *
                  </label>

                  <div className="mt-1 sm:col-span-4 sm:mt-0">
                    <div className="relative rounded-md shadow-sm">
                      <input
                        defaultValue={user?.email || ""}
                        disabled={isRequestSubmitting}
                        {...register("email")}
                        autoComplete="benutzer-email"
                        id="email"
                        name="email"
                        type="text"
                        className={cn("block w-full shadow-sm  sm:text-sm rounded-md", {
                          "border-gray-300 focus:ring-gray-500 focus:border-gray-500": !errors?.email,
                          "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": errors.email
                        })}
                      />

                      {errors?.email && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon aria-hidden="true" className="size-5 text-red-500" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-5 sm:items-center sm:gap-4">
                  <label className="-mt-1 block text-sm font-medium text-gray-700" htmlFor="phone">
                    Telefon
                  </label>

                  <div className="mt-1 sm:col-span-4 sm:mt-0">
                    <div className="relative rounded-md shadow-sm">
                      <input
                        defaultValue=""
                        disabled={isRequestSubmitting}
                        {...register("phone")}
                        {...rest}
                        autoComplete="benutzer-telefon"
                        id="phone"
                        name="phone"
                        type="text"
                        className={cn("block w-full shadow-sm sm:text-sm rounded-md", {
                          "border-gray-300 focus:ring-gray-500 focus:border-gray-500": !errors?.phone,
                          "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": errors.phone
                        })}
                        ref={(e) => {
                          initialHookFormReference(e);
                          initialInputReference.current = e;
                        }}
                      />

                      {errors?.phone && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon aria-hidden="true" className="size-5 text-red-500" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4">
                  <label className="block text-sm font-medium text-gray-700" htmlFor="comment">
                    Projekt *
                  </label>

                  <div className="mt-1 sm:col-span-4 sm:mt-0">
                    <div className="relative rounded-md shadow-sm">
                      <textarea
                        defaultValue=""
                        disabled={isRequestSubmitting}
                        {...register("comment")}
                        id="comment"
                        placeholder="Bitte geben Sie uns die Ihnen bekannten Eckdaten wie Projektname, Adresse, Bauträger, Vermarkter an."
                        rows={6}
                        className={cn("block w-full shadow-sm  sm:text-sm rounded-md", {
                          "border-gray-300 focus:ring-gray-500 focus:border-gray-500": !errors?.comment,
                          "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500": errors.comment
                        })}
                      />

                      {errors?.comment && (
                        <div className="pointer-events-none absolute right-0 top-3 flex items-center pr-3">
                          <ExclamationCircleIcon aria-hidden="true" className="size-5 text-red-500" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="items-start justify-between md:flex">
                  <p className="block text-xs text-gray-700" htmlFor="comment">
                    Hinweis: * Pflichtfelder
                  </p>

                  <p className="mt-4 h-4 text-sm text-red-500 md:mt-0">
                    {isSubmitted && Object.keys(errors).length > 0 &&
                      <span>Bitte überprüfen Sie ihre Eingaben!</span>}
                  </p>
                </div>

              </div>

              <div className="mt-5 sm:mt-6">
                <div className="flex justify-end">
                  <button
                    className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    onClick={handleClose}
                    type="button"
                  >
                    Abbrechen
                  </button>

                  {isRequestSubmitting && (
                    <button
                      disabled
                      className="ml-3 inline-flex w-44 cursor-not-allowed justify-center rounded-md border border-transparent bg-primary py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-lighter focus:ring-offset-2"
                      type="submit"
                    >
                      <svg className="-ml-1 mr-3 size-5 animate-spin text-white" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />

                        <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
                      </svg>

                      <span>wird gesendet...</span>
                    </button>
                  )}

                  {!isRequestSubmitting && (
                    <button
                      className="ml-3 inline-flex w-44 justify-center rounded-md border border-transparent bg-primary py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-lighter focus:ring-offset-2"
                      type="submit"
                    >
                      Abschicken
                    </button>
                  )}
                </div>
              </div>

            </form>
          )}

        </DialogPanel>
      </div>
    </Dialog>
  );
}
