/**
 *
 * @param spec
 * @param colGetters
 * @example
 */
const getColumnsFromSpec = (spec, colGetters) => (
  Object.fromEntries(
    spec.map(
      ({ key, ...colSpec }, index) => [
        key,
        {
          ...colSpec,
          get: colGetters?.[key] ?? ((record) => record[key]),
          number: index + 1
        }
      ]
    )
  )
);

/**
 *
 * @param col
 * @param labels
 * @example
 */
const addLabelsToCols = (col, labels) => {
  for (const colKey in col) {
    col[colKey].label = labels[colKey];
  }
};

export { addLabelsToCols, getColumnsFromSpec };
