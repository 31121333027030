/**
 *
 * @param root0 - The root object
 * @param root0.transactionId - The root object
 * @param root0.notes - The root object
 * @param root0.id - The root object
 * @example
 */
const NoteElement = ({ id, notes }) => (
  <p className="w-full hyphens-auto break-words rounded bg-gray-100 px-2.5 py-1.5 text-justify leading-[1.4rem]" id={id}>
    <span className="font-semibold ">Bemerkungen: </span> {notes || "-"}
  </p>
);

export default NoteElement;
