/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @example
 */
export default function MarkerSmall({ className }) {
  return (
    <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M50,0C34.1,0,21.2,12.9,21.2,28.8C21.2,54.9,50,100,50,100s28.8-46.8,28.8-71.2C78.8,12.9,65.9,0,50,0z M50,49.5
  c-11,0-20-8.9-20-20c0-11,8.9-20,20-20s20,8.9,20,20S61,49.5,50,49.5z"
      />
    </svg>
  );
}
