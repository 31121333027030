import CountWohnungenByYearChart from "~/src/features/charts/count-wohnungen-by-year/index.jsx";
import MarketTrendOfferChart from "~/src/features/charts/market-trend-offer/index.jsx";
import MarketTrendSaleChart from "~/src/features/charts/market-trend-sale/index.jsx";
import MarketTrendByAreaOfferChart from "~/src/features/charts/square-meter-price-by-area-category-offer/index.jsx";
import MarketTrendByAreaSaleChart from "~/src/features/charts/square-meter-price-by-area-category-sale/index.jsx";
import SquareMeterPriceByAreaOfferChart from "~/src/features/charts/square-meter-price-by-area-offer/index.jsx";
import SquareMeterPriceByAreaSaleChart from "~/src/features/charts/square-meter-price-by-area-sale/index.jsx";

const ChartsToDisplay = ({
  chartToDisplay,
  countWohnungenByYear,
  isPrint,
  marketTrendAreaGroupsOffer,
  marketTrendAreaGroupsSale,
  marketTrendOffer,
  marketTrendSale,
  squareMeterPriceByAreaOffer,
  squareMeterPriceByAreaSale
}) => {
  if (isPrint) {
    return (
      <>
        <CountWohnungenByYearChart statistic={countWohnungenByYear} />

        <MarketTrendOfferChart statistic={marketTrendOffer} />

        <MarketTrendSaleChart statistic={marketTrendSale} />

        <SquareMeterPriceByAreaOfferChart statistic={squareMeterPriceByAreaOffer} />

        <SquareMeterPriceByAreaSaleChart statistic={squareMeterPriceByAreaSale} />

        <MarketTrendByAreaOfferChart statistic={marketTrendAreaGroupsOffer} />

        <MarketTrendByAreaSaleChart statistic={marketTrendAreaGroupsSale} />
      </>
    );
  }

  switch (chartToDisplay) {
    case "countWohnungen":
      return (
        <CountWohnungenByYearChart statistic={countWohnungenByYear} />
      );
    case "marketTrend":
      return (
        <>
          <MarketTrendOfferChart statistic={marketTrendOffer} />

          <MarketTrendSaleChart statistic={marketTrendSale} />
        </>
      );
    case "marketTrendByArea":
      return (
        <>
          <SquareMeterPriceByAreaOfferChart statistic={squareMeterPriceByAreaOffer} />

          <SquareMeterPriceByAreaSaleChart statistic={squareMeterPriceByAreaSale} />

          <MarketTrendByAreaOfferChart statistic={marketTrendAreaGroupsOffer} />

          <MarketTrendByAreaSaleChart statistic={marketTrendAreaGroupsSale} />
        </>
      );
    default:
      return null;
  }
};

export default ChartsToDisplay;
