import { dynamicYearRange } from "~/src/_common/_exports.js";

import { finishYearLabels } from "~/src/modules/labels.js";
import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 * A dynamic Finish Year Search Filter component
 *
 * @param {object} root0 - The root object
 * @param {string} root0.query - Comma-separated string of selected items
 * @param {string} root0.tempQuery - Comma-separated string of temporary selected items
 * @param {Function} root0.update - Callback to update query state
 * @param {number} root0.startYear - Start of the year range
 * @param {number} root0.endYear - End of the year range
 * @example
 */
export default function FinishYearSearchFilter({
  query,
  tempQuery,
  update
}) {
  const {
    endYear,
    startYear
  } = dynamicYearRange();

  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

  /**
   * Handles checkbox change
   *
   * @param {object} event - The checkbox change event
   * @param {string} key - The year being checked/unchecked
   * @example
   */
  function onCheck(event, key) {
    handleCheck("finish_year", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   * Determines if a checkbox is checked
   *
   * @param {string} item - Year in the main query
   * @param {string} temporaryItem - Year in the temporary query
   * @returns {boolean} - Checked status
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Fertigstellung <span className="font-light">(Bau)</span>
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        {years
          .map((year, index) => (
            <div key={year}>
              <label className="inline-flex cursor-pointer items-center">
                <input
                  checked={checked(items.includes(`${year}`), temporaryItems.includes(`${year}`))}
                  className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
                  onChange={(e) => onCheck(e, `${year}`)}
                  type="checkbox"
                />

                <span className="ml-3">
                  {finishYearLabels[year]}
                </span>
              </label>
            </div>
          ))
          .reverse()}
      </div>
    </div>
  );
}
