import {
  Status,
  Wrapper
} from "@googlemaps/react-wrapper";
import { useCallback } from "react";

import { loadingIcon } from "../_common/export.js";

import { GoogleMapsContainer } from "./google-maps/_exports.js";

/**
 *
 * @param props - The root object.
 * @param props.setIsStreetView - The root object.
 * @param props.isSimple - The root object.
 * @param props.projectSlugs - The root object.
 * @param props.searchLocation - The root object.
 * @param props.automated - The root object.
 * @example
 */
const GoogleMapsWrapper = ({
  automated,
  isSimple,
  projectSlugs,
  searchLocation,
  setIsStreetView
}) => {
  const render = useCallback(
    (status) => {
      switch (status) {
        case Status.LOADING:
          return loadingIcon;

        case Status.SUCCESS:
          return (
            <GoogleMapsContainer
              {...{
                automated,
                isSimple,
                projectSlugs,
                searchLocation,
                setIsStreetView
              }}
            />
          );
        case Status.FAILURE:
        default:
          return <span>FAILED</span>;
      }
    },
    [
      automated,
      isSimple,
      projectSlugs,
      searchLocation,
      setIsStreetView
    ]
  );

  return (
    <div className="size-full">
      <Wrapper
        apiKey={window.settings.GOOGLE_MAPS_API_KEY}
        render={render}
      />
    </div>
  );
};

export default GoogleMapsWrapper;
