import {
  Description,
  Dialog, DialogBackdrop, DialogPanel, DialogTitle
} from "@headlessui/react";

const ExportLimitModal = ({ isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      className="relative z-[150]"
      onClose={handleClose}
      open={isOpen}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex max-h-full w-full flex-col gap-5 bg-white p-6 sm:max-h-full sm:max-w-xl sm:rounded-lg"
        >
          <DialogTitle as="h3" className="flex w-full justify-center text-lg font-medium leading-6 text-gray-900">
            Export-Limit erreicht!
          </DialogTitle>

          <Description className="self-center text-sm text-gray-600">
            Ihr projektübergreifendes Export-Kontingent für den aktuellen Monat ist aufgebraucht. Zu Beginn des nächsten Monats stehen Ihnen die Export-Funktionen wieder zur Verfügung.
          </Description>

          <button
            className="self-start py-2 text-sm font-medium text-gray-500 underline hover:text-gray-800"
            onClick={handleClose}
            type="button"
          >
            Schließen
          </button>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ExportLimitModal;
