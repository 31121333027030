import React from "react";
import { useParams } from "react-router-dom";

import { formatNumberInt } from "~/src/modules/formatters";

import useMediaQuery from "~/src/hooks/use-media-query";
import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

import NoteElement from "./note-element";

/**
 *
 * @example
 */
// eslint-disable-next-line max-lines-per-function
const ProjectESGCertificates = () => {
  const { id } = useParams();

  const isMobile = useMediaQuery("(max-width: 1279px)");

  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    project_certificates: projectCertificates
  } = project;

  if (isMobile) {
    return (projectCertificates.length > 0)
      ? (
        <ContentSection id="esgCertificates" title="Gebäudezertifikate">
          {
            projectCertificates
              .map((certificate) => Object.fromEntries(Object.entries(certificate).filter(([key, value]) => value !== null)))
              .map(({
                id: certificateId,
                assigned_points: assignedPoints,
                issuer: {
                  max_points: maxPoints,
                  name: issuerName
                } = { },
                issuer_certificate: {
                  name: certificateName
                } = { },
                notes
              }) => (
                <div className="flex w-full flex-col gap-2" key={`certificate_${certificateId}`}>
                  <div className="grid grid-cols-3 gap-x-8">
                    <span className="col-span-1 font-bold md:col-span-1">Aussteller</span>

                    <span className="col-span-1 font-bold md:col-span-1">Zertifikat</span>

                    <span className="col-span-1 font-bold">Punkte</span>

                    <span className="col-span-1 break-words">
                      {(issuerName)
                        ? issuerName
                        : "-"}
                    </span>

                    <span className="col-span-1 break-words">
                      {(certificateName)
                        ? certificateName
                        : "-"}
                    </span>

                    <span className="col-span-1">
                      {(assignedPoints && maxPoints)
                        ? `${formatNumberInt(assignedPoints)}/${formatNumberInt(maxPoints)}`
                        : "-"}
                    </span>
                  </div>

                  <NoteElement id={`cert_note_${certificateId}`} notes={notes} />
                </div>

              ))
          }
        </ContentSection>
      )
      : (
        <ContentSection id="esgCertificates" title="Gebäudezertifikate">
          -
        </ContentSection>
      );
  }

  return (
    (projectCertificates.length > 0)
      ? (
        <ContentSection id="esgCertificates" title="Gebäudezertifikate">
          <div className="grid w-full grid-cols-5 gap-x-8 gap-y-0.5">
            <span className="col-span-1 font-bold md:col-span-1">Aussteller</span>

            <span className="col-span-1 font-bold md:col-span-1">Zertifikat</span>

            <span className="col-span-1 font-bold">Punkte</span>

            <span className="col-span-2 font-bold">Bemerkung</span>

            {
              projectCertificates
                .map((certificate) => Object.fromEntries(Object.entries(certificate).filter(([key, value]) => value !== null)))
                .map(({
                  id: certificateId,
                  assigned_points: assignedPoints,
                  issuer: {
                    max_points: maxPoints,
                    name: issuerName
                  } = {},
                  issuer_certificate: {
                    name: certificateName
                  } = {},
                  notes
                }) => (
                  <React.Fragment key={`certificate_${certificateId}`}>
                    <span className="col-span-1 break-words">
                      {(issuerName)
                        ? issuerName
                        : "-"}
                    </span>

                    <span className="col-span-1 break-words">
                      {(certificateName)
                        ? certificateName
                        : "-"}
                    </span>

                    <span className="col-span-1">
                      {(assignedPoints && maxPoints)
                        ? `${formatNumberInt(assignedPoints)}/${formatNumberInt(maxPoints)}`
                        : "-"}
                    </span>

                    <span className="col-span-2 md:whitespace-pre-line">
                      {(notes)
                        ? notes
                        : "-"}
                    </span>
                  </React.Fragment>
                ))
            }
          </div>
        </ContentSection>
      )
      : (
        <ContentSection id="esgCertificates" title="Gebäudezertifikate">
          -
        </ContentSection>
      )
  );
};

export default ProjectESGCertificates;
