import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { Fragment } from "react";
import { useController } from "react-hook-form";

const Option = ({
  label,
  selectedValue,
  value
}) => (
  <Listbox.Option
    as="li"
    value={value}
    className={({ active }) => {
      const selected = value === selectedValue;

      return cn(
        "relative cursor-pointer select-none py-2 pl-10 pr-4",
        {
          "bg-gray-200": active && !selected,
          "bg-gray-300": selected,
          white: !active && !selected
        }
      );
    }}
  >
    {() => {
      const selected = value === selectedValue;

      return (
        <>
          <span
            className={`block truncate ${selected ? "font-medium" : "font-normal"
            }`}
          >
            {label}
          </span>

          {selected
            ? (
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                <CheckIcon aria-hidden="true" className="size-5" />
              </span>
            )
            : null}
        </>
      );
    }}
  </Listbox.Option>
);

/**
 *
 * @param props - The root object
 * @param props.name - The root object
 * @param props.label - The root object
 * @param props.options - The root object
 * @param props.defaultValue - The root object
 * @param props.control - The root object
 * @param props.className - The root object
 * @param props.onChange - The root object
 * @param props.color - The root object
 * @example
 */
export default function Select({
  className,
  color = "red",
  control,
  defaultValue,
  label,
  name,
  onChange = () => { },
  options = [
    {
      label: "",
      value: ""
    }
  ]
}) {
  const {
    field: {
      onBlur, onChange: innerOnChange, ref, value
    },
    fieldState: {
      invalid, isDirty, isTouched
    },
    formState: { dirtyFields, touchedFields }
  } = useController({
    control,
    defaultValue,
    name
  });

  const flatOptions = options.flatMap((option) => (Array.isArray(option.value) ? option.value : option));

  const disabled = flatOptions.length === 0;

  return (
    <Listbox
      {...{
        disabled,
        onBlur,
        onChange: (e) => {
          innerOnChange(e);
          onChange(e);
        },
        value: value?.value ?? ""
      }}
    >
      <div className={cn("relative", className)}>
        {label && <Listbox.Label>{label}</Listbox.Label>}

        <Listbox.Button
          className={({ disabled }) => cn(
            "relative min-h-[41px] w-full not-disabled:cursor-pointer rounded bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-gray-200  outline outline-2 outline-offset-[-2px] outline-transparent cursor-pointer not-disabled:hover:border-gray-400 disabled:cursor-not-allowed disabled:opacity-50 disabled:shadow-none",
            {
              "ui-open:outline-primary": !disabled && color === "red",
              "ui-open:outline-primary-green-dark": !disabled && color === "green"
            }
          )}
        >
          <span className="block truncate">{flatOptions.find((option) => value === option.value)?.label ?? <span className="text-gray-400">Keine Preisdaten vorhanden</span>}</span>

          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="size-5 text-gray-400"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options as="ul" className="absolute z-50 mt-1 max-h-72 w-full overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {options
              .map((option, index) => {
                if (Array.isArray(option.value)) {
                  return (
                    <li className="flex flex-col" key={index}>
                      <span className="w-full bg-gray-100 px-4 py-2 text-sm font-medium">{option.label}</span>

                      <ul>
                        {
                          option.value
                            .filter(({ shown }) => shown)
                            .map((groupOption, groupOptionIndex) => (
                              <Option key={groupOptionIndex} label={groupOption.label} selectedValue={value} value={groupOption.value} />
                            ))
                        }
                      </ul>
                    </li>
                  );
                }

                if (option.shown) {
                  return (
                    <Option key={index} label={option.label} selectedValue={value} value={option.value} />
                  );
                }

                return null;
              })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
