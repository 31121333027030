import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { components } from "react-select";
import { default as Select } from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

import API from "~/src/modules/api";

/**
 *
 * @param root0 - The root object
 * @param root0.onUpdate - The root object
 * @example
 */
export default function InsolvencyCategorySelect({ onUpdate }) {
  const handleSelect = (selected) => {
    onUpdate(selected?.value, selected?.label);
  };

  return (
    <Select
      cacheOptions
      isClearable
      blurInputOnSelect={true}
      className="react-select"
      defaultOptions={true}
      instanceId="insolvency-category"
      isSearchable={false}
      loadOptions={handleLoadOptions}
      name="insolvency-category"
      noOptionsMessage={noOptionsMessage}
      onChange={handleSelect}
      placeholder="Verfahrensart"
      styles={customStyles}
      value=""
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input,
        LoadingIndicator: () => null
      }}
    />
  );
}

const loadOptions = async (inputValue, callback) => {
  try {
    const res = await API.get("/insolvency-categories");

    const options = res.data.payload.insolvencyCategories.map((cat) => ({
      body: {
        cat
      },
      label: `${cat.name}`,
      value: cat.id
    }));

    callback(options);
  }
  catch (error) {
    console.log("error", error);
  }
};

const loadOptionsDebounced = debounce(500, loadOptions);
const loadOptionsThrottled = throttle(500, loadOptions);

const handleLoadOptions = (inputValue) => new Promise((resolve, reject) => {
  if (inputValue.length < 4) {
    loadOptionsThrottled(inputValue, (options) => {
      resolve(options);
    });
  }
  else {
    loadOptionsDebounced(inputValue, (options) => {
      resolve(options);
    });
  }
});

const noOptionsMessage = (data) => {
  if (data.inputValue.length > 0) {
    return "Keine Vorschläge gefunden...";
  }

  return "Art der Änderung auswählen...";
};

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} className="pl-2">
      <RectangleStackIcon className="size-5 text-gray-400" /> {children}
    </components.Control>
  );
}

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: 44,
    "&:hover": {
      borderColor: state.isFocused ? "#9ca3af" : "#9ca3af"
    },
    borderColor: state.isFocused ? "#9ca3af" : "#e5e5e5",
    borderRadius: 2,
    borderWidth: state.isFocused ? "1px" : "1px",
    boxShadow: "state.isFocused ? \"0 0 0 20px rgba(56,178,172,1)\" : 0",
    height: 44
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af",
    fontSize: "16px"
  }),

  singleValue: (base) => ({
    ...base,
    background: "#822c42",
    borderRadius: "5px",
    color: "white",
    padding: "0 6px"
  })
};
