import { useEffect, useState } from "react";

import ChartError from "~/src/ui/charts/error";
import ChartLegend from "~/src/ui/charts/legend";
import MetaTooltip from "~/src/ui/charts/meta-tooltip";
import PieChart from "~/src/ui/charts/pie-chart";

import { transformToPieData } from "../../helpers";

/**
 *
 * @param props - The root object
 * @param props.statistic - The root object
 * @param props.isLoading - The root object
 * @param props.isError - The root object
 * @example
 */
export default function BuyersProvenanceChart({
  isError, isLoading, statistic
}) {
  const [displayWithChild, setDisplayWithChild] = useState(false);
  const [pieDataWithChild, setPieDataWithChild] = useState([]);
  const [pieData, setPieData] = useState([]);

  const changeDisplayWithChild = () => {
    if (displayWithChild) {
      setDisplayWithChild(false);
    }
    else {
      setDisplayWithChild(true);
    }
  };

  const createPieDataWithChild = () => {
    const withChild = pieData.map((sliceValue) => sliceValue?.sub ?? sliceValue);

    setPieDataWithChild(withChild.flat());
  };

  useEffect(() => {
    if (statistic !== undefined) {
      setPieData(transformToPieData(statistic, "buyers", { useTotalPercentage: true }));
    }
  }, [statistic]);

  useEffect(() => {
    if (pieData.length > 0) {
      createPieDataWithChild();
    }
  }, [pieData]);

  if (isError) {
    return (
      <div className="flex flex-col gap-4 rounded border border-gray-200 bg-white p-4 shadow-sm">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-medium">Personenart/Herkunft</h2>
        </div>

        <ChartError />
      </div>
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 rounded border border-gray-200 bg-white p-4 shadow-sm">
      <div className="flex items-center gap-2">
        <h2 className="text-xl font-medium">Personenart/Herkunft</h2>

        <MetaTooltip
          {...((pieData) && {
            meta: statistic.meta
          })}
        />
      </div>

      <div className="relative h-[450px] w-full">
        <div className="absolute h-[450px] w-full content-center">
          <PieChart
            changeDisplayWithChild={changeDisplayWithChild}
            data={(displayWithChild) ? pieData : pieDataWithChild}
            small={true}
            sortByValue={false}
          />
        </div>

        <div className="absolute h-[450px] w-full content-center">
          <PieChart
            changeDisplayWithChild={changeDisplayWithChild}
            data={(displayWithChild) ? pieDataWithChild : pieData}
            small={false}
            sortByValue={false}
          />
        </div>
      </div>

      <div className="w-full">
        <ChartLegend data={(displayWithChild) ? pieDataWithChild : pieData} />
      </div>
    </div>
  );
}
