import { useParams } from "react-router-dom";

import useProject from "~/src/hooks/use-project";

import ContentSection from "~/src/ui/sections/content-section";

/**
 *
 * @example
 */
const ProjectOverviewDescription = () => {
  const { id } = useParams();
  const {
    isError,
    project
  } = useProject(id);

  if (isError) {
    return null;
  }

  const {
    description
  } = project;

  return (
    <ContentSection id="description" title="Kurzbeschreibung">
      <p className="hyphens-auto text-justify">
        {(description)
          ? description
          : "-"}
      </p>
    </ContentSection>
  );
};

export default ProjectOverviewDescription;
