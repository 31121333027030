import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

/**
 *
 * @param options0 - The root object
 * @param options0.docId - The root object
 * @example
 */
export default function usePurchaseByDocumentId({ docId }) {
  // If docId is undefined, skip the useSWR hook
  const { data, error } = useSWR(docId ? `/purchases/${docId}` : null, fetcher);

  return {
    isError: Boolean(error),
    isLoading: !error && !data && docId !== undefined,
    purchase: data?.payload?.purchase
  };
}
