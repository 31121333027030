import cn from "classnames";

import { displayRechtsform } from "~/src/lib/project-interface.js";

import dateFormatter from "~/src/modules/date-formatter.js";
import { formatEuro } from "~/src/modules/formatters.js";
import {
  constructionPhaseLabels,
  liegenschaftLabels,
  marketingPhaseLabels,
  priceLabels
} from "~/src/modules/labels.js";

import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";

import ProjectCompany from "~/src/ui/displays/project-company/index.jsx";
import ProjectLocation from "~/src/ui/displays/project-location/index.jsx";
import CheckmarkCircleIcon from "~/src/ui/icons/checkmark-circle-icon/index.jsx";
import EsgIcon from "~/src/ui/icons/esg-icon/index.jsx";
import ProjectLink from "~/src/ui/links/project-link/index.jsx";
/* eslint-disable import/max-dependencies */
import { useEffect, useState } from "react";

/**
 *
 * @param props - The root object
 * @param props.project - The root object
 * @param props.handleCheckbox - The root object
 * @param props.handleSingleMapProject - The root object
 * @param props.watchlists - The root object
 * @param props.mutateWatchlists - The root object
 * @param props.disabled - The root object
 * @param props.mainSelectionType - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function, complexity
const ProjectListItemPrint4 = ({
  project
}) => {
  const { query: { sort } } = useProjectQueryParams();
  const [currentSortValue, setCurrentSortValue] = useState(null);
  const [showMultipleLocations, setShowMultipleLocations] = useState(false);

  useEffect(() => {
    if (sort) {
      setCurrentSortValue(sort);
    }
    else {
      setCurrentSortValue(null);
    }
  }, [sort]);

  const builders = project?.company_roles.filter((role) => role.name === "builder");
  const owners = project?.company_roles.filter((role) => role.name === "owner");
  const marketers = project?.company_roles.filter((role) => role.name === "marketer");

  const completed = project?.timeline?.current_construction_phase === "construction_phase_completed";
  let completedDate;

  if (completed) {
    completedDate = (project?.timeline?.construction_phase_completed_date !== null && project?.timeline?.construction_phase_completed_date_format !== null)
      ? dateFormatter(project?.timeline?.construction_phase_completed_date, project?.timeline?.construction_phase_completed_date_format)
      : project.finish_year;
  }

  if (!completed && project?.construction_infos?.estimated_finish_date) {
    completedDate = dateFormatter(project.construction_infos.estimated_finish_date, project.construction_infos?.estimated_finish_date_format);
  }

  const lastContractDate = project?.timeline?.last_contract_date ? dateFormatter(project.timeline.last_contract_date, "year") : null;

  return (
    <li className="mb-4 flex break-inside-avoid gap-4 rounded-lg border border-gray-200 p-2" key={project.slug} style={{ zoom: 0.7 }}>

      <div className="mb-2 flex w-3/5 items-start justify-between gap-4 pb-2">
        <div className="flex flex-col items-start gap-2 break-words">
          <div className="flex items-center gap-2 pl-0.5 text-sm">
            <ProjectLink backlink="projects" inNewTab={true} project={project}>
              <p className="inline-block font-medium text-primary">
                <span className="border-b-2 border-primary hover:text-primary-dark">{project.name}</span>
              </p>
            </ProjectLink>
          </div>

          <div>
            <div className="flex" key={`single_location_${location.id}`}>
              <ProjectLocation location={project.locations[0]} />
            </div>
          </div>

          <div className="overflow-hidden">
            {[
              ...owners,
              ...builders,
              ...marketers
            ].map((role, index) => <ProjectCompany automated={project?.automated} key={index} role={role} />)}
          </div>
        </div>

        <div>
          {project?.show_image_public && project?.image_file_name && (
            <ProjectLink backlink="projects" inNewTab={true} project={project}>
              <div className="relative mt-0.5 h-[7.5rem] w-48 overflow-hidden rounded-lg">
                <img alt={project?.name} className={cn("absolute object-cover w-full h-full transition duration-500 transform", { "hover:scale-110": !project?.automated })} src={`https://immodeveloper.fra1.digitaloceanspaces.com/${project?.image_file_name}`} />
              </div>
            </ProjectLink>
          )}
        </div>
      </div>

      <div className="col-span-4 w-2/5 text-xs">
        <div className="flex w-full items-center justify-between">
          <div
            className={cn(
              "flex items-center text-xs gap-1",
              {
                "text-gray-500 font-medium": sort !== "finishYear",
                "text-primary font-semibold": sort === "finishYear"
              }
            )}
          >
            <CheckmarkCircleIcon className="-ml-0.5 size-5" />

            <span className="text-xs">
              {
                project?.automated
                  ? `Historisches Projekt${lastContractDate ? ` - ${lastContractDate}` : ""}`
                  : (completedDate ?? "nicht bekannt")

              }
            </span>
          </div>

          {
            (project?.project_certificates && project?.project_certificates.length > 0)
              ? <EsgIcon className="!h-5 !w-5" innerClassName="!w-[14px] !h-3" />
              : null
          }
        </div>

        <div className="mt-2 flex flex-col">
          <div className="w-full">
            {(project?.timeline?.current_construction_phase && project.timeline.current_construction_phase !== "unknown") && (
              <div className="flex w-full justify-between">
                <div className="shrink-0">
                  <p className="font-semibold text-gray-600">Bauphase:</p>
                </div>

                <div>
                  <p>{constructionPhaseLabels[project.timeline.current_construction_phase || ""]}</p>
                </div>
              </div>
            )}

            {(project?.timeline?.current_marketing_phase && project.timeline.current_marketing_phase !== "unknown") && (
              <div className="flex w-full justify-between">
                <div className="shrink-0">
                  <p className="font-semibold text-gray-600">Vermarktungsphase:</p>
                </div>

                <div>
                  <p>{marketingPhaseLabels[project.timeline.current_marketing_phase || ""]}</p>
                </div>
              </div>
            )}
          </div>

          <div className="mt-2">

            {(project?.transaction_filter && liegenschaftLabels[project?.transaction_filter]) && (
              <div className="flex w-full justify-between">
                <div>
                  <p className="font-semibold text-gray-600">Liegenschaftsankauf: </p>
                </div>

                <div>
                  <p>{liegenschaftLabels[project.transaction_filter]}</p>
                </div>
              </div>
            )}

            <div className="flex w-full justify-between">
              <div>
                <p className="font-semibold text-gray-600">Rechtsform:</p>
              </div>

              <div>
                {displayRechtsform(project)}
              </div>
            </div>

            {
              project.count_wohnungen !== 0 && (
                <div className="flex w-full justify-between">
                  <div>
                    <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "countWohnungen" })}>
                      {project.automated ? "Erfasste Transaktionen" : "Gesamtanzahl Wohneinheiten"}:
                    </p>
                  </div>

                  <div>
                    <p className={cn("", { "text-primary font-semibold": currentSortValue === "countWohnungen" })}>
                      {project.count_wohnungen}
                    </p>
                  </div>
                </div>
              )
            }
          </div>

          {
            (project?.funding?.not_funded || project?.funding?.normal_funded) && (
              <div className="flex w-full justify-between">
                <div>
                  <p className={cn("font-semibold text-gray-600")}>
                    Förderung:
                  </p>
                </div>

                <div>
                  {project.funding.not_funded && project.funding.normal_funded &&
                    <p>gemischt</p>}

                  {!project.funding.not_funded && project.funding.normal_funded &&
                    <p>gefördert</p>}

                  {project.funding.not_funded && !project.funding.normal_funded &&
                    <p>freifinanziert</p>}
                </div>
              </div>
            )
          }

          {project?.gka && (
            <div className="flex w-full justify-between">
              <div>
                <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "gka" })}>
                  {priceLabels.gka}:
                </p>
              </div>

              <div>
                <p className={cn("", { "text-primary font-semibold": currentSortValue === "gka" })}>
                  {formatEuro(project?.gka)}/m²
                </p>
              </div>
            </div>
          )}

          {project?.avg_offer_price_eigennutzer && (
            <div className="flex w-full justify-between">
              <div>
                <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgOfferPriceEigennutzer" })}>
                  {priceLabels.averageOfferPriceNormal}:
                </p>
              </div>

              <div>
                <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgOfferPriceEigennutzer" })}>
                  {formatEuro(project?.avg_offer_price_eigennutzer)}/m²
                </p>
              </div>
            </div>
          )}

          {project?.avg_offer_price_investor && (
            <div className="flex w-full justify-between">
              <div>
                <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgOfferPriceEigennutzer" })}>
                  {priceLabels.averageOfferPriceInvestorNet}:
                </p>
              </div>

              <div>
                <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgOfferPriceEigennutzer" })}>
                  {formatEuro(project?.avg_offer_price_investor)}/m²
                </p>
              </div>
            </div>
          )}

          {project?.avg_miete && (
            <div className="flex w-full justify-between">
              <div>
                <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgMiete" })}>
                  {priceLabels.averageRentNet}:
                </p>
              </div>

              <div>
                <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgMiete" })}>
                  {formatEuro(project?.avg_miete)}/m²
                </p>
              </div>
            </div>
          )}

          {project?.avg_rent_bk && (
            <div className="flex w-full justify-between">
              <div>
                <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgMiete" })}>
                  {priceLabels.averageRentTotal}:
                </p>
              </div>

              <div>
                <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgMiete" })}>
                  {formatEuro(project?.avg_rent_bk)}/m²
                </p>
              </div>
            </div>
          )}

          {project?.avg_sale_price_eigennutzer && (
            <div className="flex w-full justify-between">
              <div>
                <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgSalePriceEigennutzer" })}>
                  {priceLabels.averageSalePriceNormal}:
                </p>
              </div>

              <div>
                <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgSalePriceEigennutzer" })}>
                  {formatEuro(project?.avg_sale_price_eigennutzer)}/m²
                </p>
              </div>
            </div>
          )}

          {project?.avg_sale_price_investor && (
            <div className="flex w-full justify-between">
              <div>
                <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgSalePriceEigennutzer" })}>
                  {priceLabels.averageSalePriceInvestorNet}:
                </p>
              </div>

              <div>
                <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgSalePriceEigennutzer" })}>
                  {formatEuro(project?.avg_sale_price_investor)}/m²
                </p>
              </div>
            </div>
          )}
        </div>

      </div>
    </li>
  );
};

export default ProjectListItemPrint4;
