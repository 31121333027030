import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.watchlist - The root object
 * @param root0.watchlistLength - The root object
 * @param root0.index - The root object
 * @param root0.project - The root object
 * @param root0.handleCheckbox - The root object
 * @example
 */
export default function WatchlistDropdownItem({
  handleCheckbox, index, project, watchlist, watchlistLength
}) {
  const watchlistProjectSlugs = watchlist.projects.map((p) => p.slug);
  const inWatchlist = watchlistProjectSlugs.includes(project?.slug);

  return (
    <label className="truncate" htmlFor={`watchlist-${watchlist.uuid}`} key={watchlist.uuid}>
      <div
        className={cn("relative flex items-start cursor-pointer bg-secondary-light py-2 px-4 rounded-lg", {
          "mb-4": (watchlistLength > 1 && watchlistLength === index + 1) || watchlistLength === 1,
          "mt-4": index === 0
        })}
      >
        <div className="flex h-5 cursor-pointer items-center">
          <input
            checked={inWatchlist}
            className="size-4 cursor-pointer rounded border-gray-300 text-primary focus:ring-0 focus:ring-transparent"
            id={`watchlist-${watchlist.uuid}`}
            name={`watchlist-${watchlist.uuid}`}
            onChange={(e) => handleCheckbox(e, watchlist.uuid)}
            type="checkbox"
          />
        </div>

        <div className="ml-3 truncate text-sm">
          <p className="cursor-pointer truncate font-medium text-gray-600">
            {watchlist.name}
          </p>
        </div>
      </div>
    </label>
  );
}
