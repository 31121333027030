import { ScaleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import priceTerms from "~/src/lib/price-terms.js";

import { NoValueDefinedLine, getPricePerSqm } from "./_common/_exports.js";
import { SqmSalePrice } from "./sqm-sale-prices/_exports.js";

const {
  gross: grossTerm,
  investment: investmentTerm,
  net: netTerm,
  normal: normalTerm
} = priceTerms;

/**
 *
 * @param root0 - The root object
 * @param root0.unit - The root object
 * @param root0.showCustom - The root object
 * @param root0.customArea - The root object
 * @example
 */
export default function SqmSalePrices({
  customArea, showCustom, unit
}) {
  const area = Number.isFinite(customArea) ? customArea : unit.sale_area;

  const prices = [];

  const sqmSalePriceExclUst = getPricePerSqm(unit.sale_price_net, area);
  const sqmSalePriceInclUst = getPricePerSqm(unit.sale_price_gross, area);

  if (sqmSalePriceExclUst !== null && sqmSalePriceInclUst !== null) {
    prices.push(
      <SqmSalePrice key="netto" label={`${investmentTerm} ${netTerm}`} value={sqmSalePriceExclUst} />,
      <SqmSalePrice key="brutto" label={`${investmentTerm} ${grossTerm}`} value={sqmSalePriceInclUst} />
    );
  }
  else if (sqmSalePriceExclUst !== null) {
    prices.push(
      <SqmSalePrice key="eigen" label={normalTerm} value={sqmSalePriceExclUst} />
    );
  }

  if (prices.length === 0) {
    prices.push(<NoValueDefinedLine />);
  }

  return (
    <div className="relative bg-gray-200 p-2 text-center text-sm text-gray-500">
      {showCustom && (Number.parseFloat(customArea).toFixed(2).toString() !== unit.sale_area) && (
        <div className="absolute right-2 top-2">
          <ScaleIcon
            className="-mt-0.5 size-6 rounded-full bg-primary p-0.5 text-white"
            data-tooltip-content="Preis / m² aus individueller Gewichtung berechnet"
            data-tooltip-id="tooltip-unit-sale-avg-price"
          />

          <Tooltip
            className="z-50"
            delayShow={500}
            id="tooltip-unit-sale-avg-price"
            place="bottom"
          />
        </div>
      )}

      <p>Preis / m²</p>

      <div className="mt-0.5 space-y-3">
        {prices}
      </div>
    </div>
  );
}
